import Validator, { ValidationTypes } from 'js-object-validation';
import { message } from '../messages';

export const loginValidator = (data: any) => {
  const validations = {
    email: {
      [ValidationTypes.REQUIRED]: true,
      [ValidationTypes.EMAIL]: true,
    },
    password: {
      [ValidationTypes.REQUIRED]: true,
      [ValidationTypes.MINLENGTH]: 6,
      [ValidationTypes.MAXLENGTH]: 18
    },
  };
  // Error messages
  const messages = {
    email: {
      [ValidationTypes.EMAIL]: message.InvalidEmail,
      [ValidationTypes.REQUIRED]: message.RequiredEmail,
    },
    password: {
      [ValidationTypes.REQUIRED]: message.RequiredPassword,
      [ValidationTypes.MINLENGTH]: message.MinLengthPassword,
      [ValidationTypes.MAXLENGTH]: message.MaxLengthPassword
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
