import React, { Fragment, PureComponent } from 'react';
import { IAddTermsOfServiceCMSProps, IAddTermsOfServiceCMSState, } from './interface';
import AddTermsOfServiceCMSComponent from './AddTermsOfServiceCMS.component';
import { asyncSetState } from '../../../settings/helpers/Common';
import { showSuccessToast, showErrorToast } from '../../../settings/helpers/toast';
import { ApiHelper } from '../../../settings/helpers/ApiHelper';
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { addTermsOfServiceValidator } from '../../../settings/helpers/validator';

class AddTermsOfServiceCMS extends PureComponent<IAddTermsOfServiceCMSProps, IAddTermsOfServiceCMSState> {
	state: IAddTermsOfServiceCMSState = {
		heading: '',
		content: '',
		errors: { question: '', content: '' },
		editorState: EditorState.createEmpty(),
		contentList: [],
		loading: true,
		isContentEdit: false,
		contentIndex: -1
	};

	componentDidMount = async () => {
		this.getTermsOfServiceCmsDetails();
	};

	// Function to change editor status
	onEditorStateChange = (editorState: any) => {
		const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
		this.setState({
			editorState,
			content: html,
			contentError: false
		});
	};


	// Function to get user details
	getTermsOfServiceCmsDetails = async () => {
		try {
			await asyncSetState(this)({ ...this.state, loading: true });
			const result = await new ApiHelper().FetchFromServer(
				'/superadmin',
				'/termsofservices/getTermsOfServiceCmsDetails',
				'GET',
				true,
				undefined,
				undefined
			);
			if (!result.isError) {
				const { data } = result;
				if (data.data.length > 0) {
					let details = data.data[0];
					await asyncSetState(this)({ ...this.state, loading: false, contentList: details.contentList });
				} else {
					await asyncSetState(this)({ ...this.state, loading: false });
				}
			}
		} catch (error) {
			await asyncSetState(this)({ ...this.state, loading: false });
		}
	};

	// Function to bind values
	handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		this.setState({
			...this.state,
			[name]: value,
			errors: {
				...this.state.errors,
				[name]: '',
			},
		});
	};

	// Function to add content
	onAddContent = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		let { heading, content } = this.state;
		heading = heading.trim();
		let payload = { heading, content };
		// To validate form fields
		const { isValid, errors } = addTermsOfServiceValidator(payload);
		if (isValid) {
			if (this.state.content.length <= 8) {
				this.setState({ contentError: true });
			} else {
				let contentList = [...this.state.contentList];
				if (this.state.isContentEdit) {
					contentList.map((t: any, i: number) => {
						if (this.state.contentIndex === i) {
							t.heading = payload.heading;
							t.content = payload.content;
						}
						return t;
					});
				} else {
					contentList.push(payload);
				}

				this.setState({
					contentList: contentList,
					contentError: false,
					isContentEdit: false,
					contentIndex: -1,
					heading: '',
					content: '',
					editorState: EditorState.createEmpty()
				});
			}
		} else {
			this.setState({
				errors
			});
			return;
		}
	};

	// update content
	handleUpdateContentList = async (item: any, index: number) => {
		const html = item.content;
		const contentBlock = htmlToDraft(html);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(
				contentBlock.contentBlocks
			);
			const editorState = EditorState.createWithContent(contentState);
			const content = draftToHtml(
				convertToRaw(editorState.getCurrentContent())
			);

			await asyncSetState(this)({
				...this.state,
				content,
				editorState,
				heading: item.heading,
				isContentEdit: true,
				contentIndex: index
			});
		}
	}

	// remove content
	handleRemoveContentList = async (index: number) => {
		this.setState({
			contentList: this.state.contentList.filter((t: any, i: number) => i !== index)
		});
	}

	// Function to update page details
	handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		this.setState({ isSubmitting: true });
		let { contentList } = this.state;

		try {
			const res = await new ApiHelper().FetchFromServer(
				'/superadmin',
				'/termsofservices/update',
				'POST',
				true,
				undefined,
				{ contentList }
			);

			const { data } = res;
			if (data.statusCode === 200) {
				showSuccessToast(res.data.message);
			} else {
				showErrorToast(res.data.message);
			}
			this.setState({ isSubmitting: false });
		} catch (error) {
			this.setState({ isSubmitting: false });
		}
	};

	render() {
		return (
			<Fragment>
				<AddTermsOfServiceCMSComponent
					{...this.state}
					handleChange={this.handleChange}
					handleSubmit={this.handleSubmit}
					onAddContent={this.onAddContent}
					handleUpdateContentList={this.handleUpdateContentList}
					handleRemoveContentList={this.handleRemoveContentList}
					onEditorStateChange={this.onEditorStateChange}
				/>
			</Fragment>
		);
	}
}

export default AddTermsOfServiceCMS;
