import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { IStudioDetailsComponentState } from './interface';
import { Row, Col, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

import close from '../../../assets/img/cancel.svg';
import closeblue from '../../../assets/img/cancel-blue.svg';
import { getFullAddress } from '../../../settings/helpers/constants';
// import { getFileIconFromExt } from '../../../settings/helpers/Common';

class StudioDetailsComponent extends Component<IStudioDetailsComponentState> {
  state: IStudioDetailsComponentState = {};

  render() {
    const { studioDetails } = this.props;
    // const svgFile = getFileIconFromExt(this.props.studioDetails.certificateFileUrl);

    // console.log('studioDetails ', studioDetails);

    return (
      <Fragment>
        <Modal
          size={'xl'}
          show={this.props.isModelShow}
          onHide={this.props.onModelHide}
          aria-labelledby='contained-modal-title-vcenter'
          centered
          className='details-modal-wrapper'
        >
          <Modal.Header>
            <Modal.Title id='contained-modal-title-vcenter'>
              Details
            </Modal.Title>
            <button
              type='button'
              className='close modal-close'
              onClick={this.props.onModelHide}
              aria-label='Close'
            >
              <img src={close} alt='close' className='main-img' />
              <img src={closeblue} alt='close' className='hover-img' />
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className='custom-scrollbar'>
              {/* <Row>
								<Col md={6}>
									<label className='text-label'>Name</label>
								</Col>
								<Col md={6}>
									<span className='custom-text-value'>{studioDetails.name}</span>
								</Col>
							</Row> */}
              <Row>
                <Col md={6}>
                  <div className='d-flex'>
                    <label className='text-label'>Name</label>
                    <span className='custom-text-value'>
                      {studioDetails.name}
                    </span>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='d-flex'>
                    <label className='text-label'>Email</label>
                    <span className='custom-text-value'>
                      {studioDetails.email}
                    </span>
                  </div>
                </Col>
              </Row>
              {/* <Row>
										<Col md={4}>
											<label className='text-label'>Email</label>
										</Col>
										<Col md={8}>
											<span className='custom-text-value'>{studioDetails.email}</span>
										</Col>
									</Row> */}
              {/* <Row>
										<Col md={4}>
											<label className='text-label'>Contact</label>
										</Col>
										<Col md={8}>
											<span className='custom-text-value'>{studioDetails.contact}</span>
										</Col>
									</Row>
									<Row>
										<Col md={4}>
											<label className='text-label'>Birthday</label>
										</Col>
										<Col md={8}>
											<span className='custom-text-value'>{studioDetails.birthday}</span>
										</Col>
									</Row> */}
              <Row>
                <Col md={6}>
                  <div className='d-flex'>
                    <label className='text-label'>Contact</label>
                    <span className='custom-text-value'>
                      {studioDetails.contact}
                    </span>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='d-flex'>
                    <label className='text-label'>Birthday</label>
                    <span className='custom-text-value'>
                      {' '}
                      {moment(studioDetails.birthday).format('MM/DD/YYYY')}
                    </span>
                  </div>
                </Col>
              </Row>
              {/* <Row>
										<Col md={4}>
											<label className='text-label'>Gender</label>
										</Col>
										<Col md={8}>
											<span className='custom-text-value'>{studioDetails.gender}</span>
										</Col>
									</Row>
									<Row>
										<Col md={4}>
											<label className='text-label'>Business Name</label>
										</Col>
										<Col md={8}>
											<span className='custom-text-value'>{studioDetails.studioName}</span>
										</Col>
									</Row> */}
              <Row>
                <Col md={6}>
                  <div className='d-flex'>
                    <label className='text-label'>Gender</label>
                    <span className='custom-text-value'>
                      {studioDetails.gender}
                    </span>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='d-flex'>
                    <label className='text-label'>Language</label>
                    <span className='custom-text-value'>
                      {studioDetails.language
                        ? studioDetails.language
                        : 'Not Provided'}
                    </span>
                  </div>
                </Col>
              </Row>
              {/* <Row>
										<Col md={4}>
											<label className='text-label'>Wellness Provider Email</label>
										</Col>
										<Col md={8}>
											<span className='custom-text-value'>{studioDetails.studioEmail}</span>
										</Col>
									</Row> */}
              {/* <Row>
										<Col md={4}>
											<label className='text-label'>Wellness Provider Contact</label>
										</Col>
										<Col md={8}>
											<span className='custom-text-value'>{studioDetails.studioContact}</span>
										</Col>
									</Row> */}
              {/* <Row>
										<Col md={4}>
											<label className='text-label'>Business Types</label>
										</Col>
										<Col md={8}>
											<span className='custom-text-value'>{studioDetails.businessType}</span>
										</Col>
									</Row>
									<Row>
										<Col md={4}>
											<label className='text-label'>Facebook Link</label>
										</Col>
										<Col md={8}>
											<span className='custom-text-value'>{studioDetails.facebookLink}</span>
										</Col>
									</Row> */}
              <Row>
                <Col md={6}>
                  <div className='d-flex'>
                    <label className='text-label'>Business Name</label>
                    <span className='custom-text-value'>
                      {studioDetails.studioName}
                    </span>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='d-flex'>
                    <label className='text-label'>Business Types</label>
                    <span className='custom-text-value'>
                      {studioDetails.businessType}
                    </span>
                  </div>
                </Col>
              </Row>
              {/* <Row>
										<Col md={4}>
											<label className='text-label'>Instagram Link</label>
										</Col>
										<Col md={8}>
											<span className='custom-text-value'>{studioDetails.instagramLink}</span>
										</Col>
									</Row> */}
              <Row>
                {/* {studioDetails.businessWebsite ? ( */}
                <Col md={6}>
                  <div className='d-flex'>
                    <label className='text-label'>Business Website</label>
                    <span className='custom-text-value'>
                      {studioDetails.businessWebsite
                        ? studioDetails.businessWebsite
                        : 'Not Provided'}
                    </span>
                  </div>
                </Col>
                {/* ) : null} */}
                {/* {studioDetails.facebookLink ? */}
                <Col md={6}>
                  <div className='d-flex'>
                    <label className='text-label'>Facebook Link</label>
                    <span className='custom-text-value'>
                      {studioDetails.facebookLink
                        ? studioDetails.facebookLink
                        : 'Not Provided'}
                    </span>
                  </div>
                </Col>
                {/* : null} */}
                {/* {studioDetails.instagramLinkf ? */}
                <Col md={6}>
                  <div className='d-flex'>
                    <label className='text-label'>Instagram Link</label>
                    <span className='custom-text-value'>
                      {studioDetails.instagramLink
                        ? studioDetails.instagramLink
                        : 'Not Provided'}
                    </span>
                  </div>
                </Col>
                {/* : null} */}
                {studioDetails.address ? (
                  <Col md={6}>
                    <div className='d-flex'>
                      <label className='text-label'>Business Address</label>
                      <span className='custom-text-value'>
                        {getFullAddress(studioDetails)}
                      </span>
                    </div>
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col md={6}>
                  <div className='d-flex'>
                    <label className='text-label'>Request&nbsp;Date</label>
                    <span className='custom-text-value'>
                      {moment(studioDetails.createdAt).format('LLL')}
                    </span>
                  </div>
                </Col>
                <Col md={6}>
                  <div className='d-flex'>
                    <label className='text-label'>Status</label>
                    <span className='custom-text-value'>
                      {studioDetails.status}
                    </span>
                    {/* <span
											className={
												'badge ' +
												(studioDetails.status === 'Active'
													? 'badge-success'
													: 'badge-danger')
											}
										>
											{studioDetails.status}
										</span> */}
                  </div>
                </Col>
              </Row>
              {studioDetails.certificate &&
              studioDetails.certificate === 'yes' ? (
                <>
                  <Row>
                    <Col md={6}>
                      <div className='d-flex'>
                        <label className='text-label'>Certification Name</label>
                        <span className='custom-text-value'>
                          {studioDetails.certificationName}
                        </span>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className='d-flex'>
                        <label className='text-label'>Institute</label>
                        <span className='custom-text-value'>
                          {studioDetails.institute}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className='d-flex'>
                        <label className='text-label'>Certificate</label>
                        <OverlayTrigger
                          popperConfig={{
                            modifiers: {
                              preventOverflow: {
                                enabled: false,
                              },
                            },
                          }}
                          placement={'top'}
                          overlay={
                            <Tooltip id='tooltip-view'>
                              View Certificate File
                            </Tooltip>
                          }
                        >
                          <span
                            className='custom-text-value link-text'
                            onClick={() => {
                              window.open(
                                studioDetails.certificateFileUrl,
                                '_blank',
                              );
                            }}
                          >
                            {/* <img width="24px"
														src={svgFile}
														alt=""
													/>
													{' '} */}
                            {studioDetails.certificateFile}
                          </span>
                        </OverlayTrigger>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <h6>
                  <b>No Certification details added</b>
                </h6>
              )}
              {/* {studioDetails.certificate && studioDetails.certificate === 'yes' ?
								<>
									<Row>
										<Col md={4}>
											<label className='text-label'>Certification Name</label>
										</Col>
										<Col md={8}>
											<span className='custom-text-value'>{studioDetails.certificationName}</span>
										</Col>
									</Row>
									<Row>
										<Col md={4}>
											<label className='text-label'>Institute</label>
										</Col>
										<Col md={8}>
											<span className='custom-text-value'>{studioDetails.institute}</span>
										</Col>
									</Row>
									<Row>
										<Col md={4}>
											<label className='text-label'>Certificate</label>
										</Col>
										<Col md={8}>
											<OverlayTrigger
												popperConfig={{
													modifiers: {
														preventOverflow: {
															enabled: false,
														},
													},
												}}
												placement={'top'}
												overlay={
													<Tooltip id='tooltip-view'>View Certificate File</Tooltip>
												}
											>
												<span className='custom-text-value link-text' onClick={() => { window.open(studioDetails.certificateFileUrl, '_blank') }}>
													<img width="24px"
														src={svgFile}
														alt=""
													/>
													{' '}
													{studioDetails.certificateFile}
												</span>
											</OverlayTrigger>
										</Col>
									</Row>
								</>
								: null} */}

              {/* <Row>
										<Col md={4}>
											<label className='text-label'>Request&nbsp;Date</label>
										</Col>
										<Col md={8}>
											<span className='custom-text-value'>
												{moment(studioDetails.createdAt).format('LLL')}
											</span>
										</Col>
									</Row>
									<Row>
										<Col md={4}>
											<label className='text-label'>Status</label>
										</Col>
										<Col md={8}>
											<label
												className={
													'badge ' +
													(studioDetails.status === 'Active'
														? 'badge-success'
														: 'badge-danger')
												}
											>
												{studioDetails.status}
											</label>
										</Col>
								
							</Row> */}
            </div>
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}

export default StudioDetailsComponent;
