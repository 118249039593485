import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { IContactUsDetailsComponentState } from './interface';
import {
	Row,
	Col,
	Modal,
	OverlayTrigger,
	Tooltip
} from 'react-bootstrap';

import close from "../../../assets/img/cancel.svg";
import closeblue from "../../../assets/img/cancel-blue.svg";
import { getFileIconFromExt } from '../../../settings/helpers/Common';
// import { getFileIconFromExt } from '../../../settings/helpers/Common';

class ContactUsDetailsComponent extends Component<IContactUsDetailsComponentState> {
	state: IContactUsDetailsComponentState = {};

	render() {
		const { contactUsDetails, isModelShow, onModelHide } = this.props;

		const svgFile = getFileIconFromExt(contactUsDetails.attachment);

		return (
			<Fragment>
				<Modal
					size={"xl"}
					show={isModelShow}
					onHide={onModelHide}
					aria-labelledby="contained-modal-title-vcenter"
					centered
					className='details-modal-wrapper'
				>
					<Modal.Header>
						<Modal.Title id="contained-modal-title-vcenter">Contact Us Details</Modal.Title>
						<button
							type="button"
							className="close modal-close"
							onClick={onModelHide}
							aria-label="Close"
						>
							<img src={close} alt="close" className="main-img" />
							<img src={closeblue} alt="close" className="hover-img" />
						</button>
					</Modal.Header>
					<Modal.Body>
						<div className='custom-scrollbar'>
							<Row>
								<Col md={12}>
									<Row>
										<Col md={4}>
											<label className='text-label'>Name</label>
										</Col>
										<Col md={8}>
											<span className='text-value'>{contactUsDetails.name}</span>
										</Col>
									</Row>
									<Row>
										<Col md={4}>
											<label className='text-label'>Email</label>
										</Col>
										<Col md={8}>
											<span className='text-value'>{contactUsDetails.email}</span>
										</Col>
									</Row>
									<Row>
										<Col md={4}>
											<label className='text-label'>Contact</label>
										</Col>
										<Col md={8}>
											<span className='text-value'>{contactUsDetails.contact}</span>
										</Col>
									</Row>
									{contactUsDetails.website ?
										<Row>
											<Col md={4}>
												<label className='text-label'>Website</label>
											</Col>
											<Col md={8}>
												<span className='text-value'>{contactUsDetails.website}</span>
											</Col>
										</Row>
										: null}
									<Row>
										<Col md={4}>
											<label className='text-label'>User Type</label>
										</Col>
										<Col md={8}>
											<span className='text-value text-capitalize'>{contactUsDetails.userType}</span>
										</Col>
									</Row>
									<Row>
										<Col md={4}>
											<label className='text-label'>Subject</label>
										</Col>
										<Col md={8}>
											<span className='text-value'>{contactUsDetails.subject}</span>
										</Col>
									</Row>
									<Row>
										<Col md={4}>
											<label className='text-label'>Request&nbsp;Date</label>
										</Col>
										<Col md={8}>
											<span className='text-value'>
												{moment(contactUsDetails.createdAt).format('LLL')}
											</span>
										</Col>
									</Row>
									{contactUsDetails.message ?
										<Row>
											<Col md={4}>
												<label className='text-label'>Message</label>
											</Col>
											<Col md={8}>
												<span className='text-value'>{contactUsDetails.message}</span>
											</Col>
										</Row>
										: null}
									{contactUsDetails.attachment && contactUsDetails.attachment !== 'null' ?
										<Row>
											<Col md={4}>
												<label className='text-label'>Attachment</label>
											</Col>
											<Col md={8}>
												<span className='text-value'>
													<OverlayTrigger
														popperConfig={{
															modifiers: {
																preventOverflow: {
																	enabled: false,
																},
															},
														}}
														placement={'top'}
														overlay={
															<Tooltip id='tooltip-view'>Attachment</Tooltip>
														}
													>
														<span className='text-value link-text' onClick={() => { window.open(contactUsDetails.attachmentUrl, '_blank') }}>
															<img width="24px"
																src={svgFile}
																alt=""
															/>
															{' '}
															{contactUsDetails.attachment}
														</span>
													</OverlayTrigger>
												</span>
											</Col>
										</Row>
										: null}
								</Col>
							</Row>
						</div>
					</Modal.Body>
				</Modal>
			</Fragment>
		)
	}
}

export default ContactUsDetailsComponent;
