import React, { Component } from 'react';
import { ISettingsProps, ISettingsState } from './interface';
import websiteSettingsIcon from '../../assets/img/web-setting.svg';
import websiteSettingsIconHover from '../../assets/img/web-setting-hover.svg';
import amenitiesIcon from '../../assets/img/amenities.svg';
import amenitiesIconHover from '../../assets/img/amenities-hover.svg';
import * as qs from 'query-string';
import WebsiteSettings from './WebsiteSettings';
import Amenities from './Amenities';

class Settings extends Component<ISettingsProps, ISettingsState> {
	constructor(props: any) {
		super(props);
		this.state = {
			component: WebsiteSettings,
			settingsActive: true,
			amenitiesActive: false
		};
	}

	componentDidMount = async () => {
		let query: any = { ...qs.parse(this.props.location.search) };
		let tab = 'amenities';
		if (query.tab === 'amenities') {
			tab = 'amenities';
			this.setState({
				component: Amenities,
				settingsActive: false,
				amenitiesActive: true,
			});
		}
		this.props.history.push(
			`/settings?${qs.stringify({
				tab: tab,
				page: 1,
				name: ''
			})}`
		);
	}

	// Function to change components tab
	changeComponent = async (cmp: any) => {
		let tab = 'settings';
		if (cmp === 'amenities') {
			tab = 'amenities';
		}

		this.props.history.push(
			`/settings?${qs.stringify({
				tab: tab,
				page: 1,
				name: ''
			})}`
		);

		if (cmp === 'amenities') {
			this.setState({
				component: Amenities,
				settingsActive: false,
				amenitiesActive: true
			});
		} else {
			this.setState({
				component: WebsiteSettings,
				settingsActive: true,
				amenitiesActive: false
			});
		}
	}

	render() {
		return (
			<div className="page-header-wrap" >
				<div className="page-header">
					<h3 className="page-title">
						<span className="heading-text">Settings</span>
					</h3>
				</div>
				<div className="nav-user-tabs d-flex align-items-center ">
					<div className={"nav-item " + (this.state.settingsActive ? "active" : null)} onClick={() => this.changeComponent('settings')}>
						<span className="icon">
							<img src={websiteSettingsIcon} alt="" className="activeIco" />
							<img src={websiteSettingsIconHover} alt="" className="nonactiveIco" />
						</span>
						<span>Settings</span>
					</div>
					<div className={"nav-item " + (this.state.amenitiesActive ? "active" : null)} onClick={() => this.changeComponent('amenities')}>
						<span className="icon">
							<img src={amenitiesIcon} alt="" className="activeIco" />
							<img src={amenitiesIconHover} alt="" className="nonactiveIco" />
						</span>
						<span>Amenities</span>
					</div>
				</div>
				<this.state.component {...this.props} />
			</div>
		);
	}
}

export default Settings;