import React, { Component } from 'react';
import moment from 'moment';
import { IContactUsComponentProps, IContactUsComponentState } from './interface';
import { Row, Col, Card, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import RCPagination from 'rc-pagination';
//@ts-ignore
import localeInfo from 'rc-pagination/lib/locale/en_US';
import searchIco from '../../../assets/img/magnifying-glass.svg';
import icon_empty from '../../../assets/img/Icon_Empty.svg';

class ContactUsComponent extends Component<IContactUsComponentProps, IContactUsComponentState> {
	render() {

		const { loading, contactUsName, messages, totalRecords, pageLimit, pageNumber, onSearchChange, onPageChange, firstRecordNo, lastRecordNo, onModelShow } = this.props;

		return (
			<Row>
				<Col lg={12} className="grid-margin">
					<Card>
						<Card.Header>
							<Card.Title>
								<span>Contact Us List</span>
							</Card.Title>
							<div className="table-header-actions header-actions">
								<div className="search-input-wrap">
									<input
										type="text"
										name="contactUsName"
										value={contactUsName}
										onChange={onSearchChange}
										placeholder="Search by user name"
										autoComplete="false"
										className="form-control"></input>
									<span className="search-icon">
										<img src={searchIco} alt="search" width="18px" />
									</span>
								</div>
							</div>
						</Card.Header>
						<Card.Body className="table-card-body">
							<div className="table-responsive-wrap">
								<table className="table">
									<thead>
										<tr>
											<th className="no-block">No.</th>
											<th className="studio-block">User Information</th>
											<th className="owner-block user-type">User Type</th>
											<th className="owner-block">Subject</th>
											<th className="paid-price-block">Message</th>
											<th className="paid-price-block">Created Date</th>
											<th className="action-block text-center">Action</th>
										</tr>
									</thead>
									<tbody>
										{loading ?
											<tr>
												<td colSpan={7}>
													<div className="d-flex justify-content-center align-items-center table-spinner">
														<Spinner animation="border" />
													</div>
												</td>
											</tr>
											: messages.length ?
												messages.map((item: any, index: any) => {
													return (
														<tr key={index}>
															<td>
																{index + 1 + (pageNumber - 1) * pageLimit}.
																</td>

															<td>
																<div className='info-column'>
																	<p className='mb-1 text-ellipsis' >
																		<span className="">
																			{item.name}
																		</span>
																	</p>
																	<p className='mb-0 text-ellipsis'>
																		<i className='fas fa-envelope mr-1  text-teal'></i>
																		<span className='mr-2  text-dark'>Email:</span>
																		<span>{item.email}</span>
																	</p>
																	<p className='mb-0 text-ellipsis'>
																		<i className='fas fa-phone-square mr-1  text-teal'></i>
																		<span className='mr-2  text-dark'>Contact:</span>
																		<span>{item.contact}</span>
																	</p>
																	{item.website ?
																		<p className='mb-0 text-ellipsis'>
																			<i className='fas fa-globe mr-1  text-teal'></i>
																			<span className='mr-2  text-dark'>Website:</span>
																			<span>{item.website}</span>
																		</p>
																		: null}
																</div>
															</td>
															<td className="text-capitalize">{item.userType}</td>
															<td>{item.subject}</td>
															<td>
																{item.message.length > 70
																	? item.message.substring(0, 70) +
																	'...'
																	: item.message}
															</td>
															<td>{moment(item.createdAt).format('MM/DD/YYYY')}</td>
															<td className="text-center">
																<div className='action-buttons'>
																	<OverlayTrigger
																		placement={'top'}
																		overlay={
																			<Tooltip id='tooltip-view'>View Details</Tooltip>
																		}
																	>
																		<span
																			className='btn-icon'
																			onClick={() => onModelShow(item)}
																			data-toggle="modal"
																		>
																			<i className='fas fa-eye'></i>
																		</span>
																	</OverlayTrigger>
																</div>
															</td>
														</tr>
													)
												})
												: (
													<tr>
														<td colSpan={6} className={"text-center"}>
															<div className={'no-result-block'}>
																<div className='empty-ico'>
																	<img alt='Icon_Empty.svg' width='50' src={icon_empty} />
																</div>
																<h5>No Records Found</h5>
															</div>
														</td>
													</tr>
												)}
									</tbody>
								</table>

							</div>
							<div className="table-pagination-wrap">
								<div>
									<RCPagination
										className="custom-pagination-design"
										current={pageNumber}
										total={totalRecords}
										defaultPageSize={pageLimit}
										pageSize={pageLimit}
										hideOnSinglePage={true}
										showPrevNextJumpers={true}
										showSizeChanger={true}
										pageSizeOptions={['5', '10', '25', '100']}
										onChange={onPageChange}
										locale={localeInfo}
									/>
								</div>
								{totalRecords > 0 ? (
									<div className="records-filter">
										<div className="records-number">
											Displaying {firstRecordNo} - {lastRecordNo} of{' '}
											{totalRecords} records{' '}
										</div>
									</div>
								) : null}
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		);
	}
}

export default ContactUsComponent;
