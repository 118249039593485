import React, { Component } from 'react';
import { IManageCMSProps, IManageCMSState } from './interface';
// import cmsIcon from '../../assets/img/manage-cms.svg';
// import cmsIconHover from '../../assets/img/manage-cms-hover.svg';
import faqIcon from '../../assets/img/manage-faq-help.svg';
import faqIconHover from '../../assets/img/manage-faq-help-hover.svg';
import homeCmsIcon from '../../assets/img/home-cms.svg';
import homeCmsIconHover from '../../assets/img/home-cms-hover.svg';
import privacyPolicyCmsIcon from '../../assets/img/privacy-policy-cms.svg';
import privacyPolicyCmsIconHover from '../../assets/img/privacy-policy-cms-hover.svg';
import termsOfServiceCmsIcon from '../../assets/img/terms-of-service-cms.svg';
import termsOfServiceCmsIconHover from '../../assets/img/terms-of-service-cms-hover.svg';
import aboutUsCmsIcon from '../../assets/img/aboutus-cms.svg';
import aboutUsCmsIconHover from '../../assets/img/aboutus-cms-hover.svg';
import * as qs from 'query-string';
import FAQ from './FAQ';
import AddHomeCMS from './HomeCMS';
import AddPrivacyPolicyCMS from './PrivacyPolicyCMS';
import AddAboutUsCMS from './AboutUsCMS';
import AddTermsOfServiceCMS from './TermsOfServiceCMS';

class ManageCMSFAQ extends Component<IManageCMSProps, IManageCMSState> {
	constructor(props: any) {
		super(props);
		this.state = {
			component: AddHomeCMS,
			faqActive: false,
			homeCmsActive: true,
			privacyPolicyCmsActive: false,
			termsOfServiceCmsActive: false,
			aboutUsCmsActive: false
		};
	}

	componentDidMount = async () => {
		let query: any = { ...qs.parse(this.props.location.search) };
		let tab = 'cms';
		if (query.tab === 'faq') {
			tab = 'faq';
			this.setState({
				component: FAQ,
				faqActive: true,
				homeCmsActive: false,
				privacyPolicyCmsActive: false,
				termsOfServiceCmsActive: false,
				aboutUsCmsActive: false
			});
		} else if (query.tab === 'privacy-policy-cms') {
			tab = 'privacy-policy-cms';
			this.setState({
				component: AddPrivacyPolicyCMS,
				faqActive: false,
				homeCmsActive: false,
				privacyPolicyCmsActive: true,
				termsOfServiceCmsActive: false,
				aboutUsCmsActive: false
			});
		} else if (query.tab === 'terms-of-service-cms') {
			tab = 'terms-of-service-cms';
			this.setState({
				component: AddTermsOfServiceCMS,
				faqActive: false,
				homeCmsActive: false,
				privacyPolicyCmsActive: false,
				termsOfServiceCmsActive: true,
				aboutUsCmsActive: false
			});
		} else if (query.tab === 'aboutus-cms') {
			tab = 'aboutus-cms';
			this.setState({
				component: AddAboutUsCMS,
				faqActive: false,
				homeCmsActive: false,
				privacyPolicyCmsActive: false,
				termsOfServiceCmsActive: false,
				aboutUsCmsActive: true
			});
		}
		this.props.history.push(
			`/manage-cms?${qs.stringify({
				tab: tab,
				page: 1,
				name: ''
			})}`
		);
	}

	// Function to change components tab
	changeComponent = async (cmp: any) => {
		let tab = 'home-cms';
		if (cmp === 'faq') {
			tab = 'faq';
		} else if (cmp === 'privacy-policy-cms') {
			tab = 'privacy-policy-cms';
		} else if (cmp === 'terms-of-service-cms') {
			tab = 'terms-of-service-cms';
		} else if (cmp === 'aboutus-cms') {
			tab = 'aboutus-cms';
		}
		this.props.history.push(
			`/manage-cms?${qs.stringify({
				tab: tab,
				page: 1,
				name: ''
			})}`
		);

		if (cmp === 'faq') {
			this.setState({
				component: FAQ,
				faqActive: true,
				homeCmsActive: false,
				privacyPolicyCmsActive: false,
				termsOfServiceCmsActive: false,
				aboutUsCmsActive: false
			});
		} else if (cmp === 'privacy-policy-cms') {
			this.setState({
				component: AddPrivacyPolicyCMS,
				faqActive: false,
				homeCmsActive: false,
				privacyPolicyCmsActive: true,
				termsOfServiceCmsActive: false,
				aboutUsCmsActive: false
			});
		} else if (cmp === 'terms-of-service-cms') {
			this.setState({
				component: AddTermsOfServiceCMS,
				faqActive: false,
				homeCmsActive: false,
				privacyPolicyCmsActive: false,
				termsOfServiceCmsActive: true,
				aboutUsCmsActive: false
			});
		} else if (cmp === 'aboutus-cms') {
			this.setState({
				component: AddAboutUsCMS,
				faqActive: false,
				homeCmsActive: false,
				privacyPolicyCmsActive: false,
				termsOfServiceCmsActive: false,
				aboutUsCmsActive: true
			});
		} else {
			this.setState({
				component: AddHomeCMS,
				faqActive: false,
				homeCmsActive: true,
				privacyPolicyCmsActive: false,
				termsOfServiceCmsActive: false,
				aboutUsCmsActive: false
			});
		}
	}

	render() {
		return (
			<div className="page-header-wrap" >
				<div className="page-header">
					<h3 className="page-title">
						<span className="heading-text">CMS</span>
					</h3>
				</div>
				<div className="nav-user-tabs d-flex align-items-center ">
					<div className={"nav-item " + (this.state.homeCmsActive ? "active" : null)} onClick={() => this.changeComponent('home-cms')}>
						<span className="icon">
							<img src={homeCmsIcon} alt="" className="activeIco" />
							<img src={homeCmsIconHover} alt="" className="nonactiveIco" />
						</span>
						<span>Home CMS</span>
					</div>
					<div className={"nav-item " + (this.state.privacyPolicyCmsActive ? "active" : null)} onClick={() => this.changeComponent('privacy-policy-cms')}>
						<span className="icon">
							<img src={privacyPolicyCmsIcon} alt="" className="activeIco" />
							<img src={privacyPolicyCmsIconHover} alt="" className="nonactiveIco" />
						</span>
						<span>Policy CMS</span>
					</div>
					<div className={"nav-item " + (this.state.termsOfServiceCmsActive ? "active" : null)} onClick={() => this.changeComponent('terms-of-service-cms')}>
						<span className="icon">
							<img src={termsOfServiceCmsIcon} alt="" className="activeIco" />
							<img src={termsOfServiceCmsIconHover} alt="" className="nonactiveIco" />
						</span>
						<span>Terms Of Service CMS</span>
					</div>
					<div className={"nav-item " + (this.state.aboutUsCmsActive ? "active" : null)} onClick={() => this.changeComponent('aboutus-cms')}>
						<span className="icon">
							<img src={aboutUsCmsIcon} alt="" className="activeIco" />
							<img src={aboutUsCmsIconHover} alt="" className="nonactiveIco" />
						</span>
						<span>About Us CMS</span>
					</div>
					<div className={"nav-item " + (this.state.faqActive ? "active" : null)} onClick={() => this.changeComponent('faq')}>
						<span className="icon">
							<img src={faqIcon} alt="" className="activeIco" />
							<img src={faqIconHover} alt="" className="nonactiveIco" />
						</span>
						<span>FAQ List</span>
					</div>
				</div>
				<this.state.component {...this.props}
				/>
			</div>
		);
	}
}

export default ManageCMSFAQ;