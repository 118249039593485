import React, { PureComponent } from 'react';
import { INavbarProps, INavbarStates } from './interface';
import NavbarComponent from './Navbar.component';
import { ApiHelper } from "../../settings/helpers/ApiHelper";
import * as constants from '../../settings/helpers/constants';
// import { FrontEndWebsite } from "../../settings/config";
import { showErrorToast } from '../../settings/helpers/toast';

class Navbar extends PureComponent<INavbarProps, INavbarStates> {
	state: INavbarStates = {
		loading: true,
		isAuthenticated: false,
		userDetails: {}
	};

	componentDidMount() {
		if (!localStorage.getItem(constants.adminId)) {
			this.props.history.push('/');
		} else {
			this.checkAuthentication();
		}
	}

	componentDidUpdate(location: any) {
		const { pathname } = location.location;
		if (pathname !== this.props.location.pathname) {
			if (!localStorage.getItem(constants.adminId)) {
				this.setState({ isAuthenticated: false });
				this.props.history.push('/');
			} else {
				this.checkAuthentication();
			}
		}
	}

	// Function to check authentication of super admin
	checkAuthentication = async () => {
		try {
			const res = await new ApiHelper().FetchFromServer("/superadmin", "/validate", "POST", true, undefined);
			if (res.data.statusCode === 200) {
				this.setState({
					isAuthenticated: true,
					loading: false,
					userDetails: res.data.data
				});
			} else {
				localStorage.removeItem('adminId');
				// localStorage.clear();
				// sessionStorage.clear();
				this.props.history.push('/');
				this.setState({
					isAuthenticated: false
				});
				showErrorToast(res.data.message);
			}
		} catch (error) {
		}
	};

	// Function to logout super admin
	onLogout = (e: any) => {
		e.preventDefault();
		localStorage.removeItem('adminId');
		// localStorage.clear();
		// sessionStorage.clear();
		this.props.history.push('/');
		this.setState({
			isAuthenticated: false
		});
	}

	// Function to view profile of super admin
	ViewProfile = (e: any) => {
		e.preventDefault();
		this.props.history.push('/profile');
	}

	// Function to go main app
	goToMainApp = (e: any) => {
		e.preventDefault();
		window.open('https://helloatma.com/home')
		this.props.history.push('/profile');
	}

	render() {
		return (
			<>
				{this.state.isAuthenticated ?
					<NavbarComponent
						onLogout={this.onLogout}
						ViewProfile={this.ViewProfile}
						goToMainApp={this.goToMainApp}
						userDetails={this.state.userDetails}
					/>
					: null}
			</>
		);
	}
}

export default Navbar;