import React, { Component, Fragment } from 'react';
import { IFinanceComponentProps, IFinanceState } from './interface';
import { Row, Col, Card, Dropdown, Spinner } from 'react-bootstrap';
import StudioIncome from './StudioIncome';
// import CategoryIncome from './CategoryIncome';
// import IncomeLineChart from './Charts/IncomeLineChart';

class FinanceComponent extends Component<IFinanceComponentProps, IFinanceState> {
	render() {
		const { incomeLoading, totalIncomes, onRevenueChange, revenueDateRange, onCommissionChange, commissionDateRange, revenueDataLoader, revenueData, handleModalShow, commissionData } = this.props;
		return (
			<Fragment>
				<Row>
					<Col lg={6}>
						<Row>
							<Col lg={12} className='grid-margin'>
								<Card>
									<Card.Header>
										<Card.Title>
											<span>Commission</span>
											<span className='green-font-color ml-2'>	{commissionData.commission}%</span>
										</Card.Title>
										<div className='table-pagination-wrap p-0'>
											<Dropdown onSelect={onCommissionChange} className="status-drodown">
												<Dropdown.Toggle variant id='dropdown-basic'>
													<span>{commissionDateRange}</span>
												</Dropdown.Toggle>
												<Dropdown.Menu>
													<Dropdown.Item eventKey='Today'>Today</Dropdown.Item>
													<Dropdown.Item eventKey='Last 7 Days'>
														Last 7 Days
											</Dropdown.Item>
													<Dropdown.Item eventKey='Last 1 Month'>
														Last 1 Month
											</Dropdown.Item>
													<Dropdown.Item eventKey='Last 3 Months'>
														Last 3 Months
											</Dropdown.Item>
													<Dropdown.Item eventKey='Last 6 Months'>
														Last 6 Months
											</Dropdown.Item>
													<Dropdown.Item eventKey='Last 1 Year'>
														Last 1 Year
											</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</div>
									</Card.Header>
									{incomeLoading ?
										<div className="d-flex justify-content-center align-items-center table-spinner custom-spinner-height">
											<Spinner animation="border" />
										</div>
										:
										<Card.Body className='gray-font-color'>
											<h1>
												{totalIncomes ? (
													<>${totalIncomes.toFixed(2)}</>
												) : (
														<>$0.00</>
													)}{" "}
											</h1>
											<div className="d-flex justify-content-between w-100">
												<span>{commissionDateRange} Commission</span>
												<span className="link-text text-right" onClick={() => { handleModalShow(); }}>Update Percentage</span>
											</div>
										</Card.Body>
									}
								</Card>
							</Col>
						</Row>
					</Col>
					<Col lg={6}>
						<Row>
							<Col lg={12} className='grid-margin'>
								<Card>
									<Card.Header>
										<Card.Title>
											<span>Total Income</span>
										</Card.Title>
										<div className='table-pagination-wrap p-0'>
											<Dropdown onSelect={onRevenueChange} className="status-drodown">
												<Dropdown.Toggle variant id='dropdown-basic'>
													<span>{revenueDateRange}</span>
												</Dropdown.Toggle>
												<Dropdown.Menu>
													<Dropdown.Item eventKey='Today'>Today</Dropdown.Item>
													<Dropdown.Item eventKey='Last 7 Days'>
														Last 7 Days
											</Dropdown.Item>
													<Dropdown.Item eventKey='Last 1 Month'>
														Last 1 Month
											</Dropdown.Item>
													<Dropdown.Item eventKey='Last 3 Months'>
														Last 3 Months
											</Dropdown.Item>
													<Dropdown.Item eventKey='Last 6 Months'>
														Last 6 Months
											</Dropdown.Item>
													<Dropdown.Item eventKey='Last 1 Year'>
														Last 1 Year
											</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</div>
									</Card.Header>
									{revenueDataLoader ?
										<div className="d-flex justify-content-center align-items-center table-spinner custom-spinner-height">
											<Spinner animation="border" />
										</div>
										:
										<>
											<Card.Body className='green-font-color'>
												<h1>
													{revenueData.total ? (
														<>${revenueData.total.toFixed(2)}</>
													) : (
															<>$0.00</>
														)}{" "}
												</h1>
												<span>{revenueDateRange} Income</span>
											</Card.Body>
											{/* <IncomeLineChart {...this.props} /> */}
										</>
									}
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
				<StudioIncome />
				{/* <CategoryIncome /> */}
			</Fragment>
		);
	}
}

export default FinanceComponent;
