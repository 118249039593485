import React, { Component, Fragment, ChangeEvent, FormEvent } from 'react';
import { ApiHelper } from "../../settings/helpers/ApiHelper";
import { ICustomerComponentProps, ICustomerComponentState } from './interface';
import CustomerTableComponent from './Customer.component';
import { asyncSetState, confirmBox, logger } from '../../settings/helpers/Common';
import * as qs from 'query-string';
import debounce from 'lodash/debounce';
import { showSuccessToast, showErrorToast } from '../../settings/helpers/toast';
import EditCustomer from './EditCustomer';

class Customer extends Component<ICustomerComponentProps, ICustomerComponentState> {
	constructor(props: any) {
		super(props);
		this.state = {
			customers: [],
			studios: [],
			studioManagerId: '',
			totalPages: 0,
			totalRecords: 0,
			pageNumber: 1,
			pageLimit: 100,
			name: "",
			status: "",
			firstRecordNo: 0,
			lastRecordNo: 0,
			loading: true,
			isModelShow: false,
			customerModelLoading: false,
			customerDetails: {}
		};
	}

	componentDidMount = async () => {
		await this.getAllCustomers();
		await this.getAllStudiosForSelect();
		this.onChangeDebounced = debounce(this.onChangeDebounced, 500);
	};

	componentDidUpdate = async (prevProps: ICustomerComponentProps) => {
		if (prevProps.location !== this.props.location) {
			await this.getAllCustomers();
		}
	};

	getAllStudiosForSelect = async () => {
		try {
			const result: any = await new ApiHelper().FetchFromServer(
				'/superadmin',
				'/studios/getAllStudiosForSelect',
				'GET',
				true,
				undefined,
				undefined
			);

			if (result.isError) {
				throw new Error(result.messages);
			}

			const { data } = result;
			if (data.statusCode === 200 && data.success) {
				this.setState({ studios: data.data });
			}
		} catch (error) {
			this.setState({ studios: [] });
		}
	};

	// Function to get all customers
	getAllCustomers = async () => {
		try {
			await asyncSetState(this)({ ...this.state, loading: true });

			const current = this.props.location.search;
			let search: any = {};
			search = { ...qs.parse(current) };
			let pageNumber = 1;
			let name = '';
			let status = '';
			let studioManagerId = '';

			const currentPage = this.state.pageNumber;
			if (search.page && parseInt(search.page) !== currentPage) {
				pageNumber = parseInt(search.page);
			}
			if (search.name) {
				name = search.name;
			}
			if (search.status) {
				status = search.status;
			}

			if (search.studioManagerId) {
				studioManagerId = search.studioManagerId;
			}

			await asyncSetState(this)({
				...this.state,
				pageNumber,
				name,
				status,
				studioManagerId
			});
            console.log(this.state.name,'this.state.name')
			const res: any = await new ApiHelper().FetchFromServer(
				'/superadmin',
				`/customers/getAllCustomers?page=${this.state.pageNumber}&limit=${this.state.pageLimit}&name=${this.state.name}&status=${this.state.status}&studioManagerId=${this.state.studioManagerId}`,
				'GET',
				true,
				undefined,
				undefined
			);

			if (res.data.data.length > 0) {
				this.setState({
					customers: res.data.data,
					totalRecords: res.data.totalCount,
					totalPages: res.data.totalPages,
					firstRecordNo: ((this.state.pageNumber - 1) * this.state.pageLimit) + 1,
					lastRecordNo: ((this.state.pageNumber - 1) * this.state.pageLimit) + res.data.data.length,
					loading: false
				});
			} else {
				this.setState({
					loading: false,
					customers: [],
					totalRecords: 0,
					totalPages: 0,
					firstRecordNo: 0,
					lastRecordNo: 0
				});
			}
		} catch (error) {
			this.setState({
				loading: false,
				customers: [],
				totalRecords: 0,
				totalPages: 0,
				firstRecordNo: 0,
				lastRecordNo: 0
			});
		}
	};

	// Function to change page number
	onPageChange = async (page: number) => {
		if (page !== this.state.pageNumber) {
			await this.props.history.push(
				`/customers?${qs.stringify({
					page: page,
					name: this.state.name,
					status: this.state.status,
					studioManagerId: this.state.studioManagerId
				})}`
			);
		}
	};

	// Function to search records
	onSearch = async (e: FormEvent<HTMLFormElement> | undefined) => {
		if (e) {
			e.preventDefault();
		}
		new ApiHelper().cancelRequest(); // Cancel previous pending request if any
		this.props.history.push(
			`/customers?${qs.stringify({
				page: 1,
				name: this.state.name,
				status: this.state.status,
				studioManagerId: this.state.studioManagerId
			})}`
		);
	};

	// Function for search change
	onSearchChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		await asyncSetState(this)({ ...this.state, [name]: value });
		await this.onChangeDebounced();
	};

	// Function to filter records by studio
	onFilterByStudio = async (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		await asyncSetState(this)({ ...this.state, [name]: value });
		await this.onSearch(undefined);
	};

	onChangeDebounced = async () => {
		await this.onSearch(undefined); // Delayed function, call api to get searched values values
	};

	sendResetPasswordLink = async (email: string) => {
		try {
			const { value } = await confirmBox({
				title: "Are you sure?",
				text: "Do you want to send reset password link to customer email",
				confirmButtonText: "Yes"
			});

			if (value) {
				const result: any = await new ApiHelper().FetchFromServer(
					'/commons',
					'/forgot',
					'POST',
					true,
					undefined,
					{ email }
				);

				if (result.isError) {
					throw new Error(result.messages);
				}

				const { data } = result;
				if (data.statusCode === 200 && data.success) {
					showSuccessToast(data.message);
				}
			} else {
				return;
			}
		} catch (error) {
			showErrorToast(error.message);
		}
	};

	fetchCustomerData = async (customerId: string) => {
		try {
			await asyncSetState(this)({ ...this.state, customerModelLoading: true });
			const result: any = await new ApiHelper().FetchFromServer(
				'/studiomanagers',
				'/customers/getCustomerDetails',
				'POST',
				true,
				undefined,
				{ customerId }
			);

			if (result.isError) {
				throw new Error(result.messages);
			}

			const { data } = result;
			if (data.statusCode === 200 && data.success) {
				if (data.data.length) {
					await asyncSetState(this)({
						...this.state,
						customerDetails: { ...data.data[0], avatar: null }
					});

					const avatar: File | null = data.data[0].profileImageUrl;
					const customerData: any = {
						...data.data[0],
						avatar,
					};
					await asyncSetState(this)({
						...this.state,
						customerDetails: customerData,
						customerModelLoading: false,
						isModelShow: true
					});
				}
			}
		} catch (error) {
			logger(error);
			this.setState(() => ({ ...this.state, customerModelLoading: false }));
		}
	};

	// Function to show model
	onModelShow = async (customerId: string) => {
		// await this.setState({ isModelShow: true, });
		this.fetchCustomerData(customerId)

	}
	// Function to hide model
	onModelHide = async () => {
		this.setState({ isModelShow: false });
	};

	render() {
		return (
			<Fragment>
				<CustomerTableComponent
					{...this.state}
					{...this.props}
					onPageChange={this.onPageChange}
					onSearchChange={this.onSearchChange}
					onFilterByStudio={this.onFilterByStudio}
					sendResetPasswordLink={this.sendResetPasswordLink}
					onModelShow={this.onModelShow}
				/>
				{this.state.isModelShow ?
					<EditCustomer {...this.state}
						onModelHide={this.onModelHide}
						getAllCustomers={this.getAllCustomers}
					/>
					: null}
			</Fragment>
		);
	}
}

export default Customer;