import { maskArray } from "react-text-mask";
import { IMaskResponse } from "../interfaces/constants.interface";

// LOCAL STORAGE KEYS
export const adminId = 'adminId';
export const userId = 'userId';
// export const rememberMe = 'rememberMe';
export const email = 'email';
export const password = 'password';
export const TIME_FORMAT = "hh:mm a"
export const getFullAddress = (item: any) => {
	let address = '';
	if (item) {
		address =
			(item.street ? item.street : '') + '' +
			(item.address ? item.address : '') + '' +
			(item.addressLine1 ? item.addressLine1 : '') + '' +
			(item.addressLine2 ? (', ' + item.addressLine2) : '') + '' +
			(item.city ? (', ' + item.city) : '') + '' +
			(item.state ? (', ' + item.state) : '') + '' +
			(item.zip ? (', ' + item.zip) : '') + '' +
			(item.country ? (', ' + item.country) : '')
	}
	return address;
}

export const dateFormat = 'MM/DD/YYYY';

export const phoneMask: maskArray = [
	"(", /[0-9]/, /[0-9]/, /[0-9]/, ")", " ",
	/[0-9]/, /[0-9]/, /[0-9]/,
	"-",
	/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/
];

export const contactValidator = (phoneString: string): IMaskResponse => {
	if (!/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/.test(phoneString)) {
		return {
			isValid: false,
			message: 'Please enter a valid number'
		};
	}
	return {
		isValid: true,
		message: 'Number is valid'
	};
};