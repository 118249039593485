import React, { Component, Fragment } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { IChangePasswordProps, IChangePasswordState } from "./interface";
import { passwordValidator } from "../../../settings/helpers/validator";
import { showErrorToast, showSuccessToast } from "../../../settings/helpers/toast";
import { ApiHelper } from "../../../settings/helpers/ApiHelper";
import close from "../../../assets/img/cancel.svg";
import closeblue from "../../../assets/img/cancel-blue.svg";

class ChangePassword extends Component<
  IChangePasswordProps,
  IChangePasswordState
  > {
  constructor(props: any) {
    super(props);
    this.state = {
      errors: { newPassword: "", confirmPassword: "" },
      newPassword: "",
      confirmPassword: "",
      isSubmitting: false
    };
  }

  // Function to bind values
  handleChange = (e: any) => {
    const { name, value } = e.target;
    this.setState({
      ...this.state,
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: ""
      }
    });
  };

  // Function to change password of provider
  onPasswordChange = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    const { newPassword, confirmPassword } = this.state;
    const payload = { newPassword, confirmPassword };

    // To validate form fields
    const { isValid, errors } = passwordValidator(payload);
    if (isValid) {
      try {
        const res = await new ApiHelper().FetchFromServer(
          "/superadmin",
          "/users/changePassword",
          "POST",
          true,
          undefined,
          {
            newPassword: newPassword,
            userId: this.props.userId
          }
        );
        const { data } = res;
        if (data.statusCode === 200) {
          showSuccessToast(res.data.message);
          this.props.onModelHide();
        } else {
          showErrorToast(res.data.message);
        }
        this.setState({
          isSubmitting: false,
          newPassword: "",
          confirmPassword: ""
        });
      } catch (error) {
        this.setState({
          isSubmitting: false,
          newPassword: "",
          confirmPassword: ""
        });
      }
    } else {
      this.setState({
        errors,
        isSubmitting: false
      });
      return;
    }
  };

  render() {
    const { newPassword, confirmPassword, errors, isSubmitting } = this.state;

    return (
      <Fragment>
        <Modal
          show={this.props.isModelShow}
          onHide={this.props.onModelHide}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Change Password
            </Modal.Title>
            <button
              type="button"
              className="close modal-close"
              onClick={this.props.onModelHide}
              aria-label="Close"
            >
              <img src={close} alt="close" className="main-img" />
              <img src={closeblue} alt="close" className="hover-img" />
            </button>
          </Modal.Header>
          <Form
            onSubmit={this.onPasswordChange}
            noValidate={true}
            className="form-section"
          >
            <Modal.Body>
              <Form.Group controlId="newpassword">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="**********"
                  aria-describedby="newpassword"
                  value={newPassword}
                  name="newPassword"
                  onChange={this.handleChange}
                  isValid={newPassword && !errors.newPassword}
                  isInvalid={errors.newPassword}
                  autoComplete="new-password"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.newPassword}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="confirmpassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="**********"
                  aria-describedby="confirmpassword"
                  value={confirmPassword}
                  name="confirmPassword"
                  onChange={this.handleChange}
                  isValid={confirmPassword && !errors.confirmPassword}
                  isInvalid={errors.confirmPassword}
                  autoComplete="new-password"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Form.Group className="d-flex justify-content-end">
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className="save-btn"
                >
                  Change Password
                </Button>
              </Form.Group>
              <Form.Group className="d-flex justify-content-end">
                <Button
                  type="button"
                  variant="link"
                  className="btn btn-secondary"
                  onClick={this.props.onModelHide}
                >
                  Close
                </Button>
              </Form.Group>
            </Modal.Footer>
          </Form>
        </Modal>
      </Fragment>
    );
  }
}

export default ChangePassword;
