import React, { Component, Fragment } from "react";
import { Form, Button, Modal, Row, Col } from "react-bootstrap";
import { IEditCustomerProps, IEditCustomerState } from "./interface";
import { showErrorToast, showSuccessToast } from "../../settings/helpers/toast";
import { ApiHelper } from "../../settings/helpers/ApiHelper";
import defaultImage from '../../assets/img/upload-ic.svg'
import close from "../../assets/img/cancel.svg";
import closeblue from "../../assets/img/cancel-blue.svg";
import { blobUrl, imageValidateAndSave } from "../../settings/helpers/Common";
import { AcceptedImageFormat } from "../../settings/config";
import Flatpickr from 'react-flatpickr';
import { customerValidator } from "../../settings/helpers/validator";
import MaskedInput from 'react-text-mask';
import { phoneMask, contactValidator } from "../../settings/helpers/constants";

class EditCustomer extends Component<
  IEditCustomerProps,
  IEditCustomerState
  > {
  constructor(props: any) {
    super(props);
    this.state = {
      errors: { name: "", email: "", contact: "", gender: "" },
      avatar: null,
      name: "",
      email: "",
      contact: "",
      birthday: "",
      gender: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      customerId: "",
      oldProfileImage: "",
      avatarErrorMsg: "",
      avatarError: false,
      isSubmitting: false,
      loading: true
    };
  }

  componentDidMount = async () => {
    const { customerDetails } = this.props;
    console.log('customerDetails ', customerDetails);
    this.setState({
      avatar: customerDetails.avatar || null,
      name: customerDetails.name || '',
      email: customerDetails.email || '',
      birthday: customerDetails.birthday || '',
      contact: customerDetails.contact || '',
      gender: customerDetails.gender || '',
      address: customerDetails.address || '',
      city: customerDetails.city || '',
      state: customerDetails.state || '',
      zip: customerDetails.zip || '',
      country: customerDetails.country || '',
      customerId: customerDetails._id,
      oldProfileImage: customerDetails.profileImage || '',
    });

  }

  // Function to bind values
  handleChange = (e: any) => {
    const { name, value } = e.target;
    this.setState({
      ...this.state,
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: ""
      }
    });
  };

  // Function to set field Values of image
  setFieldValue = (name: string, obj: any) => {
    if (obj) {
      if (!obj.name.match(/.(jpg|JPG|jpeg|JPEG|bmp|BMP|gif|GIF|png|PNG)$/i)) {
        this.setState({
          [name]: null,
          [name + 'Error']: true,
          [name + 'ErrorMsg']: 'Only PNG, JPG, JPEG, BMP & GIF are accepted',
        });
      } else {
        this.setState({
          [name]: obj,
          [name + 'Error']: false,
          [name + 'ErrorMsg']: '',
        });
      }
    }
  };

  // Function to set field error of image
  setFieldError = (name: string, value: any) => {
    if (value) {
      this.setState({
        [name]: null,
        [name + 'Error']: true,
        [name + 'ErrorMsg']: value,
      });
    }
  };

  // Function to bind values
  handleBirthdayChange = (name: string, value: any) => {
    this.setState({
      ...this.state,
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: '',
      },
    });
  };

  // Function to update customer details
  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ isSubmitting: true });

    let { avatar, name, email, contact, gender, birthday, address, city, state, zip, country, customerId, oldProfileImage } = this.state;
    name = name.trim();
    gender = gender.trim();
    const payload = { avatar, name, email, contact, gender, birthday, address, city, state, zip, country, customerId, oldProfileImage };

    // To validate form fields
    let { isValid, errors } = customerValidator(payload);

    let isContactValid = await contactValidator(contact);

    if (isValid) {
      isValid = isContactValid.isValid;
      errors.contact = isContactValid.message;
    }

    if (isValid) {
      try {

        let file: FormData = new FormData();
        Object.entries(payload).forEach(([key, value]) => {
          switch (key) {
            case 'avatar':
              file.append('profileImage', value);
              break;

            default:
              file.append(key, value);
              break;
          }
        });

        const res = await new ApiHelper().FetchFromServer(
          '/studiomanagers',
          '/customers/update',
          'POST',
          true,
          undefined,
          file
        );
        const { data } = res;
        if (data.statusCode === 200) {
          showSuccessToast(res.data.message);
          this.props.getAllCustomers();
          this.props.onModelHide();
        } else {
          showErrorToast(res.data.message);
        }
        this.setState({ isSubmitting: false });
      } catch (error) {
        this.setState({ isSubmitting: false });
      }
    } else {
      this.setState({
        errors,
        isSubmitting: false
      });
      return;
    }

  };

  render() {
    const {
      avatar,
      name,
      email,
      contact,
      birthday,
      gender,
      address,
      city,
      state,
      zip,
      country,
      avatarError,
      avatarErrorMsg,
      errors,
      isSubmitting
    } = this.state;

    const avatarUrl = avatar && blobUrl(avatar);

    return (
      <Fragment>
        <Modal
          size="xl"
          show={this.props.isModelShow}
          onHide={this.props.onModelHide}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit Customer Details
            </Modal.Title>
            <button
              type="button"
              className="close modal-close"
              onClick={this.props.onModelHide}
              aria-label="Close"
            >
              <img src={close} alt="close" className="main-img" />
              <img src={closeblue} alt="close" className="hover-img" />
            </button>
          </Modal.Header>
          <Form
            onSubmit={this.handleSubmit}
            noValidate={true}
            className="form-section"
          >
            <Modal.Body>

              <Row>
                {/* LEFT SECTION */}
                <Col lg={6}>
                  <Row>
                    <Col lg={12}>
                      <Form.Group controlId='profilepic'>
                        <Form.Label>Profile image</Form.Label>
                        <label className='file-input-upload profile-wrap half-profile '>
                          <div className='file-input-inner-upload text-center'>
                            {avatarUrl ? (
                              <div className='img-preview customer-edit-popup'>
                                <img
                                  src={`${avatarUrl}`}
                                  alt=''
                                />
                              </div>
                            ) : (
                                <>
                                  <img
                                    src={defaultImage}
                                    alt='upload'
                                  />
                                  <h6>Select file to upload profile image</h6>
                                  <p className='mb-0'>
                                    200 x 200px ideal for hi-res
															</p>
                                </>
                              )}
                            <Form.Control
                              type='file'
                              placeholder=''
                              aria-describedby='avatar'
                              name='avatar'
                              onChange={(event: any) => {
                                imageValidateAndSave(
                                  event.currentTarget.files[0],
                                  this.setFieldValue,
                                  this.setFieldError,
                                  'avatar',
                                  200,
                                  200
                                );
                              }}
                              accept={AcceptedImageFormat.join()}
                            />
                          </div>
                        </label>
                        <p className="editor-invalid-feedback">
                          {avatarError ? avatarErrorMsg : ''}
                        </p>
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group controlId='customername'>
                        <Form.Label>
                          Name
												<span className='required-field'> *</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter customer name'
                          aria-describedby='customername'
                          name='name'
                          value={name}
                          onChange={this.handleChange}
                          autoComplete='name'
                        />
                        <div className='invalid-feedback'
                          style={
                            errors.name ? { display: 'block' } : { display: 'none' }
                          }
                        >
                          {errors.name}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group controlId='customeremail'>
                        <Form.Label>
                          Email<span className='required-field'> *</span>
                        </Form.Label>
                        <Form.Control
                          disabled
                          type='email'
                          placeholder='Enter email'
                          aria-describedby='email'
                          name='email'
                          value={email}
                          onChange={this.handleChange}
                          autoComplete='email'
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                {/* LEFT SECTION */}

                {/* RIGHT SECTION */}
                <Col lg={6}>
                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId='customernumber'>
                        <Form.Label>
                          Contact number<span className='required-field'> *</span>
                        </Form.Label>
                        <MaskedInput
                          className="form-control"
                          name={"contact"}
                          placeholder={'(999) 999-9999'}
                          mask={phoneMask}
                          onChange={this.handleChange}
                          value={contact}
                        />
                        <div className='invalid-feedback'
                          style={
                            errors.contact ? { display: 'block' } : { display: 'none' }
                          }
                        >
                          {errors.contact}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group controlId='birthday'>
                        <Form.Label>Birthday</Form.Label>
                        <div className='custom-datepick-design'>
                          <Flatpickr
                            value={birthday}
                            className='form-control'
                            options={{
                              enableTime: false,
                              altInput: true,
                              altFormat: 'F j, Y',
                              dateFormat: 'Y-m-d',
                              minDate: new Date(
                                new Date().setFullYear(
                                  new Date().getFullYear() - 60
                                )
                              ),
                              maxDate: new Date(
                                new Date().setFullYear(
                                  new Date().getFullYear() - 18
                                )
                              ),
                            }}
                            onChange={(date: any) =>
                              this.handleBirthdayChange(
                                'birthday',
                                new Date(new Date(date).setHours(0, 0, 0, 0))
                              )
                            }
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.birthday}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="gender">
                        <Form.Label>Gender <span className='required-field'> *</span></Form.Label>
                        <div className="d-flex mt-2">
                          <Form.Check
                            type="radio"
                            label="Male"
                            name="gender"
                            value={'male'}
                            checked={gender === 'male'}
                            id="gender1"
                            className="mr-3"
                            onChange={this.handleChange}
                          />
                          <Form.Check
                            type="radio"
                            label="Female"
                            name="gender"
                            value={'female'}
                            checked={gender === 'female'}
                            id="gender2"
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className='invalid-feedback'
                          style={
                            errors.gender ? { display: 'block' } : { display: 'none' }
                          }
                        >
                          {errors.gender}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group controlId='address'>
                        <Form.Label>Street</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter street'
                          aria-describedby='address'
                          name='address'
                          value={address}
                          onChange={this.handleChange}
                          autoComplete='address'
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.address}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group controlId='city'>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter city'
                          aria-describedby='city'
                          name='city'
                          value={city}
                          onChange={this.handleChange}
                          autoComplete='city'
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.city}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group controlId='state'>
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter state'
                          aria-describedby='state'
                          name='state'
                          value={state}
                          onChange={this.handleChange}
                          autoComplete='state'
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.state}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={6}>
                      <Form.Group controlId='zip'>
                        <Form.Label>Zip</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter zip'
                          aria-describedby='zip'
                          name='zip'
                          value={zip}
                          onChange={this.handleChange}
                          autoComplete='zip'
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.zip}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group controlId='country'>
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Country'
                          aria-describedby='country'
                          name='country'
                          value={country}
                          onChange={this.handleChange}
                          autoComplete='country'
                        />
                        <Form.Control.Feedback type='invalid'>
                          {errors.country}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                {/* RIGHT SECTION */}
              </Row>

            </Modal.Body>
            <Modal.Footer>
              <Form.Group className="d-flex justify-content-end">
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className="save-btn"
                >
                  Update
                </Button>
              </Form.Group>
              <Form.Group className="d-flex justify-content-end">
                <Button
                  type="button"
                  variant="link"
                  className="btn btn-secondary"
                  onClick={this.props.onModelHide}
                >
                  Close
                </Button>
              </Form.Group>
            </Modal.Footer>
          </Form>
        </Modal>
      </Fragment>
    );
  }
}

export default EditCustomer;
