import React, { Fragment, PureComponent } from 'react';
import { IAddHomeCMSProps, IAddHomeCMSState, } from './interface';
import AddHomeCMSComponent from './AddHomeCMS.component';
import { blobImageUrl, getImageBlob } from '../../../settings/helpers/Common';
import { showSuccessToast, showErrorToast } from '../../../settings/helpers/toast';
import { ApiHelper } from '../../../settings/helpers/ApiHelper';
// import { profileUpdateValidator } from '../../../settings/helpers/validator';


class AddHomeCMS extends PureComponent<IAddHomeCMSProps, IAddHomeCMSState> {
	state: IAddHomeCMSState = {
		bannerCover: null,
		// logo: null,
		bannerHeading: '',
		bannerDescription: '',
		visualCover1: null,
		visualCover2: null,
		visualDescription: '',
		visualButtonLink: '',
		serviceCover1: null,
		serviceCover2: null,
		serviceMainDescription: '',
		serviceHeading1: '',
		serviceButtonLink1: '',
		serviceDescription1: '',
		serviceHeading2: '',
		serviceButtonLink2: '',
		serviceDescription2: '',

		bannerCoverError: false,
		// logoError: false,
		visualCover1Error: false,
		visualCover2Error: false,
		serviceCover1Error: false,
		serviceCover2Error: false,

		// logoErrorMsg: '',
		visualCover1ErrorMsg: '',
		visualCover2ErrorMsg: '',
		serviceCover1ErrorMsg: '',
		serviceCover2ErrorMsg: '',
		bannerCoverErrorMsg: '',


		clientDescription: '',

		clientName: '',
		riderectLink: '',
		clientTestimonial: '',
		clientFileName: '',
		clientProfile: null,
		clientProfileError: false,
		clientProfileErrorMsg: '',
		testimonials: [],

		loading: true,
		isTestimonialEdit: false,
		testimonialIndex: -1
	};


	componentDidMount = async () => {
		this.getHomePageCmsDetails();
	};


	// Function to get user details
	getHomePageCmsDetails = async () => {
		try {
			const result = await new ApiHelper().FetchFromServer(
				'/superadmin',
				'/homepagecms/getHomePageCmsDetails',
				'GET',
				true,
				undefined,
				undefined
			);

			if (!result.isError) {
				const { data } = result;
				if (data.data.length > 0) {
					let details = data.data[0];
					this.setState({
						// logo: await getImageBlob(details.logo),
						// bannerCover: await getImageBlob(details.bannerCover),
						// visualCover1: await getImageBlob(details.visualCover1),
						// visualCover2: await getImageBlob(details.visualCover2),
						// serviceCover1: await getImageBlob(details.serviceCover1),
						// serviceCover2: await getImageBlob(details.serviceCover2),
						bannerCover: details.bannerCoverUrl,
						visualCover1: details.visualCover1Url,
						visualCover2: details.visualCover2Url,
						serviceCover1: details.serviceCover1Url,
						serviceCover2: details.serviceCover2Url,
						bannerHeading: details.bannerHeading,
						bannerDescription: details.bannerDescription,
						visualDescription: details.visualDescription,
						visualButtonLink: details.visualButtonLink,
						serviceMainDescription: details.serviceMainDescription,
						serviceHeading1: details.serviceHeading1,
						serviceButtonLink1: details.serviceButtonLink1,
						serviceDescription1: details.serviceDescription1,
						serviceHeading2: details.serviceHeading2,
						serviceButtonLink2: details.serviceButtonLink2,
						serviceDescription2: details.serviceDescription2,
						clientDescription: details.clientDescription,
						testimonials: details.testimonials,
					});
				} else {
					this.setState({
						loading: false
					});
				}
			}
		} catch (error) {
			this.setState({
				loading: false
			});
		}
	};

	// Function to bind values
	handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		this.setState({
			...this.state,
			[name]: value,
			errors: {
				...this.state.errors,
				[name]: '',
			},
		});
	};

	// update testimonial
	handleUpdateTestimonial = async (item: any, index: number) => {
		let { clientName, riderectLink, clientTestimonial, clientProfile } = item;
		if (clientProfile && !clientProfile.name) {
			clientProfile = await getImageBlob(clientProfile);
		}

		let clientFileName = '';
		if (clientProfile && clientProfile.name) {
			clientFileName = clientProfile.name;
		}

		this.setState({ ...this.state, clientName, riderectLink, clientTestimonial, clientProfile, clientFileName, isTestimonialEdit: true, testimonialIndex: index });
	}

	// save testimonial
	handleSaveTestimonial = async (index: number) => {
		let { clientName, riderectLink, clientTestimonial, clientProfile, clientFileName } = this.state;
		const clientProfileUrl = clientProfile && blobImageUrl(clientProfile);
		let obj = { clientName, riderectLink, clientTestimonial, clientProfile, clientProfileUrl, clientFileName };
		let testimonials = [...this.state.testimonials];
		testimonials.map((t: any, i: number) => {
			if (index === i) {
				t.clientName = obj.clientName;
				t.riderectLink = obj.riderectLink;
				t.clientTestimonial = obj.clientTestimonial;
				t.clientProfile = obj.clientProfile;
				t.clientProfileUrl = obj.clientProfileUrl;
				t.clientFileName = obj.clientFileName;
			}
			return t;
		});
		this.setState({
			testimonials: testimonials,
			isTestimonialEdit: false,
			clientName: '',
			riderectLink: '',
			clientTestimonial: '',
			clientProfile: '',
			clientProfileUrl: '',
			testimonialIndex: -1,
			clientFileName: ''
		});
	}

	// remove testimonial
	handleRemoveTestimonial = async (index: number) => {
		this.setState({
			testimonials: this.state.testimonials.filter((t: any, i: number) => i !== index)
		});
	}

	// add testimonial
	handleAddTestimonial = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		let { clientName, riderectLink, clientTestimonial, clientProfile, clientFileName } = this.state;
		const clientProfileUrl = clientProfile && blobImageUrl(clientProfile);
		let obj = { clientName, riderectLink, clientTestimonial, clientProfile, clientProfileUrl, clientFileName };
		let testimonials = [...this.state.testimonials];
		testimonials.push(obj);
		this.setState({
			testimonials: testimonials,
			isTestimonialEdit: false,
			clientName: '',
			riderectLink: '',
			clientTestimonial: '',
			clientProfile: '',
			clientProfileUrl: '',
			clientFileName: ''
		});
	};

	// Function to update home page cms details
	handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		this.setState({ isSubmitting: true });

		let {
			bannerCover,
			// logo,
			bannerHeading,
			bannerDescription,
			visualCover1,
			visualCover2,
			visualDescription,
			visualButtonLink,
			serviceCover1,
			serviceCover2,
			serviceMainDescription,
			serviceHeading1,
			serviceButtonLink1,
			serviceDescription1,
			serviceHeading2,
			serviceButtonLink2,
			serviceDescription2,
			clientDescription,
			testimonials
		} = this.state;

		const payload = {
			bannerCover,
			// logo,
			bannerHeading,
			bannerDescription,
			visualCover1,
			visualCover2,
			visualDescription,
			visualButtonLink,
			serviceCover1,
			serviceCover2,
			serviceMainDescription,
			serviceHeading1,
			serviceButtonLink1,
			serviceDescription1,
			serviceHeading2,
			serviceButtonLink2,
			serviceDescription2,
			clientDescription,
			testimonials
		};



		// // To validate form fields
		// let { isValid, errors } = profileUpdateValidator(payload);

		// if (this.state.avatarError) {
		// 	isValid = false;
		// 	this.setState({ avatarErrorMsg: 'Profile image is required' })

		// }

		// if (isValid) {
		try {
			// let formData = new FormData();
			// if (this.state.avatar) {
			// 	formData.append('profileImage', this.state.avatar);
			// }

			let formData: FormData = new FormData();

			if (payload.testimonials.length) {
				payload.testimonials.map((k: any, i: number) => {
					return formData.append(`file-${i}`, k.clientProfile);
				});
			}

			Object.entries(payload).forEach(([key, value]) => {
				switch (key) {
					case 'testimonials':
						formData.append('testimonials', JSON.stringify(payload.testimonials));
						break;
					default:
						formData.append(key, value);
						break;
				}
			});

			const res = await new ApiHelper().FetchFromServer(
				'/superadmin',
				'/homepagecms/update',
				'POST',
				true,
				undefined,
				formData
			);
			const { data } = res;
			if (data.statusCode === 200) {
				showSuccessToast(res.data.message);
				// this.setState({ userDetails: res.data.data[0], currentPassword: '', newPassword: '', confirmPassword: '' })
			} else {
				showErrorToast(res.data.message);
			}
			this.setState({ isSubmitting: false });
		} catch (error) {
			this.setState({ isSubmitting: false });
		}
		// } else {
		// 	this.setState({
		// 		errors,
		// 		isSubmitting: false
		// 	});
		// 	return;
		// }
	};

	// Function to set field Values of image
	setFieldValue = (name: string, obj: any) => {
		if (obj) {
			if (!obj.name.match(/.(jpg|JPG|jpeg|JPEG|bmp|BMP|gif|GIF|png|PNG)$/i)) {
				this.setState({
					[name]: null,
					[name + 'Error']: true,
					[name + 'ErrorMsg']: 'Only PNG, JPG, JPEG, BMP & GIF are accepted',
				});
				if (name === 'clientProfile') {
					this.setState({ clientFileName: '' });
				}
			} else {
				this.setState({
					[name]: obj,
					[name + 'Error']: false,
					[name + 'ErrorMsg']: '',
				});
				if (name === 'clientProfile') {
					this.setState({ clientFileName: obj.name });
				}
			}
		}
	};

	// Function to set field error of image
	setFieldError = (name: string, value: any) => {
		if (value) {
			this.setState({
				[name]: null,
				[name + 'Error']: true,
				[name + 'ErrorMsg']: value,
			});
		}
	};

	render() {
		return (
			<Fragment>
				<AddHomeCMSComponent
					{...this.state}
					handleChange={this.handleChange}
					handleSubmit={this.handleSubmit}
					setFieldValue={this.setFieldValue}
					setFieldError={this.setFieldError}
					handleAddTestimonial={this.handleAddTestimonial}
					handleUpdateTestimonial={this.handleUpdateTestimonial}
					handleSaveTestimonial={this.handleSaveTestimonial}
					handleRemoveTestimonial={this.handleRemoveTestimonial}
				/>
			</Fragment>
		);
	}
}

export default AddHomeCMS;
