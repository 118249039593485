import React, { Component, Fragment, FormEvent, ChangeEvent } from 'react';
import { ApiHelper } from "../../../settings/helpers/ApiHelper";
import { IAmenitiesComponentProps, IAmenitiesComponentState } from './interface';
import AmenitiesComponent from './Amenities.component';
import { amenitiesSaveValidator } from '../../../settings/helpers/validator';
import { confirmBox, asyncSetState, getImageBlob } from "../../../settings/helpers/Common";
import { showErrorToast, showSuccessToast } from '../../../settings/helpers/toast';
import * as qs from 'query-string';
import debounce from 'lodash/debounce';

class Amenities extends Component<IAmenitiesComponentProps, IAmenitiesComponentState> {
	constructor(props: any) {
		super(props);
		this.state = {
			logo: null,
			amenity: '',
			errors: { amenity: '' },
			name: '',
			logoError: false,
			logoErrorMsg: '',
			isSubmitting: false,
			amenities: [],
			totalPages: 0,
			totalRecords: 0,
			pageNumber: 1,
			pageLimit: 100,
			firstRecordNo: 0,
			lastRecordNo: 0,
			loading: true,
			ids: [],
			checked: false,
			isEdit: false,
			amenityId: ''
		};
	}

	componentDidMount = async () => {
		await this.getAllAmenities();
		this.onChangeDebounced = debounce(this.onChangeDebounced, 500);
	}

	componentDidUpdate = async (prevProps: IAmenitiesComponentProps) => {
		if (prevProps.location !== this.props.location) {
			await this.getAllAmenities();
		}
	};

	// function to save amenity
	onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		this.setState({ isSubmitting: true });
		let { amenity, logo, isEdit, amenityId } = this.state;
		amenity = amenity.trim();
		const payload = { amenity, logo, amenityId };
		// To validate form fields
		let { isValid, errors } = amenitiesSaveValidator(payload);
		if (isValid) {
			try {

				let formData: FormData = new FormData();
				Object.entries(payload).forEach(([key, value]) => {
					formData.append(key, value);
				});

				let apiUrl = 'save';
				if (isEdit) {
					apiUrl = 'update';
				}

				const res = await new ApiHelper().FetchFromServer(
					'/superadmin',
					'/amenities/' + apiUrl,
					'POST',
					true,
					undefined,
					formData
				);

				const { data } = res;
				if (data.statusCode === 200) {
					showSuccessToast(res.data.message);
					this.getAllAmenities();
					this.setState({ amenity: '', logo: null, isEdit: false });
				} else {
					showErrorToast(res.data.message);
				}
				this.setState({ isSubmitting: false });
			} catch (error) {
				this.setState({ isSubmitting: false });
			}
		} else {
			this.setState({
				errors,
				isSubmitting: false
			});
			return;
		}
	};

	// Update amenity status
	onUpdateStatus = async (item: any) => {
		try {
			const { value } = await confirmBox({
				title: "Are you sure?",
				text: `Do you want to ${
					item.status === 'Active' ? 'inactivate' : 'activate'
					} this amenity?`,
				confirmButtonText: `Yes, ${
					item.status === 'Active' ? 'inactivate' : 'activate'
					} it`,
			});

			if (value) {
				if (item.status === 'Active') {
					item.status = 'Inactive'
				} else {
					item.status = 'Active'
				}

				const res = await new ApiHelper().FetchFromServer(
					"/superadmin",
					"/amenities/updateStatus",
					"POST",
					true,
					undefined,
					item
				);

				if (res.data.statusCode === 200) {
					showSuccessToast(res.data.message);
					this.setState(
						{
							pageNumber: this.state.pageNumber,
							isEdit: false,
							amenity: '',
						},
						() => {
							this.getAllAmenities();
						}
					);
				} else {
					showErrorToast(res.data.message);
					this.getAllAmenities();
				}
			} else {
				return;
			}
		} catch (error) { }
	};

	// Update function
	// update = async (item: any) => {
	// 	const res = await new ApiHelper().FetchFromServer(
	// 		"/superadmin",
	// 		"/amenities/updateStatus",
	// 		"POST",
	// 		true,
	// 		undefined,
	// 		item
	// 	);

	// 	if (res.data.statusCode === 200) {
	// 		showSuccessToast(res.data.message);
	// 		this.setState(
	// 			{
	// 				pageNumber: this.state.pageNumber,
	// 				isEdit: false,
	// 				amenity: '',
	// 			},
	// 			() => {
	// 				this.getAllAmenities();
	// 			}
	// 		);
	// 	} else {
	// 		showErrorToast(res.data.message);
	// 		this.getAllAmenities();
	// 	}
	// };

	// Recet form function
	onCancel = () => {
		this.setState({
			isEdit: false,
			amenity: '',
			logo: null,
			errors: ''
		})
	}

	// Function to remove amenity
	onAmenitiesRemove = async (amenityIds: any) => {
		let obj = {};
		let text = "Do you want to delete this amenity?";
		if (typeof amenityIds === 'string') {
			obj = {
				ids: [amenityIds]
			}
		} else {
			obj = {
				ids: this.state.ids
			}
			text = "Do you want to delete selected amenities?";
		}

		const { value } = await confirmBox({
			title: "Are you sure?",
			text: text
		});
		if (value) {
			try {
				const res = await new ApiHelper().FetchFromServer(
					"/superadmin",
					"/amenities/remove",
					"POST",
					true,
					undefined,
					obj
				);

				if (res.data.statusCode === 200) {
					showSuccessToast(res.data.message);
					this.setState({ ids: [], checked: false },
						() => {
							this.getAllAmenities();
						}
					);
				}
			} catch (error) { }
		} else {
			return;
		}
	};

	// Get all amenities
	getAllAmenities = async () => {
		try {
			await asyncSetState(this)({ ...this.state, loading: true });

			const current = this.props.location.search;
			let search: any = {};
			search = { ...qs.parse(current) };
			let pageNumber = 1;
			let name = '';
			let status = '';

			const currentPage = this.state.pageNumber;
			if (search.page && parseInt(search.page) !== currentPage) {
				pageNumber = parseInt(search.page);
			}
			if (search.name) {
				name = search.name;
			}
			if (search.status) {
				status = search.status;
			}

			await asyncSetState(this)({
				...this.state,
				pageNumber,
				name,
				status
			});

			const res: any = await new ApiHelper().FetchFromServer(
				'/superadmin',
				`/amenities/getAllAmenities?page=${this.state.pageNumber}&limit=${this.state.pageLimit}&name=${this.state.name}&status=${this.state.status}`,
				'GET',
				true,
				undefined,
				undefined
			);

			if (res.data.data.length > 0) {
				this.setState({
					amenities: res.data.data,
					totalRecords: res.data.totalCount,
					totalPages: res.data.totalPages,
					firstRecordNo: ((this.state.pageNumber - 1) * this.state.pageLimit) + 1,
					lastRecordNo: ((this.state.pageNumber - 1) * this.state.pageLimit) + res.data.data.length,
					loading: false
				});
			} else {
				this.setState({
					loading: false,
					amenities: [],
					totalRecords: 0,
					totalPages: 0,
					firstRecordNo: 0,
					lastRecordNo: 0
				});
			}
		} catch (error) {
			this.setState({
				loading: false,
				amenities: [],
				totalRecords: 0,
				totalPages: 0,
				firstRecordNo: 0,
				lastRecordNo: 0
			});
		}
	};

	// Function for page change
	onPageChange = async (page: number) => {
		if (page !== this.state.pageNumber) {
			await this.props.history.push(
				`/settings?${qs.stringify({
					tab: "amenities",
					page: page,
					name: this.state.name,
					status: this.state.status
				})}`
			);
		}
	};

	// Function for search records
	onSearch = async (e: FormEvent<HTMLFormElement> | undefined) => {
		if (e) {
			e.preventDefault();
		}
		new ApiHelper().cancelRequest(); // Cancel previous pending request if any
		await this.props.history.push(
			`/settings?${qs.stringify({
				tab: "amenities",
				page: 1,
				name: this.state.name,
				status: this.state.status
			})}`
		);
	};

	onSearchChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		await asyncSetState(this)({ ...this.state, [name]: value });
		await this.onChangeDebounced();
	};

	// Function for filter records
	onFilter = async (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		await asyncSetState(this)({ ...this.state, [name]: value });
		await this.onSearch(undefined);
	};

	onChangeDebounced = async () => {
		await this.onSearch(undefined); // Delayed function, call api to get searched values values
	};

	// Function to bind values
	handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		this.setState({
			...this.state,
			[name]: value,
			errors: {
				...this.state.errors,
				[name]: '',
			},
		});
	};

	// Function for check all checkbox
	handleCheckAllCheckBox = (e: any) => {
		const { checked } = e.target;
		const { amenities } = this.state;
		if (!checked) {
			this.setState({
				ids: [],
			});
		} else {
			const checkBoxIds = [];
			for (let i = 0; i < amenities.length; i++) {
				const element = amenities[i];
				checkBoxIds.push(element._id);
			}
			this.setState({
				ids: checkBoxIds,
			});
		}
	};

	// Function for check box change
	handleCheckboxChange = (e: any) => {
		const { checked, value } = e.target;
		const { ids } = this.state;
		if (checked) {
			ids.push(value);
		} else {
			var index = ids.indexOf(value);
			if (index !== -1) {
				ids.splice(index, 1);
			}
		}
		this.setState({
			ids,
		});
	};

	// Function for update
	onAmenitiesUpdate = async (item: any) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		let { logo, name, _id } = item;
		logo = await getImageBlob(logo);
		this.setState({ ...this.state, logo, amenity: name, isEdit: true, amenityId: _id });
	}

	// Function to set field Values of image
	setFieldValue = (name: string, obj: any) => {
		if (obj) {
			if (!obj.name.match(/.(jpg|JPG|jpeg|JPEG|bmp|BMP|gif|GIF|png|PNG)$/i)) {
				this.setState({
					[name]: null,
					[name + 'Error']: true,
					[name + 'ErrorMsg']: 'Only PNG, JPG, JPEG, BMP & GIF are accepted',
				});
			} else {
				this.setState({
					[name]: obj,
					[name + 'Error']: false,
					[name + 'ErrorMsg']: '',
				});
			}
		}
	};

	render() {
		return (
			<Fragment>
				<AmenitiesComponent
					{...this.state}
					handleChange={this.handleChange}
					onSubmit={this.onSubmit}
					onCancel={this.onCancel}
					onPageChange={this.onPageChange}
					onSearchChange={this.onSearchChange}
					onFilter={this.onFilter}
					onUpdateStatus={this.onUpdateStatus}
					onAmenitiesRemove={this.onAmenitiesRemove}
					handleCheckAllCheckBox={this.handleCheckAllCheckBox}
					handleCheckboxChange={this.handleCheckboxChange}
					onAmenitiesUpdate={this.onAmenitiesUpdate}
					setFieldValue={this.setFieldValue}

				/>
			</Fragment>
		);
	}
}

export default Amenities;