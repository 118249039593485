import React, { Fragment, PureComponent } from 'react';
import { IAddAboutUsCMSProps, IAddAboutUsCMSState, } from './interface';
import AddAboutUsCMSComponent from './AddAboutUsCMS.component';
import { showSuccessToast, showErrorToast } from '../../../settings/helpers/toast';
import { ApiHelper } from '../../../settings/helpers/ApiHelper';
import { aboutUsCmsValidator } from '../../../settings/helpers/validator';


class AddAboutUsCMS extends PureComponent<IAddAboutUsCMSProps, IAddAboutUsCMSState> {
	state: IAddAboutUsCMSState = {
		bannerCover: null,
		mainDescription: '',
		cardCover1: null,
		cardHeading1: '',
		cardDescription1: '',
		cardCover2: null,
		cardHeading2: '',
		cardDescription2: '',
		cardCover3: null,
		cardHeading3: '',
		cardDescription3: '',
		cardCover4: null,
		cardHeading4: '',
		cardDescription4: '',
		cardCover5: null,
		cardHeading5: '',
		cardDescription5: '',
		cardCover6: null,
		cardHeading6: '',
		cardDescription6: '',
		serviceButtonLink: '',
		serviceDescription: '',
		serviceCover1: null,
		serviceCover2: null,
		visionButtonLink1: '',
		visionButtonLink2: '',
		visionDescription: '',

		errors: { mainDescription: '', cardHeading1: '', cardDescription1: '', cardHeading2: '', cardDescription2: '', cardHeading3: '', cardDescription3: '', cardHeading4: '', cardDescription4: '', cardHeading5: '', cardDescription5: '', cardHeading6: '', cardDescription6: '', serviceButtonLink: '', serviceDescription: '', visionButtonLink1: '', visionButtonLink2: '', visionDescription: '' },

		bannerCoverError: false,
		bannerCoverErrorMsg: '',
		cardCover1Error: false,
		cardCover1ErrorMsg: '',
		cardCover2Error: false,
		cardCover2ErrorMsg: '',
		cardCover3Error: false,
		cardCover3ErrorMsg: '',
		cardCover4Error: false,
		cardCover4ErrorMsg: '',
		cardCover5Error: false,
		cardCover5ErrorMsg: '',
		cardCover6Error: false,
		cardCover6ErrorMsg: '',
		serviceCover1Error: false,
		serviceCover2Error: false,
		serviceCover1ErrorMsg: '',
		serviceCover2ErrorMsg: '',
		loading: true,
	};


	componentDidMount = async () => {
		this.getAboutUsCmsDetails();
	};


	// Function to get user details
	getAboutUsCmsDetails = async () => {
		try {
			const result = await new ApiHelper().FetchFromServer(
				'/superadmin',
				'/aboutuscms/getAboutUsCmsDetails',
				'GET',
				true,
				undefined,
				undefined
			);

			if (!result.isError) {
				const { data } = result;
				if (data.data.length > 0) {
					let details = data.data[0];
					this.setState({
						// bannerCover: await getImageBlob(details.bannerCover),
						// cardCover1: await getImageBlob(details.cardCover1),
						// cardCover2: await getImageBlob(details.cardCover2),
						// cardCover3: await getImageBlob(details.cardCover3),
						// cardCover4: await getImageBlob(details.cardCover4),
						// cardCover5: await getImageBlob(details.cardCover5),
						// cardCover6: await getImageBlob(details.cardCover6),
						// serviceCover1: await getImageBlob(details.serviceCover1),
						// serviceCover2: await getImageBlob(details.serviceCover2),

						bannerCover: details.bannerCoverUrl,
						cardCover1: details.cardCover1Url,
						cardCover2: details.cardCover2Url,
						cardCover3: details.cardCover3Url,
						cardCover4: details.cardCover4Url,
						cardCover5: details.cardCover5Url,
						cardCover6: details.cardCover6Url,
						serviceCover1: details.serviceCover1Url,
						serviceCover2: details.serviceCover2Url,

						mainDescription: details.mainDescription,
						cardHeading1: details.cardHeading1,
						cardDescription1: details.cardDescription1,
						cardHeading2: details.cardHeading2,
						cardDescription2: details.cardDescription2,
						cardHeading3: details.cardHeading3,
						cardDescription3: details.cardDescription3,
						cardHeading4: details.cardHeading4,
						cardDescription4: details.cardDescription4,
						cardHeading5: details.cardHeading5,
						cardDescription5: details.cardDescription5,
						cardHeading6: details.cardHeading6,
						cardDescription6: details.cardDescription6,

						serviceButtonLink: details.serviceButtonLink,
						serviceDescription: details.serviceDescription,
						visionButtonLink1: details.visionButtonLink1,
						visionButtonLink2: details.visionButtonLink2,
						visionDescription: details.visionDescription,
					});
				} else {
					this.setState({
						loading: false
					});
				}
			}
		} catch (error) {
			this.setState({
				loading: false
			});
		}
	};

	// Function to bind values
	handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		this.setState({
			...this.state,
			[name]: value,
			errors: {
				...this.state.errors,
				[name]: '',
			},
		});
	};

	// Function to update home page cms details
	handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		this.setState({ isSubmitting: true });

		let {
			bannerCover,
			mainDescription,
			cardCover1,
			cardHeading1,
			cardDescription1,
			cardCover2,
			cardHeading2,
			cardDescription2,
			cardCover3,
			cardHeading3,
			cardDescription3,
			cardCover4,
			cardHeading4,
			cardDescription4,
			cardCover5,
			cardHeading5,
			cardDescription5,
			cardCover6,
			cardHeading6,
			cardDescription6,
			serviceButtonLink,
			serviceDescription,
			serviceCover1,
			serviceCover2,
			visionButtonLink1,
			visionButtonLink2,
			visionDescription,
		} = this.state;

		const payload = {
			bannerCover,
			mainDescription,
			cardCover1,
			cardHeading1,
			cardDescription1,
			cardCover2,
			cardHeading2,
			cardDescription2,
			cardCover3,
			cardHeading3,
			cardDescription3,
			cardCover4,
			cardHeading4,
			cardDescription4,
			cardCover5,
			cardHeading5,
			cardDescription5,
			cardCover6,
			cardHeading6,
			cardDescription6,
			serviceButtonLink,
			serviceDescription,
			serviceCover1,
			serviceCover2,
			visionButtonLink1,
			visionButtonLink2,
			visionDescription,
		};



		// To validate form fields
		let { isValid, errors } = aboutUsCmsValidator(payload);

		if (isValid) {
			try {

				let formData: FormData = new FormData();

				Object.entries(payload).forEach(([key, value]) => {
					formData.append(key, value);
				});

				const res = await new ApiHelper().FetchFromServer(
					'/superadmin',
					'/aboutuscms/update',
					'POST',
					true,
					undefined,
					formData
				);
				const { data } = res;
				if (data.statusCode === 200) {
					showSuccessToast(res.data.message);
					// this.setState({ userDetails: res.data.data[0], currentPassword: '', newPassword: '', confirmPassword: '' })
				} else {
					showErrorToast(res.data.message);
				}
				this.setState({ isSubmitting: false });
			} catch (error) {
				this.setState({ isSubmitting: false });
			}
		} else {
			this.setState({
				errors,
				isSubmitting: false
			});
			return;
		}
	};

	// Function to set field Values of image
	setFieldValue = (name: string, obj: any) => {
		if (obj) {
			if (!obj.name.match(/.(jpg|JPG|jpeg|JPEG|bmp|BMP|gif|GIF|png|PNG)$/i)) {
				this.setState({
					[name]: null,
					[name + 'Error']: true,
					[name + 'ErrorMsg']: 'Only PNG, JPG, JPEG, BMP & GIF are accepted',
				});
			} else {
				this.setState({
					[name]: obj,
					[name + 'Error']: false,
					[name + 'ErrorMsg']: '',
				});
			}
		}
	};

	// Function to set field error of image
	setFieldError = (name: string, value: any) => {
		if (value) {
			this.setState({
				[name]: null,
				[name + 'Error']: true,
				[name + 'ErrorMsg']: value,
			});
		}
	};

	render() {
		return (
			<Fragment>
				<AddAboutUsCMSComponent
					{...this.state}
					handleChange={this.handleChange}
					handleSubmit={this.handleSubmit}
					setFieldValue={this.setFieldValue}
					setFieldError={this.setFieldError}
				/>
			</Fragment>
		);
	}
}

export default AddAboutUsCMS;
