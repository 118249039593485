import React, { PureComponent, ChangeEvent, Fragment, FormEvent } from 'react';
import { IWaitListProps, IWaitListState } from './interface';
import WaitListComponent from './WaitList.component';
import { ApiHelper } from '../../settings/helpers/ApiHelper';
import { asyncSetState, confirmBox, } from '../../settings/helpers/Common';
import { showSuccessToast } from '../../settings/helpers/toast';
import produce from 'immer';
import * as qs from 'query-string';
import debounce from 'lodash/debounce';

class WaitList extends PureComponent<IWaitListProps, IWaitListState> {
	state: IWaitListState = {
		waitlists: [],
		selectedWaitLists: [],
		email: '',
		pageNumber: 1,
		pageLimit: 100,
		totalRecords: 0,
		firstRecordNo: 0,
		lastRecordNo: 0,
		loading: true
	};

	componentDidMount = async () => {
		await this.fetchWaitLists();
		this.onChangeDebounced = debounce(this.onChangeDebounced, 500);
	}

	componentDidUpdate = async (prevProps: IWaitListProps) => {
		if (prevProps.location !== this.props.location) {
			await this.fetchWaitLists();
		}
	};

	// Function to get all wait lists
	fetchWaitLists = async () => {
		try {
			this.setState({ loading: true, waitlists: [], });
			const current = this.props.location.search;
			let search: any = {};
			search = { ...qs.parse(current) };
			let pageNumber = 1;
			let email = '';

			const currentPage = this.state.pageNumber;
			if (search.page && parseInt(search.page) !== currentPage) {
				pageNumber = parseInt(search.page);
			}

			if (search.email) {
				email = search.email;
			}

			await asyncSetState(this)({
				...this.state,
				pageNumber,
				email
			});

			const result: any = await new ApiHelper().FetchFromServer(
				'/superadmin',
				`/waitlists/getAllWaitListUsers?page=${this.state.pageNumber}&limit=${this.state.pageLimit}&email=${this.state.email}`,
				'GET',
				true,
				undefined,
				undefined,
			);

			if (result.isError) {
				this.setState({
					waitlists: [],
					pageNumber: 1,
					totalRecords: 0,
					firstRecordNo: 0,
					lastRecordNo: 0,
					loading: false
				});
				throw new Error(result.messages);
			}

			const { data } = result;
			if (data.statusCode === 200 && data.success) {
				this.setState({
					waitlists: data.data,
					pageNumber: data.pageNumber,
					pageLimit: data.pageSize,
					totalRecords: data.totalCount,
					firstRecordNo: (this.state.pageNumber - 1) * this.state.pageLimit + 1,
					lastRecordNo:
						(this.state.pageNumber - 1) * this.state.pageLimit +
						data.data.length,
					loading: false
				});
			} else {
				this.setState({
					waitlists: [],
					pageNumber: 1,
					totalRecords: 0,
					firstRecordNo: 0,
					lastRecordNo: 0,
					loading: false
				});
			}
		} catch (error) {
			this.setState({ loading: false });
		}
	};

	// Function to select one record
	onSelectOne = (e: ChangeEvent<HTMLInputElement>, waitlist: any) => {
		const indx: number = this.state.selectedWaitLists.findIndex(
			(d: any) => d._id === waitlist._id,
		);

		if (e.currentTarget.checked) {
			if (indx === -1) {
				let temp: any[] = [...this.state.selectedWaitLists, waitlist];
				this.setState({ selectedWaitLists: [...temp] });
			}
		} else {
			if (indx > -1) {
				let newState: any = [];
				newState = produce(this.state.selectedWaitLists, (newArr: any[]) => {
					newArr.splice(indx, 1);
				});
				this.setState({ selectedWaitLists: [...newState] });
			}
		}
	};

	// Function to select all records
	onSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.currentTarget.checked) {
			this.setState({ selectedWaitLists: [...this.state.waitlists] });
		} else {
			this.setState({ selectedWaitLists: [] });
		}
	};

	// Function to change page number
	onPageChange = async (page: number) => {
		if (page !== this.state.pageNumber) {
			await this.props.history.push(
				`/waitlist?${qs.stringify({
					page: page,
					email: this.state.email
				})}`
			);
		}
	};

	// Function to search records
	onSearch = async (e: FormEvent<HTMLFormElement> | undefined) => {
		if (e) {
			e.preventDefault();
		}
		new ApiHelper().cancelRequest(); // Cancel previous pending request if any
		this.props.history.push(
			`/waitlist?${qs.stringify({
				page: 1,
				email: this.state.email
			})}`
		);
	};

	// Function for search change
	onSearchChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		await asyncSetState(this)({ ...this.state, [name]: value });
		await this.onChangeDebounced();
	};

	onChangeDebounced = async () => {
		await this.onSearch(undefined); // Delayed function, call api to get searched values values
	};

	// Function to bind values
	onChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		this.setState({
			...this.state,
			[name]: value
		});
	};

	// Function to remove wait list
	onRemove = async (waitlistIds: any) => {
		let obj = {};
		let text = '';
		if (typeof waitlistIds === 'string') {
			obj = {
				ids: [waitlistIds]
			}
			text = "Do you want to delete this waitlist?"
		} else {
			let ids = []
			for (let i = 0; i < this.state.selectedWaitLists.length; i++) {
				let s = this.state.selectedWaitLists[i];
				ids.push(s._id);
			}
			obj = {
				ids: ids
			}
			text = "Do you want to delete selected waitlists?"
		}

		const { value } = await confirmBox({
			title: "Are you sure?",
			text: text
		});
		if (value) {
			try {
				const res = await new ApiHelper().FetchFromServer(
					"/superadmin",
					"/waitlists/remove",
					"POST",
					true,
					undefined,
					obj
				);

				if (res.data.statusCode === 200) {
					showSuccessToast(res.data.message);
					this.setState({ selectedWaitLists: [] },
						() => {
							this.fetchWaitLists();
						}
					);
				}
			} catch (error) { }
		} else {
			return;
		}
	};

	// END WAIT LIST

	render() {
		return (
			<Fragment>
				<div className="page-header-wrap">
					<div className="page-header">
						<h3 className="page-title">
							<span className="heading-text">Wait List</span>
						</h3>
					</div>
					<WaitListComponent
						{...this.state}
						onSelectOne={this.onSelectOne}
						onSelectAll={this.onSelectAll}
						onPageChange={this.onPageChange}
						handleChange={this.onChange}
						onSearchChange={this.onSearchChange}
						onRemove={this.onRemove}
					/>
				</div>
			</Fragment>
		);
	}
}

export default WaitList;
