import React, { Component, Fragment } from 'react'
import { Spinner } from 'react-bootstrap'
import icon_empty from '../../../assets/img/Icon_Empty.svg';
import { ApiHelper } from '../../../settings/helpers/ApiHelper';
import { asyncSetState, logger } from '../../../settings/helpers/Common';
import History from '../../../settings/helpers/History';
import RCPagination from 'rc-pagination';
//@ts-ignore
import localeInfo from 'rc-pagination/lib/locale/en_US';
import { IClassListState } from '../interface';
import moment from 'moment'
import * as qs from 'query-string';

class ClassList extends Component<any, IClassListState>{
	constructor(props: any) {
		super(props)
		this.state = {
			classes: [],
			classLoading: false,
			classPageNumber: 1,
			classPageLimit: 10,
			classTotalRecords: 0,
			classFirstRecordNo: 0,
			classLastRecordNo: 0,
			className: ''

		}
	}

	componentDidMount = async () => {
		const { providerId } = this.props
		console.log(providerId, 'providerId');
		let search: any = {};
		search = { ...qs.parse(History.location.search) };
		console.log(search, 'search')
		if (search.id)
			await this.fetchClasses();
		// this.onChangeDebounced = debounce(this.onChangeDebounced, 500);
	};

	componentDidUpdate = async (prevProps: any) => {
		if (prevProps.location !== this.props.location) {

			await this.fetchClasses();
		}
	};
	fetchClasses = async () => {
		try {
			console.log(this.props, 'locationnnnnnnnnn');

			const current = this.props.location.search;
			let search: any = {};
			search = { ...qs.parse(current) };
			let id = search.id
			let classPageNumber = 1;
			let className = '';
			console.log(search, 'searchsearch')
			const currentPage = this.state.classPageNumber;
			console.log(search.page && parseInt(search.page) !== currentPage, 'conditionnnnnnnn')
			if (search.page && parseInt(search.page) !== currentPage) {
				classPageNumber = parseInt(search.page);
				console.log(classPageNumber, 'classPageNumber', search.page)
			}
			if (search.name) {
				className = search.name;
			}

			await asyncSetState(this)({
				...this.state,
				classPageNumber,
				className,
			});
			this.setState({ classLoading: true })
			const result: any = await new ApiHelper().FetchFromServer(
				'/superadmin',
				`/classes/getProviderClasses?page=${this.state.classPageNumber}&limit=${this.state.classPageLimit}&name=${this.state.className}&providerId=${id}`,
				'GET',
				true,
				undefined,
				undefined
			);

			if (result.isError) {
				throw new Error(result.messages);
			}

			const { data } = result;
			if (data.statusCode === 200 && data.success) {
				await asyncSetState(this)({
					...this.state,
					classes: data.data,
					classPageNumber: data.pageNumber,
					classPageLimit: data.pageSize,
					classTotalRecords: data.totalCount,
					classFirstRecordNo:
						(this.state.classPageNumber - 1) * this.state.classPageLimit +
						1,
					classLastRecordNo:
						(this.state.classPageNumber - 1) * this.state.classPageLimit +
						data.data.length,
					classLoading: false,
				});
			}
			else {
				await asyncSetState(this)({
					...this.state,
					classes: [],
					classPageNumber: 1,
					classTotalRecords: 0,
					classFirstRecordNo: 0,
					classLastRecordNo: 0,
					classLoading: false,
				});
			}
		} catch (error) {
			logger(error);
			await asyncSetState(this)({
				...this.state,
				classes: [],
				classPageNumber: 1,
				classTotalRecords: 0,
				classFirstRecordNo: 0,
				classLastRecordNo: 0,
				classLoading: false,
			});
		}
	};
	onServicePageChange = async (page: number) => {
		if (page !== this.state.classPageNumber) {
			let query: any = { ...qs.parse(this.props.location.search) };
			console.log(query, 'query')
			query = {
				...query,
				page: page,
			};

			this.props.history.push(`/wellness-providers/profile?${qs.stringify(query)}`);
		}
	};
	render() {
		const { classes, classLoading, classPageNumber, classTotalRecords, classFirstRecordNo, classLastRecordNo, classPageLimit } = this.state

		return (
			<Fragment>
				<div className="service-list-wrapper table-responsive provider-service-list">
					<table className="table service-used-list">
						<thead>
							<tr>
								<th>S.No</th>
								<th>Activity</th>
								<th className="w-125">Type</th>
								<th className="w-85">$Price</th>
								<th>Duration</th>
								<th>Date</th>
							</tr>
						</thead>
						<tbody>
							{classLoading ?
								<tr>
									<td colSpan={8}>
										<div className="d-flex justify-content-center align-items-center table-spinner">
											<Spinner animation="border" />
										</div>
									</td>
								</tr>
								: classes && classes.length > 0 ?
									classes.map((value: any, index: number) => (
										<tr>

											<td>{index + 1 + (classPageNumber - 1) * classPageLimit}</td>
											<td>{value.name}</td>
											<td>{value.type}</td>
											<td>{value.price ? (
												<>$ {value.price.toFixed(2)}</>
											) : (
													<>$ 0.00</>
												)}</td>
											<td>{value.duration}</td>
											<td>{` ${moment(value.date).format('MM/DD/YYYY')}`}</td>
										</tr>
									))
									: (
										<tr>
											<td colSpan={8} className={"text-center"}>
												<div className={'no-result-block'}>
													<div className='empty-ico'>
														<img alt='Icon_Empty.svg' width='50' src={icon_empty} />
													</div>
													<h5>No Classes Found</h5>
												</div>
											</td>
										</tr>
									)}
						</tbody>
					</table>
				</div>
				<div className="table-pagination-wrap">
					<div>
						<RCPagination
							className="custom-pagination-design"
							current={classPageNumber}
							total={classTotalRecords}
							defaultPageSize={classPageLimit}
							pageSize={classPageLimit}
							hideOnSinglePage={true}
							showPrevNextJumpers={true}
							showSizeChanger={true}
							pageSizeOptions={['5', '10', '25', '100']}
							onChange={this.onServicePageChange}
							locale={localeInfo}
						/>
					</div>
					{classTotalRecords > 0 ? (
						<div className="records-filter">
							<div className="records-number">
								Displaying {classFirstRecordNo} - {classLastRecordNo} of{' '}
								{classTotalRecords} records{' '}
							</div>
						</div>
					) : null}
				</div>
			</Fragment>

		)
	}
}

export default ClassList