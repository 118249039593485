import React, { Fragment, Component } from 'react';
import { IProfileComponentProps, IProfileComponentState } from './interface';
import { Form, Col, Button, Row, Card } from 'react-bootstrap';
import defaultImage from '../../assets/img/upload-ic.svg'
import { blobImageUrl } from '../../settings/helpers/Common';
import { AcceptedImageFormat } from '../../settings/config/AppConfig';
class ProfileComponent extends Component<IProfileComponentProps, IProfileComponentState> {

	render() {
		const {
			currentPassword, newPassword, confirmPassword,
			errors,
			isSubmitting,
			handleChange,
			onProfileUpdate,
			setFieldValue,
			userName,
			email,
			avatar,
			avatarError,
			avatarErrorMsg
		} = this.props;

		const avatarUrl = avatar && blobImageUrl(avatar);

		return (
			<Fragment>
				<Form onSubmit={onProfileUpdate} noValidate={true} className="form-section">
					<div className="page-header-wrap">
						<div className="page-header">
							<h3 className="page-title">
								{/* <i className="fas fa-arrow-left"></i> */}
								<span className="heading-text">My Profile</span>
							</h3>
						</div>
						<Row>
							<Col lg={6} className="grid-margin">
								<Card>
									<Card.Header>
										<Card.Title>
											<span>Profile Information</span>
										</Card.Title>
									</Card.Header>
									<Card.Body>
										<Form.Group controlId="profilepic">
											{/* <Form.Label>Avatar</Form.Label> */}
											<label className="file-input-upload profile-wrap half-profile">
												<div className="file-input-inner-upload text-center">
													{avatarUrl ? (
														<div
															className="img-preview"
															style={{
																backgroundImage: `url(${avatarUrl})`,
															}}
														/>
													) : (
															<>
																<img
																	src={defaultImage}
																	alt="upload"
																/>
																<h6 className="">select file to upload your service cover</h6>
																<p className="mb-0">1000 x 1000px ideal for hi-res</p>
															</>
														)}
													<Form.Control
														type="file"
														placeholder="Select image to upload"
														aria-describedby="profilepic"
														name="avatar"
														onChange={(event: any) => { setFieldValue(event.currentTarget.files[0]) }}
														autoComplete="profileImage"
														accept={AcceptedImageFormat.join()}
													/>
												</div>
											</label>
											<p className="editor-invalid-feedback">
												{avatarError ? avatarErrorMsg : ''}
											</p>
										</Form.Group>
										<Form.Group controlId="userName">
											<Form.Label>Name</Form.Label>
											<Form.Control
												type="text"
												placeholder="John Doe"
												aria-describedby="userName"
												name="userName"
												value={userName}
												onChange={handleChange}
												isValid={userName && !errors.userName}
												isInvalid={errors.userName}
												autoComplete="username"
											/>
											<Form.Control.Feedback type="invalid">
												{errors.userName}
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group controlId="email">
											<Form.Label>Email</Form.Label>
											<Form.Control
												type="email"
												disabled
												placeholder="johndoe@email.com"
												aria-describedby="email"
												name="email"
												value={email}
												onChange={handleChange}
												autoComplete="email"
											/>
											<Form.Control.Feedback type="invalid">
												{errors.email}
											</Form.Control.Feedback>
										</Form.Group>
									</Card.Body>
								</Card>
							</Col>
							<Col lg={6} className="grid-margin">
								<Row>
									<Col lg={12} className="grid-margin">
										<Card>
											<Card.Header>
												<Card.Title>
													<span>Reset Password</span>
												</Card.Title>
											</Card.Header>
											<Card.Body>
												<Form.Group controlId="currentpassword">
													<Form.Label>Current Password</Form.Label>
													<Form.Control
														type="password"
														placeholder="**********"
														aria-describedby="currentpassword"
														name="currentPassword"
														value={currentPassword}
														onChange={handleChange}
														isValid={!errors.currentPassword}
														isInvalid={errors.currentPassword}
														autoComplete={'off'}
														// autoComplete="current-password"
													/>
													<Form.Control.Feedback type="invalid">
														{errors.currentPassword}
													</Form.Control.Feedback>
												</Form.Group>
												<Form.Group controlId="newpassword">
													<Form.Label>New Password</Form.Label>
													<Form.Control
														type="password"
														placeholder="**********"
														aria-describedby="newpassword"
														name="newPassword"
														value={newPassword}
														onChange={handleChange}
														isValid={!errors.newPassword}
														isInvalid={errors.newPassword}
														autoComplete="new-password"
													/>
													<Form.Control.Feedback type="invalid">
														{errors.newPassword}
													</Form.Control.Feedback>
												</Form.Group>
												<Form.Group controlId="confirmpassword">
													<Form.Label>Confirm Password</Form.Label>
													<Form.Control
														type="password"
														placeholder="**********"
														aria-describedby="confirmpassword"
														name="confirmPassword"
														value={confirmPassword}
														onChange={handleChange}
														isValid={!errors.confirmPassword}
														isInvalid={errors.confirmPassword}
														autoComplete="new-password"
													/>
													<Form.Control.Feedback type="invalid">
														{errors.confirmPassword}
													</Form.Control.Feedback>
												</Form.Group>
											</Card.Body>
										</Card>
									</Col>
								</Row>
							</Col>
						</Row>
						<Form.Group className="d-flex justify-content-end">
							<Button type="submit" disabled={isSubmitting} className="save-btn">
								{isSubmitting ? 'Updating...' : 'Update'}
							</Button>
						</Form.Group>
					</div>
				</Form>
			</Fragment>
		);
	};
}
export default ProfileComponent;
