import React, { Component } from 'react';
import { IWebsiteSettingsComponentProps, IWebsiteSettingsComponentState } from './interface';
import { Row, Col, Card, OverlayTrigger, Tooltip, Form, Button } from 'react-bootstrap';

class WebsiteSettingsComponent extends Component<IWebsiteSettingsComponentProps, IWebsiteSettingsComponentState> {

	render() {
		const { email, phone1, phone2, address, handleChange, errors,
			isSubmitting,
			// link1,
			// link2,
			link3,
			link4,
			// link5,
			// link1Status,
			// link2Status,
			link3Status,
			link4Status,
			// link5Status,
			onStatusUpdate,
			onUpdate,
			// errorLink1,
			// errorLink2,
			errorLink3,
			errorLink4,
			// errorLink5
		} = this.props;
		return (
			<div className="page-header-wrap">
				{/* <div className="page-header">
					<h3 className="page-title">
						<span className="heading-text">Settings</span>
					</h3>
				</div > */}
				<Form onSubmit={onUpdate} className="form-section">
					<Row>
						<Col lg={12} className="grid-margin">
							<Card>
								<Card.Header>
									<Card.Title>
										<span>Contact Information</span>
									</Card.Title>
								</Card.Header>
								<Card.Body>
									<Row>
										<Col lg={12}>
											<Row>
												<Col lg={6}>
													<Form.Group controlId="email">
														<Form.Label>Email</Form.Label>
														<Form.Control
															type="email"
															aria-describedby="email"
															name="email"
															value={email}
															onChange={handleChange}
															isValid={email && !errors.email}
															isInvalid={!email && errors.email}
															autoComplete="email"
														/>
														<Form.Control.Feedback type="invalid">
															{errors.email}
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
												<Col lg={6}>
													<Form.Group controlId="phone1">
														<Form.Label>Phone 1</Form.Label>
														<Form.Control
															type="text"
															aria-describedby="phone1"
															name="phone1"
															value={phone1}
															onChange={handleChange}
															isValid={phone1 && !errors.phone1}
															isInvalid={!phone1 && errors.phone1}
															autoComplete="phone1"
														/>
														<Form.Control.Feedback type="invalid">
															{errors.phone1}
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
												<Col lg={6}>
													<Form.Group controlId="phone2">
														<Form.Label>Phone 2</Form.Label>
														<Form.Control
															type="text"
															aria-describedby="phone2"
															name="phone2"
															value={phone2}
															onChange={handleChange}
															isValid={phone2 && !errors.phone2}
															isInvalid={!phone2 && errors.phone2}
															autoComplete="phone2"
														/>
														<Form.Control.Feedback type="invalid">
															{errors.phone2}
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
												<Col lg={6}>
													<Form.Group controlId="address">
														<Form.Label>Address</Form.Label>
														<Form.Control
															type="text"
															aria-describedby="address"
															name="address"
															value={address}
															onChange={handleChange}
															isValid={address && !errors.address}
															isInvalid={!address && errors.address}
															autoComplete="address"
														/>
														<Form.Control.Feedback type="invalid">
															{errors.address}
														</Form.Control.Feedback>
													</Form.Group>
												</Col>
											</Row>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
						<Col lg={12} className="grid-margin">
							<Card>
								<Card.Header>
									<Card.Title>
										<span>Social Media Links</span>
									</Card.Title>
								</Card.Header>
								<Card.Body>
									<Row>
										<Col lg={2}>
											<Form.Group>
												<Form.Label>Brand</Form.Label>
											</Form.Group>
										</Col>
										<Col lg={7}>
											<Form.Group>
												<Form.Label>Links</Form.Label>
											</Form.Group>
										</Col>
										<Col lg={3} className="text-center">
											<Form.Group>
												<Form.Label>Status</Form.Label>
											</Form.Group>
										</Col>
									</Row>
									{/* <Row>
										<Col lg={2}>
											<i style={{ width: 36, height: 36, color: '#3b5999' }} className="fab fa-facebook-square"></i>
										</Col>
										<Col lg={7}>
											<Form.Group controlId="link1">
												<Form.Control
													type="text"
													placeholder="https://www.facebook.com"
													aria-describedby="link1"
													name="link1"
													value={link1}
													onChange={handleChange}
												/>
												<div className="editor-invalid-feedback">
													{errorLink1 ? 'Please add valid URL' : null}
												</div>
											</Form.Group>
										</Col>
										<Col lg={3} className="text-center">
											<Form.Group>
												<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{link1Status ? 'Click to Inactive' : 'Click to Active'}</Tooltip>}>
													<span style={{ width: 86 }} onClick={() => onStatusUpdate({ link1Status: link1Status })} className={"cursor-pointer badge" + (link1Status ? " badge-success" : " badge-danger")}>
														{link1Status ? 'Active' : 'Inactive'}
													</span>
												</OverlayTrigger>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col lg={2}>
											<i style={{ width: 36, height: 36, color: '#55acee' }} className="fab fa-twitter-square"></i>
										</Col>
										<Col lg={7}>
											<Form.Group controlId="link2">
												<Form.Control
													type="text"
													placeholder="https://twitter.com"
													aria-describedby="link2"
													name="link2"
													value={link2}
													onChange={handleChange}
												/>
												<div className="editor-invalid-feedback">
													{errorLink2 ? 'Please add valid URL' : null}
												</div>
											</Form.Group>
										</Col>
										<Col lg={3} className="text-center">
											<Form.Group>
												<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{link2Status ? 'Click to Inactive' : 'Click to Active'}</Tooltip>}>
													<span style={{ width: 86 }} onClick={() => onStatusUpdate({ link2Status: link2Status })} className={"cursor-pointer badge" + (link2Status ? " badge-success" : " badge-danger")}>
														{link2Status ? 'Active' : 'Inactive'}
													</span>
												</OverlayTrigger>
											</Form.Group>
										</Col>
									</Row> */}
									<Row>
										<Col lg={2}>
											<i style={{ width: 36, height: 36, color: '#e4405f' }} className="fab fa-instagram"></i>
										</Col>
										<Col lg={7}>
											<Form.Group controlId="link3">
												<Form.Control
													type="text"
													placeholder="https://www.instagram.com"
													aria-describedby="link3"
													name="link3"
													value={link3}
													onChange={handleChange}
												/>
												<div className="editor-invalid-feedback">
													{errorLink3 ? 'Please add valid URL' : null}
												</div>
											</Form.Group>
										</Col>
										<Col lg={3} className="text-center">
											<Form.Group>
												<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{link3Status ? 'Click to Inactive' : 'Click to Active'}</Tooltip>}>
													<span style={{ width: 86 }} onClick={() => onStatusUpdate({ link3Status: link3Status })} className={"cursor-pointer badge" + (link3Status ? " badge-success" : " badge-danger")}>
														{link3Status ? 'Active' : 'Inactive'}
													</span>
												</OverlayTrigger>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col lg={2}>
											<i style={{ width: 36, height: 36, color: '#0077B5' }} className="fab fa-linkedin"></i>
										</Col>
										<Col lg={7}>
											<Form.Group controlId="link4">
												<Form.Control
													type="text"
													placeholder="https://www.linkedin.com"
													aria-describedby="link4"
													name="link4"
													value={link4}
													onChange={handleChange}
												/>
												<div className="editor-invalid-feedback">
													{errorLink4 ? 'Please add valid URL' : null}
												</div>
											</Form.Group>
										</Col>
										<Col lg={3} className="text-center">
											<Form.Group>
												<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{link4Status ? 'Click to Inactive' : 'Click to Active'}</Tooltip>}>
													<span style={{ width: 86 }} onClick={() => onStatusUpdate({ link4Status: link4Status })} className={"cursor-pointer badge" + (link4Status ? " badge-success" : " badge-danger")}>
														{link4Status ? 'Active' : 'Inactive'}
													</span>
												</OverlayTrigger>
											</Form.Group>
										</Col>
									</Row>
									{/* <Row>
										<Col lg={2}>
											<i style={{ width: 36, height: 36, color: '#cd201f' }} className="fab fa-youtube-square"></i>
										</Col>
										<Col lg={7}>
											<Form.Group controlId="link5">
												<Form.Control
													type="text"
													placeholder="https://www.youtube.com"
													aria-describedby="link5"
													name="link5"
													value={link5}
													onChange={handleChange}
												/>
												<div className="editor-invalid-feedback">
													{errorLink5 ? 'Please add valid URL' : null}
												</div>
											</Form.Group>
										</Col>
										<Col lg={3} className="text-center">
											<Form.Group>
												<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{link5Status ? 'Click to Inactive' : 'Click to Active'}</Tooltip>}>
													<span style={{ width: 86 }} onClick={() => onStatusUpdate({ link5Status: link5Status })} className={"cursor-pointer badge" + (link5Status ? " badge-success" : " badge-danger")}>
														{link5Status ? 'Active' : 'Inactive'}
													</span>
												</OverlayTrigger>
											</Form.Group>
										</Col>
									</Row> */}
								</Card.Body>
							</Card>
						</Col>
						<Col lg={12}>
							<Form.Group className="d-flex justify-content-end">
								<Button type="submit" disabled={isSubmitting}>
									{isSubmitting ? 'Updating...' : 'Update'}
								</Button>
							</Form.Group>
						</Col>
					</Row>
				</Form>
			</ div>
		);
	};
}

export default WebsiteSettingsComponent;