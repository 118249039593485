import React, { Component } from 'react';
import { IManageStudioComponentProps, IManageStudioComponentState } from './interface';
import { Dropdown, Row, Col, Card, OverlayTrigger, Tooltip, Spinner, ButtonGroup, Button } from 'react-bootstrap';
import { Input } from "reactstrap";
import RCPagination from 'rc-pagination';
import moment from 'moment';
//@ts-ignore
import localeInfo from 'rc-pagination/lib/locale/en_US';
import searchIco from '../../../assets/img/magnifying-glass.svg';
import icon_empty from '../../../assets/img/Icon_Empty.svg';
import { CSVLink } from 'react-csv';
class ManageStudioComponent extends Component<IManageStudioComponentProps, IManageStudioComponentState> {

	render() {
		const { loading, users, name, ids, totalRecords, pageLimit, pageNumber, onPageChange, onSearchChange, onFilter, status, firstRecordNo, lastRecordNo, onStudioStatusUpdate,
			// onFeaturedUpdate,
			proxyLogin, onModelShow, handleCheckAllCheckBox, handleCheckboxChange } = this.props;
		let csvData: any = [];
		if (users.length > 0 && csvData.length === 0) {
			csvData.push(['Company Name', 'Email ID', 'Provider Name', 'Created Date ']);
		}

		users.forEach((w: any, i: number) => {
			console.log(w, 'w');

			let innerArray: any = [];
			innerArray.push(w.studioName);
			innerArray.push(w.email);
			innerArray.push(w.name);
			innerArray.push(moment(w.createdAt).format('DD-MM-YYYY'));
			csvData.push(innerArray);
		})
		return (
			<Row>
				<Col lg={12} className="grid-margin">
					<Card>
						<Card.Header>
							<Card.Title>
								<span>Wellness Providers List</span>
							</Card.Title>
							<div className="table-header-actions header-actions">
								<div className="search-input-wrap">
									<input
										type="text"
										name="name"
										value={name}
										onChange={onSearchChange}
										placeholder="Search by name & email"
										autoComplete="false"
										className="form-control"></input>
									<span className="search-icon">
										<img src={searchIco} alt="search" width="18px" />
									</span>
								</div>
								<div className="select-btn mr-2">
									<Input type="select" name="status" value={status} onChange={onFilter}>
										<option value={""}>Select status</option>
										<option value={"Active"}>Active</option>
										<option value={"Inactive"}>Inactive</option>
									</Input>
								</div>

								<div>
									{csvData.length > 0 ?
										<OverlayTrigger
											placement={'top'}
											overlay={
												<Tooltip id='export-customer-list'>Click to export provider list</Tooltip>
											}
										>
											<CSVLink className="export-btn"
												data={csvData}
												filename={"provider-list-" + new Date().getTime() + (".csv")}
												target="_blank"
											>Export</CSVLink>
										</OverlayTrigger>
										: null}
								</div>
							</div>
						</Card.Header>
						<Card.Body className="table-card-body">
							<div className="table-responsive-wrap">
								<table className="table">
									<thead>
										<tr>
											<th className='check-block table-checkbox-width'>
												<Dropdown
													as={ButtonGroup}
													className='dropdown-checkbox'
												>
													<Button variant='link' className='btn-checkbox'>
														<label className="checkWrap">
															<input type="checkbox" name="checkbox" checked={users.length > 0 && ids.length === users.length} onChange={handleCheckAllCheckBox} />
															<span className="checkmark"></span>
														</label>
													</Button>
													<Dropdown.Toggle
														split
														variant='link'
														className='btn-split pl-2'
														id='dropdown-split-basic'
													/>
													<Dropdown.Menu>
														<Dropdown.Item
															onClick={() => onStudioStatusUpdate('Active', ids)}
															disabled={ids.length === 0}
														>
															<span>Active</span>
														</Dropdown.Item>
														<Dropdown.Item
															onClick={() => onStudioStatusUpdate('Inactive', ids)}
															disabled={ids.length === 0}
														>
															<span>Inactive</span>
														</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</th>
											<th className="name-block">Company Details</th>
											<th className="staff-block">Contact Person</th>
											<th className="slot-block text-center">Total Bookings</th>
											<th className="status-block text-center">Status</th>
											{/* <th className="status-block text-center">Featured <i className="fas fa-info-circle cursor-pointer" title="You can mark provider as Featured/Unfeatured using below yes/No button"></i></th> */}
											<th className="action-block text-center">Action</th>
										</tr>
									</thead>
									<tbody>
										{loading ?
											<tr>
												<td colSpan={7}>
													<div className="d-flex justify-content-center align-items-center table-spinner">
														<Spinner animation="border" />
													</div>
												</td>
											</tr>
											: users.length ?
												users.map((item: any, index: any) => {
													return (
														<tr key={index}>
															<td>
																<label className="checkWrap">
																	<input type="checkbox" value={item._id} checked={ids.indexOf(item._id) > -1} name="checkbox" onChange={handleCheckboxChange} />
																	<span className="checkmark"></span>
																</label>
																<span className="align-middle pl-1">{index + 1 + (pageNumber - 1) * pageLimit}.</span>
															</td>
															<td>
																<div className='info-column'>
																	<p className='info-title mb-1 text-ellipsis' >
																		<span className="link-text">
																			<OverlayTrigger
																				placement={'top'}
																				overlay={
																					<Tooltip id='tooltip-login'>View Profile</Tooltip>
																				}
																			>
																				<span
																					className='btn-icon'
																					onClick={() => { this.props.history.push(`/wellness-providers/profile?id=${item._id}`) }}
																				>
																					{item.studioName}
																				</span>
																			</OverlayTrigger>
																		</span>
																	</p>
																	<OverlayTrigger
																		placement={'top'}
																		overlay={
																			<Tooltip id='tooltip-email'>
																				{item.email}
																			</Tooltip>
																		}
																	>
																		<p className='mb-0 text-ellipsis'>
																			<i className='fas fa-envelope mr-1  text-teal'></i>
																			<span className='mr-2  text-dark'>Email:</span>
																			<span>{item.email}</span>
																		</p>
																	</OverlayTrigger>
																	<p className='mb-0'>
																		<i className='far fa-calendar-alt mr-1  text-teal'></i>
																		<span className='mr-2  text-dark'>Created Date:</span>
																		<span>{moment(item.createdAt).format('MM/DD/YYYY')}</span>
																	</p>
																</div>
															</td>
															<td>{item.name}</td>
															{/* <td className="text-center">{item.TotalBookings}</td> */}

															<td className="text-center">
																{item.TotalBookings > 0 ?
																	<OverlayTrigger
																		placement={'top'}
																		overlay={
																			<Tooltip id='tooltip-view'>View Bookings</Tooltip>
																		}
																	>
																		<span
																			className='btn-icon link-text'
																			onClick={() => { this.props.history.push('/bookings?providerId=' + item._id) }}
																			data-toggle="modal"
																		>
																			{item.TotalBookings}
																		</span>
																	</OverlayTrigger>
																	:
																	<span className='btn-icon' data-toggle="modal">
																		{item.TotalBookings}
																	</span>
																}
															</td>


															<td className="text-center">
																<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{item.status === 'Active' ? 'Click to Inactive' : 'Click to Active'}</Tooltip>}>
																	<label style={{ width: 86 }} onClick={() => onStudioStatusUpdate(item.status, item._id)} className={"cursor-pointer badge" + (item.status === 'Active' ? " active-badge" : " close-badge")}>
																		{item.status}
																	</label>
																</OverlayTrigger>
															</td>
															{/* <td className="text-center">
																<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{item.isFeatured === 'Yes' ? 'Click to Unfeatured' : 'Click to Featured'}</Tooltip>}>
																	<label style={{ width: 86 }} onClick={() => onFeaturedUpdate(item.isFeatured, item._id)} className={"cursor-pointer badge" + (item.isFeatured === 'Yes' ? " active-badge" : " close-badge")}>
																		{item.isFeatured}
																	</label>
																</OverlayTrigger>
															</td> */}
															<td className="text-center">
																<div className='action-buttons'>
																	<OverlayTrigger
																		placement={'top'}
																		overlay={
																			<Tooltip id='tooltip-login'>Proxy Login</Tooltip>
																		}
																	>
																		<span
																			className='btn-icon'
																			onClick={() => proxyLogin(item._id, false)}
																		>
																			<i className='fas fa-sign-in-alt'></i>
																		</span>
																	</OverlayTrigger>

																	<OverlayTrigger
																		placement={'top'}
																		overlay={
																			<Tooltip id='tooltip-login'>Click here to view Customers</Tooltip>
																		}
																	>
																		<span
																			className='btn-icon'
																			onClick={() => proxyLogin(item._id, true)}
																		>
																			<i className='fas fa-users'></i>
																		</span>
																	</OverlayTrigger>

																	<OverlayTrigger
																		placement={'top'}
																		overlay={
																			<Tooltip id='tooltip-password'>Change Password</Tooltip>
																		}
																	>
																		<span
																			className='btn-icon'
																			onClick={() => onModelShow(item._id)} data-toggle="modal" data-target="#changeModal"
																		>
																			<i className='fas fa-key'></i>
																		</span>
																	</OverlayTrigger>
																</div>
															</td>
														</tr>
													)
												})
												: (
													<tr>
														<td colSpan={7} className={"text-center"}>
															<div className={'no-result-block'}>
																<div className='empty-ico'>
																	<img alt='Icon_Empty.svg' width='50' src={icon_empty} />
																</div>
																<h5>No Wellness Providers Found</h5>
															</div>
														</td>
													</tr>
												)}
									</tbody>
								</table>
							</div>
							<div className="table-pagination-wrap">
								<div>
									<RCPagination
										className="custom-pagination-design"
										current={pageNumber}
										total={totalRecords}
										defaultPageSize={pageLimit}
										pageSize={pageLimit}
										hideOnSinglePage={true}
										showPrevNextJumpers={true}
										showSizeChanger={true}
										pageSizeOptions={['5', '10', '25', '100']}
										onChange={onPageChange}
										locale={localeInfo}
									/>
								</div>
								{totalRecords > 0 ? (
									<div className="records-filter">
										<div className="records-number">
											Displaying {firstRecordNo} - {lastRecordNo} of{' '}
											{totalRecords} records{' '}
										</div>
									</div>
								) : null}
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		);
	};
}

export default ManageStudioComponent;