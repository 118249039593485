import React, { Fragment, FormEvent } from 'react';
import { ICommissionForm, ICommissionFormViewProps } from './interface';
import { FormikProps } from 'formik';
import { Form, Row, Col, Button } from 'react-bootstrap';

const CommissionFormViewComponent = (
	props: FormikProps<ICommissionForm> & ICommissionFormViewProps
) => {
	const {
		values: {
			commission
		},
		touched,
		errors,
		isSubmitting,
		handleChange,
		handleBlur,
		handleSubmit,
		handleModalClose,
		
	} = props;

	return (
		<Fragment>
			<Form
				onSubmit={(e: FormEvent<HTMLFormElement>) => {
					e.stopPropagation();
					handleSubmit(e);
				}}
				noValidate={true}
				className='form-section'
			>
				<div className='page-header-wrap'>
					<Row>
						<Col lg={12}>
							<Form.Group controlId='commission'>
								<Form.Label> Commission (in %) <span className='required-field'> *</span>
								</Form.Label>
								<Form.Control
									type='text'
									placeholder=''
									aria-describedby='commission'
									name='commission'
									value={commission}
									onChange={handleChange}
									onBlur={handleBlur}
									isValid={touched.commission && !errors.commission}
									isInvalid={touched.commission && !!errors.commission}
									autoComplete='commission'
								/>
								<Form.Control.Feedback type='invalid'>
									{errors.commission}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>

					<div className="d-flex justify-content-end">
						<Button
							variant='secondary'
							type='button'
							className="mr-3"
							onClick={handleModalClose}
						>
							Cancel
						</Button>
						<Button
							variant='primary'
							type='submit'
							disabled={isSubmitting}
						>
							Update
						</Button>
					</div>	
				</div>
			</Form>
		</Fragment>
	);
};

export default CommissionFormViewComponent;
