import React, { Fragment } from "react";
import { Line } from "react-chartjs-2";
import { IChartProps, IChartState } from './interface';

class RevenueLineChart extends React.Component<IChartProps, IChartState> {
    render() {
        const { revenueData } = this.props;
        let dataLine = {
            labels: revenueData.chartLabels,
            datasets: [
                {
                    label: "Income",
                    fill: true,
                    backgroundColor: "#e6e6e6",
                    borderColor: "#818181",
                    pointHoverBorderWidth: 1,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: revenueData.chartData
                }
            ]
        }

        let options = {
            responsive: true,
            elements: {
                line: {
                    tension: 0
                }
            },
            scales: {
                xAxes: [{
                    display: false,
                    gridLines: {
                        display: false
                    }
                }],
                yAxes: [{
                    display: false,
                    gridLines: {
                        display: false
                    }
                }]
            },
            legend: {
                display: false
            },
        }

        return (
            <Fragment>
                {/* {revenueData.length > 0 ? */}
                    <Line data={dataLine} options={options} height={120} />
                    {/* : null} */}
            </Fragment>
        );
    }
}

export default RevenueLineChart;