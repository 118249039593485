import Validator, { ValidationTypes } from 'js-object-validation';

export const blogValidator = (data: any) => {
  const validations = {
    title: {
      [ValidationTypes.REQUIRED]: true
    },
    author: {
      [ValidationTypes.REQUIRED]: true
    },
    // date: {
    //   [ValidationTypes.REQUIRED]: true
    // },
    // description: {
    //   [ValidationTypes.REQUIRED]: true
    // },
  };
  // Error messages
  const messages = {
    title: {
      [ValidationTypes.REQUIRED]: "Blog title is required.",
    },
    author: {
      [ValidationTypes.REQUIRED]: "Author name is required.",
    },
    // date: {
    //   [ValidationTypes.REQUIRED]: "Date is required.",
    // },
    // description: {
    //   [ValidationTypes.REQUIRED]: "Description is required.",
    // },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};