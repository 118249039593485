import React, { Component, Fragment, FormEvent, ChangeEvent } from 'react';
import { ApiHelper } from "../../../settings/helpers/ApiHelper";
import { IBusinessTypeComponentProps, IBusinessTypeComponentState } from './interface';
import BusinessTypeComponent from './BusinessType.component';
import { businessTypeSaveValidator } from '../../../settings/helpers/validator';
import { confirmBox, asyncSetState } from "../../../settings/helpers/Common";
import { showErrorToast, showSuccessToast } from '../../../settings/helpers/toast';
import * as qs from 'query-string';
import debounce from 'lodash/debounce';

class BusinessType extends Component<IBusinessTypeComponentProps, IBusinessTypeComponentState> {
	constructor(props: any) {
		super(props);
		this.state = {
			businessTypeName: '',
			errors: { businessTypeName: '' },
			name: '',
			isSubmitting: false,
			businessTypes: [],
			totalPages: 0,
			totalRecords: 0,
			pageNumber: 1,
			pageLimit: 100,
			firstRecordNo: 0,
			lastRecordNo: 0,
			loading: true,
			ids: [],
			checked: false,
			isEdit: false
		};
	}

	componentDidMount = async () => {
		await this.getAllBusinessTypes();
		this.onChangeDebounced = debounce(this.onChangeDebounced, 500);
	}

	componentDidUpdate = async (prevProps: IBusinessTypeComponentProps) => {
		if (prevProps.location !== this.props.location) {
			await this.getAllBusinessTypes();
		}
	};

	// function to save business type
	onBusinessTypeSave = async () => {
		this.setState({ isSubmitting: true });
		let { businessTypeName } = this.state;
		businessTypeName = businessTypeName.trim();
		const payload = { businessTypeName };
		// To validate form fields
		let { isValid, errors } = businessTypeSaveValidator(payload);
		if (isValid) {
			try {
				const res = await new ApiHelper().FetchFromServer(
					'/superadmin',
					'/businesstypes/save',
					'POST',
					true,
					undefined,
					payload
				);
				const { data } = res;
				if (data.statusCode === 200) {
					showSuccessToast(res.data.message);
					this.getAllBusinessTypes();
					this.setState({ businessTypeName: '' });
				} else {
					showErrorToast(res.data.message);
				}
				this.setState({ isSubmitting: false });
			} catch (error) {
				this.setState({ isSubmitting: false });
			}
		} else {
			this.setState({
				errors,
				isSubmitting: false
			});
			return;
		}
	};

	// Update businessType details
	onBusinessTypeUpdate = async (item: any, statusUpdate: boolean, name: string, ) => {
		try {
			if (!statusUpdate) {
				let { businessTypeName } = this.state;
				businessTypeName = businessTypeName.trim();
				const payload = { businessTypeName };
				let { isValid, errors } = businessTypeSaveValidator(payload);

				if (isValid) {
					item.name = name;
					this.update(item);
				} else {
					this.setState({
						errors
					});
					return;
				}
			} else {
				const { value } = await confirmBox({
					title: "Are you sure?",
					text: `Do you want to ${
						item.status === 'Active' ? 'inactivate' : 'activate'
						} this businessType?`,
					confirmButtonText: `Yes, ${
						item.status === 'Active' ? 'inactivate' : 'activate'
						} it`,
				});

				if (value) {
					if (item.status === 'Active') {
						item.status = 'Inactive'
					} else {
						item.status = 'Active'
					}
					this.update(item);
				} else {
					return;
				}
			}
		} catch (error) { }
	};

	// Update function
	update = async (item: any) => {
		const res = await new ApiHelper().FetchFromServer(
			"/superadmin",
			"/businesstypes/update",
			"POST",
			true,
			undefined,
			item
		);

		if (res.data.statusCode === 200) {
			showSuccessToast(res.data.message);
			this.setState(
				{
					pageNumber: this.state.pageNumber,
					isEdit: false,
					businessTypeName: '',
				},
				() => {
					this.getAllBusinessTypes();
				}
			);
		} else {
			showErrorToast(res.data.message);
			this.getAllBusinessTypes();
		}
	};

	// Comman function for save and update businessType
	onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (this.state.isEdit) {
			this.onBusinessTypeUpdate(this.state.newObject, false, this.state.businessTypeName);
		} else {
			this.onBusinessTypeSave();
		}
	}

	// Recet form function
	onCancel = () => {
		this.setState({
			newObject: '',
			isEdit: false,
			businessTypeName: '',
			errors: ''
		})
	}

	// Function to remove businessType
	onBusinessTypeRemove = async (businessTypeIds: any) => {
		let obj = {};
		let text = "Do you want to delete this business type?";
		if (typeof businessTypeIds === 'string') {
			obj = {
				ids: [businessTypeIds]
			}
		} else {
			obj = {
				ids: this.state.ids
			}
			text = "Do you want to delete selected business types?";
		}

		const { value } = await confirmBox({
			title: "Are you sure?",
			text: text
		});
		if (value) {
			try {
				const res = await new ApiHelper().FetchFromServer(
					"/superadmin",
					"/businesstypes/remove",
					"POST",
					true,
					undefined,
					obj
				);

				if (res.data.statusCode === 200) {
					showSuccessToast(res.data.message);
					this.setState({ ids: [], checked: false },
						() => {
							this.getAllBusinessTypes();
						}
					);
				}
			} catch (error) { }
		} else {
			return;
		}
	};

	// Get all businessTypes
	getAllBusinessTypes = async () => {
		try {
			await asyncSetState(this)({ ...this.state, loading: true });

			const current = this.props.location.search;
			let search: any = {};
			search = { ...qs.parse(current) };
			let pageNumber = 1;
			let name = '';
			let status = '';

			const currentPage = this.state.pageNumber;
			if (search.page && parseInt(search.page) !== currentPage) {
				pageNumber = parseInt(search.page);
			}
			if (search.name) {
				name = search.name;
			}
			if (search.status) {
				status = search.status;
			}

			await asyncSetState(this)({
				...this.state,
				pageNumber,
				name,
				status
			});

			const res: any = await new ApiHelper().FetchFromServer(
				'/superadmin',
				`/businesstypes/getAllBusinessTypes?page=${this.state.pageNumber}&limit=${this.state.pageLimit}&name=${this.state.name}&status=${this.state.status}`,
				'GET',
				true,
				undefined,
				undefined
			);

			if (res.data.data.length > 0) {
				this.setState({
					businessTypes: res.data.data,
					totalRecords: res.data.totalCount,
					totalPages: res.data.totalPages,
					firstRecordNo: ((this.state.pageNumber - 1) * this.state.pageLimit) + 1,
					lastRecordNo: ((this.state.pageNumber - 1) * this.state.pageLimit) + res.data.data.length,
					loading: false
				});
			} else {
				this.setState({
					loading: false,
					businessTypes: [],
					totalRecords: 0,
					totalPages: 0,
					firstRecordNo: 0,
					lastRecordNo: 0
				});
			}
		} catch (error) {
			this.setState({
				loading: false,
				businessTypes: [],
				totalRecords: 0,
				totalPages: 0,
				firstRecordNo: 0,
				lastRecordNo: 0
			});
		}
	};

	// Function for page change
	onPageChange = async (page: number) => {
		if (page !== this.state.pageNumber) {
			await this.props.history.push(
				`/wellness-providers?${qs.stringify({
					tab: "business-types",
					page: page,
					name: this.state.name,
					status: this.state.status
				})}`
			);
		}
	};

	// Function for search records
	onSearch = async (e: FormEvent<HTMLFormElement> | undefined) => {
		if (e) {
			e.preventDefault();
		}
		new ApiHelper().cancelRequest(); // Cancel previous pending request if any
		await this.props.history.push(
			`/wellness-providers?${qs.stringify({
				tab: "business-types",
				page: 1,
				name: this.state.name,
				status: this.state.status
			})}`
		);
	};

	onSearchChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		await asyncSetState(this)({ ...this.state, [name]: value });
		await this.onChangeDebounced();
	};

	// Function for filter records
	onFilter = async (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		await asyncSetState(this)({ ...this.state, [name]: value });
		await this.onSearch(undefined);
	};

	onChangeDebounced = async () => {
		await this.onSearch(undefined); // Delayed function, call api to get searched values values
	};

	// Function to bind values
	handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		this.setState({
			...this.state,
			[name]: value,
			errors: {
				...this.state.errors,
				[name]: '',
			},
		});
	};

	// Function for check all checkbox
	handleCheckAllCheckBox = (e: any) => {
		const { checked } = e.target;
		const { businessTypes } = this.state;
		if (!checked) {
			this.setState({
				ids: [],
			});
		} else {
			const checkBoxIds = [];
			for (let i = 0; i < businessTypes.length; i++) {
				const element = businessTypes[i];
				checkBoxIds.push(element._id);
			}
			this.setState({
				ids: checkBoxIds,
			});
		}
	};

	// Function for check box change
	handleCheckboxChange = (e: any) => {
		const { checked, value } = e.target;
		const { ids } = this.state;
		if (checked) {
			ids.push(value);
		} else {
			var index = ids.indexOf(value);
			if (index !== -1) {
				ids.splice(index, 1);
			}
		}
		this.setState({
			ids,
		});
	};

	// Function for update
	handleUpdate = (item: any) => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		this.setState({
			newObject: item,
			isEdit: true,
			businessTypeName: item.name,
		})
	}

	render() {
		return (
			<Fragment>
				<BusinessTypeComponent
					{...this.state}
					handleChange={this.handleChange}
					onSubmit={this.onSubmit}
					onCancel={this.onCancel}
					onPageChange={this.onPageChange}
					onSearchChange={this.onSearchChange}
					onFilter={this.onFilter}
					onBusinessTypeUpdate={this.onBusinessTypeUpdate}
					onBusinessTypeRemove={this.onBusinessTypeRemove}
					handleCheckAllCheckBox={this.handleCheckAllCheckBox}
					handleCheckboxChange={this.handleCheckboxChange}
					handleUpdate={this.handleUpdate}
				/>
			</Fragment>
		);
	}
}

export default BusinessType;