import React, { PureComponent } from 'react';
import { IFinanceProps, IFinanceState } from './interface';
import FinanceComponent from './Finance.component';
import { ApiHelper } from '../../settings/helpers/ApiHelper';
import { asyncSetState } from '../../settings/helpers/Common';
import { withRouter } from 'react-router';
import CommissionModal from './CommissionModel';

class Finance extends PureComponent<IFinanceProps, IFinanceState> {
	state: IFinanceState = {
		commissionData: {},
		commissionLoading: false,
		totalIncomes: 0,
		incomeLoading: true,
		revenueData: [],
		revenueDataLoader: true,
		revenueDateRange: 'Today',
		commissionDateRange: 'Today',
		isModelShow: false
	};

	componentDidMount = async () => {
		this.getTotalCommission();
		this.getRevenues();
		this.getCommissionDetails();
	};

	// Function to get commission
	getTotalCommission = async () => {
		try {
			const result = await new ApiHelper().FetchFromServer(
				"/superadmin",
				"/finances/getTotalCommission",
				"POST",
				true,
				undefined,
				{ dateRange: this.state.commissionDateRange }
			);
			const { data } = result;
			if (data.data.length > 0) {
				await asyncSetState(this)({
					...this.state,
					totalIncomes: data.data[0].total,
					incomeLoading: false,
				});
			} else {
				this.setState({
					totalIncomes: 0,
					incomeLoading: false
				});
			}
		} catch (error) {
			this.setState({
				totalIncomes: 0,
				incomeLoading: false
			});
		}
	}

	// Function to get revenues
	getRevenues = async () => {
		try {
			const res = await new ApiHelper().FetchFromServer(
				"/superadmin",
				"/dashboard/getRevenues",
				"POST",
				true,
				undefined,
				{ dateRange: this.state.revenueDateRange }
			);
			if (res.data.data.length > 0) {
				this.setState({
					revenueData: res.data.data[0],
					revenueDataLoader: false
				});
			} else {
				this.setState({
					revenueData: [],
					revenueDataLoader: false
				});
			}
		} catch (error) {
			this.setState({
				revenueData: [],
				revenueDataLoader: false
			});
		}
	}

	// Function to change revenue state
	onRevenueChange = async (value: any) => {
		this.setState({ revenueDateRange: value },
			() => { this.getRevenues(); }
		);
	}

	// Function to change revenue state
	onCommissionChange = async (value: any) => {
		this.setState({ commissionDateRange: value },
			() => { this.getTotalCommission(); }
		);
	}

	// Function to get commission details
	getCommissionDetails = async () => {
		try {
			this.setState({ commissionLoading: true, commissionData: {} });
			const res = await new ApiHelper().FetchFromServer(
				"/superadmin",
				"/commissions/getCommissionDetails",
				"GET",
				true,
				undefined,
				undefined
			);

			if (res.data.data.length > 0) {
				this.setState({
					commissionData: res.data.data[0],
					commissionLoading: false
				});
			} else {
				this.setState({
					commissionData: {},
					commissionLoading: false
				});
			}
		} catch (error) {
			this.setState({
				commissionData: {},
				commissionLoading: false
			});
		}
	};

	handleModalShow = async () => {
		this.setState({ isModelShow: true });
	};

	handleModalClose = async () => {
		this.setState({ isModelShow: false });
	};

	render() {
		return (
			<div className='page-header-wrap'>
				<div className='page-header'>
					<h3 className='page-title'>
						<span className='heading-text'>Finance</span>
					</h3>
				</div>
				<FinanceComponent
					{...this.state}
					onRevenueChange={this.onRevenueChange}
					onCommissionChange={this.onCommissionChange}
					handleModalShow={this.handleModalShow}
				/>
				<CommissionModal {...this.state} handleModalClose={this.handleModalClose} getCommissionDetails={this.getCommissionDetails} />
			</div>
		);
	}
}

export default withRouter(Finance);