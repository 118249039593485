import {AppConfiguration, AppEnvironment} from './interface';

const appEnv: string = process.env.REACT_APP_ENV || "development";

const appModes: Record<string, AppEnvironment> = {
	development: AppEnvironment.DEVELOPMENT,
	staging: AppEnvironment.STAGING,
	production: AppEnvironment.PROD
}

const mode: AppEnvironment = appModes[appEnv]

const appConfigs: Record<string, AppConfiguration> = {
	[AppEnvironment.PROD]: {
		SITE_ENDPOINT: 'https://newhobi.com',
		API_ENDPOINT: 'https://api.newhobi.com',
		SOCKET_ENDPOINT: 'wss://api.newhobi.com',
		API_VERSION: 'api',
		IMAGE_URL: 'https://api.newhobi.com/api/files/uploads/thumbnail/200px/',
	},
	[AppEnvironment.STAGING]: {
		SITE_ENDPOINT: 'https://stg.newhobi.com',
		API_ENDPOINT: 'https://api.stg.newhobi.com',
		SOCKET_ENDPOINT: 'wss://api.stg.newhobi.com',
		API_VERSION: 'api',
		IMAGE_URL: 'https://api.stg.newhobi.com/api/files/uploads/thumbnail/200px/',
	},
	[AppEnvironment.DEVELOPMENT]: {
		SITE_ENDPOINT: 'http://localhost:3000',
		API_ENDPOINT: 'http://localhost:3005',
		SOCKET_ENDPOINT: 'wss://localhost:3005',
		API_VERSION: 'api',
		IMAGE_URL: 'http://localhost:3005/api/files/uploads/thumbnail/200px/',
	},
}

const data: AppConfiguration = {
	itemsPerPage: 10,
	defaultDateFormat: 'ddd, Do MMMM YYYY',
	defaultActivityDateFormat: 'YYYY-MM-DD HH:mm:s',
	...appConfigs[mode]
}

export const AcceptedImageFormat = [
  'image/jpg',
  'image/JPG',
  'image/png',
  'image/PNG',
  'image/bmp',
  'image/BMP',
  'image/jpeg',
  'image/JPEG',
  'image/tiff',
  'image/TIFF',
  'image/gif',
  'image/GIF',
];

export const isProd = mode === AppEnvironment.PROD;
export const AppConfig = data;

export const FrontEndWebsite = data.SITE_ENDPOINT
