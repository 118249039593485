import React, { Fragment, Component } from 'react';
import { IAddAboutUsCMSComponentProps, IAddAboutUsCMSComponentState } from './interface';
import { Form, Row, Col, Card, Button } from 'react-bootstrap';
import { blobUrl, imageValidateAndSave } from '../../../settings/helpers/Common';
import defaultImage from '../../../assets/img/upload-ic.svg'
import { AcceptedImageFormat } from '../../../settings/config';

class AddAboutUsCMSComponent extends Component<IAddAboutUsCMSComponentProps, IAddAboutUsCMSComponentState> {
	render() {
		const {
			// bannerCover,
			mainDescription,

			cardCover1,
			cardHeading1,
			cardDescription1,
			cardCover2,
			cardHeading2,
			cardDescription2,
			cardCover3,
			cardHeading3,
			cardDescription3,
			cardCover4,
			cardHeading4,
			cardDescription4,
			cardCover5,
			cardHeading5,
			cardDescription5,
			cardCover6,
			cardHeading6,
			cardDescription6,

			serviceButtonLink,
			serviceDescription,
			serviceCover1,
			serviceCover2,
			visionButtonLink1,
			visionButtonLink2,
			visionDescription,

			// bannerCoverError,
			// bannerCoverErrorMsg,
			cardCover1Error,
			cardCover1ErrorMsg,
			cardCover2Error,
			cardCover2ErrorMsg,
			cardCover3Error,
			cardCover3ErrorMsg,
			cardCover4Error,
			cardCover4ErrorMsg,
			cardCover5Error,
			cardCover5ErrorMsg,
			cardCover6Error,
			cardCover6ErrorMsg,
			serviceCover1Error,
			serviceCover1ErrorMsg,
			serviceCover2Error,
			serviceCover2ErrorMsg,

			handleChange,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			setFieldError,
			errors
		} = this.props;

		// const bannerCoverUrl = bannerCover && blobUrl(bannerCover);
		const cardCover1Url = cardCover1 && blobUrl(cardCover1);
		const cardCover2Url = cardCover2 && blobUrl(cardCover2);
		const cardCover3Url = cardCover3 && blobUrl(cardCover3);
		const cardCover4Url = cardCover4 && blobUrl(cardCover4);
		const cardCover5Url = cardCover5 && blobUrl(cardCover5);
		const cardCover6Url = cardCover6 && blobUrl(cardCover6);
		const serviceCover1Url = serviceCover1 && blobUrl(serviceCover1);
		const serviceCover2Url = serviceCover2 && blobUrl(serviceCover2);

		return (
			<Fragment>
				<div className="page-header-wrap">
					<div className="page-header">
						<h3 className='page-title'>
							<span className='heading-text'>About Us CMS</span>
						</h3>
					</div>
					<Form onSubmit={handleSubmit} className="form-section">

						<Row>

							{/* =========================================== */}

							{/* <Col lg={12} className="grid-margin">
								<Card>
									<Card.Header>
										<Card.Title>
											<span>Banner Section</span>
										</Card.Title>
									</Card.Header>
									<Card.Body>
										<Row>
											<Col lg={6}>
												<Form.Group controlId="profilepic">
													<Form.Label>Cover (1000 x 1000px ideal for hi-res)</Form.Label>
													<label className="file-input-upload profile-wrap half-profile">
														<div className="file-input-inner-upload text-center">
															{bannerCoverUrl ? (
																<div
																	className="img-preview"
																	style={{
																		backgroundImage: `url(${bannerCoverUrl})`,
																	}}
																/>
															) : (
																	<>
																		<img
																			src={defaultImage}
																			alt="upload"
																		/>
																		<h6 className="">Select file to upload your cover</h6>
																		<p className="mb-0">1000 x 1000px ideal for hi-res</p>
																	</>
																)}
															<Form.Control
																type="file"
																placeholder="Select image to upload"
																aria-describedby="profilepic"
																name="bannerCover"
																onChange={(event: any) => {
																	imageValidateAndSave(
																		event.currentTarget.files[0],
																		setFieldValue,
																		setFieldError,
																		'bannerCover',
																		1000,
																		1000
																	);
																}}
																autoComplete="profileImage"
																accept={AcceptedImageFormat.join()}
															/>
														</div>
													</label>
													<p className="editor-invalid-feedback">
														{bannerCoverError ? bannerCoverErrorMsg : ''}
													</p>
												</Form.Group>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col> */}

							{/* ======================================== */}

							<Col lg={12} className="grid-margin">
								<Card>
									<Card.Header>
										<Card.Title className="w-100">
											<div className="d-flex justify-content-between w-100">
												<span>What We Do </span>
												<span>Logo should be uploaded in a minimum 100 x 100px ideal resolution</span>
											</div>
										</Card.Title>
									</Card.Header>
									<Card.Body>
										<Row>
											<Col lg={6}>
												<Form.Group controlId="mainDescription">
													<Form.Label>Main Description (max 250 characters)</Form.Label>
													<Form.Control
														type="text"
														maxLength={250}
														aria-describedby="mainDescription"
														name="mainDescription"
														value={mainDescription}
														onChange={handleChange}
														autoComplete="mainDescription"
														isValid={mainDescription && !errors.mainDescription}
														isInvalid={errors.mainDescription}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.mainDescription}
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
										</Row>
										<Row>
											<Col lg={4}>
												<Form.Group controlId="profilepic">
													<Form.Label>Logo 1</Form.Label>
													<label className="file-input-upload profile-wrap half-profile">
														<div className="file-input-inner-upload text-center">
															{cardCover1Url ? (
																<div
																	className="img-preview"
																	style={{
																		backgroundImage: `url(${cardCover1Url})`,
																	}}
																/>
															) : (
																	<>
																		<img
																			src={defaultImage}
																			alt="upload"
																		/>
																		<h6 className="">Select file to upload your logo</h6>
																		{/* <p className="mb-0">500 x 500px ideal for hi-res</p> */}
																	</>
																)}
															<Form.Control
																type="file"
																placeholder="Select image to upload"
																aria-describedby="profilepic"
																name="cardCover1"
																onChange={(event: any) => {
																	setFieldValue('cardCover1', event.currentTarget.files[0])
																}}
																autoComplete="profileImage"
																accept={AcceptedImageFormat.join()}
															/>
														</div>
													</label>
													<p className="editor-invalid-feedback">
														{cardCover1Error ? cardCover1ErrorMsg : ''}
													</p>
												</Form.Group>
												<Form.Group controlId="cardHeading1">
													<Form.Label>Heading 1</Form.Label>
													<Form.Control
														type="text"
														maxLength={100}
														aria-describedby="cardHeading1"
														name="cardHeading1"
														value={cardHeading1}
														onChange={handleChange}
														autoComplete="cardHeading1"
														isValid={cardHeading1 && !errors.cardHeading1}
														isInvalid={errors.cardHeading1}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.cardHeading1}
													</Form.Control.Feedback>
												</Form.Group>
												<Form.Group controlId="cardDescription1">
													<Form.Label>Description 1 (max 250 characters)</Form.Label>
													<Form.Control
														as="textarea"
														maxLength={250}
														type="text"
														aria-describedby="cardDescription1"
														name="cardDescription1"
														value={cardDescription1}
														onChange={handleChange}
														autoComplete="cardDescription1"
														isValid={cardDescription1 && !errors.cardDescription1}
														isInvalid={errors.cardDescription1}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.cardDescription1}
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
											<Col lg={4}>
												<Form.Group controlId="profilepic">
													<Form.Label>Logo 2</Form.Label>
													<label className="file-input-upload profile-wrap half-profile">
														<div className="file-input-inner-upload text-center">
															{cardCover2Url ? (
																<div
																	className="img-preview"
																	style={{
																		backgroundImage: `url(${cardCover2Url})`,
																	}}
																/>
															) : (
																	<>
																		<img
																			src={defaultImage}
																			alt="upload"
																		/>
																		<h6 className="">Select file to upload your logo</h6>
																		{/* <p className="mb-0">500 x 500px ideal for hi-res</p> */}
																	</>
																)}
															<Form.Control
																type="file"
																placeholder="Select image to upload"
																aria-describedby="profilepic"
																name="cardCover2"
																onChange={(event: any) => {
																	setFieldValue('cardCover2', event.currentTarget.files[0])
																}}
																autoComplete="profileImage"
																accept={AcceptedImageFormat.join()}
															/>
														</div>
													</label>
													<p className="editor-invalid-feedback">
														{cardCover2Error ? cardCover2ErrorMsg : ''}
													</p>
												</Form.Group>
												<Form.Group controlId="cardHeading2">
													<Form.Label>Heading 2</Form.Label>
													<Form.Control
														type="text"
														maxLength={100}
														aria-describedby="cardHeading2"
														name="cardHeading2"
														value={cardHeading2}
														onChange={handleChange}
														autoComplete="cardHeading2"
														isValid={cardHeading2 && !errors.cardHeading2}
														isInvalid={errors.cardHeading2}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.cardHeading2}
													</Form.Control.Feedback>
												</Form.Group>
												<Form.Group controlId="cardDescription2">
													<Form.Label>Description 2 (max 250 characters)</Form.Label>
													<Form.Control
														as="textarea"
														type="text"
														maxLength={250}
														aria-describedby="cardDescription2"
														name="cardDescription2"
														value={cardDescription2}
														onChange={handleChange}
														autoComplete="cardDescription2"
														isValid={cardDescription2 && !errors.cardDescription2}
														isInvalid={errors.cardDescription2}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.cardDescription2}
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
											<Col lg={4}>
												<Form.Group controlId="profilepic">
													<Form.Label>Logo 3</Form.Label>
													<label className="file-input-upload profile-wrap half-profile">
														<div className="file-input-inner-upload text-center">
															{cardCover3Url ? (
																<div
																	className="img-preview"
																	style={{
																		backgroundImage: `url(${cardCover3Url})`,
																	}}
																/>
															) : (
																	<>
																		<img
																			src={defaultImage}
																			alt="upload"
																		/>
																		<h6 className="">Select file to upload your logo</h6>
																		{/* <p className="mb-0">500 x 500px ideal for hi-res</p> */}
																	</>
																)}
															<Form.Control
																type="file"
																placeholder="Select image to upload"
																aria-describedby="profilepic"
																name="cardCover3"
																onChange={(event: any) => {
																	setFieldValue('cardCover3', event.currentTarget.files[0])
																}}
																autoComplete="profileImage"
																accept={AcceptedImageFormat.join()}
															/>
														</div>
													</label>
													<p className="editor-invalid-feedback">
														{cardCover3Error ? cardCover3ErrorMsg : ''}
													</p>
												</Form.Group>
												<Form.Group controlId="cardHeading3">
													<Form.Label>Heading 3</Form.Label>
													<Form.Control
														type="text"
														maxLength={100}
														aria-describedby="cardHeading3"
														name="cardHeading3"
														value={cardHeading3}
														onChange={handleChange}
														autoComplete="cardHeading3"
														isValid={cardHeading3 && !errors.cardHeading3}
														isInvalid={errors.cardHeading3}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.cardHeading3}
													</Form.Control.Feedback>
												</Form.Group>
												<Form.Group controlId="cardDescription3">
													<Form.Label>Description 3 (max 250 characters)</Form.Label>
													<Form.Control
														as="textarea"
														type="text"
														maxLength={250}
														aria-describedby="cardDescription3"
														name="cardDescription3"
														value={cardDescription3}
														onChange={handleChange}
														autoComplete="cardDescription3"
														isValid={cardDescription3 && !errors.cardDescription3}
														isInvalid={errors.cardDescription3}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.cardDescription3}
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
										</Row>
									</Card.Body>
									<Card.Body>
										<Row>
											<Col lg={4}>
												<Form.Group controlId="profilepic">
													<Form.Label>Logo 4</Form.Label>
													<label className="file-input-upload profile-wrap half-profile">
														<div className="file-input-inner-upload text-center">
															{cardCover4Url ? (
																<div
																	className="img-preview"
																	style={{
																		backgroundImage: `url(${cardCover4Url})`,
																	}}
																/>
															) : (
																	<>
																		<img
																			src={defaultImage}
																			alt="upload"
																		/>
																		<h6 className="">Select file to upload your logo</h6>
																		{/* <p className="mb-0">500 x 500px ideal for hi-res</p> */}
																	</>
																)}
															<Form.Control
																type="file"
																placeholder="Select image to upload"
																aria-describedby="profilepic"
																name="cardCover4"
																onChange={(event: any) => {
																	setFieldValue('cardCover4', event.currentTarget.files[0])
																}}
																autoComplete="profileImage"
																accept={AcceptedImageFormat.join()}
															/>
														</div>
													</label>
													<p className="editor-invalid-feedback">
														{cardCover4Error ? cardCover4ErrorMsg : ''}
													</p>
												</Form.Group>
												<Form.Group controlId="cardHeading4">
													<Form.Label>Heading 4</Form.Label>
													<Form.Control
														type="text"
														maxLength={100}
														aria-describedby="cardHeading4"
														name="cardHeading4"
														value={cardHeading4}
														onChange={handleChange}
														autoComplete="cardHeading4"
														isValid={cardHeading4 && !errors.cardHeading4}
														isInvalid={errors.cardHeading4}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.cardHeading4}
													</Form.Control.Feedback>
												</Form.Group>
												<Form.Group controlId="cardDescription4">
													<Form.Label>Description 4 (max 250 characters)</Form.Label>
													<Form.Control
														as="textarea"
														maxLength={250}
														type="text"
														aria-describedby="cardDescription4"
														name="cardDescription4"
														value={cardDescription4}
														onChange={handleChange}
														autoComplete="cardDescription4"
														isValid={cardDescription4 && !errors.cardDescription4}
														isInvalid={errors.cardDescription4}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.cardDescription4}
													</Form.Control.Feedback>
												</Form.Group>
											</Col>

											<Col lg={4}>
												<Form.Group controlId="profilepic">
													<Form.Label>Logo 5</Form.Label>
													<label className="file-input-upload profile-wrap half-profile">
														<div className="file-input-inner-upload text-center">
															{cardCover5Url ? (
																<div
																	className="img-preview"
																	style={{
																		backgroundImage: `url(${cardCover5Url})`,
																	}}
																/>
															) : (
																	<>
																		<img
																			src={defaultImage}
																			alt="upload"
																		/>
																		<h6 className="">Select file to upload your logo</h6>
																		{/* <p className="mb-0">500 x 500px ideal for hi-res</p> */}
																	</>
																)}
															<Form.Control
																type="file"
																placeholder="Select image to upload"
																aria-describedby="profilepic"
																name="cardCover5"
																onChange={(event: any) => {
																	setFieldValue('cardCover5', event.currentTarget.files[0])
																}}
																autoComplete="profileImage"
																accept={AcceptedImageFormat.join()}
															/>
														</div>
													</label>
													<p className="editor-invalid-feedback">
														{cardCover5Error ? cardCover5ErrorMsg : ''}
													</p>
												</Form.Group>
												<Form.Group controlId="cardHeading5">
													<Form.Label>Heading 5</Form.Label>
													<Form.Control
														type="text"
														maxLength={100}
														aria-describedby="cardHeading5"
														name="cardHeading5"
														value={cardHeading5}
														onChange={handleChange}
														autoComplete="cardHeading5"
														isValid={cardHeading5 && !errors.cardHeading5}
														isInvalid={errors.cardHeading5}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.cardHeading5}
													</Form.Control.Feedback>
												</Form.Group>
												<Form.Group controlId="cardDescription5">
													<Form.Label>Description 5 (max 250 characters)</Form.Label>
													<Form.Control
														as="textarea"
														type="text"
														maxLength={250}
														aria-describedby="cardDescription5"
														name="cardDescription5"
														value={cardDescription5}
														onChange={handleChange}
														autoComplete="cardDescription5"
														isValid={cardDescription5 && !errors.cardDescription5}
														isInvalid={errors.cardDescription5}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.cardDescription5}
													</Form.Control.Feedback>
												</Form.Group>
											</Col>

											<Col lg={4}>
												<Form.Group controlId="profilepic">
													<Form.Label>Logo 6</Form.Label>
													<label className="file-input-upload profile-wrap half-profile">
														<div className="file-input-inner-upload text-center">
															{cardCover6Url ? (
																<div
																	className="img-preview"
																	style={{
																		backgroundImage: `url(${cardCover6Url})`,
																	}}
																/>
															) : (
																	<>
																		<img
																			src={defaultImage}
																			alt="upload"
																		/>
																		<h6 className="">Select file to upload your logo</h6>
																		{/* <p className="mb-0">500 x 500px ideal for hi-res</p> */}
																	</>
																)}
															<Form.Control
																type="file"
																placeholder="Select image to upload"
																aria-describedby="profilepic"
																name="cardCover6"
																onChange={(event: any) => {
																	setFieldValue('cardCover6', event.currentTarget.files[0])
																}}
																autoComplete="profileImage"
																accept={AcceptedImageFormat.join()}
															/>
														</div>
													</label>
													<p className="editor-invalid-feedback">
														{cardCover6Error ? cardCover6ErrorMsg : ''}
													</p>
												</Form.Group>
												<Form.Group controlId="cardHeading6">
													<Form.Label>Heading 6</Form.Label>
													<Form.Control
														type="text"
														maxLength={100}
														aria-describedby="cardHeading6"
														name="cardHeading6"
														value={cardHeading6}
														onChange={handleChange}
														autoComplete="cardHeading6"
														isValid={cardHeading6 && !errors.cardHeading6}
														isInvalid={errors.cardHeading6}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.cardHeading6}
													</Form.Control.Feedback>
												</Form.Group>
												<Form.Group controlId="cardDescription6">
													<Form.Label>Description 6 (max 250 characters)</Form.Label>
													<Form.Control
														as="textarea"
														type="text"
														maxLength={250}
														aria-describedby="cardDescription6"
														name="cardDescription6"
														value={cardDescription6}
														onChange={handleChange}
														autoComplete="cardDescription6"
														isValid={cardDescription6 && !errors.cardDescription6}
														isInvalid={errors.cardDescription6}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.cardDescription6}
													</Form.Control.Feedback>
												</Form.Group>
											</Col>

										</Row>
									</Card.Body>
								</Card>
							</Col>

							{/* ========================================= */}

							<Col lg={12} className="grid-margin">
								<Card>
									<Card.Header>
										<Card.Title>
											<span>BEST SERVICE</span>
										</Card.Title>
									</Card.Header>
									<Card.Body>
										<Row>
											<Col lg={6}>
												<Row>
													<Col lg={12}>
														<Form.Group controlId="serviceButtonLink">
															<Form.Label>Button Link</Form.Label>
															<Form.Control
																type="text"
																maxLength={150}
																aria-describedby="serviceButtonLink"
																name="serviceButtonLink"
																value={serviceButtonLink}
																onChange={handleChange}
																autoComplete="serviceButtonLink"
																isValid={serviceButtonLink && !errors.serviceButtonLink}
																isInvalid={errors.serviceButtonLink}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.serviceButtonLink}
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
													<Col lg={12}>
														<Form.Group controlId="serviceDescription">
															<Form.Label>Description (max 250 characters)</Form.Label>
															<Form.Control
																as="textarea"
																type="text"
																maxLength={250}
																aria-describedby="serviceDescription"
																name="serviceDescription"
																value={serviceDescription}
																onChange={handleChange}
																autoComplete="serviceDescription"
																isValid={serviceDescription && !errors.serviceDescription}
																isInvalid={errors.serviceDescription}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.serviceDescription}
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
												</Row>
											</Col>
											<Col lg={6}>
												<Row>
													<Col lg={6}>
														<Form.Group controlId="profilepic">
															<Form.Label>Cover 1 (300 x 300px ideal for hi-res)</Form.Label>
															<label className="file-input-upload profile-wrap half-profile">
																<div className="file-input-inner-upload text-center">
																	{serviceCover1Url ? (
																		<div
																			className="img-preview"
																			style={{
																				backgroundImage: `url(${serviceCover1Url})`,
																			}}
																		/>
																	) : (
																			<>
																				<img
																					src={defaultImage}
																					alt="upload"
																				/>
																				<h6 className="">Select file to upload your cover</h6>
																				<p className="mb-0">300 x 300px ideal for hi-res</p>
																			</>
																		)}
																	<Form.Control
																		type="file"
																		placeholder="Select image to upload"
																		aria-describedby="profilepic"
																		name="serviceCover1"
																		// onChange={(event: any) => {
																		// 	setFieldValue('serviceCover1', event.currentTarget.files[0])
																		// }}
																		onChange={(event: any) => {
																			imageValidateAndSave(
																				event.currentTarget.files[0],
																				setFieldValue,
																				setFieldError,
																				'serviceCover1',
																				300,
																				300
																			);
																		}}
																		autoComplete="serviceCover1"
																		accept={AcceptedImageFormat.join()}
																	/>
																</div>
															</label>
															<p className="editor-invalid-feedback">
																{serviceCover1Error ? serviceCover1ErrorMsg : ''}
															</p>
														</Form.Group>
													</Col>
													<Col lg={6}>
														<Form.Group controlId="profilepic">
															<Form.Label>Cover 2 (300 x 300px ideal for hi-res)</Form.Label>
															<label className="file-input-upload profile-wrap half-profile">
																<div className="file-input-inner-upload text-center">
																	{serviceCover2Url ? (
																		<div
																			className="img-preview"
																			style={{
																				backgroundImage: `url(${serviceCover2Url})`,
																			}}
																		/>
																	) : (
																			<>
																				<img
																					src={defaultImage}
																					alt="upload"
																				/>
																				<h6 className="">Select file to upload your cover</h6>
																				<p className="mb-0">300 x 300px ideal for hi-res</p>
																			</>
																		)}
																	<Form.Control
																		type="file"
																		placeholder="Select image to upload"
																		aria-describedby="profilepic"
																		name="serviceCover2"
																		// onChange={(event: any) => {
																		// 	setFieldValue('serviceCover2', event.currentTarget.files[0])
																		// }}
																		onChange={(event: any) => {
																			imageValidateAndSave(
																				event.currentTarget.files[0],
																				setFieldValue,
																				setFieldError,
																				'serviceCover2',
																				300,
																				300
																			);
																		}}
																		autoComplete="profileImage"
																		accept={AcceptedImageFormat.join()}
																	/>
																</div>
															</label>
															<p className="editor-invalid-feedback">
																{serviceCover2Error ? serviceCover2ErrorMsg : ''}
															</p>
														</Form.Group>
													</Col>
												</Row>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col>

							{/* ========================================= */}

							<Col lg={12} className="grid-margin">
								<Card>
									<Card.Header>
										<Card.Title>
											<span>We Bring Your Vision To Life</span>
										</Card.Title>
									</Card.Header>
									<Card.Body>
										<Row>
											<Col lg={6}>
												<Row>
													<Col lg={6}>
														<Form.Group controlId="visionButtonLink1">
															<Form.Label>Button Link 1</Form.Label>
															<Form.Control
																type="text"
																maxLength={150}
																aria-describedby="visionButtonLink1"
																name="visionButtonLink1"
																value={visionButtonLink1}
																onChange={handleChange}
																autoComplete="visionButtonLink1"
																isValid={visionButtonLink1 && !errors.visionButtonLink1}
																isInvalid={errors.visionButtonLink1}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.visionButtonLink1}
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
													<Col lg={6}>
														<Form.Group controlId="visionButtonLink2">
															<Form.Label>Button Link 2</Form.Label>
															<Form.Control
																type="text"
																maxLength={250}
																aria-describedby="visionButtonLink2"
																name="visionButtonLink2"
																value={visionButtonLink2}
																onChange={handleChange}
																autoComplete="visionButtonLink2"
																isValid={visionButtonLink2 && !errors.visionButtonLink2}
																isInvalid={errors.visionButtonLink2}
															/>
															<Form.Control.Feedback type="invalid">
																{errors.visionButtonLink2}
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
												</Row>
											</Col>
											<Col lg={6}>
												<Form.Group controlId="visionDescription">
													<Form.Label>Description (max 250 characters)</Form.Label>
													<Form.Control
														as="textarea"
														type="text"
														maxLength={250}
														aria-describedby="visionDescription"
														name="visionDescription"
														value={visionDescription}
														onChange={handleChange}
														autoComplete="visionDescription"
														isValid={visionDescription && !errors.visionDescription}
														isInvalid={errors.visionDescription}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.visionDescription}
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col>


							{/* ========================================= */}
							<Col lg={12}>
								<Form.Group className="d-flex justify-content-end">
									<Button type="submit" disabled={isSubmitting}>
										{isSubmitting ? "Saving..." : "Save"}
									</Button>
								</Form.Group>
							</Col>
						</Row>
					</Form>
				</div>
			</Fragment >
		);
	};
}
export default AddAboutUsCMSComponent;
