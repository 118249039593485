import React, { Component } from 'react'
import { Card, Spinner } from 'react-bootstrap'
import { ICustomeServiceProps } from '../interface'
import moment from 'moment'
import icon_empty from '../../../assets/img/Icon_Empty.svg';
import RCPagination from 'rc-pagination';
//@ts-ignore
import localeInfo from 'rc-pagination/lib/locale/en_US';
import {TIME_FORMAT} from '../../../settings/helpers/constants'
class Service extends Component<any>{
	render() {
		const { title, services, onSearchChange, isServiceLoading, totalRecords, pageLimit, onPageChange, firstRecordNo ,lastRecordNo, pageNumber} = this.props
		console.log(services,'services');
		
		return (
			<>
				<div className="service-box-wrapper">
					<div className="service-haeder d-flex justify-content-between align-items-center">
						<h3 className="sub-title clr-title mb-0">Services used</h3>
						<input className="search-filed form-control" type={title} name="title" placeholder="Search" onChange={onSearchChange}/>
					</div>
					
						<div className="service-list-wrapper table-responsive">
							<table className="table service-used-list">
								<thead>
									<tr>
										<th>S.No</th>
										<th>Activity</th>
										<th>$Price</th>
										<th>Time</th>
									</tr>
								</thead>
								<tbody>
										{isServiceLoading ?
												<tr>
													<td colSpan={8}>
														<div className="d-flex justify-content-center align-items-center table-spinner">
															<Spinner animation="border" />
														</div>
													</td>
												</tr>
												:services && services.length > 0 ?
													services.map((value:any, index:number) =>(
									<tr key ={index}>
										<td>{index + 1 + (pageNumber - 1) * pageLimit}</td>
										<td>{value.title}</td>

										<td>{value.price}</td>
										<td>
											{ `
												${moment(value.startTime).format(TIME_FORMAT)} -  
												${moment(value.date).format('MM/DD/YYYY')} 
											`}</td>
										{/*
										<td>{value.price ? (
												<>$ {value.price.toFixed(2)}</>
											) : (
													<>$ 0.00</>
												)}</td>
										<td>{ `${value.startTime} ${moment(value.date).format('MM/DD/YYYY')}`}</td>
										*/}

									</tr>
								))
										:		(
											<tr>
												<td colSpan={8} className={"text-center"}>
													<div className={'no-result-block'}>
														<div className='empty-ico'>
															<img alt='Icon_Empty.svg' width='50' src={icon_empty} />
														</div>
														<h5> No service booked yet</h5>
													</div>
												</td>
											</tr>
										)	}
								</tbody>
							</table>
						</div>
						<div className="table-pagination-wrap">
									<div>
										<RCPagination
											className="custom-pagination-design"
											current={pageNumber}
											total={totalRecords}
											defaultPageSize={pageLimit}
											pageSize={pageLimit}
											hideOnSinglePage={true}
											showPrevNextJumpers={true}
											showSizeChanger={true}
											pageSizeOptions={['5', '10', '25', '100']}
											onChange={onPageChange}
											locale={localeInfo}
										/>
									</div>
									{totalRecords > 0 ? (
										<div className="records-filter">
											<div className="records-number">
												Displaying {firstRecordNo} - {lastRecordNo} of{' '}
												{totalRecords} records{' '}
											</div>
										</div>
									) : null}
								</div>

										</div>
									

			</>
		)
	}
}
export default Service