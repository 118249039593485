import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { IEventDetailsComponentState } from './interface';
import {
	Row,
	Col,
	Card,
	Modal,
} from 'react-bootstrap';

import close from "../../../assets/img/cancel.svg";
import closeblue from "../../../assets/img/cancel-blue.svg";

class EventDetailsComponent extends Component<IEventDetailsComponentState> {
	state: IEventDetailsComponentState = {};

	render() {
		const { eventDetails } = this.props;

		return (
			<Fragment>
				<Modal
					size={"lg"}
					show={this.props.isModelShow}
					onHide={this.props.onModelHide}
					aria-labelledby="contained-modal-title-vcenter"
					centered
					className='details-modal-wrapper'
				>
					<Modal.Header>
						<Modal.Title id="contained-modal-title-vcenter">Event Details</Modal.Title>
						<button
							type="button"
							className="close modal-close"
							onClick={this.props.onModelHide}
							aria-label="Close"
						>
							<img src={close} alt="close" className="main-img" />
							<img src={closeblue} alt="close" className="hover-img" />
						</button>
					</Modal.Header>
					<Modal.Body>
						<div className='custom-scrollbar'>
							<Row>
								<Col lg={6} className='grid-margin'>
									<Card>
										<Card.Header>
											<Card.Title>
												<span>Event Information</span>
											</Card.Title>
										</Card.Header>
										<Card.Body>
											<Row>
												<Col md={12} className='text-center'>
													<div className='image-section mb-3'>
														<div
															className='info-image'
														// style={{
														// 	backgroundImage: `url(${eventDetails.coverThumbUrl})`,
														// }}
														>
															<img src={eventDetails.coverThumbUrl} alt="" />
														</div>
													</div>
												</Col>
												<Col md={4}>
													<label className='text-label'>Event Name</label>
												</Col>
												<Col md={8}>
													<span className='text-value'>{eventDetails.name}</span>
												</Col>
											</Row>
											<Row>
												<Col md={4}>
													<label className='text-label'>Branch</label>
												</Col>
												<Col md={8}>
													<span className='text-value'>
														{eventDetails.branchName}
													</span>
												</Col>
											</Row>
											<Row>
												<Col md={4}>
													<label className='text-label'>Price</label>
												</Col>
												<Col md={8}>
													<span className='text-value price-text'>
														{eventDetails.price ? (
															<>$ {eventDetails.price.toFixed(2)}</>
														) : (
																<>$ 0.00</>
															)}
													</span>
												</Col>
											</Row>

											<Row>
												<Col md={4}>
													<label className='text-label'>Type</label>
												</Col>
												<Col md={8}>
													<span className='text-value'>{eventDetails.type}</span>
												</Col>
											</Row>

											<Row>
												<Col md={4}>
													<label className='text-label'>Request&nbsp;Date</label>
												</Col>
												<Col md={8}>
													<span className='text-value'>
														{moment(eventDetails.createdAt).format('LLL')}
													</span>
												</Col>
											</Row>
											<Row>
												<Col md={4}>
													<label className='text-label'>Status</label>
												</Col>
												<Col md={8}>
													<label
														className={
															'badge ' +
															(eventDetails.status === 'Active'
																? 'badge-success'
																: 'badge-danger')
														}
													>
														{eventDetails.status}
													</label>
													{/* <DropdownButton
												alignRight
												id=''
												title={eventDetails.status}
												variant='link'
												className={
													'status-dropdown-wrap ' +
													(eventDetails.status === 'Active'
														? 'success'
														: 'danger')
												}
											>
												{eventDetails.status !== 'Active' ? (
													<Dropdown.Item eventKey='Active'>Active</Dropdown.Item>
												) : null}
												{eventDetails.status !== 'Inactive' ? (
													<Dropdown.Item eventKey='Inactive'>
														Inactive
												</Dropdown.Item>
												) : null}
												{eventDetails.status !== 'Upcoming' ? (
													<Dropdown.Item eventKey='Upcoming'>
														Upcoming
												</Dropdown.Item>
												) : null}
												{eventDetails.status !== 'Closed' ? (
													<Dropdown.Item eventKey='Closed'>Closed</Dropdown.Item>
												) : null}
											</DropdownButton> */}
												</Col>
											</Row>
											{eventDetails.description ?
												<Row>
													<Col md={4}>
														<label className='text-label'>Description</label>
													</Col>
													<Col md={8}>
														<div className='text-value mb-2'>
															{eventDetails.description}
														</div>
													</Col>
												</Row>
												: null}
										</Card.Body>
									</Card>
								</Col>
								<Col lg={6} className='grid-margin'>
									<Card>
										<Card.Header>
											<Card.Title>
												<span>Slot Details</span>
											</Card.Title>
										</Card.Header>
										<Card.Body>
											<div className='event-timing'>
												<Row>
													<Col md={4}>
														<label className='text-label'>Total Slot</label>
													</Col>
													<Col md={8}>
														<span className='text-value'>{eventDetails.slot}</span>
													</Col>
												</Row>
												{/* <Row>
												<Col md={4}>
													<label className='text-label'>Buying Slot</label>
												</Col>
												<Col md={8}>
													<span className='text-value'>
														{eventDetails.buyingSlot}
													</span>
												</Col>
											</Row> 
											 <Row>
												<Col md={4}>
													<label className='text-label'>Remaining Slot</label>
												</Col>
												<Col md={8}>
													<span className='text-value'>
														{eventDetails.slot - eventDetails.buyingSlot}
													</span>
												</Col>
											</Row>{' '} */}
												<Row>
													<Col md={4}>
														<label className='text-label'>Address</label>
													</Col>
													<Col md={8}>
														<span className='text-value'>{eventDetails.address}</span>
													</Col>
												</Row>
												<Row>
													<Col md={4}>
														<label className='text-label'>Event Time</label>
													</Col>
													<Col md={8}>
														<span className='text-value'>
															{moment(eventDetails.eventTime).format('h:mm A')}
														</span>
													</Col>
												</Row>
												<Row>
													<Col md={4}>
														<label className='text-label'>Event Dates</label>
													</Col>
													<Col md={8}>
														<span className='text-value'>
															{eventDetails.eventDates
																? eventDetails.eventDates.map(
																	(item: any, index: number) => {
																		return (
																			<span key={index} className='mr-2'>
																				{moment(item).format('D MMM YYYY')}
																			</span>
																		);
																	}
																)
																: null}
														</span>
													</Col>
												</Row>
											</div>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</div>
					</Modal.Body>
					{/* <Modal.Footer>
						<Button
							type="button"
							variant="link"
							className="btn btn-secondary"
							onClick={this.props.onModelHide}
						>
							Close
						</Button>
					</Modal.Footer> */}
				</Modal>
			</Fragment>
		)
	}
}

export default EventDetailsComponent;
