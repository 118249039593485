import Validator, { ValidationTypes } from 'js-object-validation';

export const aboutUsCmsValidator = (data: any) => {
  const validations = {
    mainDescription: {
      [ValidationTypes.REQUIRED]: true
    },
    cardHeading1: {
      [ValidationTypes.REQUIRED]: true
    },
    cardDescription1: {
      [ValidationTypes.REQUIRED]: true
    },
    cardHeading2: {
      [ValidationTypes.REQUIRED]: true
    },
    cardDescription2: {
      [ValidationTypes.REQUIRED]: true
    },
    cardHeading3: {
      [ValidationTypes.REQUIRED]: true
    },
    cardDescription3: {
      [ValidationTypes.REQUIRED]: true
    },
    cardHeading4: {
      [ValidationTypes.REQUIRED]: true
    },
    cardDescription4: {
      [ValidationTypes.REQUIRED]: true
    },
    cardHeading5: {
      [ValidationTypes.REQUIRED]: true
    },
    cardDescription5: {
      [ValidationTypes.REQUIRED]: true
    },
    cardHeading6: {
      [ValidationTypes.REQUIRED]: true
    },
    cardDescription6: {
      [ValidationTypes.REQUIRED]: true
    },
    serviceButtonLink: {
      [ValidationTypes.REQUIRED]: true
    },
    serviceDescription: {
      [ValidationTypes.REQUIRED]: true
    },
    visionButtonLink1: {
      [ValidationTypes.REQUIRED]: true
    },
    visionButtonLink2: {
      [ValidationTypes.REQUIRED]: true
    },
    visionDescription: {
      [ValidationTypes.REQUIRED]: true
    }
  };
  // Error messages
  const messages = {
    mainDescription: {
      [ValidationTypes.REQUIRED]: "Main Description is required.",
    },
    cardHeading1: {
      [ValidationTypes.REQUIRED]: "Heading 1 is required.",
    },
    cardDescription1: {
      [ValidationTypes.REQUIRED]: "Description 1 is required.",
    },
    cardHeading2: {
      [ValidationTypes.REQUIRED]: "Heading 2 is required.",
    },
    cardDescription2: {
      [ValidationTypes.REQUIRED]: "Description 2 is required.",
    },
    cardHeading3: {
      [ValidationTypes.REQUIRED]: "Heading 3 is required.",
    },
    cardDescription3: {
      [ValidationTypes.REQUIRED]: "Description 3 is required.",
    },
    cardHeading4: {
      [ValidationTypes.REQUIRED]: "Heading 4 is required.",
    },
    cardDescription4: {
      [ValidationTypes.REQUIRED]: "Description 4 is required.",
    },
    cardHeading5: {
      [ValidationTypes.REQUIRED]: "Heading 5 is required.",
    },
    cardDescription5: {
      [ValidationTypes.REQUIRED]: "Description 5 is required.",
    },
    cardHeading6: {
      [ValidationTypes.REQUIRED]: "Heading 6 is required.",
    },
    cardDescription6: {
      [ValidationTypes.REQUIRED]: "Description 6 is required.",
    },
    serviceButtonLink: {
      [ValidationTypes.REQUIRED]: "Button Link is required.",
    },
    serviceDescription: {
      [ValidationTypes.REQUIRED]: "Description is required.",
    },
    visionButtonLink1: {
      [ValidationTypes.REQUIRED]: "Button Link 1 is required.",
    },
    visionButtonLink2: {
      [ValidationTypes.REQUIRED]: "Button Link 2 is required.",
    },
    visionDescription: {
      [ValidationTypes.REQUIRED]: "Description is required.",
    }
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};