import React, { Component } from 'react'
import { Card, Spinner, Nav } from 'react-bootstrap'
import subService from '../../../assets/img/sub-service.svg';
import subServiceHover from '../../../assets/img/sub-service-hover.svg';
import service from '../../../assets/img/service-tab.svg';
import serviceHover from '../../../assets/img/service-tab-hover.svg';
import classIco from '../../../assets/img/class1.svg';
import classIcoHover from '../../../assets/img/class-hover.svg';
import eventHover from '../../../assets/img/gestures-tab-hover.svg';
import event from '../../../assets/img/gestures.svg';

import moment from 'moment'
import ServiceList from './ServiceList'
import ClassList from './ClassList';
import EventList from './EventList';
class Service extends Component<any> {

	render() {
		const {services, title, onSearchChange, tab, onLinkSelect, providerId}= this.props
		console.log(tab,'tabbbbbbbbbbb')
		return (
			<div className="service-box-wrapper">
				<div className="service-haeder d-flex justify-content-between align-items-center btm-bdr">
					<h3 className="sub-title clr-title mb-0">Services Offered</h3>
					<input className="search-filed form-control" type={title} name="title" placeholder="Search" onChange={onSearchChange} />
				</div>
				<Nav
						className='custom-nav-tabs'
						onSelect={(key: any) => {
							onLinkSelect(key);
						}}
					>
						<Nav.Item className="service-tabs">
							<Nav.Link
								eventKey='service'
								className={
									' ' + (tab === 'service' ? 'active' : null)
								}
							>
								<img src={service} alt='class' className='main-img' />
								<img src={serviceHover} alt='class' className='hover-img' />
								Private Sessions
							</Nav.Link>
						</Nav.Item>
						<Nav.Item className="service-tabs">
							<Nav.Link
								eventKey='class'
								className={' ' + (tab === 'class' ? 'active' : null)}
							>
								<img src={classIco} alt='class' className='main-img' />
								<img src={classIcoHover} alt='class' className='hover-img' />
								Group Classes
							</Nav.Link>
						</Nav.Item>
						<Nav.Item className="service-tabs">
							<Nav.Link
								eventKey='event'
								className={' ' + (tab === 'event' ? 'active' : null)}
							>
								<img src={event} alt='class' className='main-img' />
								<img src={eventHover} alt='class' className='hover-img' />
								Retreat Experience
							</Nav.Link>
						</Nav.Item>
						{/* <Nav.Item>
							<Nav.Link
								eventKey='visual-session'
								className={
									' ' + (this.state.tab === 'visual-session' ? 'active' : null)
								}
							>
								
									<img src={visualSession} alt='' className='main-img' />
									<img src={visualSessionHover} alt='' className='hover-img' />Virtual Session List
							</Nav.Link>
						</Nav.Item> */}
					</Nav>

					{ tab === 'service' ? (
					<ServiceList providerId={providerId} {...this.props}/>

					) 
					:
					tab === 'class' ? (
						<ClassList providerId={providerId} {...this.props}/>
	
						) 
					 : tab === 'event' ? (
						<EventList providerId={providerId} {...this.props}/>
	
						)
					:null
					}
          </div>
				
		)
	}
}
export default Service