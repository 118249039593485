import React, { Component } from 'react';
import { IWellnessProviderProps, IWellnessProviderState } from './interface';
import manager from '../../assets/img/managers.svg';
import usersicon from '../../assets/img/users.svg';
import managerhover from '../../assets/img/managers-hover.svg';
import usersiconhover from '../../assets/img/users-hover.svg';
import * as qs from 'query-string';
import Provider from './ManageProvider';
import BusinessType from './BusinessType';

class WellnessProvider extends Component<IWellnessProviderProps, IWellnessProviderState> {
	constructor(props: any) {
		super(props);
		this.state = {
			component: Provider,
			providerActive: true,
			businessTypeActive: false
		};
	}

	componentDidMount = async () => {
		let query: any = { ...qs.parse(this.props.location.search) };
		let tab = 'providers';
		if (query.tab === 'business-types') {
			tab = 'business-types';
			this.setState({
				component: BusinessType,
				businessTypeActive: true,
				providerActive: false,
			});
		}
		this.props.history.push(
			`/wellness-providers?${qs.stringify({
				tab: tab,
				page: 1,
				name: ''
			})}`
		);
	}

	// Function to change components tab
	changeComponent = async (cmp: any) => {
		let tab = 'providers';
		if (cmp === 'business-types') {
			tab = 'business-types';
		}

		this.props.history.push(
			`/wellness-providers?${qs.stringify({
				tab: tab,
				page: 1,
				name: ''
			})}`
		);

		if (cmp === 'business-types') {
			this.setState({
				component: BusinessType,
				providerActive: false,
				businessTypeActive: true
			});
		} else {
			this.setState({
				component: Provider,
				providerActive: true,
				businessTypeActive: false
			});
		}
	}


	render() {

		return (
			<div className="page-header-wrap" >
				<div className="page-header">
					<h3 className="page-title">
						<span className="heading-text">Wellness Providers</span>
					</h3>
				</div>
				<div className="nav-user-tabs d-flex align-items-center ">
					<div className={"nav-item " + (this.state.providerActive ? "active" : null)} onClick={() => this.changeComponent('providers')}>
						<span className="icon">
							<img src={manager} alt="" className="activeIco" />
							<img src={managerhover} alt="" className="nonactiveIco" />
						</span>
						<span>Providers List</span>
					</div>
					<div className={"nav-item " + (this.state.businessTypeActive ? "active" : null)} onClick={() => this.changeComponent('business-types')}>
						<span className="icon">
							<img src={usersicon} alt="" className="activeIco" />
							<img src={usersiconhover} alt="" className="nonactiveIco" />
						</span>
						<span>Business Types List</span>
					</div>
				</div>
				<this.state.component {...this.props}
				/>
			</div>
		);
	}
}

export default WellnessProvider;