import React, { Fragment, Component } from 'react';
import { IAddHomeCMSComponentProps, IAddHomeCMSComponentState } from './interface';
import { Form, Row, Col, Card, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { blobImageUrl, imageValidateAndSave, blobUrl } from '../../../settings/helpers/Common';
import defaultImage from '../../../assets/img/upload-ic.svg'
import { AcceptedImageFormat } from '../../../settings/config';
import icon_empty from '../../../assets/img/Icon_Empty.svg';

class AddHomeCMSComponent extends Component<IAddHomeCMSComponentProps, IAddHomeCMSComponentState> {
	render() {
		const {
			bannerCover,
			// logo,
			bannerHeading,
			bannerDescription,
			visualCover1,
			visualCover2,
			visualButtonLink,
			visualDescription,
			serviceCover1,
			serviceCover2,
			// serviceMainDescription,
			serviceHeading1,
			serviceButtonLink1,
			serviceDescription1,
			serviceHeading2,
			serviceButtonLink2,
			serviceDescription2,

			clientDescription,
			clientName,
			riderectLink,
			clientTestimonial,
			clientProfile,
			clientFileName,
			clientProfileError,
			clientProfileErrorMsg,
			testimonials,

			bannerCoverError,
			// logoError,
			visualCover1Error,
			visualCover2Error,
			serviceCover1Error,
			serviceCover2Error,
			bannerCoverErrorMsg,
			// logoErrorMsg,
			visualCover1ErrorMsg,
			visualCover2ErrorMsg,
			serviceCover1ErrorMsg,
			serviceCover2ErrorMsg,

			handleChange,
			handleSubmit,
			handleAddTestimonial,
			isSubmitting,
			setFieldValue,
			setFieldError,
			handleUpdateTestimonial,
			handleSaveTestimonial,
			handleRemoveTestimonial,
			isTestimonialEdit,
			testimonialIndex
		} = this.props;

		// const logoUrl = logo && blobImageUrl(logo);
		const bannerCoverUrl = bannerCover && blobUrl(bannerCover);
		const visualUrlCover1 = visualCover1 && blobUrl(visualCover1);
		const visualUrlCover2 = visualCover2 && blobUrl(visualCover2);
		const serviceCover1Url = serviceCover1 && blobUrl(serviceCover1);
		const serviceCover2Url = serviceCover2 && blobUrl(serviceCover2);
		const clientProfileUrl = clientProfile && blobImageUrl(clientProfile);

		return (
			<Fragment>
				<div className="page-header-wrap">
					<div className="page-header">
						<h3 className='page-title'>
							<span className='heading-text'>Home CMS</span>
						</h3>
					</div>
					<Form onSubmit={handleSubmit} className="form-section">

						<Row>

							{/* =========================================== */}

							<Col lg={12} className="grid-margin">
								<Card>
									<Card.Header>
										<Card.Title>
											<span>Banner Section</span>
										</Card.Title>
									</Card.Header>
									<Card.Body>
										<Row>
											<Col lg={6}>
												<Form.Group controlId="bannerHeading">
													<Form.Label>Heading</Form.Label>
													<Form.Control
														maxLength={100}
														as="textarea"
														type="text"
														aria-describedby="bannerHeading"
														name="bannerHeading"
														value={bannerHeading}
														onChange={handleChange}
														autoComplete="bannerHeading"
													/>
												</Form.Group>
												<Form.Group controlId="bannerDescription">
													<Form.Label>Description (max 250 characters)</Form.Label>
													<Form.Control
														maxLength={250}
														as="textarea"
														type="text"
														aria-describedby="bannerDescription"
														name="bannerDescription"
														value={bannerDescription}
														onChange={handleChange}
														autoComplete="bannerDescription"
													/>
												</Form.Group>
											</Col>
											<Col lg={6}>
												<Form.Group controlId="profilepic">
													<Form.Label>Cover (1000 x 1000px ideal for hi-res)</Form.Label>
													<label className="file-input-upload profile-wrap half-profile">
														<div className="file-input-inner-upload text-center">
															{bannerCoverUrl ? (
																<div
																	className="img-preview"
																	style={{
																		backgroundImage: `url(${bannerCoverUrl})`,
																	}}
																/>
															) : (
																	<>
																		<img
																			src={defaultImage}
																			alt="upload"
																		/>
																		<h6 className="">Select file to upload your cover</h6>
																		<p className="mb-0">1000 x 1000px ideal for hi-res</p>
																	</>
																)}
															<Form.Control
																type="file"
																placeholder="Select image to upload"
																aria-describedby="profilepic"
																name="bannerCover"
																// onChange={(event: any) => {
																// 	setFieldValue('bannerCover', event.currentTarget.files[0])
																// }}
																onChange={(event: any) => {
																	imageValidateAndSave(
																		event.currentTarget.files[0],
																		setFieldValue,
																		setFieldError,
																		'bannerCover',
																		1000,
																		1000
																	);
																}}
																autoComplete="bannerCover"
																accept={AcceptedImageFormat.join()}
															/>
														</div>
													</label>
													<p className="editor-invalid-feedback">
														{bannerCoverError ? bannerCoverErrorMsg : ''}
													</p>
												</Form.Group>
												{/* <Form.Group controlId="bannerDescription">
													<Form.Label>Description (max 250 characters)</Form.Label>
													<Form.Control
														maxLength={250}
														as="textarea"
														type="text"
														aria-describedby="bannerDescription"
														name="bannerDescription"
														value={bannerDescription}
														onChange={handleChange}
														autoComplete="bannerDescription"
													/>
												</Form.Group> */}
											</Col>
											{/* <Col lg={6}>
												<Form.Group controlId="profilepic">
													<Form.Label>Logo</Form.Label>
													<label className="file-input-upload profile-wrap half-profile">
														<div className="file-input-inner-upload text-center">
															{logoUrl ? (
																<div
																	className="img-preview"
																	style={{
																		backgroundImage: `url(${logoUrl})`,
																	}}
																/>
															) : (
																	<>
																		<img
																			src={defaultImage}
																			alt="upload"
																		/>
																		<h6 className="">Select file to upload your logo</h6>
																	</>
																)}
															<Form.Control
																type="file"
																placeholder="Select image to upload"
																aria-describedby="profilepic"
																name="logo"
																onChange={(event: any) => {
																	setFieldValue('logo', event.currentTarget.files[0])
																}}
																autoComplete="profileImage"
																accept={AcceptedImageFormat.join()}
															/>
														</div>
													</label>
													<p className="editor-invalid-feedback">
														{logoError ? logoErrorMsg : ''}
													</p>
												</Form.Group>
											</Col> */}
										</Row>
									</Card.Body>
								</Card>
							</Col>

							{/* ========================================= */}

							<Col lg={12} className="grid-margin">
								<Card>
									<Card.Header>
										<Card.Title>
											<span>Group Class</span>
										</Card.Title>
									</Card.Header>
									<Card.Body>
										{/* <Row>
											<Col lg={6}>
												<Form.Group controlId="serviceMainDescription">
													<Form.Label>Main Description (max 250 characters)</Form.Label>
													<Form.Control
														as="textarea"
														maxLength={250}
														type="text"
														aria-describedby="serviceMainDescription"
														name="serviceMainDescription"
														value={serviceMainDescription}
														onChange={handleChange}
														autoComplete="serviceMainDescription"
													/>
												</Form.Group>
											</Col>
										</Row> */}

										<Row>
											<Col lg={6}>
												<h5>Livestream Classes</h5>
												<Form.Group controlId="serviceHeading1">
													<Form.Label>Heading 1</Form.Label>
													<Form.Control
														type="text"
														maxLength={100}
														aria-describedby="serviceHeading1"
														name="serviceHeading1"
														value={serviceHeading1}
														onChange={handleChange}
														autoComplete="serviceHeading1"
													/>
												</Form.Group>
												<Form.Group controlId="profilepic">
													<Form.Label>Cover 1 (400 x 400px ideal for hi-res)</Form.Label>
													<label className="file-input-upload profile-wrap half-profile">
														<div className="file-input-inner-upload text-center">
															{serviceCover1Url ? (
																<div
																	className="img-preview"
																	style={{
																		backgroundImage: `url(${serviceCover1Url})`,
																	}}
																/>
															) : (
																	<>
																		<img
																			src={defaultImage}
																			alt="upload"
																		/>
																		<h6 className="">Select file to upload your cover</h6>
																		<p className="mb-0">400 x 400px ideal for hi-res</p>
																	</>
																)}
															<Form.Control
																type="file"
																placeholder="Select image to upload"
																aria-describedby="serviceCover1"
																name="serviceCover1"
																// onChange={(event: any) => {
																// 	setFieldValue('serviceCover1', event.currentTarget.files[0])
																// }}
																onChange={(event: any) => {
																	imageValidateAndSave(
																		event.currentTarget.files[0],
																		setFieldValue,
																		setFieldError,
																		'serviceCover1',
																		400,
																		200
																	);
																}}
																autoComplete="serviceCover1"
																accept={AcceptedImageFormat.join()}
															/>
														</div>
													</label>
													<p className="editor-invalid-feedback">
														{serviceCover1Error ? serviceCover1ErrorMsg : ''}
													</p>
												</Form.Group>
												<Form.Group controlId="serviceDescription1">
													<Form.Label>Description 1 (max 500 characters)</Form.Label>
													<Form.Control
														as="textarea"
														maxLength={500}
														type="text"
														aria-describedby="serviceDescription1"
														name="serviceDescription1"
														value={serviceDescription1}
														onChange={handleChange}
														autoComplete="serviceDescription1"
													/>
												</Form.Group>
												<Form.Group controlId="serviceButtonLink1">
													<Form.Label>Button Link 1</Form.Label>
													<Form.Control
														type="text"
														maxLength={250}
														aria-describedby="serviceButtonLink1"
														name="serviceButtonLink1"
														value={serviceButtonLink1}
														onChange={handleChange}
														autoComplete="serviceButtonLink1"
													/>
												</Form.Group>
											</Col>

											<Col lg={6}>
												<h5>Book Classes and More</h5>
												<Form.Group controlId="serviceHeading2">
													<Form.Label>Heading 2</Form.Label>
													<Form.Control
														type="text"
														maxLength={150}
														aria-describedby="serviceHeading2"
														name="serviceHeading2"
														value={serviceHeading2}
														onChange={handleChange}
														autoComplete="serviceHeading2"
													/>
												</Form.Group>
												<Form.Group controlId="profilepic">
													<Form.Label>Cover 2 (400 x 400px ideal for hi-res)</Form.Label>
													<label className="file-input-upload profile-wrap half-profile">
														<div className="file-input-inner-upload text-center">
															{serviceCover2Url ? (
																<div
																	className="img-preview"
																	style={{
																		backgroundImage: `url(${serviceCover2Url})`,
																	}}
																/>
															) : (
																	<>
																		<img
																			src={defaultImage}
																			alt="upload"
																		/>
																		<h6 className="">Select file to upload your cover</h6>
																		<p className="mb-0">400 x 400px ideal for hi-res</p>
																	</>
																)}
															<Form.Control
																type="file"
																placeholder="Select image to upload"
																aria-describedby="serviceCover2"
																name="serviceCover2"
																// onChange={(event: any) => {
																// 	setFieldValue('serviceCover2', event.currentTarget.files[0])
																// }}
																onChange={(event: any) => {
																	imageValidateAndSave(
																		event.currentTarget.files[0],
																		setFieldValue,
																		setFieldError,
																		'serviceCover2',
																		400,
																		200
																	);
																}}
																autoComplete="serviceCover2"
																accept={AcceptedImageFormat.join()}
															/>
														</div>
													</label>
													<p className="editor-invalid-feedback">
														{serviceCover2Error ? serviceCover2ErrorMsg : ''}
													</p>
												</Form.Group>
												<Form.Group controlId="serviceDescription2">
													<Form.Label>Description 2 (max 500 characters)</Form.Label>
													<Form.Control
														as="textarea"
														type="text"
														maxLength={500}
														aria-describedby="serviceDescription2"
														name="serviceDescription2"
														value={serviceDescription2}
														onChange={handleChange}
														autoComplete="serviceDescription2"
													/>
												</Form.Group>
												<Form.Group controlId="serviceButtonLink2">
													<Form.Label>Button Link 2</Form.Label>
													<Form.Control
														type="text"
														maxLength={250}
														aria-describedby="serviceButtonLink2"
														name="serviceButtonLink2"
														value={serviceButtonLink2}
														onChange={handleChange}
														autoComplete="serviceButtonLink2"
													/>
												</Form.Group>
											</Col>

										</Row>
									</Card.Body>
								</Card>
							</Col>

							{/* ======================================== */}

							<Col lg={12} className="grid-margin">
								<Card>
									<Card.Header>
										<Card.Title>
											<span>ABOUT SECTION</span>
										</Card.Title>
									</Card.Header>
									<Card.Body>
										<Row>
											<Col lg={6}>
												<Row>
													<Col lg={12}>
														<Form.Group controlId="visualDescription">
															<Form.Label>Description (max 500 characters)</Form.Label>
															<Form.Control
																as="textarea"
																maxLength={500}
																type="text"
																aria-describedby="visualDescription"
																name="visualDescription"
																value={visualDescription}
																onChange={handleChange}
																autoComplete="visualDescription"
															/>
														</Form.Group>
													</Col>
													<Col lg={12}>
														<Form.Group controlId="visualButtonLink">
															<Form.Label>Button Link 1</Form.Label>
															<Form.Control
																type="text"
																maxLength={250}
																aria-describedby="visualButtonLink"
																name="visualButtonLink"
																value={visualButtonLink}
																onChange={handleChange}
																autoComplete="visualButtonLink"
															/>
														</Form.Group>
													</Col>
												</Row>
											</Col>
											<Col lg={6}>
												<Row>
													<Col lg={6}>
														<Form.Group controlId="profilepic">
															<Form.Label>Cover 1 (400 x 400px ideal for hi-res)</Form.Label>
															<label className="file-input-upload profile-wrap half-profile">
																<div className="file-input-inner-upload text-center">
																	{visualUrlCover1 ? (
																		<div
																			className="img-preview"
																			style={{
																				backgroundImage: `url(${visualUrlCover1})`,
																			}}
																		/>
																	) : (
																			<>
																				<img
																					src={defaultImage}
																					alt="upload"
																				/>
																				<h6 className="">Select file to upload your cover</h6>
																				<p className="mb-0">400 x 400px ideal for hi-res</p>
																			</>
																		)}
																	<Form.Control
																		type="file"
																		placeholder="Select image to upload"
																		aria-describedby="profilepic"
																		name="visualCover1"
																		// onChange={(event: any) => {
																		// 	setFieldValue('visualCover1', event.currentTarget.files[0])
																		// }}
																		onChange={(event: any) => {
																			imageValidateAndSave(
																				event.currentTarget.files[0],
																				setFieldValue,
																				setFieldError,
																				'visualCover1',
																				400,
																				200
																			);
																		}}
																		autoComplete="visualCover1"
																		accept={AcceptedImageFormat.join()}
																	/>
																</div>
															</label>
															<p className="editor-invalid-feedback">
																{visualCover1Error ? visualCover1ErrorMsg : ''}
															</p>
														</Form.Group>
													</Col>
													<Col lg={6}>
														<Form.Group controlId="profilepic">
															<Form.Label>Cover 2 (400 x 400px ideal for hi-res)</Form.Label>
															<label className="file-input-upload profile-wrap half-profile">
																<div className="file-input-inner-upload text-center">
																	{visualUrlCover2 ? (
																		<div
																			className="img-preview"
																			style={{
																				backgroundImage: `url(${visualUrlCover2})`,
																			}}
																		/>
																	) : (
																			<>
																				<img
																					src={defaultImage}
																					alt="upload"
																				/>
																				<h6 className="">Select file to upload your cover</h6>
																				<p className="mb-0">400 x 400px ideal for hi-res</p>
																			</>
																		)}
																	<Form.Control
																		type="file"
																		placeholder="Select image to upload"
																		aria-describedby="profilepic"
																		name="visualCover2"
																		// onChange={(event: any) => {
																		// 	setFieldValue('visualCover2', event.currentTarget.files[0])
																		// }}
																		onChange={(event: any) => {
																			imageValidateAndSave(
																				event.currentTarget.files[0],
																				setFieldValue,
																				setFieldError,
																				'visualCover2',
																				400,
																				200
																			);
																		}}
																		autoComplete="visualCover2"
																		accept={AcceptedImageFormat.join()}
																	/>
																</div>
															</label>
															<p className="editor-invalid-feedback">
																{visualCover2Error ? visualCover2ErrorMsg : ''}
															</p>
														</Form.Group>
													</Col>
												</Row>
											</Col>
										</Row>

									</Card.Body>
								</Card>
							</Col>

							{/* ===================== What our client say ==================== */}

							<Col lg={12} className="grid-margin">
								<Card>
									<Card.Header>
										<Card.Title>
											<span>All of Our Service</span>
										</Card.Title>
									</Card.Header>
									<Card.Body>
										<Row>
											<Col lg={6}>
												<Form.Group controlId="clientDescription">
													<Form.Label>Description (max 250 characters)</Form.Label>
													<Form.Control
														as="textarea"
														type="text"
														maxLength={250}
														aria-describedby="clientDescription"
														name="clientDescription"
														value={clientDescription}
														onChange={handleChange}
														autoComplete="clientDescription"
													/>
												</Form.Group>
											</Col>
										</Row>

										<Row>

											<Col lg={6}>
												<Form.Group controlId="profilepic">
													<Form.Label>Image (400 x 400px ideal for hi-res)</Form.Label>
													<label className="file-input-upload profile-wrap half-profile">
														<div className="file-input-inner-upload text-center">
															{clientProfileUrl ? (
																<div
																	className="img-preview"
																	style={{
																		backgroundImage: `url(${clientProfileUrl})`,
																	}}
																/>
															) : (
																	<>
																		<img
																			src={defaultImage}
																			alt="upload"
																		/>
																		<h6 className="">Select file to upload your image</h6>
																		<p className="mb-0">400 x 400px ideal for hi-res</p>
																	</>
																)}
															<Form.Control
																type="file"
																placeholder="Select image to upload"
																aria-describedby="profilepic"
																name="clientProfile"
																// onChange={(event: any) => {
																// 	setFieldValue('clientProfile', event.currentTarget.files[0])
																// }}
																onChange={(event: any) => {
																	imageValidateAndSave(
																		event.currentTarget.files[0],
																		setFieldValue,
																		setFieldError,
																		'clientProfile',
																		400,
																		200
																	);
																}}
																autoComplete="clientProfile"
																accept={AcceptedImageFormat.join()}
															/>
														</div>
													</label>
													<p className="editor-invalid-feedback">
														{clientProfileError ? clientProfileErrorMsg : ''}
													</p>
												</Form.Group>
											</Col>


											<Col lg={6}>
												<Row>
													<Col lg={6}>
														<Form.Group controlId="clientName">
															<Form.Label>Title</Form.Label>
															<Form.Control
																type="text"
																maxLength={30}
																aria-describedby="clientName"
																name="clientName"
																value={clientName}
																onChange={handleChange}
																autoComplete="clientName"
															/>
														</Form.Group>
													</Col>
													<Col lg={6}>
														<Form.Group controlId="riderectLink">
															<Form.Label>Link</Form.Label>
															<Form.Control
																type="text"
																maxLength={500}
																aria-describedby="riderectLink"
																name="riderectLink"
																value={riderectLink}
																onChange={handleChange}
																autoComplete="riderectLink"
															/>
														</Form.Group>
													</Col>
													<Col lg={12}>
														<Form.Group controlId="clientTestimonial">
															<Form.Label>Description (max 300 characters)</Form.Label>
															<Form.Control
																as="textarea"
																rows="4"
																type="text"
																maxLength={300}
																aria-describedby="clientTestimonial"
																name="clientTestimonial"
																value={clientTestimonial}
																onChange={handleChange}
																autoComplete="clientTestimonial"
															/>
														</Form.Group>
													</Col>
												</Row>
											</Col>
											<Col lg={12}>
												<Form.Group className="d-flex justify-content-end">
													{isTestimonialEdit ?
														<Button disabled={!clientName || !clientTestimonial || !clientFileName} type="button" onClick={() => {
															handleSaveTestimonial(testimonialIndex)
														}}>Update</Button>
														:
														<Button disabled={!clientName || !clientTestimonial || !clientFileName} type="button" onClick={handleAddTestimonial}>Add</Button>
													}
												</Form.Group>
											</Col>
										</Row>

										<Row className="table-card-body">
											<div className="table-responsive-wrap">
												<table className="table">
													<thead>
														<tr>
															<th className="studio-block">Image</th>
															<th className="owner-block">Title</th>
															<th className="paid-price-block">Description</th>
															<th className="booking-block">Link</th>
															<th className="action-block text-center">Action</th>
														</tr>
													</thead>
													<tbody>
														{testimonials.length > 0 ?
															testimonials.map((item: any, index: any) => {
																return (
																	<tr key={index}>
																		<td>
																			<img className="client-profile-image" src={item.clientProfileUrl} alt="" />
																		</td>
																		<td>{item.clientName}</td>
																		<td>{item.clientTestimonial}</td>
																		<td>{item.riderectLink}</td>
																		<td className='text-center'>
																			<div className='action-buttons'>
																				<OverlayTrigger
																					placement={'top'}
																					overlay={
																						<Tooltip id='tooltip-edit'>Edit</Tooltip>
																					}
																				>
																					<span
																						className='btn-icon'
																						onClick={() => {
																							handleUpdateTestimonial(item, index);
																							window.scrollTo({ top: 1450, behavior: 'smooth' });
																						}}
																					>
																						<i className='fas fa-edit'></i>
																					</span>
																				</OverlayTrigger>
																				<OverlayTrigger
																					placement={'top'}
																					overlay={
																						<Tooltip id='tooltip-delete'>
																							Delete
																		</Tooltip>
																					}
																				>
																					<span
																						className='btn-icon'
																						onClick={() => handleRemoveTestimonial(index)}
																					>
																						<i className='fas fa-trash'></i>
																					</span>
																				</OverlayTrigger>
																			</div>
																		</td>
																	</tr>
																)
															})
															: (
																<tr>
																	<td colSpan={6} className={"text-center"}>
																		<div className={'no-result-block'}>
																			<div className='empty-ico'>
																				<img alt='Icon_Empty.svg' width='50' src={icon_empty} />
																			</div>
																			<h5>No Service Found</h5>
																		</div>
																	</td>
																</tr>
															)}
													</tbody>
												</table>
											</div>
										</Row>
									</Card.Body>
								</Card>
							</Col>


							{/* ========================================= */}
							<Col lg={12}>
								<Form.Group className="d-flex justify-content-end">
									<Button type="submit" disabled={isSubmitting}>
										{isSubmitting ? "Saving..." : "Save"}
									</Button>
								</Form.Group>
							</Col>
						</Row>
					</Form>
				</div>
			</Fragment >
		);
	};
}
export default AddHomeCMSComponent;
