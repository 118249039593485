import React, { Component, Fragment } from 'react';
import { ApiHelper } from "../../../settings/helpers/ApiHelper";
import { IWebsiteSettingsComponentProps, IWebsiteSettingsComponentState } from './interface';
import WebsiteSettingsComponent from './WebsiteSettings.component';
import { confirmBox, asyncSetState } from '../../../settings/helpers/Common';
import { showSuccessToast } from '../../../settings/helpers/toast';
import { websiteSettingValidator } from '../../../settings/helpers/validator';

class WebsiteSettings extends Component<IWebsiteSettingsComponentProps, IWebsiteSettingsComponentState> {
	state: IWebsiteSettingsComponentState = {
		email: '',
		phone1: '',
		phone2: '',
		address: '',
		errors: {
			email: '',
			phone1: '',
			address: '',
		},
		errorLink1: false,
		errorLink2: false,
		errorLink3: false,
		errorLink4: false,
		errorLink5: false,
		isSubmitting: false,
		loading: true,
		link1: '',
		link2: '',
		link3: '',
		link4: '',
		link5: '',
		link1Status: '',
		link2Status: '',
		link3Status: '',
		link4Status: '',
		link5Status: '',
		_id: ''
	};

	componentDidMount() {
		this.getSettingDetails();
	}

	// Function to get setting details
	getSettingDetails = async () => {
		try {
			this.setState({
				loading: true,
			});
			const res = await new ApiHelper().FetchFromServer(
				"/superadmin",
				"/websitesettings/getSettingDetails",
				"GET",
				true,
				undefined,
				undefined
			);

			if (res.data.data.length > 0) {
				const data = res.data.data[0];
				this.setState({
					email: data.email,
					phone1: data.phone1,
					phone2: data.phone2,
					address: data.address,
					link1: data.link1,
					link2: data.link2,
					link3: data.link3,
					link4: data.link4,
					link5: data.link5,
					link1Status: data.link1Status,
					link2Status: data.link2Status,
					link3Status: data.link3Status,
					link4Status: data.link4Status,
					link5Status: data.link5Status,
					_id: data._id,
					loading: false
				});
			} else {
				this.setState({
					loading: false
				});
			}
		} catch (error) {
			this.setState({
				loading: false
			});
		}
	};

	// Function to validate url
	validURL = (linkURL: string) => {
		let urlRegex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
		if (!linkURL) {
			return true;
		}
		return urlRegex.test(linkURL);
	}

	// Function to update setting details
	onUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		try {
			let { _id, email, phone1, phone2, address, link1, link2, link3, link4, link5, link1Status, link2Status, link3Status, link4Status, link5Status
			} = this.state;

			let item = { _id, email, phone1, phone2, address, link1, link2, link3, link4, link5, link1Status, link2Status, link3Status, link4Status, link5Status };

			email = email.trim();
			phone1 = phone1.trim();
			address = address.trim();

			const payload = { email, phone1, address };
			// To validate form fields
			let { isValid, errors } = websiteSettingValidator(payload);

			if (!this.validURL(link1)) {
				isValid = false;
				this.setState({ errorLink1: true })
			} else {
				this.setState({ errorLink1: false })
			}
			if (!this.validURL(link2)) {
				isValid = false;
				this.setState({ errorLink2: true })
			} else {
				this.setState({ errorLink2: false })
			}
			if (!this.validURL(link3)) {
				isValid = false;
				this.setState({ errorLink3: true })
			} else {
				this.setState({ errorLink3: false })
			}
			if (!this.validURL(link4)) {
				isValid = false;
				this.setState({ errorLink4: true })
			} else {
				this.setState({ errorLink4: false })
			}
			if (!this.validURL(link5)) {
				isValid = false;
				this.setState({ errorLink5: true })
			} else {
				this.setState({ errorLink5: false })
			}

			if (isValid) {
				const { value } = await confirmBox({
					title: "Are you sure?",
					text: "Do you want to update settings?"
				});
				if (value) {
					try {
						const res = await new ApiHelper().FetchFromServer(
							"/superadmin",
							"/websitesettings/update",
							"POST",
							true,
							undefined,
							item
						);

						if (res.data.statusCode === 200) {
							showSuccessToast(res.data.message);
						}
					} catch (error) { }
				} else {
					return;
				}

			} else {
				this.setState({
					errors,
					isSubmitting: false
				});
				return;
			}

		} catch (error) { }
	};

	// Function to update status
	onStatusUpdate = async (status: any) => {
		try {
			let isActive = 'active';
			Object.keys(status).forEach(function (key) {
				if (status[key] === true) {
					status[key] = false;
					isActive = 'inactive';
				} else {
					status[key] = true;
				}
			});

			const { value } = await confirmBox({
				title: "Are you sure?",
				text: `Do you want to ${
					isActive === 'active' ? 'activate' : 'inactivate'
					} this link?`,
				confirmButtonText: `Yes, ${
					isActive === 'active' ? 'activate' : 'inactivate'
					} it`,
			});
			if (value) {
				try {
					const res = await new ApiHelper().FetchFromServer(
						"/superadmin",
						"/websitesettings/updateStatus",
						"POST",
						true,
						undefined,
						{ status }
					);

					if (res.data.statusCode === 200) {
						showSuccessToast(res.data.message);
						await asyncSetState(this)({ ...this.state, ...status });
					}
				} catch (error) { }
			} else {
				return;
			}
		} catch (error) { }
	};

	// Function to bind values
	handleChange = (e: any) => {
		const { name, value } = e.target;
		this.setState({
			...this.state,
			[name]: value,
			errors: {
				...this.state.errors,
				[name]: '',
			},
		});
	};

	render() {
		return (
			<Fragment>
				<WebsiteSettingsComponent
					{...this.state}
					handleChange={this.handleChange}
					onUpdate={this.onUpdate}
					onStatusUpdate={this.onStatusUpdate}
				/>
			</Fragment>
		);
	}
}

export default WebsiteSettings;