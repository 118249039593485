import React, { PureComponent } from 'react';
import { ApiHelper } from "../../settings/helpers/ApiHelper";
import { IDashboardProps, IDashboardState } from './interface';
import DashboardComponent from './Dashboard.component';

class Dashboard extends PureComponent<IDashboardProps, IDashboardState> {
	state: IDashboardState = {
		topServices: [],
		topServiceLoader: true,
		topStudioIncomes: [],
		topStudioIncomeLoader: true,
		topStudioSignup: [],
		topStudioSignupLoader: true,
		revenueData: [],
		revenueDataLoader: true,
		revenueDateRange: 'Today',
		customerData: [],
		customerDataLoader: true,
		customerDateRange: 'Today',
		bookingData: [],
		bookingDataLoader: true,
		bookingDateRange: 'Today',
		topProviders: [],
		topProvidersLoader: true
	};


	componentDidMount = async () => {
		this.getTopServices();
		this.getTopStudioIncomes();
		this.getTopStudioSignup();
		this.getDashboardRevenues();
		this.getNewCustomers();
		this.getNewBookings();
		this.getTopProviders();
	};

	// Function to get top services
	getTopProviders = async () => {
		try {
			this.setState({
				topProviders: [],
				topProvidersLoader: true
			});
			const res = await new ApiHelper().FetchFromServer(
				"/superadmin",
				"/dashboard/getTopProviders",
				"GET",
				true,
				undefined
			);
			if (res.data.data.length > 0) {
				this.setState({
					topProviders: res.data.data,
					topProvidersLoader: false
				});
			} else {
				this.setState({
					topProviders: [],
					topProvidersLoader: false
				});
			}
		} catch (error) {
			this.setState({
				topProviders: [],
				topProvidersLoader: false
			});
		}
	};

	// Function to get top services
	getTopServices = async () => {
		try {
			this.setState({
				topServices: [],
				topServiceLoader: true
			});
			const res = await new ApiHelper().FetchFromServer(
				"/superadmin",
				"/dashboard/getTopServices",
				"GET",
				true,
				undefined
			);
			if (res.data.data.length > 0) {
				this.setState({
					topServices: res.data.data,
					topServiceLoader: false
				});
			} else {
				this.setState({
					topServices: [],
					topServiceLoader: false
				});
			}
		} catch (error) {
			this.setState({
				topServices: [],
				topServiceLoader: false
			});
		}
	};

	// Function to get top studio incomes
	getTopStudioIncomes = async () => {
		try {
			this.setState({
				topStudioIncomes: [],
				topStudioIncomeLoader: true
			});
			const res = await new ApiHelper().FetchFromServer(
				"/superadmin",
				"/dashboard/getTopStudioIncomes",
				"GET",
				true,
				undefined
			);
			if (res.data.data.length > 0) {
				this.setState({
					topStudioIncomes: res.data.data,
					topStudioIncomeLoader: false
				});
			} else {
				this.setState({
					topStudioIncomes: [],
					topStudioIncomeLoader: false
				});
			}
		} catch (error) {
			this.setState({
				topStudioIncomes: [],
				topStudioIncomeLoader: false
			});
		}
	}

	// Function to get top studio signup's
	getTopStudioSignup = async () => {
		try {
			this.setState({
				topStudioSignup: [],
				topStudioSignupLoader: true
			});
			const res = await new ApiHelper().FetchFromServer(
				"/superadmin",
				"/dashboard/getTopStudioSignup",
				"GET",
				true,
				undefined
			);
			if (res.data.data.length > 0) {
				this.setState({
					topStudioSignup: res.data.data,
					topStudioSignupLoader: false
				});
			} else {
				this.setState({
					topStudioSignup: [],
					topStudioSignupLoader: false
				});
			}
		} catch (error) {
			this.setState({
				topStudioSignup: [],
				topStudioSignupLoader: false
			});
		}
	}

	// Function to get revenues
	getDashboardRevenues = async () => {
		try {
			const res = await new ApiHelper().FetchFromServer(
				"/superadmin",
				"/dashboard/getDashboardRevenues",
				"POST",
				true,
				undefined,
				{ dateRange: this.state.revenueDateRange }
			);
			if (res.data.data.length > 0) {
				this.setState({
					revenueData: res.data.data[0],
					revenueDataLoader: false
				});
			} else {
				this.setState({
					revenueData: [],
					revenueDataLoader: false
				});
			}
		} catch (error) {
			this.setState({
				revenueData: [],
				revenueDataLoader: false
			});
		}
	}

	// Function to get new customers
	getNewCustomers = async () => {
		try {
			const res = await new ApiHelper().FetchFromServer(
				"/superadmin",
				"/dashboard/getNewCustomers",
				"POST",
				true,
				undefined,
				{ dateRange: this.state.customerDateRange }
			);
			if (res.data.data.length > 0) {

				this.setState({
					customerData: res.data.data[0],
					customerDataLoader: false
				});
			} else {
				this.setState({
					customerData: [],
					customerDataLoader: false
				});
			}
		} catch (error) {
			this.setState({
				customerData: [],
				customerDataLoader: false
			});
		}
	}

	// Function to get new bookings
	getNewBookings = async () => {
		try {
			const res = await new ApiHelper().FetchFromServer(
				"/superadmin",
				"/dashboard/getNewBookings",
				"POST",
				true,
				undefined,
				{ dateRange: this.state.bookingDateRange }
			);
			if (res.data.data.length > 0) {
				this.setState({
					bookingData: res.data.data[0],
					bookingDataLoader: false
				});
			} else {
				this.setState({
					bookingData: [],
					bookingDataLoader: false
				});
			}
		} catch (error) {
			this.setState({
				bookingData: [],
				bookingDataLoader: false
			});
		}
	}

	// Function to change revenue state
	onRevenueChange = async (value: any) => {
		this.setState({ revenueDateRange: value },
			() => { this.getDashboardRevenues(); }
		);
	}

	// Function to change customer state
	onCustomerChange = async (value: any) => {
		this.setState({ customerDateRange: value },
			() => { this.getNewCustomers(); }
		);
	}

	// Function to change booking state
	onBookingChange = async (value: any) => {
		this.setState({ bookingDateRange: value },
			() => { this.getNewBookings(); }
		);
	}

	render() {
		return (
			<DashboardComponent
				{...this.state}
				onRevenueChange={this.onRevenueChange}
				onCustomerChange={this.onCustomerChange}
				onBookingChange={this.onBookingChange}
			/>
		);
	}
}

export default Dashboard;