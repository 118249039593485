import React, { Component } from 'react';
import { IEmailTemplateListComponentProps, IEmailTemplateListComponentState } from './interface';
import { Row, Col, Card, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import RCPagination from 'rc-pagination';
import moment from 'moment';
import History from '../../settings/helpers/History';

//@ts-ignore
import localeInfo from 'rc-pagination/lib/locale/en_US';
import searchIco from '../../assets/img/magnifying-glass.svg';
import icon_empty from '../../assets/img/Icon_Empty.svg';

class EmailTemplateListComponent extends Component<IEmailTemplateListComponentProps, IEmailTemplateListComponentState> {

	render() {
		const { loading, templateList, name, totalRecords, pageLimit, pageNumber, onPageChange, firstRecordNo, lastRecordNo, onSearchChange } = this.props;

		return (
			<div className="page-header-wrap">
				<div className="page-header">
					<h3 className="page-title">
						<span className="heading-text">Email Template</span>
					</h3>
				</div >
				<Row>
					<Col lg={12} className="grid-margin">
						<Card>
							<Card.Header>
								<Card.Title>
									<span>Email Template List</span>
								</Card.Title>
								<div className="table-header-actions header-actions">
									<div className="search-input-wrap">
										<input
											type="text"
											name="name"
											value={name}
											onChange={onSearchChange}
											placeholder="Search by template"
											autoComplete={'off'}
											className="form-control"></input>
										<span className="search-icon">
											<img src={searchIco} alt="search" width="18px" />
										</span>
									</div>
									{/* <Button variant='link' size='sm'
										onClick={(e: FormEvent<HTMLButtonElement>) => {
											e.preventDefault();
											History.push('/email-templates/add-template');
										}}
										className="btn-add  ml-2"
									>
										<i className='fas fa-plus'></i>
									</Button> */}
								</div>
							</Card.Header>
							<Card.Body className="table-card-body">
								<div className="table-responsive-wrap">
									<table className="table ">
										<thead>
											<tr>
												<th className="text-center">No.</th>
												<th className="template-name-block">Template Name</th>
												<th className="subject-block">Subject</th>
												<th className="created-block">Created</th>
												<th className="action-block text-center">Action</th>
											</tr>
										</thead>
										<tbody>
											{loading ?
												<tr>
													<td colSpan={5}>
														<div className="d-flex justify-content-center align-items-center table-spinner">
															<Spinner animation="border" />
														</div>
													</td>
												</tr>
												: templateList.length ?
													templateList.map((item: any, index: any) => {
														return (
															<tr key={index}>
																<td className="text-center">{index + 1 + (pageNumber - 1) * pageLimit}</td>
																<td>
																	{item.name}
																</td>
																<td>
																	{item.subject}
																</td>
																<td>
																	{moment(item.createdAt).format('MM/DD/YYYY')}
																</td>
																<td className='text-center'>
																	<div className='action-buttons'>
																		<OverlayTrigger
																			placement={'top'}
																			overlay={
																				<Tooltip id='tooltip-edit'>Edit</Tooltip>
																			}
																		>
																			<span
																				className='btn-icon'
																				onClick={() => {
																					History.push(
																						'/email-templates/add-template?id=' + item._id
																					);
																				}}
																			>
																				<i className='fas fa-edit'></i>
																			</span>
																		</OverlayTrigger>
																	</div>
																</td>
															</tr>
														)
													})
													: (
														<tr>
															<td colSpan={5} className={"text-center"}>
																<div className={'no-result-block'}>
																	<div className='empty-ico'>
																		<img alt='Icon_Empty.svg' width='50' src={icon_empty} />
																	</div>
																	<h5>No Email Template Found</h5>
																</div>
															</td>
														</tr>
													)}
										</tbody>
									</table>
									<div className="table-pagination-wrap">
										<div>
											<RCPagination
												className="custom-pagination-design"
												current={pageNumber}
												total={totalRecords}
												defaultPageSize={pageLimit}
												pageSize={pageLimit}
												hideOnSinglePage={true}
												showPrevNextJumpers={true}
												showSizeChanger={true}
												pageSizeOptions={['5', '10', '25', '100']}
												onChange={onPageChange}
												locale={localeInfo}
											/>
										</div>
										{totalRecords > 0 ? (
											<div className="records-filter">
												<div className="records-number">
													Displaying {firstRecordNo} - {lastRecordNo} of{' '}
													{totalRecords} records{' '}
												</div>
											</div>
										) : null}
									</div>
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</div >
		);
	};
}

export default EmailTemplateListComponent;