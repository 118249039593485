import React, { Component, Fragment, MouseEvent, FormEvent, ChangeEvent } from 'react'
import { Breadcrumb, Nav, Row, Col, } from 'react-bootstrap';
import * as qs from 'query-string';
import {
	logger,
	asyncSetState,
} from '../../../settings/helpers/Common';
import History from '../../../settings/helpers/History';
import { ApiHelper } from '../../../settings/helpers/ApiHelper';
import Service from './Service';
import ProviderDetailComponent from './ProviderDetailComponent';
import debounce from 'lodash/debounce';
import "./style.scss"

class ProviderDeatil extends Component<any, any>{
	constructor(props: any) {
		super(props);
		this.state = {
			providerDetails: [],
			services: [],
			bookings: [],
			pageNumber: 1,
			pageLimit: 10,
			totalRecords: 0,
			isDetailLoading: false,
			isServiceLoading: false,
			tab: 'service',
			providerId:"",
			title:""


		}
	}
	componentDidMount = async () => {
		let search: any = {};
		search = { ...qs.parse(History.location.search) };
		if (search.id) {
			this.setState({providerId:search.id})
		this.getStudioDetails(search.id);
		}
		if (search.tab) {
			this.setState({tab:search.tab})
		}
		this.onChangeDebounced = debounce(this.onChangeDebounced, 500);

	};
	onChangeDebounced = async () => {
		await this.onSearch(undefined); // Delayed function, call api to get searched values values
	};
	onSearchChange = async (e: ChangeEvent<HTMLInputElement>) => {
		console.log('onSearchChange')
		const { name, value } = e.target;
		await asyncSetState(this)({ ...this.state, [name]: value });
		await this.onChangeDebounced();
	};
	onSearch = async (e: FormEvent<HTMLFormElement> | undefined) => {
		console.log("&&&&&&&&&&&&&&&&&&&&")
		if (e) {
			e.preventDefault();
		}
		new ApiHelper().cancelRequest(); // Cancel previous pending request if any
		let query: any = { ...qs.parse(this.props.location.search) };
		query = {
			...query,
			page: 1,
		  name: this.state.title,
		};

		this.props.history.push(`/wellness-providers/profile?${qs.stringify(query)}`);

	};
	componentDidUpdate = async (prevProps: any) => {
		if (prevProps.location !== this.props.location) {
			let search: any = {};
			search = { ...qs.parse(History.location.search) };
		console.log(search,'searchsearch@@')

			if (search.tab) {
				this.setState({tab:search.tab})
			}
		}
	};
	// Function to get studio details
	getStudioDetails = async (id: string) => {
		try {
			this.setState({
				isDetailLoading: true,
				studioDetails: {}
			});
			const res = await new ApiHelper().FetchFromServer(
				"/superadmin",
				"/studios/getProviderDetail",
				"GET",
				true,
				{ providerId: id }
			);

			if (res.data.data.length > 0) {
				this.setState({
					providerDetails: res.data.data[0],
					isDetailLoading: false
				});
			} else {
				this.setState({
					providerDetails: {},
					isDetailLoading: false
				});
			}
		} catch (error) {
			this.setState({
				providerDetails: {},
				loading: false
			});
		}
	};

	onLinkSelect = (tab: any) => {
		const {providerId} = this.state
		console.log(providerId,'providerIdproviderId')
		if (tab !== this.state.tab) {
			let query: any = { ...qs.parse(this.props.location.search) };
			query = {
				tab: tab,
				id:providerId,
				name:''
			};
			this.props.history.push(`/wellness-providers/profile?${qs.stringify(query)}`);
		}
	};

	render() {
		const { providerDetails, isDetailLoading, tab, providerId, title } = this.state
		console.log(this.state.studioDetails, 'studioDetails')
		return (
			<Fragment>
				<div className='page-header-wrap'>
					<div className='page-header'>
						<Breadcrumb>
							<Breadcrumb.Item
								onClick={(e: MouseEvent) => {
									e.preventDefault();
									History.push('/wellness-providers');
								}}
							>
								<span className='breadcrumb-icon'>
									{/* <img src={dashboard} alt='icon' /> */}
								</span>
								<span>Wellness Center</span>
							</Breadcrumb.Item>
							<Breadcrumb.Item active>Provider Profile</Breadcrumb.Item>
						</Breadcrumb>
					</div>
				</div>
				<Row>
					{/* LEFT CARD */}
					<Col xl={4} lg={5} className='grid-margin'>
						<ProviderDetailComponent providerDetails={providerDetails} isDetailLoading={isDetailLoading} />
					</Col>
					{/* LEFT CARD */}
					{/* RIGHT CARD */}
					<Col xl={8} lg={7} className='grid-margin'>
						<Service onLinkSelect={this.onLinkSelect} tab={tab} providerId={providerId} {...this.props}
						onSearchChange={this.onSearchChange} title={title}/>

					</Col>
					{/* RIGHT CARD */}
				</Row>


			</Fragment>
		)
	}
}

export default ProviderDeatil