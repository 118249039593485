import React, { Component } from 'react';
import { Switch, Route, Router } from 'react-router-dom';
import appRoutes from '../config/AppRoutes';
import History from '../helpers/History';
import { IAppProps, IAppStates } from './interface';

class AppRoutes extends Component<IAppProps, IAppStates> {
	render() {
		return (
			<Router history={History}>
				<Switch>
					{appRoutes.map(r => (
						<Route
							key={r.id}
							path={r.path}
							exact={r.exact}
							component={r.main}
						/>
					))}
				</Switch>
			</Router>
		);
	}
}

export default AppRoutes;