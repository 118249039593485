// INTERFACE
import { IAppRoutes } from './interface';
import uuidv4 from 'uuid/v4';

// CONTAINERS
import Login from '../../containers/Login';
import Sidebar from '../../containers/Sidebar';
import FourZeroFour from '../../containers/FourZeroFour';

const appRoutes: IAppRoutes[] = [
	{
		path: '/',
		exact: true,
		main: Login,
		id: uuidv4(),
	},
	{
		path: '/login',
		exact: true,
		main: Login,
		id: uuidv4(),
	},
	{
		// path: '/superadmin',
		path: '/',
		exact: false,
		main: Sidebar,
		id: uuidv4(),
	},
	{
		path: '**',
		exact: false,
		main: FourZeroFour,
		id: uuidv4(),
	}
];

export default appRoutes;
