import Validator, { ValidationTypes } from 'js-object-validation';
import { message } from '../messages';

export const addTermsOfServiceValidator = (data: any) => {
  const validations = {
    heading: {
      [ValidationTypes.REQUIRED]: true
    }
  };
  // Error messages
  const messages = {
    heading: {
      [ValidationTypes.REQUIRED]: message.InvalidHeading,
    }
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};