import React, { Fragment, Component } from 'react';
import { IAddPrivacyPolicyCMSComponentProps, IAddPrivacyPolicyCMSComponentState } from './interface';
import { Form, Row, Col, Card, Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';

import icon_empty from '../../../assets/img/Icon_Empty.svg';
import { Editor } from 'react-draft-wysiwyg';

class AddPrivacyPolicyCMSComponent extends Component<IAddPrivacyPolicyCMSComponentProps, IAddPrivacyPolicyCMSComponentState> {
	render() {
		const {
			heading,
			contentError,
			editorState,
			onEditorStateChange,
			loading,

			contentList,
			errors,


			handleChange,
			handleSubmit,
			onAddContent,
			isSubmitting,

			handleUpdateContentList,
			handleRemoveContentList,
			isContentEdit,
		} = this.props;

		return (
			<Fragment>
				<div className="page-header-wrap">
					<div className="page-header">
						<h3 className='page-title'>
							<span className='heading-text'>Privacy & Policy</span>
						</h3>
					</div>
					<Form onSubmit={handleSubmit} className="form-section">
						<Row>
							<Col lg={12} className="grid-margin">
								<Card>
									<Card.Header>
										<Card.Title>
											<span>Table of Contents</span>
										</Card.Title>
									</Card.Header>
									<Card.Body>
										<Row>
											<Col lg={6}>
												<Form.Group controlId="heading">
													<Form.Label>Heading</Form.Label>
													<Form.Control
														autoFocus={isContentEdit}
														type="text"
														maxLength={100}
														aria-describedby="heading"
														name="heading"
														value={heading}
														onChange={handleChange}
														isValid={heading && !errors.heading}
														isInvalid={errors.heading}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.heading}
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
											<Col lg={12}>
												<Form.Group
													className="form-vld"
													controlId="answer"
												>
													<Form.Label>Content</Form.Label>
													<Editor
														editorState={editorState}
														wrapperClassName="demo-wrapper"
														editorClassName="demo-editor"
														onEditorStateChange={onEditorStateChange}
														toolbarClassName="toolbar-class"
														toolbar={{ options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image'] }}
													/>
													<p className="editor-invalid-feedback">
														{contentError ? 'Content is required' : ''}
													</p>
												</Form.Group>
											</Col>
											<Col lg={12}>
												<Form.Group className="d-flex justify-content-end">
													<Button type="button" onClick={onAddContent}>
														{isContentEdit ? 'Update' : 'Add'}</Button>
												</Form.Group>
											</Col>
										</Row>

										<Row className="table-card-body">
											<div className="table-responsive-wrap">
												<table className="table">
													<thead>
														<tr>
															<th className="owner-block">Heading</th>
															<th className="booking-block">Content</th>
															<th className="action-block text-center">Action</th>
														</tr>
													</thead>
													<tbody>
														{loading ?
															<tr>
																<td colSpan={3}>
																	<div className="d-flex justify-content-center align-items-center table-spinner">
																		<Spinner animation="border" />
																	</div>
																</td>
															</tr>
															: contentList.length > 0 ?
																contentList.map((item: any, index: any) => {
																	return (
																		<tr key={index}>
																			<td>{item.heading}</td>
																			<td dangerouslySetInnerHTML={{
																				__html: (item.content.length > 100
																					? item.content.substring(0, 100) +
																					'...'
																					: item.content)
																			}}></td>
																			<td className='text-center'>
																				<div className='action-buttons'>
																					<OverlayTrigger
																						placement={'top'}
																						overlay={
																							<Tooltip id='tooltip-edit'>Edit</Tooltip>
																						}
																					>
																						<span
																							className='btn-icon'
																							onClick={() => {
																								handleUpdateContentList(item, index);
																								window.scrollTo({ top: 0, behavior: 'smooth' });
																								// document.body.scrollTop = 200;
																								// document.documentElement.scrollTop = 200;
																							}}
																						>
																							<i className='fas fa-edit'></i>
																						</span>
																					</OverlayTrigger>
																					<OverlayTrigger
																						placement={'top'}
																						overlay={
																							<Tooltip id='tooltip-delete'>
																								Delete
																		</Tooltip>
																						}
																					>
																						<span
																							className='btn-icon'
																							onClick={() => handleRemoveContentList(index)}
																						>
																							<i className='fas fa-trash'></i>
																						</span>
																					</OverlayTrigger>
																				</div>
																			</td>
																		</tr>
																	)
																})
																: (
																	<tr>
																		<td colSpan={3} className={"text-center"}>
																			<div className={'no-result-block'}>
																				<div className='empty-ico'>
																					<img alt='Icon_Empty.svg' width='50' src={icon_empty} />
																				</div>
																				<h5>No Contents Found</h5>
																			</div>
																		</td>
																	</tr>
																)}
													</tbody>
												</table>
											</div>
										</Row>
									</Card.Body>
								</Card>
							</Col>

							{/* ========================================= */}
							<Col lg={12}>
								<Form.Group className="d-flex justify-content-end">
									<Button type="submit" disabled={isSubmitting || contentList.length === 0}>
										{isSubmitting ? "Saving..." : "Save"}
									</Button>
								</Form.Group>
							</Col>
						</Row>
					</Form>
				</div>
			</Fragment >
		);
	};
}
export default AddPrivacyPolicyCMSComponent;