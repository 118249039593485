import React, { Component, Fragment } from 'react'
import { Spinner } from 'react-bootstrap'
import icon_empty from '../../../assets/img/Icon_Empty.svg';
import { ApiHelper } from '../../../settings/helpers/ApiHelper';
import { asyncSetState, logger } from '../../../settings/helpers/Common';
import History from '../../../settings/helpers/History';
import RCPagination from 'rc-pagination';
//@ts-ignore
import localeInfo from 'rc-pagination/lib/locale/en_US';
import { IServiceListState } from '../interface';
import moment from 'moment'
import * as qs from 'query-string';

class ServiceList extends Component<any, IServiceListState>{
	constructor(props: any) {
		super(props)
		this.state = {
			services: [],
			serviceLoading: true,
			servicePageNumber: 1,
			servicePageLimit: 10,
			serviceTotalRecords: 0,
			serviceFirstRecordNo: 0,
			serviceLastRecordNo: 0,
			serviceName: ''

		}
	}

	componentDidMount = async () => {
		const { providerId } = this.props
		console.log(providerId, 'providerId');
		let search: any = {};
		search = { ...qs.parse(History.location.search) };
		console.log(search, 'search')
		if (search.id)
			await this.fetchServices();
	};
	componentDidUpdate = async (prevProps: any) => {
		if (prevProps.location !== this.props.location) {

			await this.fetchServices();
		}
	};
	fetchServices = async () => {
		try {
			console.log(this.props, 'locationnnnnnnnnn');

			const current = this.props.location.search;
			let search: any = {};
			search = { ...qs.parse(current) };
			let id = search.id
			let servicePageNumber = 1;
			let serviceName = '';

			const currentPage = this.state.servicePageNumber;

			if (search.page && parseInt(search.page) !== currentPage) {
				servicePageNumber = parseInt(search.page);
			}
			if (search.name) {
				serviceName = search.name;
			}

			await asyncSetState(this)({
				...this.state,
				servicePageNumber,
				serviceName,
			});

			const result: any = await new ApiHelper().FetchFromServer(
				'/superadmin',
				`/services/getProviderServices?page=${this.state.servicePageNumber}&limit=${this.state.servicePageLimit}&name=${this.state.serviceName}&providerId=${id}`,
				'GET',
				true,
				undefined,
				undefined
			);

			if (result.isError) {
				throw new Error(result.messages);
			}

			const { data } = result;
			if (data.statusCode === 200 && data.success) {
				await asyncSetState(this)({
					...this.state,
					services: data.data,
					servicePageNumber: data.pageNumber,
					servicePageLimit: data.pageSize,
					serviceTotalRecords: data.totalCount,
					serviceFirstRecordNo:
						(this.state.servicePageNumber - 1) * this.state.servicePageLimit +
						1,
					serviceLastRecordNo:
						(this.state.servicePageNumber - 1) * this.state.servicePageLimit +
						data.data.length,
					serviceLoading: false,
				});
			}
			else {
				await asyncSetState(this)({
					...this.state,
					services: [],
					servicePageNumber: 1,
					serviceTotalRecords: 0,
					serviceFirstRecordNo: 0,
					serviceLastRecordNo: 0,
					serviceLoading: false,
				});
			}
		} catch (error) {
			logger(error);
			await asyncSetState(this)({
				...this.state,
				services: [],
				servicePageNumber: 1,
				serviceTotalRecords: 0,
				serviceFirstRecordNo: 0,
				serviceLastRecordNo: 0,
				serviceLoading: false,
			});
		}
	};
	onServicePageChange = async (page: number) => {
		const { providerId } = this.props
		if (page !== this.state.servicePageNumber) {
			let query: any = { ...qs.parse(this.props.location.search) };
			query = {
				...query,
				page: page,
			};

			this.props.history.push(`/wellness-providers/profile?${qs.stringify(query)}`);
		}
	};
	render() {
		const { services, serviceLoading, servicePageNumber, serviceTotalRecords, serviceFirstRecordNo, serviceLastRecordNo, servicePageLimit } = this.state
		console.log("serviceLastRecordNo0", serviceLastRecordNo);

		return (
			<Fragment>
				<div className="service-list-wrapper provider-service-list table-responsive">
					<table className="table service-used-list">
						<thead>
							<tr>
								<th>S.No</th>
								<th>Activity</th>
								<th className="w-125">Type</th>
								<th className="w-90">$Price</th>
								<th className="w-90">Duration</th>
								<th>Date</th>

							</tr>
						</thead>
						<tbody>
							{serviceLoading ?
								<tr>
									<td colSpan={8}>
										<div className="d-flex justify-content-center align-items-center table-spinner">
											<Spinner animation="border" />
										</div>
									</td>
								</tr>
								: services && services.length > 0 ?
									services.map((value: any, index: number) => (
										<tr>
											<td>{index + 1 + (servicePageNumber - 1) * servicePageLimit}</td>
											<td>{value.name}</td>
											<td>{value.type}</td>
											<td>{value.price ? (
												<>$ {value.price.toFixed(2)}</>
											) : (
													<>$ 0.00</>
												)}</td>
											<td>{value.duration}</td>
											<td>{` ${moment(value.date).format('MM/DD/YYYY')}`}</td>
										</tr>
									))
									: (
										<tr>
											<td colSpan={8} className={"text-center"}>
												<div className={'no-result-block'}>
													<div className='empty-ico'>
														<img alt='Icon_Empty.svg' width='50' src={icon_empty} />
													</div>
													<h5>	No private session Found</h5>
												</div>
											</td>
										</tr>
									)}
						</tbody>
					</table>
				</div>
				<div className="table-pagination-wrap">
					<div>
						<RCPagination
							className="custom-pagination-design"
							current={servicePageNumber}
							total={serviceTotalRecords}
							defaultPageSize={servicePageLimit}
							pageSize={servicePageLimit}
							hideOnSinglePage={true}
							showPrevNextJumpers={true}
							showSizeChanger={true}
							pageSizeOptions={['5', '10', '25', '100']}
							onChange={this.onServicePageChange}
							locale={localeInfo}
						/>
					</div>
					{serviceTotalRecords > 0 ? (
						<div className="records-filter">
							<div className="records-number">
								Displaying {serviceFirstRecordNo} - {serviceLastRecordNo} of{' '}
								{serviceTotalRecords} records{' '}
							</div>
						</div>
					) : null}
				</div>
			</Fragment>

		)
	}
}

export default ServiceList