import React, { Component, Fragment, FormEvent, ChangeEvent } from 'react';
import { IFAQListComponentProps, IFAQListComponentState } from './interface';
import { showSuccessToast } from '../../../settings/helpers/toast';
import { ApiHelper } from '../../../settings/helpers/ApiHelper';
import { confirmBox, asyncSetState } from '../../../settings/helpers/Common';
import FAQListComponent from './FAQList.component';
import * as qs from 'query-string';
import debounce from 'lodash/debounce';

class FAQ extends Component<IFAQListComponentProps, IFAQListComponentState> {
	constructor(props: any) {
		super(props);
		this.state = {
			faqList: [],
			totalPages: 0,
			totalRecords: 0,
			pageNumber: 1,
			pageLimit: 100,
			question: "",
			status: "",
			firstRecordNo: 0,
			lastRecordNo: 0,
			loading: true,
			ids: [],
			checked: false,
		};
	}

	componentDidMount = async () => {
		await this.getAllFAQs();
		this.onChangeDebounced = debounce(this.onChangeDebounced, 500);
	};

	componentDidUpdate = async (prevProps: IFAQListComponentProps) => {
		if (prevProps.location !== this.props.location) {
			await this.getAllFAQs();
		}
	};

	// Function to update FAQ status
	onFAQStatusUpdate = async (status: string, faqId: string) => {
		if (status === 'Active') {
			status = 'Inactive'
		} else {
			status = 'Active'
		}

		const { value } = await confirmBox({
			title: "Are you sure?",
			text: `Do you want to ${
				status === 'Active' ? 'activate' : 'inactivate'
				} this FAQ?`,
			confirmButtonText: `Yes, ${
				status === 'Active' ? 'activate' : 'inactivate'
				} it`,
		});
		if (value) {
			try {
				const res = await new ApiHelper().FetchFromServer(
					"/superadmin",
					"/faqs/updateStatus",
					"POST",
					true,
					undefined,
					{
						_id: faqId,
						status: status
					}
				);

				if (res.data.statusCode === 200) {
					showSuccessToast(res.data.message);
					this.getAllFAQs();
				}
			} catch (error) { }
		} else {
			return;
		}
	};

	// Function to get all FAQs
	getAllFAQs = async () => {
		try {
			await asyncSetState(this)({ ...this.state, loading: true });

			const current = this.props.location.search;
			let search: any = {};
			search = { ...qs.parse(current) };
			let pageNumber = 1;
			let question = '';
			let status = '';

			const currentPage = this.state.pageNumber;
			if (search.page && parseInt(search.page) !== currentPage) {
				pageNumber = parseInt(search.page);
			}
			if (search.question) {
				question = search.question;
			}
			if (search.status) {
				status = search.status;
			}

			await asyncSetState(this)({
				...this.state,
				pageNumber,
				question,
				status
			});

			const res: any = await new ApiHelper().FetchFromServer(
				'/superadmin',
				`/faqs/getAllFAQs?page=${this.state.pageNumber}&limit=${this.state.pageLimit}&question=${this.state.question}&status=${this.state.status}`,
				'GET',
				true,
				undefined,
				undefined
			);

			if (res.data.data.length > 0) {
				this.setState({
					faqList: res.data.data,
					totalRecords: res.data.totalCount,
					totalPages: res.data.totalPages,
					firstRecordNo: ((this.state.pageNumber - 1) * this.state.pageLimit) + 1,
					lastRecordNo: ((this.state.pageNumber - 1) * this.state.pageLimit) + res.data.data.length,
					loading: false
				});
			} else {
				this.setState({
					loading: false,
					faqList: [],
					totalRecords: 0,
					totalPages: 0,
					firstRecordNo: 0,
					lastRecordNo: 0
				});
			}
		} catch (error) {
			this.setState({
				loading: false,
				faqList: [],
				totalRecords: 0,
				totalPages: 0,
				firstRecordNo: 0,
				lastRecordNo: 0
			});
		}
	};

	// Function to remove FAQ
	onRemove = async (faqIds: any) => {
		let obj = {};
		let text = "Do you want to delete this FAQ?";
		if (typeof faqIds === 'string') {
			obj = {
				ids: [faqIds]
			}
		} else {
			obj = {
				ids: this.state.ids
			}
			text = "Do you want to delete selected FAQs?";
		}

		const { value } = await confirmBox({
			title: "Are you sure?",
			text: text
		});
		if (value) {
			try {
				const res = await new ApiHelper().FetchFromServer(
					"/superadmin",
					"/faqs/remove",
					"POST",
					true,
					undefined,
					obj
				);

				if (res.data.statusCode === 200) {
					showSuccessToast(res.data.message);
					this.setState({ ids: [], checked: false },
						() => {
							this.getAllFAQs();
						}
					);
				}
			} catch (error) { }
		} else {
			return;
		}
	};

	// Function to change page number
	onPageChange = async (page: number) => {
		if (page !== this.state.pageNumber) {
			await this.props.history.push(
				`/manage-cms?${qs.stringify({
					tab: "faq",
					page: page,
					question: this.state.question,
					status: this.state.status
				})}`
			);
		}
	};

	// Function to search records
	onSearch = async (e: FormEvent<HTMLFormElement> | undefined) => {
		if (e) {
			e.preventDefault();
		}
		new ApiHelper().cancelRequest(); // Cancel previous pending request if any
		this.props.history.push(
			`/manage-cms?${qs.stringify({
				tab: "faq",
				page: 1,
				question: this.state.question,
				status: this.state.status
			})}`
		);
	};

	// Function to check all checkbox
	handleCheckAllCheckBox = (e: any) => {
		const { checked } = e.target;
		const { faqList } = this.state;
		if (!checked) {
			this.setState({
				ids: [],
			});
		} else {
			const checkBoxIds = [];
			for (let i = 0; i < faqList.length; i++) {
				const element = faqList[i];
				checkBoxIds.push(element._id);
			}
			this.setState({
				ids: checkBoxIds,
			});
		}
	};

	// Function to change checkbox
	handleCheckboxChange = (e: any) => {
		const { checked, value } = e.target;
		const { ids } = this.state;
		if (checked) {
			ids.push(value);
		} else {
			var index = ids.indexOf(value);
			if (index !== -1) {
				ids.splice(index, 1);
			}
		}
		this.setState({
			ids,
		});
	};

	// Function for search change
	onSearchChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		await asyncSetState(this)({ ...this.state, [name]: value });
		await this.onChangeDebounced();
	};

	// Function to filter records by status
	onFilterByStatus = async (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		await asyncSetState(this)({ ...this.state, [name]: value });
		await this.onSearch(undefined);
	};

	onChangeDebounced = async () => {
		await this.onSearch(undefined); // Delayed function, call api to get searched values values
	};

	render() {
		return (
			<Fragment>
				<FAQListComponent {...this.state}
					onPageChange={this.onPageChange}
					onRemove={this.onRemove}
					handleCheckAllCheckBox={this.handleCheckAllCheckBox}
					handleCheckboxChange={this.handleCheckboxChange}
					onFAQStatusUpdate={this.onFAQStatusUpdate}
					onSearchChange={this.onSearchChange}
					onFilterByStatus={this.onFilterByStatus}
				/>
			</Fragment>
		);
	}
}

export default FAQ;