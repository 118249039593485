import React, { Component, Fragment, FormEvent, ChangeEvent } from 'react';
import { ApiHelper } from "../../../settings/helpers/ApiHelper";
import { IManageStudioComponentProps, IManageStudioComponentState } from './interface';
import ManageStudioComponent from './ManageStudio.component';
import { confirmBox, asyncSetState } from "../../../settings/helpers/Common";
import { showSuccessToast } from '../../../settings/helpers/toast';
import { FrontEndWebsite } from "../../../settings/config";
import ChangePassword from "./ChangePassword";
import * as qs from 'query-string';
import debounce from 'lodash/debounce';

class Studio extends Component<IManageStudioComponentProps, IManageStudioComponentState> {
	constructor(props: any) {
		super(props);
		this.state = {
			users: [],
			totalPages: 0,
			totalRecords: 0,
			pageNumber: 1,
			pageLimit: 100,
			name: "",
			status: "",
			firstRecordNo: 0,
			lastRecordNo: 0,
			loading: true,
			ids: [],
			checked: false,
			userId: '',
			isModelShow: false
		};
	}

	componentDidMount = async () => {
		await this.getAllStudios();
		this.onChangeDebounced = debounce(this.onChangeDebounced, 500);
	}

	componentDidUpdate = async (prevProps: IManageStudioComponentProps) => {
		if (prevProps.location !== this.props.location) {
			await this.getAllStudios();
		}
	};

	// Function to get all studios
	getAllStudios = async () => {
		try {
			await asyncSetState(this)({ ...this.state, loading: true });

			const current = this.props.location.search;
			let search: any = {};
			search = { ...qs.parse(current) };
			let pageNumber = 1;
			let name = '';
			let status = '';

			const currentPage = this.state.pageNumber;
			if (search.page && parseInt(search.page) !== currentPage) {
				pageNumber = parseInt(search.page);
			}
			if (search.name) {
				name = search.name;
			}
			if (search.status) {
				status = search.status;
			}

			await asyncSetState(this)({
				...this.state,
				pageNumber,
				name,
				status
			});

			const res: any = await new ApiHelper().FetchFromServer(
				'/superadmin',
				`/users/getAllStudioUsers?page=${this.state.pageNumber}&limit=${this.state.pageLimit}&name=${this.state.name}&status=${this.state.status}`,
				'GET',
				true,
				undefined,
				undefined
			);

			if (res.data.data.length > 0) {
				this.setState({
					users: res.data.data,
					totalRecords: res.data.totalCount,
					totalPages: res.data.totalPages,
					firstRecordNo: ((this.state.pageNumber - 1) * this.state.pageLimit) + 1,
					lastRecordNo: ((this.state.pageNumber - 1) * this.state.pageLimit) + res.data.data.length,
					loading: false
				});
			} else {
				this.setState({
					loading: false,
					users: [],
					totalRecords: 0,
					totalPages: 0,
					firstRecordNo: 0,
					lastRecordNo: 0
				});
			}
		} catch (error) {
			this.setState({
				loading: false,
				users: [],
				totalRecords: 0,
				totalPages: 0,
				firstRecordNo: 0,
				lastRecordNo: 0
			});
		}
	};

	// Function to change page number
	onPageChange = async (page: number) => {
		if (page !== this.state.pageNumber) {
			await this.props.history.push(
				`/wellness-providers?${qs.stringify({
					page: page,
					name: this.state.name,
					status: this.state.status
				})}`
			);
		}
	};

	// Function to search records
	onSearch = async (e: FormEvent<HTMLFormElement> | undefined) => {
		if (e) {
			e.preventDefault();
		}
		new ApiHelper().cancelRequest(); // Cancel previous pending request if any
		this.props.history.push(
			`/wellness-providers?${qs.stringify({
				page: 1,
				name: this.state.name,
				status: this.state.status
			})}`
		);
	};

	// Function for search change
	onSearchChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		await asyncSetState(this)({ ...this.state, [name]: value });
		await this.onChangeDebounced();
	};

	// Function to filter records
	onFilter = async (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		await asyncSetState(this)({ ...this.state, [name]: value });
		await this.onSearch(undefined);
	};

	onChangeDebounced = async () => {
		await this.onSearch(undefined); // Delayed function, call api to get searched values values
	};

	// Function to proxy login of provider
	proxyLogin = async (userId: string, isCustomerView: boolean) => {
		try {
			const res = await new ApiHelper().FetchFromServer(
				"/superadmin",
				"/users/proxyLogin",
				"POST",
				true,
				undefined,
				{ userId: userId }
			);
			if (res.data.statusCode === 200) {
				const url = `${FrontEndWebsite}/login?${isCustomerView ? 'isCustomerView=yes&' : ''}token=${res.data.token}&roleId=${res.data.roleId}`;
				window.open(url);
			}
		} catch (error) { }
	};

	// Function to update studio manger status
	onStudioStatusUpdate = async (status: string, studioIds: any) => {
		let obj = {};
		let text = '';
		let confirmButtonText = '';

		if (typeof studioIds === 'string') {
			obj = {
				ids: [studioIds],
				status: status === 'Active' ? 'Inactive' : 'Active'
			}

			text = `Do you want to ${
				status === 'Active' ? 'inactivate' : 'activate'
				} this user?`;

			confirmButtonText = `Yes, ${
				status === 'Active' ? 'inactivate' : 'activate'
				} it`;

		} else {
			obj = {
				ids: this.state.ids,
				status: status
			}

			text = `Do you want to ${
				status === 'Active' ? 'activate' : 'inactivate'
				} selected users?`;
			confirmButtonText = `Yes, ${
				status === 'Active' ? 'activate' : 'inactivate'
				} it`;
		}
		const { value } = await confirmBox({
			title: "Are you sure?",
			text: text,
			confirmButtonText: confirmButtonText
		});

		if (value) {
			try {
				const res = await new ApiHelper().FetchFromServer(
					"/superadmin",
					"/users/update",
					"POST",
					true,
					undefined,
					obj
				);

				if (res.data.statusCode === 200) {
					showSuccessToast(res.data.message);
					this.setState({ ids: [], checked: false },
						() => {
							this.getAllStudios();
						}
					);
				}
			} catch (error) { }
		} else {
			return;
		}
	};


	// Function to update studio manger status
	onFeaturedUpdate = async (isFeatured: string, _id: any) => {
		let obj = {};
		let text = '';
		let confirmButtonText = '';

		obj = {
			_id: _id,
			isFeatured: isFeatured === 'Yes' ? 'No' : 'Yes'
		}

		text = `Do you want to ${
			isFeatured === 'Yes' ? 'remove this user from featured list' : 'mark as featured this user'
			} ?`;

		confirmButtonText = `Yes, ${
			isFeatured === 'Yes' ? 'unfeatured' : 'featured'
			} it`;

		const { value } = await confirmBox({
			title: "Are you sure?",
			text: text,
			confirmButtonText: confirmButtonText
		});

		if (value) {
			try {
				const res = await new ApiHelper().FetchFromServer(
					"/superadmin",
					"/users/updateFeature",
					"POST",
					true,
					undefined,
					obj
				);

				if (res.data.statusCode === 200) {
					showSuccessToast(res.data.message);
					await this.getAllStudios();
				}
			} catch (error) { }
		} else {
			return;
		}
	};

	// Function to check all checkbox
	handleCheckAllCheckBox = (e: any) => {
		const { checked } = e.target;
		const { users } = this.state;
		if (!checked) {
			this.setState({
				ids: [],
			});
		} else {
			const checkBoxIds = [];
			for (let i = 0; i < users.length; i++) {
				const element = users[i];
				checkBoxIds.push(element._id);
			}
			this.setState({
				ids: checkBoxIds,
			});
		}
	};

	// Function to checkbox change
	handleCheckboxChange = (e: any) => {
		const { checked, value } = e.target;
		const { ids } = this.state;
		if (checked) {
			ids.push(value);
		} else {
			var index = ids.indexOf(value);
			if (index !== -1) {
				ids.splice(index, 1);
			}
		}
		this.setState({
			ids,
		});
	};

	// Function to show model
	onModelShow = async (userId: string) => {
		this.setState({ isModelShow: true, userId: userId });
	};

	// Function to hide model
	onModelHide = async () => {
		this.setState({ isModelShow: false });
	};

	render() {

		return (
			<div className="page-header-wrap" >
				{/* <div className="page-header">
					<h3 className="page-title">
						<span className="heading-text">Wellness Providers</span>
					</h3>
				</div> */}
				<Fragment>
					<ManageStudioComponent
						{...this.state}
						{...this.props}
						onPageChange={this.onPageChange}
						onSearchChange={this.onSearchChange}
						onFilter={this.onFilter}
						onStudioStatusUpdate={this.onStudioStatusUpdate}
						onFeaturedUpdate={this.onFeaturedUpdate}
						proxyLogin={this.proxyLogin}
						onModelShow={this.onModelShow}
						handleCheckAllCheckBox={this.handleCheckAllCheckBox}
						handleCheckboxChange={this.handleCheckboxChange}
					/>
					<ChangePassword {...this.state}
						onModelHide={this.onModelHide}
					/>
				</Fragment>
			</div>
		);
	}
}

export default Studio;