import Validator, { ValidationTypes } from 'js-object-validation';
import { message } from '../messages';

export const businessTypeSaveValidator = (data: any) => {
  const validations = {
    businessTypeName: {
      [ValidationTypes.REQUIRED]: true,
      [ValidationTypes.ALPHA]: true
    }
  };
  // Error messages
  const messages = {
    businessTypeName: {
      [ValidationTypes.REQUIRED]: message.InvalidBusinessTypeName,
      [ValidationTypes.ALPHA]: message.Alphabetical,
    }
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};