import React, { Component } from 'react';
import { IProfileComponentProps, IProfileComponentState } from './interface';
import ProfileComponent from './Profile.component';
import { profileUpdateValidator } from '../../settings/helpers/validator';
import { showErrorToast, showSuccessToast } from '../../settings/helpers/toast';
import { ApiHelper } from '../../settings/helpers/ApiHelper';
import NavbarComponent from '../Navbar/Navbar.component';
import { FrontEndWebsite } from "../../settings/config";
import { getImageBlob } from '../../settings/helpers/Common';

class Profile extends Component<IProfileComponentProps, IProfileComponentState> {
	constructor(props: any) {
		super(props);
		this.state = {
			userDetails: {},
			errors: { userName: '', currentPassword: '', newPassword: '', confirmPassword: '' },
			avatar: null,
			email: '',
			userName: '',
			currentPassword: '',
			newPassword: '',
			confirmPassword: '',
			cardDetails: [],
			isSubmitting: false,
			avatarError: false,
			avatarErrorMsg: 'Only PNG, JPG, JPEG, BMP, TIFF & GIF are accepted'
		};
	}

	componentDidMount = async () => {
		this.getUserDetails();
	};

	// Function to get user details
	getUserDetails = async () => {
		try {
			const res = await new ApiHelper().FetchFromServer("/superadmin", "/validate", "POST", true, undefined);
			if (!res.isError) {
				this.setState({
					isAuthenticated: true,
					loading: false,
					userDetails: res.data.data,
					userName: res.data.data.name,
					email: res.data.data.email,
					avatar: await getImageBlob(res.data.data.profileImage)
				});
			} else {
				localStorage.removeItem('adminId');
				// localStorage.clear();
				// sessionStorage.clear();
				this.props.history.push('/');
				this.setState({
					isAuthenticated: false
				});
			}
		} catch (error) {
			localStorage.removeItem('adminId');
			// localStorage.clear();
			// sessionStorage.clear();
			this.props.history.push('/');
			this.setState({
				isAuthenticated: false
			});
		}
	};

	// Function to update profile details
	onProfileUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		this.setState({ isSubmitting: true });
		let { userName, currentPassword, newPassword, confirmPassword } = this.state;
		userName = userName.trim();
		const payload = { userName, currentPassword, newPassword, confirmPassword };

		// To validate form fields
		let { isValid, errors } = profileUpdateValidator(payload);

		if (this.state.avatarError) {
			isValid = false;
			this.setState({ avatarErrorMsg: 'Profile image is required' })

		}

		if (isValid) {
			try {
				let formData = new FormData();
				if (this.state.avatar) {
					formData.append('profileImage', this.state.avatar);
				}
				formData.append('userName', this.state.userName);
				formData.append('currentPassword', this.state.currentPassword);
				formData.append('newPassword', this.state.newPassword);
				formData.append('confirmPassword', this.state.confirmPassword);
				formData.append('oldImage', this.state.userDetails.profileImage);
				const res = await new ApiHelper().FetchFromServer(
					'/superadmin',
					'/update',
					'POST',
					true,
					undefined,
					formData
				);
				const { data } = res;
				if (data.statusCode === 200) {
					showSuccessToast(res.data.message);
					this.setState({ userDetails: res.data.data[0], currentPassword: '', newPassword: '', confirmPassword: '' })
				} else {
					showErrorToast(res.data.message);
				}
				this.setState({ isSubmitting: false });
			} catch (error) {
				this.setState({ isSubmitting: false });
			}
		} else {
			this.setState({
				errors,
				isSubmitting: false
			});
			return;
		}
	};

	// Function to bind values
	handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		this.setState({
			...this.state,
			[name]: value,
			errors: {
				...this.state.errors,
				[name]: '',
			},
		});
	};

	// Function to set field Values of image
	setFieldValue = (obj: any) => {
		if (obj) {
			if (!obj.name.match(/.(jpg|JPG|jpeg|JPEG|bmp|BMP|gif|GIF|png|PNG)$/i)) {
				this.setState({
					avatar: null,
					avatarError: true,
					avatarErrorMsg: 'Only PNG, JPG, JPEG, BMP & GIF are accepted'
				})
			} else {
				this.setState({
					avatar: obj,
					avatarError: false,
					avatarErrorMsg: ''
				})
			}
		}
	};

	// Function to logout super admin
	onLogout = (e: any) => {
		e.preventDefault();
		localStorage.removeItem('adminId');
		// localStorage.clear();
		// sessionStorage.clear();
		this.props.history.push('/');
		this.setState({
			isAuthenticated: false
		});
	}

	// Function to go main app
	goToMainApp = (e: any) => {
		e.preventDefault();
		window.open(FrontEndWebsite)
		this.props.history.push('/profile');
	}

	render() {
		return (
			<>
				<ProfileComponent
					{...this.state}
					handleChange={this.handleChange}
					onProfileUpdate={this.onProfileUpdate}
					setFieldValue={this.setFieldValue}
				/>
				<NavbarComponent
					onLogout={this.onLogout}
					goToMainApp={this.goToMainApp}
					userDetails={this.state.userDetails}
				/>
			</>
		);
	}
}

export default Profile;
