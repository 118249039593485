// INTERFACE
import { IAdminRoutes } from "./interface";
import uuidv4 from "uuid/v4";

// ALL COMPONENTS
import Dashboard from "../../containers/Dashboard";
import Finance from "../../containers/Finance";
// import StudioManagement from "../../containers/ManageStudio";
// import UserManagement from "../../containers/ManageProvider/ManageProvider";
import Requests from "../../containers/ManageRequests/StudioRequest";
import EventDetails from "../../containers/ManageRequests/EventRequest/EventDetails.component";
// import StudioDetails from "../../containers/ManageStudio/StudioDetails.component";
// import Category from "../../containers/Category";
import Profile from "../../containers/Profile";
import Booking from "../../containers/Booking";
import BookingDetails from "../../containers/Booking/OrderDetails.component";
import EmailTemplate from "../../containers/EmailTemplate";
import AddEmailTemplateComponent from "../../containers/EmailTemplate/AddEmailTemplate/AddEmailTemplate.component";
import Settings from "../../containers/Settings";
import WaitList from "../../containers/WaitList";
import ManageCMSFAQ from "../../containers/ManageCMSFAQ";
// import FAQ from "../../containers/ManageCMSFAQ/FAQ";
import AddFAQComponent from "../../containers/ManageCMSFAQ/FAQ/AddFAQ/AddFAQ.component";
import ManageBlog from "../../containers/ManageBlog";
// import CustomerFAQ from "../../containers/CustomerFAQ";
// import AddCustomerFAQComponent from "../../containers/CustomerFAQ/AddCustomerFAQ/AddCustomerFAQ.component";
import WellnessProvider from "../../containers/ManageProvider";
import ContactUs from "../../containers/ContactUs/ContactUs";
import Customer from "../../containers/Customer";
import CustomerDeatil from '../../containers/Customer/CustomerDetail'
import ProviderDeatil from '../../containers/ManageProvider/ProviderDetail'

// SIDEBAR ICONS
import dashboardIco from "../../assets/img/dashboard-icons/dashboard-ico.svg";
import dashboardIcoYellow from "../../assets/img/dashboard-icons/dashboard-ico-yellow.svg";
import financeIco from "../../assets/img/dashboard-icons/finance.svg";
import financeIcoYellow from "../../assets/img/dashboard-icons/finance-yellow.svg";
import emailIco from "../../assets/img/dashboard-icons/email.svg";
import emailIcoYellow from "../../assets/img/dashboard-icons/email-yellow.svg";
// import studioIco from "../../assets/img/dashboard-icons/studio.svg";
// import studioIcoYellow from "../../assets/img/dashboard-icons/studio-yellow.svg";
import userIco from "../../assets/img/dashboard-icons/staff.svg";
import userIcoYellow from "../../assets/img/dashboard-icons/staff-yellow.svg";
import requestIco from "../../assets/img/dashboard-icons/request.svg";
import requestIcoYellow from "../../assets/img/dashboard-icons/request-yellow.svg";
import waitingIco from "../../assets/img/dashboard-icons/waiting.svg";
import waitingIcoYellow from "../../assets/img/dashboard-icons/waiting-yellow.svg";
// import serviceIco from "../../assets/img/dashboard-icons/service.svg";
// import serviceIcoYellow from "../../assets/img/dashboard-icons/service-yellow.svg";
import cmsIco from "../../assets/img/dashboard-icons/cms.svg";
import cmsIcoYellow from "../../assets/img/dashboard-icons/cms-yellow.svg";
import blogIco from "../../assets/img/dashboard-icons/blog.svg";
import blogIcoYellow from "../../assets/img/dashboard-icons/blog-yellow.svg";
import orderIco from "../../assets/img/dashboard-icons/order.svg";
import orderIcoYellow from "../../assets/img/dashboard-icons/order-yellow.svg";
import settingIco from "../../assets/img/dashboard-icons/setting.svg";
import settingIcoYellow from "../../assets/img/dashboard-icons/setting-yellow.svg";
import webSettingIco from "../../assets/img/dashboard-icons/web-setting.svg";
import webSettingIcoYellow from "../../assets/img/dashboard-icons/web-setting-yellow.svg";
import contactUsIco from "../../assets/img/dashboard-icons/contact-us.svg";
import contactUsIcoYellow from "../../assets/img/dashboard-icons/contact-us-yellow.svg";
import faqIco from "../../assets/img/dashboard-icons/help.svg";
import customerIco from "../../assets/img/dashboard-icons/customer.svg";
import customerIcoYellow from "../../assets/img/dashboard-icons/customer-yellow.svg";
// import faqIcoYellow from "../../assets/img/dashboard-icons/help-yellow.svg";

const adminRoutes: IAdminRoutes[] = [
  {
    path: "/dashboard",
    exact: true,
    isActive: true,
    show: true,
    main: Dashboard,
    name: "Dashboard",
    nonActiveIcon: dashboardIco,
    activeIcon: dashboardIcoYellow,
    id: uuidv4()
  },
  {
    path: "/requests",
    exact: true,
    isActive: true,
    show: true,
    main: Requests,
    name: "Requests",
    nonActiveIcon: requestIco,
    activeIcon: requestIcoYellow,
    id: uuidv4()
  },
  // {
  //   path: "/studio-management",
  //   exact: true,
  //   isActive: true,
  //   show: true,
  //   main: StudioManagement,
  //   name: "Studio Management",
  //   nonActiveIcon: studioIco,
  //   activeIcon: studioIcoYellow,
  //   id: uuidv4()
  // },
  // {
  //   path: "/studio-details/:id",
  //   exact: true,
  //   isActive: true,
  //   show: false,
  //   main: StudioDetails,
  //   name: "Studio Details",
  //   nonActiveIcon: dashboardIco,
  //   activeIcon: dashboardIcoYellow,
  //   id: uuidv4()
  // },
  // {
  //   path: "/wellness-providers",
  //   exact: true,
  //   isActive: true,
  //   show: true,
  //   main: UserManagement,
  //   name: "Wellness Providers",
  //   nonActiveIcon: userIco,
  //   activeIcon: userIcoYellow,
  //   id: uuidv4()
  // },

  {
    path: "/wellness-providers",
    exact: true,
    isActive: true,
    show: true,
    main: WellnessProvider,
    name: "Wellness Providers",
    nonActiveIcon: userIco,
    activeIcon: userIcoYellow,
    id: uuidv4()
  },
  {
    path: "/customers",
    exact: true,
    isActive: true,
    show: true,
    main: Customer,
    name: "Customers",
    nonActiveIcon: customerIco,
    activeIcon: customerIcoYellow,
    id: uuidv4()
  },
  {
		path:  '/customers/profile/:id?',
		exact: true,
		isActive: true,
		show: false,
		main: CustomerDeatil,
		name: 'Customer Profile',
		id: uuidv4(),
	},
  {
		path:  '/wellness-providers/profile/:id?',
		exact: true,
		isActive: true,
		show: false,
		main: ProviderDeatil,
		name: 'Provider Profile',
		id: uuidv4(),
	},
  {
    path: "/bookings",
    exact: true,
    isActive: true,
    show: true,
    main: Booking,
    name: "Bookings",
    nonActiveIcon: orderIco,
    activeIcon: orderIcoYellow,
    id: uuidv4()
  },
  {
    path: "/booking-details/:id",
    exact: true,
    isActive: true,
    show: false,
    main: BookingDetails,
    name: "Booking Details",
    nonActiveIcon: dashboardIco,
    activeIcon: dashboardIcoYellow,
    id: uuidv4()
  },
  {
    path: "/finance",
    exact: true,
    isActive: true,
    show: true,
    main: Finance,
    name: "Finance",
    nonActiveIcon: financeIco,
    activeIcon: financeIcoYellow,
    id: uuidv4()
  },
  {
    path: '/requests/event-details/:id?',
    exact: true,
    isActive: true,
    show: false,
    main: EventDetails,
    name: 'Event Details',
    id: uuidv4(),
  },
  // {
  //   path: "/service-category",
  //   exact: true,
  //   isActive: true,
  //   show: true,
  //   main: Category,
  //   name: "Service Category",
  //   nonActiveIcon: serviceIco,
  //   activeIcon: serviceIcoYellow,
  //   id: uuidv4()
  // },
  {
    path: "/manage-cms",
    exact: true,
    isActive: true,
    show: true,
    main: ManageCMSFAQ,
    name: "CMS",
    nonActiveIcon: cmsIco,
    activeIcon: cmsIcoYellow,
    id: uuidv4()
  },
  {
    path: "/manage-cms/add-faq/:id?",
    exact: true,
    isActive: true,
    show: false,
    main: AddFAQComponent,
    name: "Add FAQ",
    nonActiveIcon: faqIco,
    activeIcon: faqIco,
    id: uuidv4()
  },
  {
    path: "/manage-blog",
    exact: true,
    isActive: true,
    show: true,
    main: ManageBlog,
    name: "Manage Blog",
    nonActiveIcon: blogIco,
    activeIcon: blogIcoYellow,
    id: uuidv4()
  },
  // {
  //   path: "/event-details/:id",
  //   exact: true,
  //   isActive: true,
  //   show: false,
  //   main: Event,
  //   name: "Event Details",
  //   nonActiveIcon: dashboardIco,
  //   activeIcon: dashboardIcoYellow,
  //   id: uuidv4()
  // },
  {
    path: "/email-templates",
    exact: true,
    isActive: true,
    show: true,
    main: EmailTemplate,
    name: "Email Templates",
    nonActiveIcon: emailIco,
    activeIcon: emailIcoYellow,
    id: uuidv4()
  },
  {
    path: "/email-templates/add-template/:id?",
    exact: true,
    isActive: true,
    show: false,
    main: AddEmailTemplateComponent,
    name: "Add Template",
    nonActiveIcon: faqIco,
    activeIcon: faqIco,
    id: uuidv4()
  },
  {
    path: "/waitlist",
    exact: true,
    isActive: true,
    show: true,
    main: WaitList,
    name: "Wait List",
    nonActiveIcon: waitingIco,
    activeIcon: waitingIcoYellow,
    id: uuidv4()
  },
  {
    path: "/profile",
    exact: true,
    isActive: true,
    show: false,
    main: Profile,
    name: "Profile",
    nonActiveIcon: settingIco,
    activeIcon: settingIcoYellow,
    id: uuidv4()
  },
  {
    path: "/contactus",
    exact: true,
    isActive: true,
    show: true,
    main: ContactUs,
    name: "Contact Us",
    nonActiveIcon: contactUsIco,
    activeIcon: contactUsIcoYellow,
    id: uuidv4()
  },
  {
    path: "/settings",
    exact: true,
    isActive: true,
    show: true,
    main: Settings,
    name: "Settings",
    nonActiveIcon: webSettingIco,
    activeIcon: webSettingIcoYellow,
    id: uuidv4()
  },
  // {
  //   path: "/faq",
  //   exact: true,
  //   isActive: true,
  //   show: true,
  //   main: FAQ,
  //   name: "FAQ",
  //   nonActiveIcon: faqIco,
  //   activeIcon: faqIcoYellow,
  //   id: uuidv4()
  // },
  // {
  //   path: "/customer/faq",
  //   exact: true,
  //   isActive: true,
  //   show: true,
  //   main: CustomerFAQ,
  //   name: "Customer FAQ",
  //   nonActiveIcon: faqIco,
  //   activeIcon: faqIcoYellow,
  //   id: uuidv4()
  // },
  // {
  //   path: "/customer/faq/add-customer-faq/:id?",
  //   exact: true,
  //   isActive: true,
  //   show: false,
  //   main: AddCustomerFAQComponent,
  //   name: "Add Customer FAQ",
  //   nonActiveIcon: faqIco,
  //   activeIcon: faqIco,
  //   id: uuidv4()
  // }
];

export default adminRoutes;
