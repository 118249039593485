import React, { Component } from 'react';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import adminRoutes from '../config/AdminRoutes';
import History from '../helpers/History';
import * as constants from '../helpers/constants';

import { IAdminProps, IAdminStates } from './interface';

class AdminRoutes extends Component<IAdminProps, IAdminStates> {

	render() {
		let isAuthenticated = false;

		if (!localStorage.getItem(constants.adminId)) {
			isAuthenticated = false;
		} else {
			isAuthenticated = true;
		}

		return (
			<Router history={History}>
				<Switch>
					{adminRoutes.map(r => (
						<Route
							key={r.id}
							path={r.path}
							exact={r.exact}
							render={(props) =>
								isAuthenticated ? (
									<r.main {...props} />
								) : (
										<Redirect to={'/'} />
									)
							}
						/>
					))}
				</Switch>
			</Router>
		);
	}
}

export default AdminRoutes;
