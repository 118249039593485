import React, {Component, Fragment} from 'react'
import {Card, Spinner} from 'react-bootstrap'
import { IProviderDetailProps } from '../interface'
import moment from 'moment'
import ProfilePicture from '../../../assets/img/customer1.svg';
class ProviderDetailComponent extends Component<IProviderDetailProps>{

    render(){
        const {providerDetails, isDetailLoading}= this.props
        console.log(providerDetails,'providerDetails')
        return(
            <Fragment>
            
            <div className="profile-box-wrapper">
            {isDetailLoading ?
            
                <div className="d-flex justify-content-center align-items-center table-spinner">
                    <Spinner animation="border" />
                </div>
                :
                <>
                {providerDetails.profileImage && providerDetails.profileImage!=="null" ?
                <div className="text-center profile-img-wrapper mb-5">
                    <img src={providerDetails.profileThumbUrl} alt="Profile Image" />
                    <h3 className="sub-title">{providerDetails.name}</h3>
                </div>
                :
                <div className="text-center profile-img-wrapper mb-5">
                    <img src={ProfilePicture} alt="Profile Image" />
                    <h3 className="sub-title">{providerDetails.name}</h3>
                </div>
                }
                <div className="table-responsive1">
               
                    <table className="customer-info-wrapper table">
                        
                        <tr>
                            <th>ID</th>
                            <td className="text-right">{providerDetails._id}</td>
                        </tr>

                        <tr>
                            <th>Phone</th>
                            <td className="text-right">{providerDetails.contact}</td>
                        </tr>
                        <tr>
                            <th>Birthday</th>
                            <td className="text-right">{providerDetails.birthday ?moment(providerDetails.birthday).format('MM/DD/YYYY'):null}</td>
                        </tr>
                        <tr>
                            <th>Country</th>
                            <td className="text-right">{providerDetails.country ?providerDetails.country:'-'}</td>
                        </tr>
                        <tr>
                            <th>Address</th>
                            <td className="text-right">{providerDetails.address?providerDetails.address:"-"}</td>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <td className="text-right">{providerDetails.email?providerDetails.email:"-"}</td>
                        </tr>
                    </table>
    
                </div>
                </>
    }
            </div>
    
        </Fragment>
        )
    }

}

export default ProviderDetailComponent