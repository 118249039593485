import React, { Fragment, Component } from 'react';
import { IBlogListComponentProps, IBlogListComponentState } from './interface';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import icon_empty from '../../assets/img/Icon_Empty.svg';
import RCPagination from 'rc-pagination';
//@ts-ignore
import localeInfo from 'rc-pagination/lib/locale/en_US';
import moment from 'moment';
import { dateFormat } from '../../settings/helpers/constants';
import History from '../../settings/helpers/History';
import ReactDragListView from 'react-drag-listview';

class BlogListComponent extends Component<IBlogListComponentProps, IBlogListComponentState> {
	render() {
		const { blogs, totalRecords, pageLimit, pageNumber, onPageChange, firstRecordNo, lastRecordNo, handleRemove,
			//  orderChangeLoading,
			  handleOrderChange, onDragEnd } = this.props;

		const dragProps = {
			onDragEnd,
			nodeSelector: 'tr',
			handleSelector: 'span'
		};

		return (
			<Fragment>
				<Card>
					<Card.Header>
						<Card.Title>
							<span>Blog List</span>
						</Card.Title>
						<div className="table-header-actions header-actions">
							<OverlayTrigger
								placement={'top'}
								overlay={
									<Tooltip id='tooltip-up'>Click to update list order</Tooltip>
								}
							>
								{/* <Button type="button" onClick={handleOrderChange} disabled={orderChangeLoading}>{orderChangeLoading ? "Updating..." : "Update"}</Button> */}
								<span className="cursor-pointer" onClick={handleOrderChange}><i className="fas fa-save"></i></span>
							</OverlayTrigger>
						</div>
					</Card.Header>
					<Card.Body className="table-card-body">
						<ReactDragListView {...dragProps}>
							<div className="table-responsive-wrap">
								<table className="table">
									<thead>
										<tr>
											<th className="studio-block">Image</th>
											<th className="owner-block">Title</th>
											<th className="owner-block">Author</th>
											<th className="booking-block">Date</th>
											<th className="action-block text-center">Action</th>
										</tr>
									</thead>
									<tbody>
										{blogs.length > 0 ?
											blogs.map((item: any, index: any) => {
												return (
													<tr key={index}>
														<td>
															<img className="ml-2 client-profile-image" src={item.blogCoverThumb} alt="" />
														</td>
														<td>{item.title}</td>
														<td>{item.author}</td>
														<td>{item.date ? moment(item.date).format(dateFormat) : '-'}</td>
														<td className='text-center'>
															<div className='action-buttons'>
																<OverlayTrigger
																	placement={'top'}
																	overlay={
																		<Tooltip id='tooltip-edit'>Edit</Tooltip>
																	}
																>
																	<span
																		className='btn-icon'
																		onClick={() => {
																			History.push('/manage-blog?id=' + item._id);
																			window.scrollTo({ top: 0, behavior: 'smooth' });
																		}}
																	>
																		<i className='fas fa-edit'></i>
																	</span>
																</OverlayTrigger>
																<OverlayTrigger
																	placement={'top'}
																	overlay={
																		<Tooltip id='tooltip-delete'>
																			Delete
																		</Tooltip>
																	}
																>
																	<span
																		className='btn-icon'
																		onClick={() => handleRemove(item._id)}
																	>
																		<i className='fas fa-trash'></i>
																	</span>
																</OverlayTrigger>
																<OverlayTrigger
																	placement={'top'}
																	overlay={
																		<Tooltip id='tooltip-up'>Drag to up/down</Tooltip>
																	}
																>
																	<span className='btn-icon'><i className="fas fa-arrows-alt"></i></span>
																</OverlayTrigger>
															</div>
														</td>
													</tr>
												)
											})
											: (
												<tr>
													<td colSpan={7} className={"text-center"}>
														<div className={'no-result-block'}>
															<div className='empty-ico'>
																<img alt='Icon_Empty.svg' width='50' src={icon_empty} />
															</div>
															<h5>No Blogs Found</h5>
														</div>
													</td>
												</tr>
											)}
									</tbody>
								</table>
							</div>
						</ReactDragListView>
						<div className="table-pagination-wrap">
							<div>
								<RCPagination
									className="custom-pagination-design"
									current={pageNumber}
									total={totalRecords}
									defaultPageSize={pageLimit}
									pageSize={pageLimit}
									hideOnSinglePage={true}
									showPrevNextJumpers={true}
									showSizeChanger={true}
									pageSizeOptions={['5', '10', '25', '100']}
									onChange={onPageChange}
									locale={localeInfo}
								/>
							</div>
							{totalRecords > 0 ? (
								<div className="records-filter">
									<div className="records-number">
										Displaying {firstRecordNo} - {lastRecordNo} of{' '}
										{totalRecords} records{' '}
									</div>
								</div>
							) : null}
						</div>
					</Card.Body>
				</Card>
			</Fragment>
		);
	};
}
export default BlogListComponent;
