import React, { Component } from 'react';
import moment from "moment";
import { ICustomerComponentProps, ICustomerComponentState } from './interface';
import { Row, Col, Card, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { Input } from "reactstrap";
import RCPagination from 'rc-pagination';
//@ts-ignore
import localeInfo from 'rc-pagination/lib/locale/en_US';
import searchIco from '../../assets/img/magnifying-glass.svg';
import icon_empty from '../../assets/img/Icon_Empty.svg';
import { CSVLink } from 'react-csv';

class CustomerTableComponent extends Component<ICustomerComponentProps, ICustomerComponentState> {
	render() {
		const { loading, name, customers, totalRecords, pageLimit, pageNumber, onSearchChange, onFilterByStudio, onPageChange, studioManagerId, firstRecordNo, lastRecordNo, studios, sendResetPasswordLink, onModelShow } = this.props;

		let csvData: any = [];

		if (customers.length > 0 && csvData.length === 0) {
			csvData.push(['Name', 'Email', 'Contact']);
		}

		customers.forEach((w: any, i: number) => {
			let innerArray: any = [];
			innerArray.push(w.name);
			innerArray.push(w.email);
			innerArray.push(w.contact);
			// innerArray.push(w.studioName);
			csvData.push(innerArray);
		})

		return (
			<div className="page-header-wrap">
				<div className="page-header">
					<h3 className="page-title">
						<span className="heading-text">Customers</span>
					</h3>
				</div >
				<Row>
					<Col lg={12} className="grid-margin">
						<Card>
							<Card.Header>
								<Card.Title>
									<span>Customer List</span>
								</Card.Title>
								<div className="table-header-actions header-actions">
									<div className="search-input-wrap">
										<input
											type="text"
											name="name"
											value={name}
											onChange={onSearchChange}
											placeholder="Search by customer name"
											autoComplete={'off'}
											className="form-control"></input>
										<span className="search-icon">
											<img src={searchIco} alt="search" width="18px" />
										</span>
									</div>
									<div className="select-btn mr-2">
										<Input type="select" name="studioManagerId" value={studioManagerId} onChange={onFilterByStudio}>
											<option value={""}>Select Studio</option>
											{
												studios && studios.length ? studios.map((item: any, index: any) => {
													return (
														<option key={index} value={item.studioManagerId}>{item.name}</option>
													)
												}) : null}
										</Input>
									</div>
									<div>
										{csvData.length > 0 ?
											<OverlayTrigger
												placement={'top'}
												overlay={
													<Tooltip id='export-customer-list'>Click to export customer list</Tooltip>
												}
											>
												<CSVLink className="export-btn"
													data={csvData}
													filename={"customer-list-" + new Date().getTime() + (".csv")}
													target="_blank"
												>Export</CSVLink>
											</OverlayTrigger>
											: null}
									</div>
								</div>
							</Card.Header>
							<Card.Body className="table-card-body">
								<div className="table-responsive-wrap">
									<table className="table ">
										<thead>
											<tr>
												<th className="text-center">No.</th>
												<th>Name</th>
												<th>Contact Info</th>
												<th className="text-center">Bookings</th>
												<th>Created Date</th>
												<th className="action-block">Action</th>
											</tr>
										</thead>
										<tbody>
											{loading ?
												<tr>
													<td colSpan={8}>
														<div className="d-flex justify-content-center align-items-center table-spinner">
															<Spinner animation="border" />
														</div>
													</td>
												</tr>
												: customers.length ?
													customers.map((item: any, index: any) => {
														return (
															<tr key={index}>
																<td className="text-center">{index + 1 + (pageNumber - 1) * pageLimit}</td>
																<td>
																	{/* <span className="link-text text-capitalize">{item.name}</span> */}

																		<OverlayTrigger overlay={<Tooltip id="">View Profile</Tooltip>}>
																			<span className="link-text text-capitalize"
																				onClick={() => { this.props.history.push('/customers/profile?id=' + item.userId) }}
																			>{item.name}</span>
																		</OverlayTrigger>
																	
																	
																</td>
																<td>
																	<div className='info-column'>
																		{/* {item.totalBookings > 0 ?
																			<OverlayTrigger overlay={<Tooltip id="">View Bookings</Tooltip>}>
																				<p className='info-title mb-1 text-ellipsis' >
																					<span className="link-text text-capitalize"
																						onClick={() => { this.props.history.push('/bookings?customerId=' + item._id) }}
																					>{item.name}</span>
																				</p>
																			</OverlayTrigger>
																			:
																			<p className='mb-1 text-ellipsis'>
																				<span className="text-capitalize">{item.name}</span>
																			</p>
																		} */}
																		<OverlayTrigger
																			placement={'top'}
																			overlay={
																				<Tooltip id='tooltip-email'>
																					{item.email}
																				</Tooltip>
																			}
																		>
																			<p className='mb-0 text-ellipsis'>
																				<i className='fas fa-envelope mr-1  text-teal'></i>
																				<span className='mr-2 text-dark'>Email:</span>
																				<span>{item.email}</span>
																			</p>
																		</OverlayTrigger>
																		<p className='mb-0 text-ellipsis'>
																			<i className='fas fa-phone-alt mr-1  text-teal'></i>
																			<span className='mr-2 text-dark'>Contact:</span>
																			<span>{item.contact}</span>
																		</p>
																	</div>
																</td>
																<td className="text-center">{item.totalBookings}</td>
																<td>{moment(item.createdAt).format('MM/DD/YYYY')}</td>
																<td>
																	<div className='action-buttons'>
																		<OverlayTrigger
																			placement={'top'}
																			overlay={
																				<Tooltip id='tooltip-edit'>Edit</Tooltip>
																			}
																		>
																			<span
																				className='btn-icon'
																				onClick={() => onModelShow(item._id)}
																				data-target="#updateCustomerModal"
																			>
																				<i className='fas fa-edit'></i>
																			</span>
																		</OverlayTrigger>
																		<OverlayTrigger
																			placement={'top'}
																			overlay={
																				<Tooltip id='tooltip-reset-password'>Send Reset Password Link</Tooltip>
																			}
																		>
																			<span
																				className='btn-icon'
																				onClick={() => { sendResetPasswordLink(item.email) }}
																				data-toggle="modal"
																			>
																				<i className='fas fa-key'></i>
																			</span>
																		</OverlayTrigger>
																		{item.totalBookings > 0 ?
																			<OverlayTrigger
																				placement={'top'}
																				overlay={
																					<Tooltip id='tooltip-view'>View Bookings</Tooltip>
																				}
																			>
																				<span
																					className='btn-icon'
																					onClick={() => { this.props.history.push('/bookings?customerId=' + item._id) }}
																					data-toggle="modal"
																				>
																					<i className='fas fa-eye'></i>
																				</span>
																			</OverlayTrigger>
																			: null}
																	</div>
																</td>
															</tr>
														)
													})
													: (
														<tr>
															<td colSpan={7} className={"text-center"}>
																<div className={'no-result-block'}>
																	<div className='empty-ico'>
																		<img alt='Icon_Empty.svg' width='50' src={icon_empty} />
																	</div>
																	<h5>	No Customers Found</h5>
																</div>
															</td>
														</tr>
													)}
										</tbody>
									</table>
								</div>
								<div className="table-pagination-wrap">
									<div>
										<RCPagination
											className="custom-pagination-design"
											current={pageNumber}
											total={totalRecords}
											defaultPageSize={pageLimit}
											pageSize={pageLimit}
											hideOnSinglePage={true}
											showPrevNextJumpers={true}
											showSizeChanger={true}
											pageSizeOptions={['5', '10', '25', '100']}
											onChange={onPageChange}
											locale={localeInfo}
										/>
									</div>
									{totalRecords > 0 ? (
										<div className="records-filter">
											<div className="records-number">
												Displaying {firstRecordNo} - {lastRecordNo} of{' '}
												{totalRecords} records{' '}
											</div>
										</div>
									) : null}
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</div>
		);
	};
}

export default CustomerTableComponent;