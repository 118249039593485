import React, { Fragment } from "react";
import { ToastContainer } from "react-toastify";
import AppRoutes from "./settings/routes/AppRoutes";
const App: React.FC = () => {
  return (
    <Fragment>
      <AppRoutes />
      <ToastContainer
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
      />
    </Fragment>
  );
};

export default App;
