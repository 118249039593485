import React, { Fragment, MouseEvent, Component } from 'react';
import AdminRoutes from '../../settings/routes/AdminRoutes';
import adminRoutes from '../../settings/config/AdminRoutes';
import Navbar from '../Navbar';
import { IAdminRoutes } from '../../settings/config/interface';
import { NavLink } from 'react-router-dom';

class SidebarComponent extends Component {
	render() {
		const stopClick = (event: MouseEvent) => {
			event.preventDefault();
		};
		return (
			<Fragment>
				<Navbar {...this.props} />
				<div className="container-fluid page-body-wrapper">
					<nav className="sidebar sidebar-offcanvas" id="sidebar">
						<ul className="nav custom-scrollbar">
							{adminRoutes.map((k: IAdminRoutes) => {
								return k.show ? (
									<li key={k.id} className="nav-item">
										<NavLink
											to={k.path}
											exact={k.exact}
											activeClassName="active"
											className="nav-link"
											onClick={!k.isActive ? stopClick : undefined}>
											<span className="dash-icon">
												<img
													className="non-active-ico"
													src={k.nonActiveIcon}
													alt=""
													width="20"
												/>
												<img
													className="active-ico"
													src={k.activeIcon}
													alt=""
													width="20"
												/>
											</span>
											<span className="menu-title">{k.name}</span>
										</NavLink>
									</li>
								) : (
										undefined
									);
							})}
						</ul>
					</nav>
					<div className="main-panel">
						<div className="content-wrapper">
							<AdminRoutes />
						</div>
					</div>
				</div>
			</Fragment>
		);
	};
}
export default SidebarComponent;