import React, { Component } from 'react';
import moment from 'moment';
import { IDashboardProps, IDashboardState } from './interface';
import { Row, Col, Card, Dropdown, Spinner } from 'react-bootstrap';
import RevenueLineChart from './Charts/RevenueLineChart';
import BookingLineChart from './Charts/BookingLineChart';
import CustomerLineChart from './Charts/CustomerLineChart';
import UserLogoIco from '../../assets/img/user.svg';
import History from '../../settings/helpers/History';
import { getFullAddress } from '../../settings/helpers/constants';

class DashboardComponent extends Component<IDashboardProps, IDashboardState> {
	render() {
		const {
			topServices,
			topServiceLoader,
			// topStudioIncomes,
			// topStudioIncomeLoader,
			topProviders,
			topProvidersLoader,
			topStudioSignup,
			topStudioSignupLoader,
			revenueData,
			revenueDataLoader,
			revenueDateRange,
			onRevenueChange,
			customerData,
			customerDataLoader,
			customerDateRange,
			onCustomerChange,
			bookingData,
			bookingDataLoader,
			bookingDateRange,
			onBookingChange,
		} = this.props;

		return (
			<div className='page-header-wrap'>
				<div className='page-header'>
					<h3 className='page-title'>
						<span className='heading-text'>Dashboard</span>
					</h3>
				</div>
				<Row>
					<Col lg={4} className='grid-margin'>
						<Card>
							<Card.Header>
								<Card.Title>
									<span>New&nbsp;Customers</span>
								</Card.Title>
								<div className='table-pagination-wrap p-0'>
									<Dropdown onSelect={onCustomerChange} className="status-drodown">
										<Dropdown.Toggle variant id='dropdown-basic'>
											<span>{customerDateRange}</span>
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item eventKey='Today'>Today</Dropdown.Item>
											<Dropdown.Item eventKey='Yesterday'>
												Yesterday
											</Dropdown.Item>
											<Dropdown.Item eventKey='Last 7 Days'>
												Last 7 Days
											</Dropdown.Item>
											<Dropdown.Item eventKey='Last 30 Days'>
												Last 30 Days
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>
							</Card.Header>
							{customerDataLoader ?
								<div className="d-flex justify-content-center align-items-center table-spinner custom-spinner-height">
									<Spinner animation="border" />
								</div>
								:
								<>
									<Card.Body>
										<h3>+ {customerData.total}</h3>
										<span className='gray-font-color'>New Customers</span>
									</Card.Body>
									<CustomerLineChart {...this.props} />
								</>
							}
						</Card>
					</Col>
					<Col lg={4} className='grid-margin'>
						<Card>
							<Card.Header>
								<Card.Title>
									<span>New&nbsp;Bookings</span>
								</Card.Title>
								<div className='table-pagination-wrap p-0'>
									<Dropdown onSelect={onBookingChange} className="status-drodown">
										<Dropdown.Toggle variant id='dropdown-basic' >
											<span>{bookingDateRange}</span>
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item eventKey='Today'>Today</Dropdown.Item>
											<Dropdown.Item eventKey='Yesterday'>
												Yesterday
											</Dropdown.Item>
											<Dropdown.Item eventKey='Last 7 Days'>
												Last 7 Days
											</Dropdown.Item>
											<Dropdown.Item eventKey='Last 30 Days'>
												Last 30 Days
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>
							</Card.Header>
							{bookingDataLoader ?
								<div className="d-flex justify-content-center align-items-center table-spinner custom-spinner-height">
									<Spinner animation="border" />
								</div>
								:
								<>	<Card.Body>
									<h3>+ {bookingData.total}</h3>
									<span className='gray-font-color'>New Bookings</span>
								</Card.Body>
									<BookingLineChart {...this.props} />
								</>
							}
						</Card>
					</Col>
					<Col lg={4} className='grid-margin'>
						<Card>
							<Card.Header>
								<Card.Title>
									<span>Total Income</span>
								</Card.Title>
								<div className='table-pagination-wrap p-0'>
									<Dropdown onSelect={onRevenueChange} className="status-drodown">
										<Dropdown.Toggle variant id='dropdown-basic'>
											<span>{revenueDateRange}</span>
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item eventKey='Today'>Today</Dropdown.Item>
											<Dropdown.Item eventKey='Yesterday'>
												Yesterday
											</Dropdown.Item>
											<Dropdown.Item eventKey='Last 7 Days'>
												Last 7 Days
											</Dropdown.Item>
											<Dropdown.Item eventKey='Last 30 Days'>
												Last 30 Days
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>
							</Card.Header>
							{revenueDataLoader ?
								<div className="d-flex justify-content-center align-items-center table-spinner custom-spinner-height">
									<Spinner animation="border" />
								</div>
								:
								<>
									<Card.Body className='gray-font-color'>
										<h3>
											{revenueData.total ? (
												<>${revenueData.total.toFixed(2)}</>
											) : (
													<>$0.00</>
												)}{" "}
										</h3>
										<span>{revenueDateRange} Income</span>
									</Card.Body>
									<RevenueLineChart {...this.props} />
								</>
							}
						</Card>
					</Col>
				</Row>
				<Row className="custom-card-wrap">
					<Col lg={6} className='grid-margin'>
						<Card>
							<Card.Header>
								<Card.Title>
									<span>Top Services</span>
								</Card.Title>
							</Card.Header>
							{topServiceLoader ?
								<div className="d-flex justify-content-center align-items-center table-spinner custom-spinner-height">
									<Spinner animation="border" />
								</div>
								:
								<>
									{topServices.length > 0
										? topServices.map((item: any, index: any) => {
											return (
												<div key={index} className="custom-card-content d-flex justify-content-between">
													<div className="custom-card-left">
														<h4 className="m-0">{item.title}</h4>
														<p className="m-0">{item.serviceType === 'service' ? 'Private Session' : item.serviceType === 'class' ? 'Group Class' : 'Wellness Event'}</p>
													</div>
													<div className="custom-card-right text-right">
														<h4 className="m-0">+{item.totalBooking}</h4>
														<small className="d-block">New Booking</small>
													</div>
												</div>
											)
										}) :
										<div className="text-center p-3">
											<h5>No Services Found</h5>
										</div>
									}
								</>
							}
						</Card>
					</Col>
					<Col lg={6} className='grid-margin'>
						<Card>
							<Card.Header>
								<Card.Title>
									<span>Top Providers</span>
								</Card.Title>
							</Card.Header>
							{topProvidersLoader ?
								<div className="d-flex justify-content-center align-items-center table-spinner custom-spinner-height">
									<Spinner animation="border" />
								</div>
								:
								<>
									{topProviders.length > 0 ? (
										topProviders.map((item: any, index: any) => {
											return (
												<div key={index} className="custom-card-content d-flex justify-content-between">
													<div className="custom-card-left">
														<h4 className="m-0">{item.name}</h4>
														{item.address ?
															<p className="m-0">{getFullAddress(item)}</p>
															: null}
													</div>
													<div className="custom-card-right text-right">
														<h3 className="m-0">{item.totalEarning ? (
															<>+${item.totalEarning.toFixed(2)}</>
														) : (
																<>+$0.00</>
															)}{" "}</h3>

													</div>
												</div>
											)
										}
										)) :
										<div className="text-center p-3">
											<h5>No Providers Found</h5>
										</div>
									}
								</>
							}
						</Card>
					</Col>
				</Row>
				<Row>
					<Col lg={12} className='grid-margin'>
						<Card>
							<Card.Header>
								<Card.Title>
									<span>Recent Wellness Provider Signups</span>
								</Card.Title>
								<span className="btn-view" onClick={() => History.push('/wellness-providers')}>View All</span>
							</Card.Header>
							<Card.Body className='table-card-body'>
								<div className="table-responsive-wrap">
									<table className='table'>
										<thead>
											<tr>
												<th className='number-block'>Profile</th>
												<th className='name-block'>Contact Person</th>
												<th className='service-block'>Email</th>
												<th className='studio-block'>Wellness Provider</th>
												<th className='slot-block'>Signup Date</th>
											</tr>
										</thead>
										<tbody>
											{topStudioSignupLoader ?
												<tr>
													<td colSpan={5}>
														<div className="d-flex justify-content-center align-items-center table-spinner">
															<Spinner animation="border" />
														</div>
													</td>
												</tr>
												:
												topStudioSignup.length ? (
													topStudioSignup.map((item: any, index: any) => {
														return (
															<tr key={index}>
																<td>
																	{item.profileThumbUrl ?
																		<img
																			className='studio-img'
																			src={item.profileThumbUrl}
																			alt=''
																		/>
																		:
																		<img
																			className='studio-img'
																			src={UserLogoIco}
																			alt=''
																		/>
																	}
																</td>
																<td>{item.name}</td>
																<td>{item.email}</td>
																<td>{item.studio ? item.studio : '-'}</td>
																<td>{moment(item.createdAt).format('MM/DD/YYYY')}</td>
															</tr>
														);
													})
												) : (
														<tr>
															<td colSpan={12} className={'text-center'}>No Wellness Providers Found</td>
														</tr>
													)}
										</tbody>
									</table>
								</div>
							</Card.Body>
						</Card>
					</Col>
					{/* <Col lg={4} className='grid-margin'> */}
					{/* <Card className="top-service-card timings-modal-wrapper">
							<Card.Header>
								<Card.Title>
									<span>Top Services</span>
								</Card.Title>
							</Card.Header>
							<Card.Body className='list-group list-group-flush service-card-list custom-scrollbar'>
								{topServiceLoader ?
									<div className="d-flex justify-content-center align-items-center table-spinner">
										<Spinner animation="border" />
									</div>
									:
									topServices.length > 0
										? topServices.map((item: any, index: any) => {
											return (
												<div key={index} className='list-group-item'>
													<Row>
														<Col lg={7}>
															<h6>{item.name}</h6>
														</Col>
														<Col lg={5} className='item-slateblue-right'>
															<h6>+ {item.totalBooking}</h6>
														</Col>
													</Row>
													<Row className='gray-font-color'>
														<Col lg={7}>
															{item.totalEarning ? (
																<>${item.totalEarning.toFixed(2)}</>
															) : (
																	<>$ 0.00</>
																)}
														</Col>
														<Col lg={5} className='item-right'>
															New Booking
														</Col>
													</Row>
												</div>
											);
										})
										:
										<Row>
											<Col className={'text-center'}>No Services Found</Col>
										</Row>
								}
							</Card.Body>
						</Card> */}

					{/* <Card>
							<Card.Header>
								<Card.Title>
									<span>Top Wellness Providers Income</span>
								</Card.Title>
							</Card.Header>
							<Card.Body className='list-group list-group-flush studio-card-list'>
								{topStudioIncomeLoader ?
									<div className="d-flex justify-content-center align-items-center table-spinner">
										<Spinner animation="border" />
									</div>
									:
									topStudioIncomes.length > 0
										? topStudioIncomes.map((item: any, index: any) => {
											return (
												<div key={index} className='list-group-item'>
													<Row className='align-items-center'>
														<Col lg={7}>
															<h6>{item.studio}</h6>
															<span className='gray-font-color'>
																{item.address}
															</span>
														</Col>
														<Col lg={5} className='item-right gray-font-color'>
															<h6>
																{item.totalAmount ? (
																	<>${item.totalAmount.toFixed(2)}</>
																) : (
																		<>+ $0.00</>
																	)}{" "}
															</h6>
														</Col>
													</Row>
												</div>
											);
										})
										:
										<Row>
											<Col className={'text-center'}>No Incomes Found</Col>
										</Row>
								}
							</Card.Body>
						</Card> */}

					{/* </Col> */}
				</Row>
				<Row>
					<Col lg={8} className='grid-margin'></Col>
					<Col lg={4} className='grid-margin'>

					</Col>
				</Row>
			</div>
		);
	}
}

export default DashboardComponent;
