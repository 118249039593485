import React, { Fragment, PureComponent } from 'react';
import { IAddEmailTemplateProps, IAddEmailTemplateState, } from './interface';
import AddEmailTemplateFormComponent from './AddEmailTemplateForm.component';
import { ApiHelper } from '../../../settings/helpers/ApiHelper';
import { showSuccessToast, showErrorToast } from '../../../settings/helpers/toast';
import { asyncSetState } from '../../../settings/helpers/Common';
import History from '../../../settings/helpers/History';
import * as qs from 'query-string';

import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { emailTemplateSaveValidator } from '../../../settings/helpers/validator';


class AddEmailTemplateComponent extends PureComponent<IAddEmailTemplateProps, IAddEmailTemplateState> {
	state: IAddEmailTemplateState = {
		name: '',
		subject: '',
		body: '',
		errors: { name: '', subject: '', body: '' },
		isSubmitting: false,
		loading: false,
		isEdit: false,
		templateId: '',
		editorState: EditorState.createEmpty(),
	};

	componentDidMount = async () => {
		let search: any = {};
		search = { ...qs.parse(History.location.search) };
		if (search.id) {
			this.getTemplateDetails(search.id);
		}
	};

	// Function to get template details
	getTemplateDetails = async (templateId: string) => {
		try {
			await asyncSetState(this)({ ...this.state, loading: true });
			const result: any = await new ApiHelper().FetchFromServer(
				'/superadmin',
				'/emailtemplates/getTemplateDetails',
				'POST',
				true,
				undefined,
				{ templateId }
			);

			if (result.isError) {
				throw new Error(result.messages);
			}

			const { data } = result;
			if (data.statusCode === 200 && data.success) {
				if (data.data.length) {

					const templateData: any = {
						...data.data[0]
					};

					await asyncSetState(this)({
						...this.state,
						name: templateData.name,
						subject: templateData.subject,
						isEdit: true,
						templateId: templateData._id,
						loading: false,
					});

					const html = templateData.body;
					const contentBlock = htmlToDraft(html);
					if (contentBlock) {
						const contentState = ContentState.createFromBlockArray(
							contentBlock.contentBlocks
						);
						const editorState = EditorState.createWithContent(contentState);
						const body = draftToHtml(
							convertToRaw(editorState.getCurrentContent())
						);

						await asyncSetState(this)({
							...this.state,
							body,
							editorState
						});
					}

				}
			}
		} catch (error) {
			this.setState({ loading: false });
		}
	};

	// Function to save page
	onPageSave = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		this.setState({ isSubmitting: true });
		let { name, subject, body, templateId } = this.state;
		name = name.trim();
		subject = subject.trim();
		let payload = { name, subject, body, templateId };
		// To validate form fields
		const { isValid, errors } = emailTemplateSaveValidator(payload);
		if (isValid) {
			try {
				if (this.state.body.length <= 8) {
					this.setState({ bodyError: true });
				} else {

					let apiUrl = 'save';
					if (this.state.isEdit) {
						apiUrl = 'update';
					}

					const res = await new ApiHelper().FetchFromServer(
						'/superadmin',
						'/emailtemplates/' + apiUrl,
						'POST',
						true,
						undefined,
						payload
					);
					const { data } = res;
					if (data.statusCode === 200) {
						showSuccessToast(res.data.message);
						this.setState({ name: '', subject: '', editorState: EditorState.createEmpty(), });
						History.push('/email-templates');
					} else {
						showErrorToast(res.data.message);
					}
				}
				this.setState({ isSubmitting: false });
			} catch (error) {
				this.setState({ isSubmitting: false });
			}
		} else {
			this.setState({
				errors,
				isSubmitting: false
			});
			return;
		}
	};

	// Function to bind values
	handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		this.setState({
			...this.state,
			[name]: value,
			errors: {
				...this.state.errors,
				[name]: '',
			},
		});
	};

	// Function to change editor state
	onEditorStateChange = (editorState: any) => {
		const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
		this.setState({
			editorState,
			body: html,
			bodyError: false
		});
	};

	render() {
		return (
			<Fragment>
				<AddEmailTemplateFormComponent {...this.state}
					handleChange={this.handleChange}
					handleSubmit={this.onPageSave}
					onEditorStateChange={this.onEditorStateChange}
				/>
			</Fragment>
		);
	}
}

export default AddEmailTemplateComponent;
