import React, { Component, MouseEvent } from 'react';
import moment from "moment";
import { IOrderComponentProps, IOrderComponentState } from './interface';
import { Row, Col, Card } from 'react-bootstrap';
import { ApiHelper } from "../../settings/helpers/ApiHelper";
import History from '../../settings/helpers/History';

class OrderDetailsComponent extends Component<IOrderComponentProps, IOrderComponentState> {
	state: IOrderComponentState = {
		loading: true,
		orderDetails: {}
	};

	// Function to get order details
	getOrderDetails = async () => {
		try {
			let orderId = this.props.match.params.id;
			this.setState({
				loading: true,
				orderDetails: {},
			});
			const res = await new ApiHelper().FetchFromServer(
				"/superadmin",
				"/orders/getOrderDetails",
				"GET",
				true,
				{ orderId: orderId }
			);

			if (res.data.data.length > 0) {
				this.setState({
					orderDetails: res.data.data[0],
					loading: false
				});
			} else {
				this.setState({
					orderDetails: {},
					loading: false
				});
			}
		} catch (error) {
			this.setState({
				orderDetails: {},
				loading: false
			});
		}
	};

	componentDidMount() {
		this.getOrderDetails();
	}

	render() {
		const { orderDetails } = this.state;
		return (
			orderDetails ?
				<div className="page-header-wrap">
					<div className="page-header">
						<h3 className="page-title">
							<span
								onClick={(e: MouseEvent<HTMLButtonElement>) => {
									e.preventDefault();
									History.goBack();
								}}>
								<i className="fas fa-arrow-left link-text"></i>
							</span>
							<span className="heading-text">Booking Details</span>
						</h3>
					</div >
					<Row>
						<Col lg={6} className="grid-margin">
							<Card>
								<Card.Header>
									<Card.Title>
										<span>Booking Summary</span>
									</Card.Title>
								</Card.Header>
								<Card.Body>
									<Row>
										<Col lg={4}><label className="label-text">Booking ID:</label></Col>
										<Col lg={8}><span className="label-value">{orderDetails._id}</span></Col>
									</Row>
									<Row>
										<Col lg={4}><label className="label-text">Transaction ID:</label></Col>
										<Col lg={8}><span className="label-value">{orderDetails.transactionId}</span></Col>
									</Row>
									<Row>
										<Col lg={4}><label className="label-text">Invoice Name:</label></Col>
										<Col lg={8}><span className="label-value">{orderDetails.customerName}</span></Col>
									</Row>
									<Row>
										<Col lg={4}><label className="label-text">Provider Name:</label></Col>
										<Col lg={8}><span className="label-value">{orderDetails.studioName}</span></Col>
									</Row>
									{/* <Row>
										<Col lg={4}><label className="label-text">Branch Name:</label></Col>
										<Col lg={8}><span className="label-value">{orderDetails.branchName}</span></Col>
									</Row> */}
									<Row>
										<Col lg={4}><label className="label-text">Type:</label></Col>
										<Col lg={8}><span className="label-value text-capitalize">{orderDetails.serviceType}</span></Col>
									</Row>
									<Row>
										<Col lg={4}><label className="label-text">Invoice Date:</label></Col>
										<Col lg={8}><span className="label-value">{moment(orderDetails.createdAt).format("LLL")}</span></Col>
									</Row>
									<Row>
										<Col lg={4}><label className="label-text">Total Payment:</label></Col>
										<Col lg={8}>
											<span className="label-value">
												{orderDetails.totalAmount ? (
													<b>$ {orderDetails.totalAmount.toFixed(2)}</b>
												) : (
														<b>$ 0.00</b>
													)}{" "}
											</span>
										</Col>
									</Row>
									{orderDetails.status ?
										<Row>
											<Col lg={4}><label className="label-text">Status:</label></Col>
											<Col lg={8}>
												<div className="custome-badge">
													<label
														className={"badge " + (
															orderDetails.status === "Confirmed" ? "active-badge"
																: orderDetails.status === "Cancelled" ? "close-badge"
																	: ""
														)}>
														{orderDetails.status}
													</label>
												</div>
											</Col>
										</Row>
										: null}
								</Card.Body>
							</Card>
						</Col>
						<Col lg={6} className="grid-margin">
							<Card>
								<Card.Header>
									<Card.Title>
										<span>Customer Details</span>
									</Card.Title>
								</Card.Header>
								<Card.Body>
									<Row>
										<Col lg={4}><label className="label-text">Customer:</label></Col>
										<Col lg={8}><span className="label-value">{orderDetails.customerName}</span></Col>
									</Row>
									{orderDetails.birthday ?
										<Row>
											<Col lg={4}><label className="label-text">Birthday:</label></Col>
											<Col lg={8}><span className="label-value">	{moment(orderDetails.birthday).format('MM/DD/YYYY')}</span></Col>
										</Row>
										: null}
									<Row>
										<Col lg={4}><label className="label-text">Email:</label></Col>
										<Col lg={8}><span className="label-value">{orderDetails.email}</span></Col>
									</Row>
									<Row>
										<Col lg={4}><label className="label-text">Phone:</label></Col>
										<Col lg={8}><span className="label-value">{orderDetails.contact}</span></Col>
									</Row>
									{orderDetails.street ?
										<Row>
											<Col lg={4}><label className="label-text">Address:</label></Col>
											<Col lg={8}><span className="label-value">{orderDetails.street}</span></Col>
										</Row>
										: null}
									{orderDetails.city ?
										<Row>
											<Col lg={4}><label className="label-text">City:</label></Col>
											<Col lg={8}><span className="label-value">{orderDetails.city}</span></Col>
										</Row>
										: null}
									{orderDetails.state ?
										<Row>
											<Col lg={4}><label className="label-text">State, Zip:</label></Col>
											<Col lg={8}><span className="label-value">{orderDetails.state}, {orderDetails.zip}</span></Col>
										</Row>
										: null}
									{orderDetails.country ?
										<Row>
											<Col lg={4}><label className="label-text">Country:</label></Col>
											<Col lg={8}><span className="label-value">{orderDetails.country}</span></Col>
										</Row>
										: null}
								</Card.Body>
							</Card>
						</Col>
						{/* <Col lg={6} className="grid-margin">
							<Card>
								<Card.Header>
									<Card.Title>
										<span>Slot Details</span>
									</Card.Title>
								</Card.Header>
								<Card.Body>
									{orderDetails.timings ? orderDetails.timings.map((item: any, index: number) => {
										return (
											<Row key={index}>
												<Col lg={4}><label>{item.day}</label></Col>
												<Col lg={3}>
													<label className={"badge " + (item.opened ? ' badge-success' : ' badge-danger')}>{item.opened ? 'Open' : 'Close'}</label>
												</Col>
												<Col lg={5}>
													{item.timings ? item.timings.map((time: any, i: number) => {
														return (
															<Row key={i}>
																<Col lg={6}><label>{time.from}</label></Col>
																<Col lg={6}>{time.to}</Col>
															</Row>
														)
													}) : null}
												</Col>
											</Row>
										)
									}) : null}
								</Card.Body>
							</Card>
						</Col> */}
					</Row>
				</ div>
				: null
		);
	};
}

export default OrderDetailsComponent;