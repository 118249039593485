import React, { Component } from 'react';
import { IManageStudioComponentProps, IManageStudioComponentState } from './interface';
import { Row, Col, Card, OverlayTrigger, Tooltip, Spinner, DropdownButton, Dropdown } from 'react-bootstrap';
// import { Input } from "reactstrap";
import RCPagination from 'rc-pagination';
import moment from 'moment';
//@ts-ignore
import localeInfo from 'rc-pagination/lib/locale/en_US';
import searchIco from '../../../assets/img/magnifying-glass.svg';
import icon_empty from '../../../assets/img/Icon_Empty.svg';

class ManageStudioComponent extends Component<IManageStudioComponentProps, IManageStudioComponentState> {

	render() {
		const { loading, users, name, totalRecords, pageLimit, pageNumber, onPageChange, onSearchChange, firstRecordNo, lastRecordNo, onStudioStatusUpdate, onModelShow } = this.props;
		return (
			<Row>
				<Col lg={12} className="grid-margin">
					<Card>
						<Card.Header>
							<Card.Title>
								<span>Wellness Provider Requests</span>
							</Card.Title>
							<div className="table-header-actions header-actions">
								<div className="search-input-wrap">
									<input
										type="text"
										name="name"
										value={name}
										onChange={onSearchChange}
										placeholder="Search by name & email"
										autoComplete="false"
										className="form-control"></input>
									<span className="search-icon">
										<img src={searchIco} alt="search" width="18px" />
									</span>
								</div>
								{/* <div className="select-btn mr-2">
									<Input type="select" name="status" value={status} onChange={onFilter}>
										<option value={""}>Select status</option>
										<option value={"Active"}>Active</option>
										<option value={"Inactive"}>Inactive</option>
										<option value={"Pending"}>Pending</option>
									</Input>
								</div> */}
							</div>
						</Card.Header>
						<Card.Body className="table-card-body">
							<div className="table-responsive-wrap">
								<table className="table">
									<thead>
										<tr>
											<th className="text-center">No.</th>
											<th className="name-block">Name</th>
											<th className="email-block">Email</th>
											<th className="date-block">Request Date</th>
											<th className="status-block text-center">Status</th>
											<th className="action-block text-center">Action</th>
										</tr>
									</thead>
									<tbody>
										{loading ?
											<tr>
												<td colSpan={6}>
													<div className="d-flex justify-content-center align-items-center table-spinner">
														<Spinner animation="border" />
													</div>
												</td>
											</tr>
											: users.length ?
												users.map((item: any, index: any) => {
													return (
														<tr key={index}>
															<td className="text-center">{index + 1 + (pageNumber - 1) * pageLimit}</td>
															<td>{item.name}</td>
															<td>{item.email}</td>
															<td>{moment(item.createdAt).format('MM/DD/YYYY')}</td>
															{/* <td className="text-center">
																<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{item.status === 'Active' ? 'Click to Inactive' : 'Click to Active'}</Tooltip>}>
																	<label style={{ width: 86 }} onClick={() => onStudioStatusUpdate(item.status, item._id)} className={"cursor-pointer badge" + (item.status === 'Active' ? " active-badge" : " close-badge")}>
																		{item.status}
																	</label>
																</OverlayTrigger>
															</td> */}
															<td className="text-center">
																<DropdownButton
																	alignRight
																	id=''
																	title={item.status}
																	onSelect={(value: MouseEvent) =>
																		onStudioStatusUpdate(value, item._id)
																	}
																	variant='link'
																	className={
																		'status-dropdown-wrap ' +
																		(item.status === 'Active'
																			? 'success'
																			: 'danger')
																	}
																>
																	<Dropdown.Item eventKey='Active'>Approve</Dropdown.Item>
																	<Dropdown.Item eventKey='Disapproved'>Disapprove</Dropdown.Item>
																</DropdownButton>
															</td>
															<td className="text-center">
																<div className='action-buttons'>
																	<OverlayTrigger
																		placement={'top'}
																		overlay={
																			<Tooltip id='tooltip-view'>View Details</Tooltip>
																		}
																	>
																		<span
																			className='btn-icon'
																			onClick={() => onModelShow(item)} data-toggle="modal"
																		//  data-target="#changeModal"
																		>
																			<i className='fas fa-eye'></i>
																		</span>
																	</OverlayTrigger>
																</div>
															</td>
														</tr>
													)
												})
												: (
													<tr>
														<td colSpan={6} className={"text-center"}>
															<div className={'no-result-block'}>
																<div className='empty-ico'>
																	<img alt='Icon_Empty.svg' width='50' src={icon_empty} />
																</div>
																<h5>No Requests Found</h5>
															</div>
														</td>
													</tr>
												)}
									</tbody>
								</table>

							</div>
							<div className="table-pagination-wrap">
								<div>
									<RCPagination
										className="custom-pagination-design"
										current={pageNumber}
										total={totalRecords}
										defaultPageSize={pageLimit}
										pageSize={pageLimit}
										hideOnSinglePage={true}
										showPrevNextJumpers={true}
										showSizeChanger={true}
										pageSizeOptions={['5', '10', '25', '100']}
										onChange={onPageChange}
										locale={localeInfo}
									/>
								</div>
								{totalRecords > 0 ? (
									<div className="records-filter">
										<div className="records-number">
											Displaying {firstRecordNo} - {lastRecordNo} of{' '}
											{totalRecords} records{' '}
										</div>
									</div>
								) : null}
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		);
	};
}

export default ManageStudioComponent;