import React, { Component, Fragment, ChangeEvent, FormEvent } from 'react';
import { ApiHelper } from "../../settings/helpers/ApiHelper";
import { IOrderComponentProps, IOrderComponentState } from './interface';
import OrderTableComponent from './Order.component';
import { asyncSetState } from '../../settings/helpers/Common';
import * as qs from 'query-string';
import debounce from 'lodash/debounce';

class Order extends Component<IOrderComponentProps, IOrderComponentState> {
	constructor(props: any) {
		super(props);
		this.state = {
			orders: [],
			serviceType: '',
			customerId: '',
			providerId: '',
			totalPages: 0,
			totalRecords: 0,
			pageNumber: 1,
			pageLimit: 100,
			name: "",
			status: "",
			firstRecordNo: 0,
			lastRecordNo: 0,
			loading: true
		};
	}

	componentDidMount = async () => {
		await this.getAllOrders();
		this.onChangeDebounced = debounce(this.onChangeDebounced, 500);
	};

	componentDidUpdate = async (prevProps: IOrderComponentProps) => {
		if (prevProps.location !== this.props.location) {
			await this.getAllOrders();
		}
	};

	// Function to get all orders
	getAllOrders = async () => {
		try {
			await asyncSetState(this)({ ...this.state, loading: true });

			const current = this.props.location.search;
			let search: any = {};
			search = { ...qs.parse(current) };
			let pageNumber = 1;
			let name = '';
			let status = '';
			let serviceType = '';
			let customerId = '';
			let providerId = '';

			const currentPage = this.state.pageNumber;
			if (search.page && parseInt(search.page) !== currentPage) {
				pageNumber = parseInt(search.page);
			}
			if (search.name) {
				name = search.name;
			}
			if (search.status) {
				status = search.status;
			}
			if (search.customerId) {
				customerId = search.customerId;
			}

			if (search.providerId) {
				providerId = search.providerId;
			}

			if (search.serviceType) {
				serviceType = search.serviceType;
			}

			await asyncSetState(this)({
				...this.state,
				pageNumber,
				name,
				status,
				serviceType,
				customerId,
				providerId
			});

			const res: any = await new ApiHelper().FetchFromServer(
				'/superadmin',
				`/orders/getAllOrders?page=${this.state.pageNumber}&limit=${this.state.pageLimit}&name=${this.state.name}&status=${this.state.status}&serviceType=${this.state.serviceType}&customerId=${this.state.customerId}&providerId=${this.state.providerId}`,
				'GET',
				true,
				undefined,
				undefined
			);

			if (res.data.data.length > 0) {
				this.setState({
					orders: res.data.data,
					totalRecords: res.data.totalCount,
					totalPages: res.data.totalPages,
					firstRecordNo: ((this.state.pageNumber - 1) * this.state.pageLimit) + 1,
					lastRecordNo: ((this.state.pageNumber - 1) * this.state.pageLimit) + res.data.data.length,
					loading: false
				});
			} else {
				this.setState({
					loading: false,
					orders: [],
					totalRecords: 0,
					totalPages: 0,
					firstRecordNo: 0,
					lastRecordNo: 0
				});
			}
		} catch (error) {
			this.setState({
				loading: false,
				orders: [],
				totalRecords: 0,
				totalPages: 0,
				firstRecordNo: 0,
				lastRecordNo: 0
			});
		}
	};

	// Function to change page number
	onPageChange = async (page: number) => {
		if (page !== this.state.pageNumber) {
			await this.props.history.push(
				`/bookings?${qs.stringify({
					page: page,
					name: this.state.name,
					status: this.state.status,
					serviceType: this.state.serviceType
				})}`
			);
		}
	};

	// Function to search records
	onSearch = async (e: FormEvent<HTMLFormElement> | undefined) => {
		if (e) {
			e.preventDefault();
		}
		new ApiHelper().cancelRequest(); // Cancel previous pending request if any
		this.props.history.push(
			`/bookings?${qs.stringify({
				page: 1,
				name: this.state.name,
				status: this.state.status,
				serviceType: this.state.serviceType
			})}`
		);
	};

	// Function for search change
	onSearchChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		await asyncSetState(this)({ ...this.state, [name]: value });
		await this.onChangeDebounced();
	};

	// Function to filter records by status
	onFilterByStatus = async (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		await asyncSetState(this)({ ...this.state, [name]: value });
		await this.onSearch(undefined);
	};

	onChangeDebounced = async () => {
		await this.onSearch(undefined); // Delayed function, call api to get searched values values
	};

	render() {
		return (
			<Fragment>
				<OrderTableComponent
					{...this.state}
					{...this.props}
					onPageChange={this.onPageChange}
					onSearchChange={this.onSearchChange}
					onFilterByStatus={this.onFilterByStatus}
				/>
			</Fragment>
		);
	}
}

export default Order;