import React, { Fragment, Component, MouseEvent } from 'react';
import { IAddEmailTemplateFormComponentProps, IAddEmailTemplateFormComponentState } from './interface';
import { Form, Row, Col, Card, Button } from 'react-bootstrap';
import History from '../../../settings/helpers/History';
import { Editor } from 'react-draft-wysiwyg';

class AddEmailTemplateFormComponent extends Component<IAddEmailTemplateFormComponentProps, IAddEmailTemplateFormComponentState> {
	render() {
		const { name, subject, editorState, errors, handleChange, handleSubmit, isSubmitting, onEditorStateChange, bodyError, isEdit } = this.props;

		return (
			<Fragment>
				<div className="page-header-wrap">
					<div className="page-header">
						<h3 className='page-title'>
							<span
								onClick={(e: MouseEvent<HTMLButtonElement>) => {
									e.preventDefault();
									History.goBack();
								}}
							>
								<i className='fas fa-arrow-left link-text'></i>
							</span>
							<span className='heading-text'>{isEdit ? 'Edit' : 'Add'} Template</span>
						</h3>
					</div>
					<Form onSubmit={handleSubmit} className="form-section">
						<Row>
							{/* LEFT CARD */}
							<Col lg={12} className="grid-margin">
								<Card>
									<Card.Body>
										<Row>
											<Col lg={12}>
												<Row>
													<Col lg={6}>
														<Form.Group
															className="form-vld"
															controlId="name"
														>
															<Form.Label>Template Name</Form.Label>
															<Form.Control
																type="text"
																maxLength={80}
																aria-describedby="name"
																name="name"
																value={name}
																onChange={handleChange}
																disabled={isEdit}
																isValid={name && !errors.name}
																isInvalid={errors.name}
																autoComplete="name"
															/>
															<Form.Control.Feedback type="invalid">
																{errors.name}
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
													<Col lg={6}>
														<Form.Group
															className="form-vld"
															controlId="subject"
														>
															<Form.Label>Template Subject</Form.Label>
															<Form.Control
																type="text"
																maxLength={80}
																aria-describedby="subject"
																name="subject"
																value={subject}
																onChange={handleChange}
																isValid={subject && !errors.subject}
																isInvalid={errors.subject}
																autoComplete="subject"
															/>
															<Form.Control.Feedback type="invalid">
																{errors.subject}
															</Form.Control.Feedback>
														</Form.Group>
													</Col>
													<Col lg={12}>
														<Form.Group
															className="form-vld"
															controlId="body"
														>
															<Form.Label>Email Template Body</Form.Label>
															<Editor
																editorState={editorState}
																wrapperClassName="demo-wrapper"
																editorClassName="demo-editor"
																onEditorStateChange={onEditorStateChange}
																toolbarClassName="toolbar-class"
																toolbar={{ options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image'] }}
															/>
															<p className="editor-invalid-feedback">
																{bodyError ? 'Page content is required' : ''}
															</p>
														</Form.Group>
													</Col>

													<Col lg={12}>
														<Form.Group className="d-flex justify-content-end">
															<Button type="submit" disabled={isSubmitting}>
																{isEdit ?
																	isSubmitting ? "Updating..." : "Update"
																	:
																	isSubmitting ? "Saving..." : "Save"
																}
															</Button>
														</Form.Group>
													</Col>
												</Row>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Form>
				</div>
			</Fragment>
		);
	};
}
export default AddEmailTemplateFormComponent;
