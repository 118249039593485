import React, { Component, Fragment } from 'react'
import { Card, Spinner } from 'react-bootstrap'
import ProfilePicture from '../../../assets/img/img_profiel.png';
import { ICustomerDeatlComponentProps } from '../interface';
import customerImg from '../../../assets/img/customer1.svg';
import moment from 'moment'
class CustomerDetailComponent extends Component<ICustomerDeatlComponentProps> {
	constructor(props: any) {
		super(props)
	}
	render() {
		const { customerDetails,isDetailLoading } = this.props
		console.log(isDetailLoading, 'ustomerDetails.profileImage')
		return (
			<Fragment>
				
					<div className="profile-box-wrapper">
					{isDetailLoading ?
					<div className="d-flex justify-content-center align-items-center table-spinner">
						<Spinner animation="border" />
					</div>
					:
					<>
						<div className="text-center profile-img-wrapper mb-5">
							{customerDetails.profileImage &&customerDetails.profileImage!=="null"  ? (
								<img src={customerDetails.profileThumbUrl} alt="Profile Image" />
							) : (
									<img src={customerImg} alt={''} />
								)}

							<h3 className="sub-title">{customerDetails.name}</h3>
						</div>
						<div className="table-responsive1">
							<table className="customer-info-wrapper table">
								<tr>
									<th>ID</th>
									<td className="text-right">{customerDetails._id}</td>
								</tr>
								
								<tr>
									<th>Phone</th>
									<td className="text-right">{customerDetails.contact}</td>
								</tr>
								<tr>
									<th>Birthday</th>
									<td className="text-right">{moment(customerDetails.birthday).format('MM/DD/YYYY')}</td>
								</tr>
								<tr>
									<th>Country</th>
									<td className="text-right">{customerDetails.country}</td>
								</tr>
								<tr>
									<th>Address</th>
							<td className="text-right">{customerDetails.city},{customerDetails.state}</td>
								</tr>
								<tr>
									<th>Email</th>
									<td className="text-right">{customerDetails.email}</td>
								</tr>
							</table>
						</div>
						</>
	}
					</div>
				
			</Fragment>
		)
	}

}

export default CustomerDetailComponent