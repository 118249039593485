import React, { Component, Fragment } from 'react'
import { Spinner } from 'react-bootstrap'
import icon_empty from '../../../assets/img/Icon_Empty.svg';
import { ApiHelper } from '../../../settings/helpers/ApiHelper';
import { asyncSetState, logger } from '../../../settings/helpers/Common';
import History from '../../../settings/helpers/History';
import RCPagination from 'rc-pagination';
//@ts-ignore
import localeInfo from 'rc-pagination/lib/locale/en_US';
import { IEventListState } from '../interface';
import moment from 'moment'
import * as qs from 'query-string';

class EventList extends Component<any, IEventListState>{
	constructor(props: any) {
		super(props)
		this.state = {
			events: [],
			eventLoading: true,
			eventPageNumber: 1,
			eventPageLimit: 10,
			eventTotalRecords: 0,
			eventFirstRecordNo: 0,
			eventLastRecordNo: 0,
			eventName: ''

		}
	}

	componentDidMount = async () => {
		const { providerId } = this.props
		console.log(providerId, 'providerId');
		let search: any = {};
		search = { ...qs.parse(History.location.search) };
		console.log(search, 'search')
		if (search.id)
			await this.fetchEvents();
		// this.onChangeDebounced = debounce(this.onChangeDebounced, 500);
	};
	componentDidUpdate = async (prevProps: any) => {
		if (prevProps.location !== this.props.location) {
			
			await this.fetchEvents();
		}
	};
	fetchEvents = async () => {
		try {
			console.log(this.props, 'locationnnnnnnnnn');

			const current = this.props.location.search;
			let search: any = {};
			console.log(search,'idddddddddd')
			search = { ...qs.parse(current) };
			let id= search.id

			let eventPageNumber = 1;
			let eventName = '';

			const currentPage = this.state.eventPageNumber;

			if (search.servicePage && parseInt(search.servicePage) !== currentPage) {
				eventPageNumber = parseInt(search.servicePage);
			}
			if (search.name) {
				eventName = search.name;
			}

			await asyncSetState(this)({
				...this.state,
				eventPageNumber,
				eventName,
			});
			console.log(this.state.eventName,eventName,search.name,'search.namesearch.name')
			this.setState({ eventLoading: true })
			const result: any = await new ApiHelper().FetchFromServer(
				'/superadmin',
				`/events/getProviderEvents?page=${this.state.eventPageNumber}&limit=${this.state.eventPageLimit}&name=${this.state.eventName}&providerId=${id}`,
				'GET',
				true,
				undefined,
				undefined
			);

			if (result.isError) {
				throw new Error(result.messages);
			}

			const { data } = result;
			if (data.statusCode === 200 && data.success) {
				await asyncSetState(this)({
					...this.state,
					events: data.data,
					eventPageNumber: data.pageNumber,
					eventPageLimit: data.pageSize,
					eventTotalRecords: data.totalCount,
					eventFirstRecordNo:
						(this.state.eventPageNumber - 1) * this.state.eventPageLimit +
						1,
					eventLastRecordNo:
						(this.state.eventPageNumber - 1) * this.state.eventPageLimit +
						data.data.length,
					eventLoading: false,
				});
			}
			else{
				await asyncSetState(this)({
					...this.state,
					events: [],
					eventPageNumber: 1,
					eventTotalRecords: 0,
					eventFirstRecordNo: 0,
					eventLastRecordNo: 0,
					eventLoading: false,
				});
			}
		} catch (error) {
			console.log('catchhhhhhhhh')
			logger(error);
			await asyncSetState(this)({
				...this.state,
				events: [],
				eventPageNumber: 1,
				eventTotalRecords: 0,
				eventFirstRecordNo: 0,
				eventLastRecordNo: 0,
				eventLoading: false,
			});
		}
	};
	onServicePageChange = async (page: number) => {
		if (page !== this.state.eventPageNumber) {
			let query: any = { ...qs.parse(this.props.location.search) };
			query = {
				...query,
				page: page,
				className: this.state.eventName,
			};

			this.props.history.push(`/wellness-providers/profile?${qs.stringify(query)}`);
		}
	};
	render() {
		const { events, eventLoading, eventPageNumber, eventTotalRecords, eventFirstRecordNo, eventLastRecordNo, eventPageLimit } = this.state
		return (
			<Fragment>
				<div className="service-list-wrapper provider-service-list  table-responsive">
					<table className="table service-used-list retreat-exp-list">
						<thead>
							<tr>
								<th>S.No.</th>
								<th>Activity</th>
								<th className="w-110">Total Tickets</th>
								<th className="w-85">$Price</th>
								<th className="w-160">Duration</th>
								<th>Date</th>
							</tr>
						</thead>
						<tbody>
							{eventLoading ?
								<tr>
									<td colSpan={8}>
										<div className="d-flex justify-content-center align-items-center table-spinner">
											<Spinner animation="border" />
										</div>
									</td>
								</tr>
								: events && events.length > 0 ?
									events.map((value: any, index: number) => (
										<tr key ={index}>
											
											<td>{index + 1 + (eventPageNumber - 1) * eventPageLimit}</td>
											<td>{value.name}</td>
											<td>{value.slot}</td>
									      <td>{value.price ? (
												<>$ {value.price.toFixed(2)}</>
											) : (
													<>$ 0.00</>
												)}</td>
											<td>{moment(value.startTime).format('LT')}{' - '}	{moment(value.endTime).format('LT')}</td>
									<td>{moment(value.startDate).format('DD/MM/YYYY')}{'-'}	{moment(value.endDate).format('DD/MM/YYYY')}</td>

										</tr>
									))
									: (
										<tr>
											<td colSpan={8} className={"text-center"}>
												<div className={'no-result-block'}>
													<div className='empty-ico'>
														<img alt='Icon_Empty.svg' width='50' src={icon_empty} />
													</div>
													<h5>	No Events Found</h5>
												</div>
											</td>
										</tr>
									)}
						</tbody>
					</table>
				</div>
				<div className="table-pagination-wrap">
					<div>
						<RCPagination
							className="custom-pagination-design"
							current={eventPageNumber}
							total={eventTotalRecords}
							defaultPageSize={eventPageLimit}
							pageSize={eventPageLimit}
							hideOnSinglePage={true}
							showPrevNextJumpers={true}
							showSizeChanger={true}
							pageSizeOptions={['5', '10', '25', '100']}
							onChange={this.onServicePageChange}
							locale={localeInfo}
						/>
					</div>
					{eventTotalRecords > 0 ? (
						<div className="records-filter">
							<div className="records-number">
								Displaying {eventFirstRecordNo} - {eventLastRecordNo} of{' '}
								{eventTotalRecords} records{' '}
							</div>
						</div>
					) : null}
				</div>
			</Fragment>

		)
	}
}

export default EventList