import React, { Fragment } from "react";
import { Line } from "react-chartjs-2";
import { IChartProps, IChartState } from './interface';

class BookingLineChart extends React.Component<IChartProps, IChartState> {
    render() {
        const { bookingData } = this.props;
        const dataLine = {
            labels: bookingData.chartLabels,
            datasets: [
                {
                    label: "New Booking",
                    fill: true,
                    backgroundColor: "#00bece",
                    borderColor: "#00bece",
                    pointHoverBorderWidth: 1,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: bookingData.chartData,
                }
            ]
        }

        let options = {
            responsive: true,
            elements: {
                line: {
                    tension: 0
                }
            },
            scales: {
                xAxes: [{
                    display: false,
                    gridLines: {
                        display: false
                    }
                }],
                yAxes: [{
                    display: false,
                    gridLines: {
                        display: false
                    }
                }]
            },
            legend: {
                display: false
            },
        }

        return (
            <Fragment>
                {/* {bookingLabels.length > 0 ? */}
                    <Line data={dataLine} options={options} height={120} />
                    {/* : null} */}
            </Fragment>
        );
    }
}

export default BookingLineChart;