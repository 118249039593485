import React, { Fragment, PureComponent } from 'react';
import { IAddFAQProps, IAddFAQState, } from './interface';
import AddFAQFormComponent from './AddFAQForm.component';
import { ApiHelper } from '../../../../settings/helpers/ApiHelper';
import { showSuccessToast, showErrorToast } from '../../../../settings/helpers/toast';
import { asyncSetState } from '../../../../settings/helpers/Common';
import History from '../../../../settings/helpers/History';
import * as qs from 'query-string';

import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { faqSaveValidator } from '../../../../settings/helpers/validator';


class AddFAQComponent extends PureComponent<IAddFAQProps, IAddFAQState> {
	state: IAddFAQState = {
		question: '',
		answer: '',
		errors: { question: '', answer: '' },
		faqDetails: {},
		isSubmitting: false,
		loading: false,
		isEdit: false,
		faqId: '',
		editorState: EditorState.createEmpty(),
	};

	componentDidMount = async () => {
		let search: any = {};
		search = { ...qs.parse(History.location.search) };
		if (search.id) {
			this.fetchFAQData(search.id);
		}
	};

	// Function to get FAQ details
	fetchFAQData = async (faqId: string) => {
		try {
			await asyncSetState(this)({ ...this.state, loading: true });
			const result: any = await new ApiHelper().FetchFromServer(
				'/superadmin',
				'/faqs/getFAQDetails',
				'POST',
				true,
				undefined,
				{ faqId }
			);

			if (result.isError) {
				throw new Error(result.messages);
			}

			const { data } = result;
			if (data.statusCode === 200 && data.success) {
				if (data.data.length) {

					const faqData: any = {
						...data.data[0]
					};

					await asyncSetState(this)({
						...this.state,
						question: faqData.question,
						isEdit: true,
						faqId: faqData._id,
						loading: false,
					});

					const html = faqData.answer;
					const contentBlock = htmlToDraft(html);
					if (contentBlock) {
						const contentState = ContentState.createFromBlockArray(
							contentBlock.contentBlocks
						);
						const editorState = EditorState.createWithContent(contentState);
						const answer = draftToHtml(
							convertToRaw(editorState.getCurrentContent())
						);

						await asyncSetState(this)({
							...this.state,
							answer,
							editorState
						});
					}

				}
			}
		} catch (error) {
			this.setState({ loading: false });
		}
	};

	// Function to save FAQ details
	onFAQSave = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		this.setState({ isSubmitting: true });
		let { question, answer, faqId } = this.state;
		question = question.trim();
		let payload = { question, answer, faqId };
		// To validate form fields
		const { isValid, errors } = faqSaveValidator(payload);
		if (isValid) {
			try {
				if (this.state.answer.length <= 8) {
					this.setState({ answerError: true });
				} else {

					let apiUrl = 'save';
					if (this.state.isEdit) {
						apiUrl = 'update';
					}

					const res = await new ApiHelper().FetchFromServer(
						'/superadmin',
						'/faqs/' + apiUrl,
						'POST',
						true,
						undefined,
						payload
					);
					const { data } = res;
					if (data.statusCode === 200) {
						showSuccessToast(res.data.message);
						this.setState({ question: '', editorState: EditorState.createEmpty(), });
						History.push('/manage-cms?tab=faq');
					} else {
						showErrorToast(res.data.message);
					}
				}
				this.setState({ isSubmitting: false });
			} catch (error) {
				this.setState({ isSubmitting: false });
			}
		} else {
			this.setState({
				errors,
				isSubmitting: false
			});
			return;
		}
	};

	// Function to bind values
	handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		this.setState({
			...this.state,
			[name]: value,
			errors: {
				...this.state.errors,
				[name]: '',
			},
		});
	};

	// Function to change editor status
	onEditorStateChange = (editorState: any) => {
		const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
		this.setState({
			editorState,
			answer: html,
			answerError: false
		});
	};

	render() {
		return (
			<Fragment>
				<AddFAQFormComponent {...this.state}
					handleChange={this.handleChange}
					handleSubmit={this.onFAQSave}
					onEditorStateChange={this.onEditorStateChange}
				/>
			</Fragment>
		);
	}
}

export default AddFAQComponent;
