import Validator, { ValidationTypes } from 'js-object-validation';
import { message } from '../messages';

export const profileUpdateValidator = (data: any) => {

  let validationsNew: any = {};
  let messagesNew: any = {};

  let validations = {
    userName: {
      [ValidationTypes.REQUIRED]: true,
      [ValidationTypes.MINLENGTH]: 3,
      [ValidationTypes.MAXLENGTH]: 30
    }
  };

  if (data.currentPassword || data.newPassword) {
    validationsNew.currentPassword = {
      [ValidationTypes.REQUIRED]: true,
      [ValidationTypes.MINLENGTH]: 6,
      [ValidationTypes.MAXLENGTH]: 18
    }
    validationsNew.newPassword = {
      [ValidationTypes.REQUIRED]: true,
      [ValidationTypes.MINLENGTH]: 6,
      [ValidationTypes.MAXLENGTH]: 18
    }
    validationsNew.confirmPassword = {
      [ValidationTypes.REQUIRED]: true,
      [ValidationTypes.EQUAL]: "newPassword"
    }

    validations = { ...validations, ...validationsNew }
  }

  // Error messages
  let messages = {
    userName: {
      [ValidationTypes.REQUIRED]: message.RequiredName,
      [ValidationTypes.MINLENGTH]: message.MinLengthName,
      [ValidationTypes.MAXLENGTH]: message.MaxLengthName,
    }
  };

  if (data.currentPassword || data.newPassword) {
    messagesNew.currentPassword = {
      [ValidationTypes.REQUIRED]: message.RequiredPassword,
      [ValidationTypes.MINLENGTH]: message.MinLengthPassword,
      [ValidationTypes.MAXLENGTH]: message.MaxLengthPassword,
    }
    messagesNew.newPassword = {
      [ValidationTypes.REQUIRED]: message.RequiredPassword,
      [ValidationTypes.MINLENGTH]: message.MinLengthPassword,
      [ValidationTypes.MAXLENGTH]: message.MaxLengthPassword,
    }
    messagesNew.confirmPassword = {
      [ValidationTypes.REQUIRED]: message.EnterConfirmPassword,
      [ValidationTypes.EQUAL]: message.PasswordMatch
    }
    messages = { ...messages, ...messagesNew }
  }

  const { isValid, errors } = Validator(data, validations, messages);

  return {
    isValid,
    errors,
  };
};

export const passwordValidator = (data: any) => {
  let validations = {
    newPassword: {
      [ValidationTypes.REQUIRED]: true,
      [ValidationTypes.MINLENGTH]: 6,
      [ValidationTypes.MAXLENGTH]: 18
    },
    confirmPassword: {
      [ValidationTypes.REQUIRED]: true,
      [ValidationTypes.EQUAL]: "newPassword"
    }
  };

  // Error messages
  let messages = {
    userName: {
      [ValidationTypes.REQUIRED]: message.RequiredName,
    },
    newPassword: {
      [ValidationTypes.REQUIRED]: message.RequiredPassword,
      [ValidationTypes.MINLENGTH]: message.MinLengthPassword,
      [ValidationTypes.MAXLENGTH]: message.MaxLengthPassword,
    },
    confirmPassword: {
      [ValidationTypes.REQUIRED]: message.EnterConfirmPassword,
      [ValidationTypes.EQUAL]: message.PasswordMatch
    }
  };

  const { isValid, errors } = Validator(data, validations, messages);

  return { isValid, errors };
};
