import React, { Fragment, PureComponent, FormEvent, ChangeEvent } from 'react';
import { IBlogListProps, IBlogListState, } from './interface';
import BlogListComponent from './BlogList.component';
import { ApiHelper } from '../../settings/helpers/ApiHelper';
import AddBlog from './AddBlog';
import * as qs from 'query-string';
import debounce from 'lodash/debounce';
import { asyncSetState, confirmBox } from '../../settings/helpers/Common';
import { showSuccessToast, showErrorToast } from '../../settings/helpers/toast';

class BlogList extends PureComponent<IBlogListProps, IBlogListState> {
	state: IBlogListState = {
		blogs: [],
		loading: true,
		totalPages: 0,
		totalRecords: 0,
		pageNumber: 1,
		pageLimit: 100,
		firstRecordNo: 0,
		lastRecordNo: 0,
		orderChangeLoading: false,
	};

	componentDidMount = async () => {
		this.getBlogList();
		this.onChangeDebounced = debounce(this.onChangeDebounced, 500);
	};

	componentDidUpdate = async (prevProps: IBlogListProps) => {
		if (prevProps.location !== this.props.location) {
			await this.getBlogList();
		}
	};

	onDragEnd = async (fromIndex: any, toIndex: any) => {
		const data = [...this.state.blogs];
		const item = data.splice(fromIndex, 1)[0];
		data.splice(toIndex, 0, item);
		this.setState({ blogs: data });
	}

	// Function to get all blogs
	getBlogList = async () => {
		try {
			await asyncSetState(this)({ ...this.state, loading: true });

			const current = this.props.location.search;
			let search: any = {};
			search = { ...qs.parse(current) };
			let pageNumber = 1;

			const currentPage = this.state.pageNumber;
			if (search.page && parseInt(search.page) !== currentPage) {
				pageNumber = parseInt(search.page);
			}

			await asyncSetState(this)({
				...this.state,
				pageNumber,
			});

			const res: any = await new ApiHelper().FetchFromServer(
				'/superadmin',
				`/blogs/getAllBlogs?page=${this.state.pageNumber}&limit=${this.state.pageLimit}`,
				'GET',
				true,
				undefined,
				undefined
			);

			if (res.data.data.length > 0) {
				this.setState({
					blogs: res.data.data,
					totalRecords: res.data.totalCount,
					totalPages: res.data.totalPages,
					firstRecordNo: ((this.state.pageNumber - 1) * this.state.pageLimit) + 1,
					lastRecordNo: ((this.state.pageNumber - 1) * this.state.pageLimit) + res.data.data.length,
					loading: false
				});
			} else {
				this.setState({
					loading: false,
					blogs: [],
					totalRecords: 0,
					totalPages: 0,
					firstRecordNo: 0,
					lastRecordNo: 0
				});
			}
		} catch (error) {
			this.setState({
				loading: false,
				blogs: [],
				totalRecords: 0,
				totalPages: 0,
				firstRecordNo: 0,
				lastRecordNo: 0
			});
		}
	};

	// Function to change page number
	onPageChange = async (page: number) => {
		if (page !== this.state.pageNumber) {
			await this.props.history.push(
				`/bookings?${qs.stringify({
					page: page,
				})}`
			);
		}
	};

	// Function to search records
	onSearch = async (e: FormEvent<HTMLFormElement> | undefined) => {
		if (e) {
			e.preventDefault();
		}
		new ApiHelper().cancelRequest(); // Cancel previous pending request if any
		this.props.history.push(
			`/bookings?${qs.stringify({
				page: 1,
			})}`
		);
	};

	// Function for search change
	onSearchChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		await asyncSetState(this)({ ...this.state, [name]: value });
		await this.onChangeDebounced();
	};

	onChangeDebounced = async () => {
		await this.onSearch(undefined); // Delayed function, call api to get searched values values
	};

	// Function to remove wait list
	handleRemove = async (blogId: string) => {
		const { value } = await confirmBox({
			title: "Are you sure?",
			text: "Do you want to delete this blog?"
		});
		if (value) {
			try {
				const res = await new ApiHelper().FetchFromServer(
					"/superadmin",
					"/blogs/remove",
					"POST",
					true,
					undefined,
					{ blogId }
				);

				if (res.data.statusCode === 200) {
					showSuccessToast(res.data.message);
					this.getBlogList();
				}
			} catch (error) { }
		} else {
			return;
		}
	};

	// Function to change order
	handleOrderChange = async () => {
		let blogs: any = [];
		this.state.blogs.forEach((b: any, index: number) => {
			blogs.push({
				_id: b._id,
				order: index + 1,
			})
		});

		const { value } = await confirmBox({
			title: "Are you sure?",
			text: "Do you want to update this blog list order?"
		});
		if (value) {
			await asyncSetState(this)({ ...this.state, orderChangeLoading: true });
			try {


				const res = await new ApiHelper().FetchFromServer(
					"/superadmin",
					"/blogs/changeOrder",
					"POST",
					true,
					undefined,
					{ blogs }
				);

				if (res.data.statusCode === 200) {
					showSuccessToast(res.data.message);
					// this.getBlogList();
					await asyncSetState(this)({ ...this.state, orderChangeLoading: false });
				} else {
					showErrorToast(res.data.message);
					await asyncSetState(this)({ ...this.state, orderChangeLoading: false });
				}
			} catch (error) {
				showErrorToast(error.message);
				await asyncSetState(this)({ ...this.state, orderChangeLoading: false });
			}
		} else {
			return;
		}
	};

	render() {
		return (
			<Fragment>
				<div className="page-header-wrap">
					<div className="page-header">
						<h3 className='page-title'>
							<span className='heading-text'>Manage Blog</span>
						</h3>
					</div>
					<AddBlog
						{...this.state}
						getBlogList={this.getBlogList}
					/>
					<BlogListComponent
						{...this.state}
						onPageChange={this.onPageChange}
						handleRemove={this.handleRemove}
						handleOrderChange={this.handleOrderChange}
						onDragEnd={this.onDragEnd}
					/>
				</div>
			</Fragment>
		);
	}
}

export default BlogList;
