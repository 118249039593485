import React, { Component } from 'react';
import { ILoginState, ILoginProps } from './interface';
import { loginValidator } from '../../settings/helpers/validator';
import { ApiHelper } from '../../settings/helpers/ApiHelper';
import LoginComponent from './LoginForm.component';
import { showErrorToast } from '../../settings/helpers/toast';
import * as constants from '../../settings/helpers/constants';
import * as qs from 'query-string';

class Login extends Component<ILoginProps, ILoginState> {
  constructor(props: ILoginProps) {
    super(props);
    this.state = {
      email: '',
      password: '',
      message: '',
      errors: {
        email: '',
        password: '',
      },
      isSubmitting: false,
      // rememberMe: false
    };
  }

  componentDidMount = () => {
    const current = this.props.location.search;
    let search: any = {};
    search = { ...qs.parse(current) };
    if (search.redirectUrl) {
      if (!localStorage.getItem(constants.adminId)) {
        this.props.history.push('/?redirectUrl=requests');
      } else {
        this.props.history.push('/' + search.redirectUrl);
      }
    } else {
      if (!localStorage.getItem(constants.adminId)) {
        this.props.history.push('/');
      } else {
        this.props.history.push('/dashboard');
      }
    }
  }

  // Function to bind values
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: '',
      },
    });
  };

  // Function to login super admin
  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ isSubmitting: true });

    const { email, password } = this.state;
    const payload = {
      email: email ? email.trim().toLowerCase() : '',
      password,
    };
    // To validate form fields
    const { isValid, errors } = loginValidator(payload);
    if (isValid) {
      try {
        const res = await new ApiHelper().FetchFromServer(
          '/superadmin',
          '/login',
          'POST',
          false,
          undefined,
          payload
        );
        const { data } = res;
        if (data.statusCode === 200) {
          localStorage.setItem(constants.adminId, data.token);
          const current = this.props.location.search;
          let search: any = {};
          search = { ...qs.parse(current) };
          if (search.redirectUrl) {
            this.props.history.push('/' + search.redirectUrl);
          } else {
            this.props.history.push('/dashboard');
          }
        } else {
          this.setState({
            message: 'Invalid email or password',
          });
          showErrorToast(res.data.message);
        }
        this.setState({ isSubmitting: false });
      } catch (error) { this.setState({ isSubmitting: true }); }
    } else {
      this.setState({
        errors,
        isSubmitting: false
      });
      return;
    }
  };
  render() {
    return (
      <LoginComponent {...this.state}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit} />
    );
  }
}

export default Login;