import React, { FunctionComponent } from 'react';
import './fourzerofour.scss';
import { Button } from 'react-bootstrap';

const FourZeroFour: FunctionComponent = (props: any) => {
	return (
		<div className="notfoundcontainer">
			<div id="notfound">
				<div className="notfound">
					<div className="notfound-404">
						<h1>404</h1>
					</div>
					<h2>Oops! This Page Could Not Be Found</h2>
					<p>
						Sorry but the page you are looking for does not exist, have been
						removed. name changed or is temporarily unavailable
					</p>
					<Button type="button" onClick={() => props.history.replace('/')}>
						Go to Home page
					</Button>
				</div>
			</div>
		</div>
	);
};

export default FourZeroFour;
