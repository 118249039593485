export const message = {
  InvalidEmail: 'Please enter valid email address',
  InvalidName: 'Name should contain only characters.',
  InvalidQuestionName: 'Question is required.',
  InvalidHeading: 'Heading is required.',
  InvalidPageTitleName: 'Page title is required.',
  InvalidTemplateName: 'Template name is required.',
  InvalidTemplateSubject: 'Template subject is required.',
  InvalidCategoryName: 'Category name is required.',
  InvalidSubCategoryName: 'Sub-Category name is required.',
  InvalidBusinessTypeName: 'Business type name is required.',
  Alphabetical: 'Please enter only alphabetical letters.',
  InvalidAmenityName: 'Amenity is required.',
  RequiredEmail: 'Email address is required.',
  RequiredName: 'Name is required.',
  MinLengthName: 'Name cannot be less than 3 characters',
  MaxLengthName: 'Name cannot be more than 30 characters',
  RequiredCompany: 'Company name is required.',
  RequiredPassword: 'Password is required.',
  RequiredRePassword: 'Confirm password is required.',
  MinLengthPassword: 'Password cannot be less than 6 chars',
  MaxLengthPassword: 'Password cannot be more than 18 chars',
  EnterConfirmPassword: "Please enter confirm password.",
  PasswordMatch: "Password and confirm password didn't match.",
  InvalidNameLength: 'Name fieid should contain atleast 2 letters',
  RequiredConatctName: 'Contact name is required',
  RequiredOfficeNumber: 'Office number is required',
  RequiredContactNumber: 'Contact number is required',
  RequiredMobile: 'Mobile number is required',
  RequiredPhone: 'Phone number is required',
  RequiredEmailBody: 'Please enter some text in email body',
  RequiredEmailSubject: 'Please enter subject of the email',
  MobileInvalid: 'Mobile number should contain atleast 10 digit',
  PhoneInvalid: 'Phone number should contain atleast 10 digit',
  OfficeNoInvalid: 'Office number should contain atleast 10 digit',
  RequiredCountry: 'Please select country',
  RequiredAddress: 'Address is required',
  RequiredCity: 'City is required',
  RequiredState: 'State is required',
  RequiredPostalCode: 'Zip code is required',
  RequiredTimeZone: 'Tiimezone is required',
  RequiredCard: 'Please enter valid card number',
  RequiredCvv: 'Please enter valid CVV',
  RequiredExpireDate: 'Please enter valid expiration date',
};
