import React, { Fragment, ChangeEvent, FormEvent, PureComponent } from 'react';
import { ApiHelper } from "../../../settings/helpers/ApiHelper";
import { IContactUsProps, IContactUsState } from './interface';
import ContactUsComponent from './ContactUs.component';
import * as qs from 'query-string';
import debounce from 'lodash/debounce';
import { asyncSetState } from '../../../settings/helpers/Common';
import { withRouter } from 'react-router';
import ContactUsDetailsComponent from './ContactUsDetails.component';

class ContactUs extends PureComponent<IContactUsProps, IContactUsState> {
	state: IContactUsState = {
		messages: [],
		totalPages: 0,
		totalRecords: 0,
		pageNumber: 1,
		pageLimit: 100,
		contactUsName: "",
		firstRecordNo: 0,
		lastRecordNo: 0,
		loading: true,
		isModelShow: false,
		contactUsDetails: {}
	};

	componentDidMount = async () => {
		await this.getAllMessages();
		this.onChangeDebounced = debounce(this.onChangeDebounced, 500);
	}

	// Function to get all studio incomes
	getAllMessages = async () => {
		try {
			await asyncSetState(this)({ ...this.state, loading: true });

			const current = this.props.location.search;
			let search: any = {};
			search = { ...qs.parse(current) };
			let pageNumber = 1;
			let contactUsName = '';

			const currentPage = this.state.pageNumber;
			if (search.contactUsPage && parseInt(search.contactUsPage) !== currentPage) {
				pageNumber = parseInt(search.contactUsPage);
			}
			if (search.contactUsName) {
				contactUsName = search.contactUsName;
			}

			await asyncSetState(this)({
				...this.state,
				pageNumber,
				contactUsName
			});
			this.setState({
				loading: true,
				messages: [],
				totalRecords: 0
			});

			const res: any = await new ApiHelper().FetchFromServer(
				'/superadmin',
				`/contactus/getAllMessages?page=${this.state.pageNumber}&limit=${this.state.pageLimit}&name=${this.state.contactUsName}`,
				'GET',
				true,
				undefined,
				undefined
			);

			if (res.data.data.length > 0) {
				this.setState({
					messages: res.data.data,
					totalRecords: res.data.totalCount,
					totalPages: res.data.totalPages,
					firstRecordNo: ((this.state.pageNumber - 1) * this.state.pageLimit) + 1,
					lastRecordNo: ((this.state.pageNumber - 1) * this.state.pageLimit) + res.data.data.length,
					loading: false
				});
			} else {
				this.setState({
					loading: false,
					messages: [],
					totalRecords: 0,
					totalPages: 0,
					firstRecordNo: 0,
					lastRecordNo: 0
				});
			}
		} catch (error) {
			this.setState({
				loading: false,
				messages: [],
				totalRecords: 0,
				totalPages: 0,
				firstRecordNo: 0,
				lastRecordNo: 0
			});
		}
	};

	// Function to change page number
	onPageChange = async (page: number) => {
		if (page !== this.state.pageNumber) {
			await this.props.history.push(
				`/contactus?${qs.stringify({
					contactUsPage: page,
					contactUsName: this.state.contactUsName,
				})}`
			);
			await this.getAllMessages();
		}
	};

	// Function to search records
	onSearch = async (e: FormEvent<HTMLFormElement> | undefined) => {
		if (e) {
			e.preventDefault();
		}
		new ApiHelper().cancelRequest(); // Cancel previous pending request if any

		await this.props.history.push(
			`/contactus?${qs.stringify({
				contactUsPage: 1,
				contactUsName: this.state.contactUsName,
			})}`
		);
		await this.getAllMessages();
	};

	// Function for search change
	onSearchChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		await asyncSetState(this)({ ...this.state, [name]: value });
		await this.onChangeDebounced();
	};

	onChangeDebounced = async () => {
		await this.onSearch(undefined); // Delayed function, call api to get searched values values
	};

	// Funciton to show model
	onModelShow = async (item: any) => {
		this.setState({ isModelShow: true, contactUsDetails: item });
	};

	// Funciton to hide model
	onModelHide = async () => {
		this.setState({ isModelShow: false });
	};

	render() {
		return (
			<Fragment>
				{/* <div className="page-header-wrap" >
					<div className="page-header">
						<h3 className="page-title">
							<span className="heading-text">Contact Us</span>
						</h3>
					</div> */}
				<>
					<ContactUsComponent
						{...this.state}
						onPageChange={this.onPageChange}
						onSearchChange={this.onSearchChange}
						onModelShow={this.onModelShow}
					/>
					<ContactUsDetailsComponent {...this.state}
						onModelHide={this.onModelHide}
					/>
				</>
				{/* </div> */}
			</Fragment>
		);
	}
}

export default withRouter(ContactUs);