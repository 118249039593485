import React, { Component, FormEvent } from 'react';
import { IFAQListComponentProps, IFAQListComponentState } from './interface';
import { Dropdown, Row, Col, Card, OverlayTrigger, Tooltip, Spinner, ButtonGroup, Button } from 'react-bootstrap';
import { Input } from "reactstrap";
import RCPagination from 'rc-pagination';
import moment from 'moment';
import History from '../../../settings/helpers/History';

//@ts-ignore
import localeInfo from 'rc-pagination/lib/locale/en_US';
import searchIco from '../../../assets/img/magnifying-glass.svg';
import icon_empty from '../../../assets/img/Icon_Empty.svg';

class FAQListComponent extends Component<IFAQListComponentProps, IFAQListComponentState> {

	render() {
		const { loading, faqList, question, ids, totalRecords, pageLimit, pageNumber, onPageChange, status, firstRecordNo, lastRecordNo, onFAQStatusUpdate, onRemove, handleCheckAllCheckBox, handleCheckboxChange, onSearchChange, onFilterByStatus } = this.props;

		return (
			// <div className="page-header-wrap">
			// 	<div className="page-header">
			// 		<h3 className="page-title"> </h3>
			// 	</div >
			<Row>
				<Col lg={12} className="grid-margin">
					<Card>
						<Card.Header>
							<Card.Title>
								<span>FAQ List</span>
							</Card.Title>
							<div className="table-header-actions header-actions">
								<div className="search-input-wrap">
									<input
										type="text"
										name="question"
										value={question}
										onChange={onSearchChange}
										placeholder="Search by questions"
										autoComplete={'off'}
										className="form-control"></input>
									<span className="search-icon">
										<img src={searchIco} alt="search" width="18px" />
									</span>
								</div>
								<div className="select-btn">
									<Input type="select" name="status" value={status} onChange={onFilterByStatus}>
										<option value={""}>Select status</option>
										<option value={"Active"}>Active</option>
										<option value={"Inactive"}>Inactive</option>
									</Input>
								</div>
								<Button variant='link' size='sm'
									onClick={(e: FormEvent<HTMLButtonElement>) => {
										e.preventDefault();
										History.push('/manage-cms/add-faq');
									}}
									className="btn-add ml-2"
								>
									<i className='fas fa-plus'></i>
								</Button>
							</div>

						</Card.Header>
						<Card.Body className="table-card-body">
							<div className="table-responsive-wrap">
								<table className="table ">
									<thead>
										<tr>
											<th className='check-block table-checkbox-width'>
												<Dropdown
													as={ButtonGroup}
													className='dropdown-checkbox'
												>
													<Button variant='link' className='btn-checkbox'>
														<label className="checkWrap">
															<input type="checkbox" name="checkbox" checked={faqList.length > 0 && ids.length === faqList.length} onChange={handleCheckAllCheckBox} />
															<span className="checkmark"></span>
														</label>
													</Button>

													<Dropdown.Toggle
														split
														variant='link'
														className='btn-split pl-2'
														id='dropdown-split-basic'
													/>

													<Dropdown.Menu>
														<Dropdown.Item
															onClick={() => onRemove(ids)}
															disabled={ids.length === 0}
														>
															<i className='fas fa-trash'></i>
															<span>Delete</span>
														</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</th>

											<th className="question-block">Question</th>
											<th className="created-block">Created</th>
											<th className="status-block text-center">Status</th>
											<th className="action-block text-center">Action</th>
										</tr>
									</thead>
									<tbody>
										{loading ?
											<tr>
												<td colSpan={5}>
													<div className="d-flex justify-content-center align-items-center table-spinner">
														<Spinner animation="border" />
													</div>
												</td>
											</tr>
											: faqList.length ?
												faqList.map((item: any, index: any) => {
													return (
														<tr key={index}>
															<td>
																<label className="checkWrap">
																	<input type="checkbox" value={item._id} checked={ids.indexOf(item._id) > -1} name="checkbox" onChange={handleCheckboxChange} />
																	<span className="checkmark"></span>
																</label>
																<span className="align-middle pl-1">{index + 1 + (pageNumber - 1) * pageLimit}.</span>
															</td>

															<td>
																{item.question}
															</td>
															<td>
																{moment(item.createdAt).format('MM/DD/YYYY')}
															</td>
															<td className="text-center">
																<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{item.status === 'Active' ? 'Click to Inactive' : 'Click to Active'}</Tooltip>}>
																	<label style={{ width: 86 }} onClick={() => onFAQStatusUpdate(item.status, item._id)} className={"cursor-pointer badge" + (item.status === 'Active' ? " active-badge" : " close-badge")}>
																		{item.status}
																	</label>
																</OverlayTrigger>
															</td>

															<td className='text-center'>
																<div className='action-buttons'>
																	<OverlayTrigger
																		placement={'top'}
																		overlay={
																			<Tooltip id='tooltip-edit'>Edit</Tooltip>
																		}
																	>
																		<span
																			className='btn-icon'
																			onClick={() => {
																				History.push(
																					'/manage-cms/add-faq?id=' + item._id
																				);
																			}}
																		>
																			<i className='fas fa-edit'></i>
																		</span>
																	</OverlayTrigger>
																	<OverlayTrigger
																		placement={'top'}
																		overlay={
																			<Tooltip id='tooltip-delete'>
																				Delete
																		</Tooltip>
																		}
																	>
																		<span
																			className='btn-icon'
																			onClick={() => onRemove(item._id)}
																		>
																			<i className='fas fa-trash'></i>
																		</span>
																	</OverlayTrigger>
																</div>
															</td>


														</tr>
													)
												})
												: (
													<tr>
														<td colSpan={5} className={"text-center"}>
															<div className={'no-result-block'}>
																<div className='empty-ico'>
																	<img alt='Icon_Empty.svg' width='50' src={icon_empty} />
																</div>
																<h5>No FAQ List Found</h5>

															</div>

														</td>
													</tr>
												)}
									</tbody>
								</table>

							</div>
							<div className="table-pagination-wrap">
								<div>
									<RCPagination
										className="custom-pagination-design"
										current={pageNumber}
										total={totalRecords}
										defaultPageSize={pageLimit}
										pageSize={pageLimit}
										hideOnSinglePage={true}
										showPrevNextJumpers={true}
										showSizeChanger={true}
										pageSizeOptions={['5', '10', '25', '100']}
										onChange={onPageChange}
										locale={localeInfo}
									/>
								</div>
								{totalRecords > 0 ? (
									<div className="records-filter">
										<div className="records-number">
											Displaying {firstRecordNo} - {lastRecordNo} of{' '}
											{totalRecords} records{' '}
										</div>
									</div>
								) : null}
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			// </div >
		);
	};
}

export default FAQListComponent;