import React, { Component, Fragment } from "react";
import { IAmenitiesComponentProps, IAmenitiesComponentState } from "./interface";
import {
  Form,
  Row,
  Col,
  Card,
  Button,
  OverlayTrigger,
  Tooltip,
  Dropdown, Spinner, ButtonGroup
} from "react-bootstrap";
import { Input } from "reactstrap";
import RCPagination from 'rc-pagination';
//@ts-ignore
import localeInfo from 'rc-pagination/lib/locale/en_US';
import searchIco from '../../../assets/img/magnifying-glass.svg';
import icon_empty from '../../../assets/img/Icon_Empty.svg';
import close from "../../../assets/img/cancel.svg";
import closeblue from "../../../assets/img/cancel-blue.svg";
import defaultImage from '../../../assets/img/upload-ic.svg'
import moment from "moment";
import { blobImageUrl } from "../../../settings/helpers/Common";
import { AcceptedImageFormat } from "../../../settings/config";

class AmenitiesComponent extends Component<
  IAmenitiesComponentProps,
  IAmenitiesComponentState
  > {

  render() {
    const { name, amenity, errors, loading, amenities, ids, totalRecords, pageLimit, pageNumber, onPageChange, onSearchChange, onFilter, status, firstRecordNo, lastRecordNo, onUpdateStatus, onAmenitiesRemove, handleCheckAllCheckBox, handleCheckboxChange, onAmenitiesUpdate,
      isSubmitting,
      handleChange,
      onSubmit,
      isEdit,
      onCancel,
      setFieldValue,
      logo,
      logoError,
      logoErrorMsg
    } = this.props;

    const logoUrl = logo && blobImageUrl(logo);

    return (
      <Fragment>
        <div className="page-header-wrap">
          <Form onSubmit={onSubmit} className="form-section">
            <Row>
              {/* LEFT CARD */}
              <Col lg={12} className="grid-margin">
                <Card>
                  <Card.Header>
                    <Card.Title>
                      <span>Amenities</span>
                    </Card.Title>
                    <div className="header-actions">
                      {isEdit ? (
                        <OverlayTrigger
                          overlay={<Tooltip id="">Click to cancel</Tooltip>}
                        >
                          <span
                            className="mandatory cursor-pointer close-img"
                            onClick={onCancel}
                          >
                            <img src={close} alt="close" className="main-img" />
                            <img
                              src={closeblue}
                              alt="close"
                              className="hover-img"
                            />
                          </span>
                        </OverlayTrigger>
                      ) : null}
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col lg={12}>
                        <Row>
                          <Col lg={6}>
                            <Form.Group controlId="logo">
                              <Form.Label>Logo</Form.Label>
                              <label className="file-input-upload profile-wrap half-profile">
                                <div className="file-input-inner-upload text-center">
                                  {logoUrl ? (
                                    <div
                                      className="img-preview"
                                      style={{
                                        backgroundImage: `url(${logoUrl})`,
                                      }}
                                    />
                                  ) : (
                                      <>
                                        <img
                                          src={defaultImage}
                                          alt="upload"
                                        />
                                        <h6 className="">Select file to upload logo</h6>
                                        {/* <p className="mb-0">100 x 100px ideal for hi-res</p> */}
                                      </>
                                    )}
                                  <Form.Control
                                    type="file"
                                    placeholder="Select image to upload"
                                    aria-describedby="logo"
                                    name="logo"
                                    onChange={(event: any) => {
                                      setFieldValue('logo', event.currentTarget.files[0])
                                    }}
                                    autoComplete="profileImage"
                                    accept={AcceptedImageFormat.join()}
                                  />
                                </div>
                              </label>
                              <p className="editor-invalid-feedback">
                                {logoError ? logoErrorMsg : ''}
                              </p>
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group
                              className="form-vld"
                              controlId="amenity"
                            >
                              <Form.Label>Amenity</Form.Label>
                              <Form.Control
                                type="text"
                                maxLength={50}
                                placeholder="XYZ Amenity"
                                aria-describedby="amenity"
                                name="amenity"
                                value={amenity}
                                onChange={handleChange}
                                isValid={amenity && !errors.amenity}
                                isInvalid={errors.amenity}
                                autoComplete="amenity"
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.amenity}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col lg={12}>
                            <Form.Group className="d-flex justify-content-end">
                              <Button type="submit" disabled={isSubmitting}>
                                {isEdit
                                  ? isSubmitting
                                    ? "Updating..."
                                    : "Update"
                                  : isSubmitting
                                    ? "Saving..."
                                    : "Save"}
                              </Button>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              {/* LEFT CARD */}
            </Row>
          </Form>
          <Row>
            <Col lg={12} className="grid-margin">
              <Card>
                <Card.Header>
                  <Card.Title>
                    <span>Amenities List</span>
                  </Card.Title>
                  <div className="table-header-actions header-actions">
                    <div className="search-input-wrap">
                      <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={onSearchChange}
                        placeholder="Search by name"
                        autoComplete="false"
                        className="form-control"></input>
                      <span className="search-icon">
                        <img src={searchIco} alt="search" width="18px" />
                      </span>
                    </div>
                    <div className="select-btn">
                      <Input type="select" name="status" value={status} onChange={onFilter}>
                        <option value={""}>Select status</option>
                        <option value={"Active"}>Active</option>
                        <option value={"Inactive"}>Inactive</option>
                      </Input>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="table-card-body">
                  <div className="table-responsive-wrap">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th className='check-block table-checkbox-width'>
                            <Dropdown
                              as={ButtonGroup}
                              className='dropdown-checkbox'
                            >
                              <Button variant='link' className='btn-checkbox'>
                                <label className="checkWrap">
                                  <input type="checkbox" name="checkbox" checked={amenities.length > 0 && ids.length === amenities.length} onChange={handleCheckAllCheckBox} />
                                  <span className="checkmark"></span>
                                </label>
                              </Button>

                              <Dropdown.Toggle
                                split
                                variant='link'
                                className='btn-split pl-2'
                                id='dropdown-split-basic'
                              />

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => onAmenitiesRemove(ids)}
                                  disabled={ids.length === 0}
                                >
                                  <i className='fas fa-trash'></i>
                                  <span>Delete</span>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </th>

                          <th className="name-block">Logo</th>
                          <th className="name-block">Amenities</th>
                          <th className="date-block">Created At</th>
                          <th className="status-block text-center">Status</th>
                          <th className="action-block text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ?
                          <tr>
                            <td colSpan={6}>
                              <div className="d-flex justify-content-center align-items-center table-spinner">
                                <Spinner animation="border" />
                              </div>
                            </td>
                          </tr>
                          : amenities.length ?
                            amenities.map((item: any, index: any) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <label className="checkWrap">
                                      <input type="checkbox" value={item._id} checked={ids.indexOf(item._id) > -1} name="checkbox" onChange={handleCheckboxChange} />
                                      <span className="checkmark"></span>
                                    </label>
                                    <span className="align-middle pl-1">{index + 1 + (pageNumber - 1) * pageLimit}.</span>
                                  </td>
                                  <td>
																			<img className="client-profile-image" src={item.logoThumbUrl} alt="" />
																		</td>
                                  <td>{item.name}</td>
                                  <td>{moment(item.createdAt).format('MM/DD/YYYY')}</td>
                                  <td className="text-center">
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{item.status === "Active" ? 'Click to Inactive' : 'Click to Active'}</Tooltip>}>
                                      <label style={{ width: 86 }} onClick={() => onUpdateStatus(item)} className={"cursor-pointer badge" + (item.status === "Active" ? " active-badge" : " close-badge")}>
                                        {item.status}
                                      </label>
                                    </OverlayTrigger>
                                  </td>
                                  <td className="text-center">
                                    <div className='action-buttons'>
                                      <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                          <Tooltip id='tooltip-view'>Edit</Tooltip>
                                        }
                                      >
                                        <span
                                          className='btn-icon'
                                          onClick={() => onAmenitiesUpdate(item)}
                                        >
                                          <i className='fas fa-edit'></i>
                                        </span>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                          <Tooltip id='tooltip-delete'>Delete</Tooltip>
                                        }
                                      >
                                        <span
                                          className='btn-icon'
                                          onClick={() => onAmenitiesRemove(item._id)}
                                        >
                                          <i className='fas fa-trash'></i>
                                        </span>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                            : (
                              <tr>
                                <td colSpan={5} className={"text-center"}>
                                  <div className={'no-result-block'}>
                                    <div className='empty-ico'>
                                      <img alt='Icon_Empty.svg' width='50' src={icon_empty} />
                                    </div>
                                    <h5>No Amenities Found</h5>
                                  </div>
                                </td>
                              </tr>
                            )}
                      </tbody>
                    </table>

                  </div>
                  <div className="table-pagination-wrap">
                    <div>
                      <RCPagination
                        className="custom-pagination-design"
                        current={pageNumber}
                        total={totalRecords}
                        defaultPageSize={pageLimit}
                        pageSize={pageLimit}
                        hideOnSinglePage={true}
                        showPrevNextJumpers={true}
                        showSizeChanger={true}
                        pageSizeOptions={['5', '10', '25', '100']}
                        onChange={onPageChange}
                        locale={localeInfo}
                      />
                    </div>
                    {totalRecords > 0 ? (
                      <div className="records-filter">
                        <div className="records-number">
                          Displaying {firstRecordNo} - {lastRecordNo} of{' '}
                          {totalRecords} records{' '}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}
export default AmenitiesComponent;
