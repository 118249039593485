import React, {
	FunctionComponent,
	Fragment,
	ChangeEvent
} from 'react';
import { IWaitListComponentProps } from './interface';
import {
	Col,
	Row,
	Card,
	Dropdown,
	Spinner,
	ButtonGroup, Button,
	OverlayTrigger, Tooltip
} from 'react-bootstrap';
import RCPagination from 'rc-pagination';
//@ts-ignore
import localeInfo from 'rc-pagination/lib/locale/en_US';
import searchIco from '../../assets/img/magnifying-glass.svg';
import moment from 'moment';
import icon_empty from '../../assets/img/Icon_Empty.svg';
import { CSVLink } from 'react-csv';

const WaitListComponent: FunctionComponent<IWaitListComponentProps> = (
	props: IWaitListComponentProps,
) => {
	const {
		email,
		waitlists,
		selectedWaitLists,
		pageNumber,
		pageLimit,
		totalRecords,
		firstRecordNo,
		lastRecordNo,
		onSelectOne,
		onSelectAll,
		onPageChange,
		onSearchChange,
		loading,
		onRemove
	} = props;

	let csvData: any = [];


	if (waitlists.length > 0 && csvData.length === 0) {
		csvData.push(['Email', 'Join Type']);
	}

	waitlists.forEach((w: any, i: number) => {
		let innerArray: any = [];
		innerArray.push(w.email);
		innerArray.push(w.joinType);
		csvData.push(innerArray);
	})

	return (
		<Fragment>
			<Row>
				<Col lg={12} className="grid-margin">
					<Card>
						<Card.Header>
							<Card.Title>
								<span>Wait List</span>
							</Card.Title>
							<div className="table-header-actions header-actions">
								<div className="search-input-wrap">
									<input
										type="text"
										name="email"
										value={email}
										onChange={onSearchChange}
										placeholder="Search by email"
										autoComplete="false"
										className="form-control"></input>
									<span className="search-icon">
										<img src={searchIco} alt="search" width="18px" />
									</span>
								</div>
								<div>
									{csvData.length > 0 ?
										<CSVLink
										className="export-btn"
											data={csvData}
											filename={"waitlist-" + new Date().getTime() + (".csv")}
											target="_blank"
										>Export</CSVLink>
										: null}
								</div>
							</div>
						</Card.Header>
						<Card.Body className="table-card-body">
							<div className="table-responsive-wrap">
								<table className="table">
									<thead>
										<tr>
											<th className='check-block table-checkbox-width'>
												<Dropdown
													as={ButtonGroup}
													className='dropdown-checkbox'
												>
													<Button variant='link' className='btn-checkbox'>
														<label className="checkWrap">
															<input
																type="checkbox"
																checked={
																	waitlists.length > 0 ?
																		selectedWaitLists.length === waitlists.length
																			? true
																			: false
																		: false
																}
																onChange={onSelectAll}
															/>
															<span className="checkmark"></span>
														</label>
													</Button>

													<Dropdown.Toggle
														split
														variant='link'
														className='btn-split pl-2'
														id='dropdown-split-basic'
													/>

													<Dropdown.Menu>
														<Dropdown.Item
															onClick={() => onRemove(selectedWaitLists)}
															disabled={selectedWaitLists.length === 0}
														>
															<i className='fas fa-trash'></i>
															<span>Delete</span>
														</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</th>
											<th className="email-block">Email</th>
											{/* <th className="joinType-block">Join Type</th> */}
											<th className="join-date-block">Join Date</th>
											<th className="action-block text-center">Actions</th>
										</tr>
									</thead>
									<tbody>
										{loading ?
											<tr>
												<td colSpan={5}>
													<div className="d-flex justify-content-center align-items-center table-spinner">
														<Spinner animation="border" />
													</div>
												</td>
											</tr>
											: waitlists.length > 0 ?
												waitlists.map((item: any, index: number) => {
													return (
														<tr key={item._id}>
															<td>
																<label className="checkWrap">
																	<input
																		type="checkbox"
																		checked={
																			selectedWaitLists.findIndex(
																				(d: any) => d._id === item._id,
																			) > -1
																				? true
																				: false
																		}
																		onChange={(e: ChangeEvent<HTMLInputElement>) =>
																			onSelectOne(e, item)
																		}
																	/>
																	<span className="checkmark"></span>
																</label>
																<span className="align-middle pl-1">{index + 1 + (pageNumber - 1) * pageLimit}.</span>
															</td>

															<td>{item.email}</td>
															{/* <td>{item.joinType}</td> */}
															<td>
																{moment(item.createdAt).format(
																	'HH:mm - MM/DD/YYYY',
																)}
															</td>
															<td className="text-center">
																<div className='action-buttons'>
																	<OverlayTrigger
																		placement={'top'}
																		overlay={
																			<Tooltip id='tooltip-view'>Delete</Tooltip>
																		}
																	>
																		<span
																			className='btn-icon'
																			onClick={() => onRemove(item._id)}
																		>
																			<i className='fas fa-trash'></i>
																		</span>
																	</OverlayTrigger>

																</div>
																{/* <div className="dropdown-manu table-action-dropdown">
																<Dropdown alignRight>
																	<Dropdown.Toggle id="dropdown-basic">
																		<span className="action-wrap">
																			<i className="fas fa-ellipsis-h"></i>
																		</span>
																	</Dropdown.Toggle>
																	<Dropdown.Menu>
																		<Dropdown.Item onClick={() => onRemove(item._id)}>
																			<i className="fa fa-trash"></i>
																			<span>Delete</span>
																		</Dropdown.Item>
																	</Dropdown.Menu>
																</Dropdown>
															</div> */}
															</td>
														</tr>
													)
												})
												: (
													<tr>
														<td colSpan={5} className="text-center">
															<div className={'no-result-block'}>
																<div className='empty-ico'>
																	<img alt='Icon_Empty.svg' width='50' src={icon_empty} />
																</div>
																<h5>No Wait List Found</h5>
															</div>
														</td>
													</tr>
												)}
									</tbody>
								</table>

							</div>
							<div className="table-pagination-wrap">
								<div>
									<RCPagination
										className="custom-pagination-design"
										current={pageNumber}
										total={totalRecords}
										defaultPageSize={pageLimit}
										pageSize={pageLimit}
										hideOnSinglePage={true}
										showPrevNextJumpers={true}
										showSizeChanger={true}
										pageSizeOptions={['5', '10', '25', '100']}
										onChange={onPageChange}
										locale={localeInfo}
									/>
								</div>
								{totalRecords > 0 ? (
									<div className="records-filter">
										<div className="records-number">
											Displaying {firstRecordNo} - {lastRecordNo} of{' '}
											{totalRecords} records{' '}
										</div>
									</div>
								) : null}
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default WaitListComponent;
