import React, { Component } from 'react';
import moment from 'moment';
import { IStudioIncomeComponentProps, IStudioIncomeComponentState } from './interface';
import { Row, Col, Card, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import RCPagination from 'rc-pagination';
//@ts-ignore
import localeInfo from 'rc-pagination/lib/locale/en_US';
import searchIco from '../../../assets/img/magnifying-glass.svg';
import icon_empty from '../../../assets/img/Icon_Empty.svg';
import History from "../../../settings/helpers/History";

class StudioIncomeComponent extends Component<IStudioIncomeComponentProps, IStudioIncomeComponentState> {
	render() {

		const { loading, studioName, studioIncomes, totalRecords, pageLimit, pageNumber, onSearchChange, onPageChange, firstRecordNo, lastRecordNo } = this.props;

		return (
			<Row>
				<Col lg={12} className="grid-margin">
					<Card>
						<Card.Header>
							<Card.Title>
								<span>Income from Wellness Providers</span>
							</Card.Title>
							<div className="table-header-actions header-actions">
								<div className="search-input-wrap">
									<input
										type="text"
										name="studioName"
										value={studioName}
										onChange={onSearchChange}
										placeholder="Search by wellness provider"
										autoComplete="false"
										className="form-control"></input>
									<span className="search-icon">
										<img src={searchIco} alt="search" width="18px" />
									</span>
								</div>
							</div>
						</Card.Header>
						<Card.Body className="table-card-body">
							<div className="table-responsive-wrap">
								<table className="table">
									<thead>
										<tr>
											<th className="no-block">No.</th>
											<th className="studio-block">Wellness Provider</th>
											<th className="owner-block">Contact Person</th>
											<th className="booking-block">Bookings</th>
											<th className="paid-price-block">Commission</th>
											<th className="paid-price-block">Provider Income</th>
											<th className="earned-price-block">Total Income</th>
										</tr>
									</thead>
									<tbody>
										{loading ?
											<tr>
												<td colSpan={7}>
													<div className="d-flex justify-content-center align-items-center table-spinner">
														<Spinner animation="border" />
													</div>
												</td>
											</tr>
											: studioIncomes.length ?
												studioIncomes.map((item: any, index: any) => {
													return (
														<tr key={index}>
															<td>
																{index + 1 + (pageNumber - 1) * pageLimit}.
																</td>

															<td>
																<div className='info-column'>
																	<p className='mb-1 text-ellipsis' >
																		<span>{item.name}</span>
																	</p>
																	<p className='mb-0 text-ellipsis'>
																		<i className='fas fa-envelope mr-1  text-teal'></i>
																		<span className='mr-2  text-dark'>Email:</span>
																		<span>{item.email}</span>
																	</p>
																	<p className='mb-0'>
																		<i className='far fa-calendar-alt mr-1  text-teal'></i>
																		<span className='mr-2  text-dark'>Created Date:</span>
																		<span>{moment(item.createdAt).format('MM/DD/YYYY')}</span>
																	</p>
																</div>
															</td>
															<td>{item.owner}</td>
															{/* <td>{item.totalOrders}</td> */}

															<td className="text-center">
																{item.totalOrders > 0 ?
																	<OverlayTrigger
																		placement={'top'}
																		overlay={
																			<Tooltip id='tooltip-view'>View Bookings</Tooltip>
																		}
																	>
																		<span
																			className='btn-icon link-text'
																			onClick={() => { History.push('/bookings?providerId=' + item.studioManagerId) }}
																			data-toggle="modal"
																		>
																			{item.totalOrders}
																		</span>
																	</OverlayTrigger>
																	:
																	<span className='btn-icon' data-toggle="modal">
																		{item.totalOrders}
																	</span>
																}
															</td>

															<td>
																{item.commission ? (
																	<>${item.commission.toFixed(2)}</>
																) : (
																		<>$0.00</>
																	)}{" "}
															</td>
															<td>
																{item.providerIncome ? (
																	<>${item.providerIncome.toFixed(2)}</>
																) : (
																		<>$0.00</>
																	)}{" "}
															</td>
															<td>
																{item.totalAmount ? (
																	<>${item.totalAmount.toFixed(2)}</>
																) : (
																		<>$0.00</>
																	)}{" "}
															</td>
														</tr>
													)
												})
												: (
													<tr>
														<td colSpan={6} className={"text-center"}>
															<div className={'no-result-block'}>
																<div className='empty-ico'>
																	<img alt='Icon_Empty.svg' width='50' src={icon_empty} />
																</div>
																<h5>No Wellness Provider Incomes Found</h5>
															</div>
														</td>
													</tr>
												)}
									</tbody>
								</table>

							</div>
							<div className="table-pagination-wrap">
								<div>
									<RCPagination
										className="custom-pagination-design"
										current={pageNumber}
										total={totalRecords}
										defaultPageSize={pageLimit}
										pageSize={pageLimit}
										hideOnSinglePage={true}
										showPrevNextJumpers={true}
										showSizeChanger={true}
										pageSizeOptions={['5', '10', '25', '100']}
										onChange={onPageChange}
										locale={localeInfo}
									/>
								</div>
								{totalRecords > 0 ? (
									<div className="records-filter">
										<div className="records-number">
											Displaying {firstRecordNo} - {lastRecordNo} of{' '}
											{totalRecords} records{' '}
										</div>
									</div>
								) : null}
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		);
	}
}

export default StudioIncomeComponent;
