import React, { Fragment, Component } from "react";
import { ILoginState, ILoginProps } from "./interface";
import { Form, Col, Button } from "react-bootstrap";

class LoginForm extends Component<ILoginProps, ILoginState> {
  render() {
    const {
      email,
      password,
      errors,
      isSubmitting,
      handleChange,
      handleSubmit
    } = this.props;

    return (
      <Fragment>
        <div className="auth-page-wrapper">
          <div className="login-bg"></div>
          <div className="signup-container">
           
            <div className="signup-card">
            <div className="signup-body">
            <h1 className="heading">
              <span>Sign In</span>
            </h1>
                <Form onSubmit={handleSubmit} noValidate={true}>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="email"
                    className="form-vld"
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="example@gmail.com"
                      aria-describedby="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      isValid={email && !errors.email}
                      isInvalid={errors.email}
                      autoComplete="email"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="password"
                    className="form-vld"
                  >
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="**********"
                      aria-describedby="password"
                      name="password"
                      value={password}
                      onChange={handleChange}
                      isValid={password && !errors.password}
                      isInvalid={errors.password}
                      autoComplete="new-password"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" className="submit-btn">
                    <Button block type="submit" disabled={isSubmitting}>
                      Sign In
                    </Button>
                  </Form.Group>
                </Form>
              </div>
              <div className="welcome-heading">
                <div className="welcome-inner">
                  <span>Welcome</span> {" "}
                  <span>to Atma</span>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default LoginForm;
