import React, { Fragment, Component } from 'react';
import { IAddBlogComponentProps, IAddBlogComponentState } from './interface';
import { Form, Row, Col, Card, Button } from 'react-bootstrap';
import { imageValidateAndSave, blobUrl } from '../../../settings/helpers/Common';
import defaultImage from '../../../assets/img/upload-ic.svg'
import { AcceptedImageFormat } from '../../../settings/config';
import Flatpickr from 'react-flatpickr';
import { Editor } from 'react-draft-wysiwyg';
import PageLoader from '../../PageLoader';

class AddBlogComponent extends Component<IAddBlogComponentProps, IAddBlogComponentState> {
	render() {
		const {
			blogCover,
			title,
			author,
			date,
			blogId,
			errors,
			blogCoverError,
			blogCoverErrorMsg,
			loading,
			handleChange,
			handleDateChange,
			handleSubmit,
			isSubmitting,
			setFieldValue,
			setFieldError,
			editorState,
			descriptionError,
			onEditorStateChange,
			resetForm,
		} = this.props;

		const blogCoverUrl = blogCover && blobUrl(blogCover);

		return (
			<Fragment>
				<Form onSubmit={handleSubmit} className="form-section">
					<Row>
						<Col lg={12} className="grid-margin">
							<Card>
								<Card.Header>
									<Card.Title>
										<span>Information</span>
									</Card.Title>
								</Card.Header>
								<Card.Body>
									<Row>
										<Col lg={6}>
											<Form.Group controlId="title">
												<Form.Label>Title <span className='required-field'> *</span></Form.Label>
												<Form.Control
													maxLength={100}
													type="text"
													aria-describedby="title"
													name="title"
													value={title}
													onChange={handleChange}
													autoComplete="title"
													isValid={title && !errors.title}
													isInvalid={errors.title}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.title}
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group controlId="author">
												<Form.Label>Author <span className='required-field'> *</span></Form.Label>
												<Form.Control
													maxLength={100}
													type="text"
													aria-describedby="author"
													name="author"
													value={author}
													onChange={handleChange}
													autoComplete="author"
													isValid={author && !errors.author}
													isInvalid={errors.author}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.author}
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group controlId="date">
												<Form.Label>Date</Form.Label>
												<Flatpickr
													value={date}
													options={{
														enableTime: false,
														altInput: true,
														altFormat: 'F j, Y',
														dateFormat: 'Y-m-d',
														minDate: 'today',
													}}
													onChange={(date: any) =>
														handleDateChange(
															'date',
															new Date(new Date(date).setHours(0, 0, 0, 0))
														)
													}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.date}
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
										<Col lg={6}>
											<Form.Group controlId="blogCover">
												<Form.Label>Cover (600 x 600px ideal for hi-res) <span className='required-field'> *</span></Form.Label>
												<label className="file-input-upload profile-wrap half-profile">
													<div className="file-input-inner-upload text-center">
														{blogCoverUrl ? (
															<div
																className="img-preview"
																style={{
																	backgroundImage: `url(${blogCoverUrl})`,
																}}
															/>
														) : loading ? (
															<PageLoader />
														) : (
																	<>
																		<img
																			src={defaultImage}
																			alt="upload"
																		/>
																		<h6 className="">Select file to upload your blog cover</h6>
																		<p className="mb-0">600 x 600px ideal for hi-res</p>
																	</>
																)}
														<Form.Control
															type="file"
															placeholder="Select image to upload"
															aria-describedby="blogCover"
															name="blogCover"
															onChange={(event: any) => {
																imageValidateAndSave(
																	event.currentTarget.files[0],
																	setFieldValue,
																	setFieldError,
																	'blogCover',
																	600,
																	200
																);
															}}
															autoComplete="blogCover"
															accept={AcceptedImageFormat.join()}
														/>
													</div>
												</label>
												<p className="editor-invalid-feedback">
													{blogCoverError ? blogCoverErrorMsg : ''}
												</p>
											</Form.Group>
										</Col>
										<Col lg={12}>
											<Form.Group controlId="description">
												<Form.Label>Description <span className='required-field'> *</span></Form.Label>
												<Editor
													editorState={editorState}
													wrapperClassName="demo-wrapper"
													editorClassName="demo-editor"
													onEditorStateChange={onEditorStateChange}
													toolbarClassName="toolbar-class"
													toolbar={{ options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image'] }}
												/>
												<p className="editor-invalid-feedback">
													{descriptionError ? 'Description is required' : ''}
												</p>
											</Form.Group>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>

						<Col lg={12}>
							<Form.Group className="d-flex justify-content-end">
								{blogId ?
									<div className="space-between">
										<Button variant="danger" className="mr-2" type="button" onClick={resetForm}>Cancel</Button>
										<Button type="submit" disabled={isSubmitting}>{isSubmitting ? "Updating..." : "Update"}</Button>
									</div>
									:
									<Button type="submit" disabled={isSubmitting}>{isSubmitting ? "Saving..." : "Save"}</Button>
								}
							</Form.Group>
						</Col>
					</Row>
				</Form>
			</Fragment >
		);
	};
}
export default AddBlogComponent;
