import React, { Fragment, PureComponent } from 'react';
import { IAddBlogProps, IAddBlogState, } from './interface';
import AddBlogComponent from './AddBlog.component';
import { asyncSetState } from '../../../settings/helpers/Common';
import { showSuccessToast, showErrorToast } from '../../../settings/helpers/toast';
import { ApiHelper } from '../../../settings/helpers/ApiHelper';
import { blogValidator } from '../../../settings/helpers/validator';
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import * as qs from 'query-string';
import History from '../../../settings/helpers/History';

class AddBlog extends PureComponent<IAddBlogProps, IAddBlogState> {
	state: IAddBlogState = {
		errors: { title: '', author: '' },
		blogCover: null,
		title: '',
		author: '',
		date: '',
		description: '',
		blogCoverError: false,
		blogCoverErrorMsg: '',
		descriptionError: false,
		editorState: EditorState.createEmpty(),
		blogId: ''
	};

	componentDidUpdate = async () => {
		const current = History.location.search;
		let search: any = {};
		search = { ...qs.parse(current) };
		if (search.id) {
			if (this.state.blogId !== search.id) {
				await this.setState({ blogId: search.id });
				this.getBlogDetails(search.id);
			}
		}
	};

	resetForm = async () => {
		History.push('/manage-blog');
		await this.setState({ blogId: '', blogCover: null, title: '', author: '', date: '', editorState: EditorState.createEmpty() });
	}

	// Function to get user details
	getBlogDetails = async (blogId: string) => {
		try {
			this.setState({ loading: true });
			const result = await new ApiHelper().FetchFromServer(
				'/superadmin',
				'/blogs/getBlogDetails',
				'GET',
				true,
				{ blogId },
				undefined
			);

			if (!result.isError) {
				const { data } = result;
				if (data.data.length > 0) {
					let details = data.data[0];
					const html = details.description;
					const contentBlock = htmlToDraft(html);
					if (contentBlock) {
						const contentState = ContentState.createFromBlockArray(
							contentBlock.contentBlocks
						);
						const editorState = EditorState.createWithContent(contentState);
						const description = draftToHtml(
							convertToRaw(editorState.getCurrentContent())
						);

						await asyncSetState(this)({
							...this.state,
							description,
							editorState,
							// blogCover: await getImageBlob(details.blogCover),
							blogCover: details.blogCoverUrl,
							title: details.title,
							author: details.author,
							date: details.date,
							loading: false
						});
					}

				} else {
					this.setState({
						loading: false
					});
				}
			}
		} catch (error) {
			this.setState({
				loading: false
			});
		}
	};

	// Function to bind values
	handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		this.setState({
			...this.state,
			[name]: value,
			errors: {
				...this.state.errors,
				[name]: '',
			},
		});
	};

	// Function to bind values
	handleDateChange = (name: string, value: any) => {
		this.setState({
			...this.state,
			[name]: value,
			errors: {
				...this.state.errors,
				[name]: '',
			},
		});
	};

	// Function to update home page cms details
	handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		this.setState({ isSubmitting: true });

		let { blogCover, title, author, date, description, blogId } = this.state;
		title = title.trim();
		author = author.trim();
		const payload = { blogCover, title, author, date, description };

		// To validate form fields
		let { isValid, errors } = blogValidator(payload);

		if (!this.state.blogCover) {
			isValid = false;
			this.setState({ blogCoverError: true, blogCoverErrorMsg: 'Blog Cover is required' })
		}

		if (this.state.blogCoverError) {
			isValid = false;
		}

		if (this.state.description.length <= 8) {
			isValid = false;
			this.setState({ descriptionError: true });
		}

		if (isValid) {
			try {
				let formData: FormData = new FormData();

				Object.entries(payload).forEach(([key, value]) => {
					formData.append(key, value);
				});

				formData.append('blogId', blogId);

				let apiUrl = 'save';
				if (blogId) {
					apiUrl = 'update';
				}

				const res = await new ApiHelper().FetchFromServer(
					'/superadmin',
					'/blogs/' + apiUrl,
					'POST',
					true,
					undefined,
					formData
				);
				const { data } = res;
				if (data.statusCode === 200) {
					showSuccessToast(res.data.message);
					this.resetForm();
					this.props.getBlogList();
				} else {
					showErrorToast(res.data.message);
				}
				this.setState({ isSubmitting: false });
			} catch (error) {
				this.setState({ isSubmitting: false });
			}
		} else {
			this.setState({
				errors,
				isSubmitting: false
			});
			return;
		}

	};

	// Function to set field Values of image
	setFieldValue = (name: string, obj: any) => {
		if (obj) {
			if (!obj.name.match(/.(jpg|JPG|jpeg|JPEG|bmp|BMP|gif|GIF|png|PNG)$/i)) {
				this.setState({
					[name]: null,
					[name + 'Error']: true,
					[name + 'ErrorMsg']: 'Only PNG, JPG, JPEG, BMP & GIF are accepted',
				});
			} else {
				this.setState({
					[name]: obj,
					[name + 'Error']: false,
					[name + 'ErrorMsg']: '',
				});
			}
		}
	};

	// Function to set field error of image
	setFieldError = (name: string, value: any) => {
		if (value) {
			this.setState({
				[name]: null,
				[name + 'Error']: true,
				[name + 'ErrorMsg']: value,
			});
		}
	};

	// Function to change editor state
	onEditorStateChange = (editorState: any) => {
		const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
		this.setState({
			editorState,
			description: html,
			descriptionError: false
		});
	};

	render() {
		return (
			<Fragment>
				<AddBlogComponent
					{...this.state}
					handleChange={this.handleChange}
					handleDateChange={this.handleDateChange}
					handleSubmit={this.handleSubmit}
					setFieldValue={this.setFieldValue}
					setFieldError={this.setFieldError}
					onEditorStateChange={this.onEditorStateChange}
					resetForm={this.resetForm}
				/>
			</Fragment>
		);
	}
}

export default AddBlog;
