import React, { Fragment, PureComponent } from 'react';
import { ICommissionForm, ICommissionModalProps } from './interface';
import * as Yup from 'yup';
import { FormikActions, Formik, FormikProps } from 'formik';
import { ApiHelper } from '../../../settings/helpers/ApiHelper';
import { showErrorToast, showSuccessToast } from '../../../settings/helpers/toast';
import CommissionFormViewComponent from './CommissionFormView.component';
import { Modal } from 'react-bootstrap';
import close from '../../../assets/img/cancel.svg';
import closeblue from '../../../assets/img/cancel-blue.svg';
import { logger } from '../../../settings/helpers/Common';

class CommissionModal extends PureComponent<ICommissionModalProps> {

	render() {

		const { commissionData } = this.props;

		const FormValues: ICommissionForm = {
			commission: JSON.stringify(commissionData.commission) || '',
		};
		const StudioCommissionFormSchema: Yup.ObjectSchema<Yup.Shape<
			object,
			ICommissionForm
		>> = Yup.object().shape<ICommissionForm>({
			commission: Yup.string()
				.matches(
					/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/,
					'Only numbers greater than 0 & can be decimal numbers upto 2 place'
				)
				.required('Commission is required')
		});

		const onCommissionSave = async (
			values: ICommissionForm,
			actions: FormikActions<ICommissionForm>
		) => {
			actions.setSubmitting(true);

			const newNum: number = parseFloat(parseFloat(values.commission).toFixed(2));

			let payload = {
				commission: newNum
			}

			try {
				const result: any = await new ApiHelper().FetchFromServer(
					'/superadmin',
					'/commissions/update',
					'POST',
					true,
					undefined,
					payload
				);

				if (result.isError) {
					throw new Error(result.messages);
				}

				const { data } = result;
				if (data.statusCode === 200 && data.success) {
					this.props.handleModalClose();
					actions.resetForm();
					actions.setSubmitting(false);
					showSuccessToast(data.message);
					this.props.getCommissionDetails();
				}
			} catch (error) {
				actions.setSubmitting(false);
				showErrorToast(error.message);
				logger(error);
			}
		};

		return (
			<Fragment>
				<Modal
					show={this.props.isModelShow}
					onHide={this.props.handleModalClose}
					centered
					backdrop={'static'}
					autoFocus={false}
					enforceFocus={false}
					className='add-staff-modal'
				>
					<Modal.Header>
						<Modal.Title>Update Percentage</Modal.Title>
						<button
							type='button'
							className='close modal-close'
							onClick={() => this.props.handleModalClose()}
							aria-label='Close'
						>
							<img src={close} alt='close' className='main-img' />
							<img src={closeblue} alt='close' className='hover-img' />
						</button>
					</Modal.Header>
					<Modal.Body>
						<div className=''>
							<Formik
								render={(formikBag: FormikProps<ICommissionForm>) => (
									<CommissionFormViewComponent {...formikBag} {...this.props} {...this.state} />
								)}
								enableReinitialize={true}
								initialValues={FormValues}
								validationSchema={StudioCommissionFormSchema}
								onSubmit={onCommissionSave}
							/>
						</div>
					</Modal.Body>
				</Modal>
			</Fragment>
		);
	}
};

export default CommissionModal;
