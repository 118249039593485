import Validator, { ValidationTypes } from 'js-object-validation';

export const customerValidator = (data: any) => {
  const validations = {
    name: {
      [ValidationTypes.REQUIRED]: true
    },
    contact: {
      [ValidationTypes.REQUIRED]: true
    },
    gender: {
      [ValidationTypes.REQUIRED]: true
    },
  };
  // Error messages
  const messages = {
    name: {
      [ValidationTypes.REQUIRED]: "Name is required.",
    },
    contact: {
      [ValidationTypes.REQUIRED]: "Contact number is required.",
    },
    gender: {
      [ValidationTypes.REQUIRED]: "Gender is required.",
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};