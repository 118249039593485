import React, { Component } from 'react';
import { INavbarComponentProps, INavbarComponentState } from './interface';
import BlacklogoIco from '../../assets/img/atma-logo-black.svg';
import UserLogoIco from '../../assets/img/user.svg';
import { NavLink } from 'react-router-dom';
import { Dropdown, Tabs, Tab } from 'react-bootstrap';
import profileIco from '../../assets/img/dashboard-icons/profile.svg';
import profileIcoYellow from '../../assets/img/dashboard-icons/profile-yellow.svg';
import externalLinkIco from '../../assets/img/external-link.svg';
import externalLinkIcoYellow from '../../assets/img/external-link-yellow.svg';
import logoutIco from '../../assets/img/logout.svg';
import logoutIcoYellow from '../../assets/img/logout-yellow.svg';
import notificationIco from '../../assets/img/notification.svg';
import NotificationIcon from '../../assets/img/notifications_outline.svg';
import notificationIcoyellow from '../../assets/img/notification-yellow.svg';
import History from '../../settings/helpers/History';
import { ApiHelper } from '../../settings/helpers/ApiHelper';
import { asyncSetState, logger } from '../../settings/helpers/Common';
import socketIOClient from 'socket.io-client';
import { AppConfig } from '../../settings/config';

class NavbarComponent extends Component<
  INavbarComponentProps,
  INavbarComponentState
> {
  state = {
    notifications: [],
    unreadNotifications: 0,
    showAlert: false,
    notificationCount: 0,
  };
  componentDidMount = async () => {
    await this.fetchNotifications();
  };
  componentDidUpdate = () => {
    const socket = socketIOClient(AppConfig.API_ENDPOINT);
    socket.on('addSuperadminNotification', (data: any) => {
      console.log(data, 'dataaaaaaaaaaaaaaaaaaaaaaaa');
      if (data.length > 0) {
        let notify = data[0];
        console.log(notify, 'notifynotify');

        this.setState({
          ...this.state,

          notifications: [...this.state.notifications, notify],
          unreadNotifications: notify.unreadNotifications,
        });

        this.setState({
          notificationCount: this.state.notificationCount + 1,
        });
      }
    });
  };
  fetchNotifications = async () => {
    try {
      await asyncSetState(this)({ ...this.state, loading: true });

      const result: any = await new ApiHelper().FetchFromServer(
        '/superadmin',
        `/notifications/getAllNotifications`,
        'GET',
        true,
        undefined,
        undefined
      );

      if (result.isError) {
        throw new Error(result.messages);
      }

      const { data } = result;
      if (data.statusCode === 200 && data.success) {
        let notify = data.data[0];
        await asyncSetState(this)({
          ...this.state,
          notifications: data.data,
          unreadNotifications: notify.unreadNotifications,
          loading: false,
        });
      }
    } catch (error) {
      logger(error);
      await asyncSetState(this)({
        ...this.state,
        notifications: [],
        unreadNotifications: 0,
        loading: false,
      });
    }
  };

  updateNotification = async (_id: string) => {
    try {
      await asyncSetState(this)({ ...this.state, loading: true });
      const result: any = await new ApiHelper().FetchFromServer(
        '/superadmin',
        `/notifications/updateNotification`,
        'POST',
        true,
        undefined,
        { _id }
      );

      if (result.isError) {
        throw new Error(result.messages);
      }

      const { data } = result;
      if (data.statusCode === 200 && data.success) {
        await asyncSetState(this)({
          ...this.state,
          loading: false,
        });
      }
      this.fetchNotifications();
    } catch (error) {
      logger(error);
      await asyncSetState(this)({
        ...this.state,
        loading: false,
      });
    }
  };
  render() {
    const { notifications, notificationCount } = this.state;
    console.log(this.state.notifications, 'notificationsnotifications');

    return (
      <nav className='navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row'>
        <div className='text-center navbar-brand-wrapper d-flex align-items-center justify-content-center'>
          <span className='navbar-brand brand-logo cursor-pointer'>
            <img
              onClick={() => {
                History.push('/dashboard');
              }}
              src={BlacklogoIco}
              alt='logo'
              width={120}
            />
          </span>
        </div>
        <div className='navbar-menu-wrapper d-flex align-items-stretch'>
          <ul className='navbar-nav navbar-nav-right'>
            <li>
              <a
                className='nav-link dropdown-toggle'
                id='notiDropdown'
                href='.'
                data-toggle='dropdown'
                aria-expanded='false'
                // onClick={this.updateNotificationCount}
              >
                <div
                  className={`noti-drop-icon ${notificationCount} ?"pulse-admin":""`}
                >
                  <img src={NotificationIcon} alt='notification' />
                  {notificationCount ? <span>{notificationCount}</span> : ''}
                </div>
              </a>
              <div
                className='dropdown-menu dropdown-reset'
                aria-labelledby='notiDropdown'
              >
                {' '}
                <div className='noti-list-wrapper'>
                  <div className='d-flex justify-content-between align-items-center view-all-wrapper'>
                    <h3>Activities</h3>
                    {notifications && notifications.length ? (
                      <button
                        onClick={() => {
                          History.push('/drawer/notification-list');
                        }}
                      >
                        View All Notification
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                  {/* <div className='navbar-menu-wrapper d-flex align-items-stretch'> */}
                  {/* <Dropdown.Menu className='dropdown-reset' flip={false}>
                      {console.log(
                        this.state.notifications,
                        'notifications && notifications.length'
                      )} */}
                  <ul>
                    {this.state.notifications &&
                    this.state.notifications.length ? (
                      this.state.notifications.map(
                        (value: any, index: number) => (
                          <li
                            key={index}
                            className={
                              value.status === 'Unread' ? 'active' : ''
                            }
                          >
                            <Dropdown.Item
                              onClick={() => {
                                History.push(`${value.redirectionUrl}`);
                                this.updateNotification(value._id);
                              }}
                            >
                              {value &&
                              value.profileImage &&
                              value.profileImage !== 'null' ? (
                                <img
                                  src={`${AppConfig.IMAGE_URL}${value.profileImage}`}
                                  alt='Profile Picture'
                                />
                              ) : (
                                <img src={UserLogoIco} alt='Profile Picture' />
                              )}
                              <div className='notification-customer-name'>
                                {value.description}
                                {/* <b className='text-capitalize'>
																{value.customerName}
															</b>{' '}
															has {value.title} your session. */}
                                {/* {value.title} a{' '}
															<b className='text-capitalize'>
																{value.serviceType === 'service'
																	? 'service'
																	: value.serviceType}
															</b>

															<div className='notification-time'>
																{moment(value.createdAt).fromNow()}
															</div> */}
                              </div>
                            </Dropdown.Item>
                          </li>
                        )
                      )
                    ) : (
                      <li className='no-noti'>
                        <a
                          href='javascript:void(0);'
                          className='dropdown-item text-center'
                        >
                          <div className='notification-customer-name'>
                            <span className='active'>
                              You do not have any notifications yet
                            </span>
                          </div>
                        </a>
                      </li>
                    )}
                  </ul>
                  {/* </Dropdown.Menu> */}
                  {/* </Dropdown> */}
                  {/* </div> */}
                </div>
              </div>
            </li>

            {this.props.userDetails ? (
              <li className='nav-item nav-profile dropdown'>
                <a
                  className='nav-link dropdown-toggle'
                  id='profileDropdown'
                  href='.'
                  data-toggle='dropdown'
                  aria-expanded='false'
                >
                  <div className='nav-profile-img'>
                    {this.props.userDetails.profileThumbUrl ? (
                      <img
                        src={this.props.userDetails.profileThumbUrl}
                        alt='profile-pic'
                      />
                    ) : (
                      <img src={UserLogoIco} alt='logo' />
                    )}
                    <span className='availability-status online'></span>
                  </div>
                  <div className='nav-profile-text text-capitalize ml-2'>
                    <p className='mb-1 text-black'>
                      {this.props.userDetails.name}
                    </p>
                  </div>
                </a>
                <div
                  className='dropdown-menu dropdown-reset'
                  aria-labelledby='profileDropdown'
                >
                  <div className='profile-drop-block'>
                    <div className='profile-img'>
                      {this.props.userDetails.profileThumbUrl ? (
                        <img
                          src={this.props.userDetails.profileThumbUrl}
                          alt='profile-pic'
                        />
                      ) : (
                        <img src={UserLogoIco} alt='logo' />
                      )}
                    </div>
                    <div className='user-deatils'>
                      <div className='name'>{this.props.userDetails.name}</div>
                      <div className='emailid'>
                        {this.props.userDetails.email}
                      </div>
                    </div>
                  </div>
                  <div className='dropdown-items'>
                    <NavLink
                      to={'/profile'}
                      exact={true}
                      activeClassName='active'
                      className=''
                    >
                      <span className='drop-ico'>
                        <img
                          className='nonactive-ico'
                          src={profileIco}
                          alt=''
                          width='25'
                        />
                        <img
                          className='active-ico'
                          src={profileIcoYellow}
                          alt=''
                          width='25'
                        />
                      </span>
                      <span>My profile</span>
                    </NavLink>
                  </div>
                  <div className='dropdown-items'>
                    <NavLink
                      to=''
                      onClick={this.props.goToMainApp}
                      exact={true}
                      className=''
                    >
                      <span className='drop-ico'>
                        <img
                          className='nonactive-ico'
                          src={externalLinkIco}
                          alt=''
                          width='25'
                        />
                        <img
                          className='active-ico'
                          src={externalLinkIcoYellow}
                          alt=''
                          width='25'
                        />
                      </span>
                      <span>Switch to Main App</span>
                    </NavLink>
                  </div>
                  <div className='dropdown-divider'></div>
                  <div className='dropdown-items'>
                    <NavLink
                      to=''
                      onClick={this.props.onLogout}
                      exact={true}
                      className='text-danger'
                    >
                      <span className='drop-ico'>
                        <img
                          className='nonactive-ico'
                          src={logoutIco}
                          alt=''
                          width='25'
                        />
                        <img
                          className='active-ico'
                          src={logoutIcoYellow}
                          alt=''
                          width='25'
                        />
                      </span>
                      <span>Logout</span>
                    </NavLink>
                    {/* <button className="btn btn-light" onClick={this.props.onLogout}>
										Logout
									</button> */}
                  </div>
                </div>
              </li>
            ) : null}
          </ul>
        </div>
      </nav>
    );
  }
}

export default NavbarComponent;
