import React, { Component, Fragment, FormEvent, ChangeEvent } from 'react';
import { IEmailTemplateListComponentProps, IEmailTemplateListComponentState } from './interface';
import { ApiHelper } from '../../settings/helpers/ApiHelper';
import { asyncSetState } from '../../settings/helpers/Common';
import EmailTemplateListComponent from './EmailTemplateList.component';
import * as qs from 'query-string';
import debounce from 'lodash/debounce';

class EmailTemplate extends Component<IEmailTemplateListComponentProps, IEmailTemplateListComponentState> {
	constructor(props: any) {
		super(props);
		this.state = {
			templateList: [],
			totalPages: 0,
			totalRecords: 0,
			pageNumber: 1,
			pageLimit: 100,
			name: "",
			firstRecordNo: 0,
			lastRecordNo: 0,
			loading: true
		};
	}

	componentDidMount = async () => {
		await this.getAllTemplates();
		this.onChangeDebounced = debounce(this.onChangeDebounced, 500);
	};

	componentDidUpdate = async (prevProps: IEmailTemplateListComponentProps) => {
		if (prevProps.location !== this.props.location) {
			await this.getAllTemplates();
		}
	};

	// Function to get all email templates
	getAllTemplates = async () => {
		try {
			await asyncSetState(this)({ ...this.state, loading: true });

			const current = this.props.location.search;
			let search: any = {};
			search = { ...qs.parse(current) };
			let pageNumber = 1;
			let name = '';

			const currentPage = this.state.pageNumber;
			if (search.page && parseInt(search.page) !== currentPage) {
				pageNumber = parseInt(search.page);
			}
			if (search.name) {
				name = search.name;
			}

			await asyncSetState(this)({
				...this.state,
				pageNumber,
				name
			});

			const res: any = await new ApiHelper().FetchFromServer(
				'/superadmin',
				`/emailtemplates/getAllTemplates?page=${this.state.pageNumber}&limit=${this.state.pageLimit}&name=${this.state.name}`,
				'GET',
				true,
				undefined,
				undefined
			);

			if (res.data.data.length > 0) {
				this.setState({
					templateList: res.data.data,
					totalRecords: res.data.totalCount,
					totalPages: res.data.totalPages,
					firstRecordNo: ((this.state.pageNumber - 1) * this.state.pageLimit) + 1,
					lastRecordNo: ((this.state.pageNumber - 1) * this.state.pageLimit) + res.data.data.length,
					loading: false
				});
			} else {
				this.setState({
					loading: false,
					templateList: [],
					totalRecords: 0,
					totalPages: 0,
					firstRecordNo: 0,
					lastRecordNo: 0
				});
			}
		} catch (error) {
			this.setState({
				loading: false,
				templateList: [],
				totalRecords: 0,
				totalPages: 0,
				firstRecordNo: 0,
				lastRecordNo: 0
			});
		}
	};

	// Function to change page number
	onPageChange = async (page: number) => {
		if (page !== this.state.pageNumber) {
			await this.props.history.push(
				`/email-templates?${qs.stringify({
					page: page,
					name: this.state.name
				})}`
			);
		}
	};

	// Function to search records
	onSearch = async (e: FormEvent<HTMLFormElement> | undefined) => {
		if (e) {
			e.preventDefault();
		}
		new ApiHelper().cancelRequest(); // Cancel previous pending request if any
		this.props.history.push(
			`/email-templates?${qs.stringify({
				page: 1,
				name: this.state.name
			})}`
		);
	};

	onSearchChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		await asyncSetState(this)({ ...this.state, [name]: value });
		await this.onChangeDebounced();
	};

	onChangeDebounced = async () => {
		await this.onSearch(undefined); // Delayed function, call api to get searched values values
	};

	render() {
		return (
			<Fragment>
				<EmailTemplateListComponent {...this.state}
					onPageChange={this.onPageChange}
					onSearchChange={this.onSearchChange}
				/>
			</Fragment>
		);
	}
}

export default EmailTemplate;