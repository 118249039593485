import React, { Component } from 'react';
import moment from "moment";
import { IOrderComponentProps, IOrderComponentState } from './interface';
import { Row, Col, Card, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { Input } from "reactstrap";
import RCPagination from 'rc-pagination';
//@ts-ignore
import localeInfo from 'rc-pagination/lib/locale/en_US';
import searchIco from '../../assets/img/magnifying-glass.svg';
import icon_empty from '../../assets/img/Icon_Empty.svg';

class OrderTableComponent extends Component<IOrderComponentProps, IOrderComponentState> {
	render() {
		const { loading, name, orders, totalRecords, pageLimit, pageNumber, onSearchChange, onFilterByStatus, onPageChange, serviceType, firstRecordNo, lastRecordNo } = this.props;
		return (
			< div className="page-header-wrap" >
				<div className="page-header">
					<h3 className="page-title">
						<span className="heading-text">Bookings</span>
					</h3>
				</div >
				<Row>
					<Col lg={12} className="grid-margin">
						<Card>
							<Card.Header>
								<Card.Title>
									<span>Booking List</span>
								</Card.Title>
								<div className="table-header-actions header-actions">
									<div className="search-input-wrap">
										<input
											type="text"
											name="name"
											value={name}
											onChange={onSearchChange}
											placeholder="Search by customer name"
											autoComplete={'off'}
											className="form-control"></input>
										<span className="search-icon">
											<img src={searchIco} alt="search" width="18px" />
										</span>
									</div>
									{/* <div className="select-btn mr-2">
										<Input type="select" name="status" value={status} onChange={onFilterByStatus}>
											<option value={""}>Select status</option>
											<option value='Pending'>Pending</option>
											<option value='Inprocess'>Inprocess</option>
											<option value='Completed'>Completed</option>
											<option value='Canceled'>Canceled</option>
										</Input>
									</div> */}
									<div className="select-btn mr-2">
										<Input type="select" name="serviceType" value={serviceType} onChange={onFilterByStatus}>
											<option value={""}>Select booking type</option>
											<option value='class'>Class</option>
											<option value='service'>Service</option>
											<option value='event'>Event</option>
										</Input>
									</div>
								</div>
							</Card.Header>
							<Card.Body className="table-card-body">
								<div className="table-responsive-wrap">
									<table className="table ">
										<thead>
											<tr>
												<th className="text-center">No.</th>
												<th>Customer Information</th>
												<th>Booking Information</th>
												{/* <th>Type</th> */}
												<th>Amount</th>
												<th>Invoice Date/Time</th>
												{/* <th className="text-center">Payment Status</th> */}
												{/* <th className="text-center">Status</th> */}
												{/* <th className="text-center">Action</th> */}
											</tr>
										</thead>
										<tbody>
											{loading ?
												<tr>
													<td colSpan={8}>
														<div className="d-flex justify-content-center align-items-center table-spinner">
															<Spinner animation="border" />
														</div>
													</td>
												</tr>
												: orders.length ?
													orders.map((item: any, index: any) => {
														return (
															<tr key={index}>
																<td className="text-center">{index + 1 + (pageNumber - 1) * pageLimit}</td>
																<td>
																	<div className='info-column'>
																		<OverlayTrigger overlay={<Tooltip id="">View Details</Tooltip>}>
																			<p className='info-title mb-1 text-ellipsis' >
																				<span className="link-text text-capitalize" onClick={() => this.props.history.push('/booking-details/:id'.replace(":id", item._id))}>{item.customerName}</span>

																			</p>
																		</OverlayTrigger>
																		<OverlayTrigger
																			placement={'top'}
																			overlay={
																				<Tooltip id='tooltip-email'>
																					{item.email}
																				</Tooltip>
																			}
																		>
																			<p className='mb-0 text-ellipsis'>
																				<i className='fas fa-envelope mr-1  text-teal'></i>
																				<span className='mr-2  text-dark'>Email:</span>
																				<span>{item.email}</span>
																			</p>
																		</OverlayTrigger>
																		<OverlayTrigger
																			placement={'top'}
																			overlay={
																				<Tooltip id='tooltip-address'>
																					{item.transactionId}
																				</Tooltip>
																			}
																		>
																			<p className='mb-0 text-ellipsis'>
																				<i className='far fa-credit-card mr-1  text-teal'></i>
																				<span className='mr-2  text-dark'>
																					Transaction ID:
																</span>
																				<span>
																					{item.transactionId}
																				</span>
																			</p>
																		</OverlayTrigger>
																	</div>
																</td>

																<td>
																	<div className='info-column'>

																		{/* <OverlayTrigger overlay={<Tooltip id="">View Details</Tooltip>}>
																			<p className='info-title mb-1 text-ellipsis' >
																				<span className="link-text" onClick={() => this.props.history.push('/booking-details/:id'.replace(":id", item._id))}>{item.bookingTitle}</span>

																			</p>
																		</OverlayTrigger> */}
																		<p className='mb-0'>
																			<i className='far fa-building mr-1  text-teal'></i>
																			<span className='mr-2  text-dark'>Provider:</span>
																			<span>{item.studioName}</span>
																		</p>
																		<OverlayTrigger
																			placement={'top'}
																			overlay={
																				<Tooltip id='tooltip-booking-title'>
																					{item.bookingTitle}
																				</Tooltip>
																			}
																		>
																			<p className='mb-0 text-ellipsis'>
																				<i className='fas fa-shopping-cart mr-1  text-teal'></i>
																				<span className='mr-2 text-dark'>Title:</span>
																				<span className="text-capitalize">{item.bookingTitle}</span>
																			</p>
																		</OverlayTrigger>
																		{item.serviceType ?
																			<p className='mb-0 text-ellipsis'>
																				<i className='fas fa-people-carry mr-1  text-teal'></i>
																				<span className='mr-2  text-dark'>Type:</span>
																				<span className="text-capitalize">{item.serviceType === 'service' ? 'Private Session' : item.serviceType === 'class' ? 'Group Class' : 'Wellness Event'}</span>
																			</p>
																			: null}
																		{/* <OverlayTrigger
																			placement={'top'}
																			overlay={
																				<Tooltip id='tooltip-address'>
																					{item.transactionId}
																				</Tooltip>
																			}
																		>
																			<p className='mb-0 text-ellipsis'>
																				<i className='far fa-credit-card mr-1  text-teal'></i>
																				<span className='mr-2  text-dark'>
																					Transaction ID:
																</span>
																				<span>
																					{item.transactionId}
																				</span>
																			</p>
																		</OverlayTrigger> */}
																	</div>
																</td>


																{/* <td className="text-capitalize">
																	{item.serviceType}
																</td> */}


																<td>
																	<span className="price-column">
																		{item.totalAmount ? (
																			<>${item.totalAmount.toFixed(2)}</>
																		) : (
																				<>$0.00</>
																			)}{" "}
																	</span>

																</td>
																<td>
																	<p className='mb-0'>
																		<i className='fas fa-calendar-alt mr-1  text-teal'></i>
																		<span className='mr-2  text-dark'>Date:</span>
																		<span>
																			{moment(item.createdAt).format('MM/DD/YYYY')}
																		</span>
																	</p>
																	<p className='mb-0'>
																		<i className='far fa-clock mr-1  text-teal'></i>
																		<span className='mr-2  text-dark'>
																			Time:
																</span>
																		<span>{moment(item.createdAt).format("hh:mm A")}</span>
																	</p>
																</td>
																{/* <td className="text-center">
																	<label style={{ width: 92 }}
																		className={"badge " +
																			(item.status === "Pending" ? "delay-badge"
																				:
																				item.status === "Inprocess" ? "inprocess-badge"
																					:
																					item.status === "Completed" ? "active-badge"
																						: "close-badge"
																			)}
																	>
																		{item.status}
																	</label>
																</td> */}
																{/* <td className="text-center">
																	<div className='action-buttons'>
																		<OverlayTrigger
																			placement={'top'}
																			overlay={
																				<Tooltip id='tooltip-view'>View Details</Tooltip>
																			}
																		>
																			<span
																				className='btn-icon'
																				onClick={() => this.props.history.push('/booking-details/:id'.replace(":id", item._id))}
																			>
																				<i className='fas fa-eye'></i>
																			</span>
																		</OverlayTrigger>
																	</div>
																</td> */}
															</tr>
														)
													})
													: (
														<tr>
															<td colSpan={7} className={"text-center"}>
																<div className={'no-result-block'}>
																	<div className='empty-ico'>
																		<img alt='Icon_Empty.svg' width='50' src={icon_empty} />
																	</div>
																	<h5>	No Booking Found</h5>
																</div>
															</td>
														</tr>
													)}
										</tbody>
									</table>

								</div>
								<div className="table-pagination-wrap">
									<div>
										<RCPagination
											className="custom-pagination-design"
											current={pageNumber}
											total={totalRecords}
											defaultPageSize={pageLimit}
											pageSize={pageLimit}
											hideOnSinglePage={true}
											showPrevNextJumpers={true}
											showSizeChanger={true}
											pageSizeOptions={['5', '10', '25', '100']}
											onChange={onPageChange}
											locale={localeInfo}
										/>
									</div>
									{totalRecords > 0 ? (
										<div className="records-filter">
											<div className="records-number">
												Displaying {firstRecordNo} - {lastRecordNo} of{' '}
												{totalRecords} records{' '}
											</div>
										</div>
									) : null}
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</div >
		);
	};
}

export default OrderTableComponent;