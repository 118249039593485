import React, { PureComponent } from 'react';
import { ISidebarProps, ISidebarStates } from './interface';
import SidebarComponent from './Sidebar.component';

class Sidebar extends PureComponent<ISidebarProps, ISidebarStates> {
	render() {
		return <SidebarComponent  {...this.props} />;
	}
}

export default Sidebar;
