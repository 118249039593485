import React, { Fragment, ChangeEvent, FormEvent, PureComponent } from 'react';
import { ApiHelper } from "../../../settings/helpers/ApiHelper";
import { IStudioIncomeProps, IStudioIncomeState } from './interface';
import StudioIncomeComponent from './StudioIncome.component';
import * as qs from 'query-string';
import debounce from 'lodash/debounce';
import { asyncSetState } from '../../../settings/helpers/Common';
import { withRouter } from 'react-router';

class StudioIncome extends PureComponent<IStudioIncomeProps, IStudioIncomeState> {
	state: IStudioIncomeState = {
		studioIncomes: [],
		totalPages: 0,
		totalRecords: 0,
		pageNumber: 1,
		pageLimit: 100,
		studioName: "",
		firstRecordNo: 0,
		lastRecordNo: 0,
		loading: true
	};

	componentDidMount = async () => {
		await this.getAllStudioIncomes();
		this.onChangeDebounced = debounce(this.onChangeDebounced, 500);
	}

	// Function to get all studio incomes
	getAllStudioIncomes = async () => {
		try {
			await asyncSetState(this)({ ...this.state, loading: true });

			const current = this.props.location.search;
			let search: any = {};
			search = { ...qs.parse(current) };
			let pageNumber = 1;
			let studioName = '';

			const currentPage = this.state.pageNumber;
			if (search.studioPage && parseInt(search.studioPage) !== currentPage) {
				pageNumber = parseInt(search.studioPage);
			}
			if (search.studioName) {
				studioName = search.studioName;
			}

			await asyncSetState(this)({
				...this.state,
				pageNumber,
				studioName
			});
			this.setState({
				loading: true,
				studioIncomes: [],
				totalRecords: 0
			});

			const res: any = await new ApiHelper().FetchFromServer(
				'/superadmin',
				`/finances/getAllStudioIncomes?page=${this.state.pageNumber}&limit=${this.state.pageLimit}&name=${this.state.studioName}`,
				'GET',
				true,
				undefined,
				undefined
			);

			if (res.data.data.length > 0) {
				this.setState({
					studioIncomes: res.data.data,
					totalRecords: res.data.totalCount,
					totalPages: res.data.totalPages,
					firstRecordNo: ((this.state.pageNumber - 1) * this.state.pageLimit) + 1,
					lastRecordNo: ((this.state.pageNumber - 1) * this.state.pageLimit) + res.data.data.length,
					loading: false
				});
			} else {
				this.setState({
					loading: false,
					studioIncomes: [],
					totalRecords: 0,
					totalPages: 0,
					firstRecordNo: 0,
					lastRecordNo: 0
				});
			}
		} catch (error) {
			this.setState({
				loading: false,
				studioIncomes: [],
				totalRecords: 0,
				totalPages: 0,
				firstRecordNo: 0,
				lastRecordNo: 0
			});
		}
	};

	// Function to change page number
	onPageChange = async (page: number) => {
		if (page !== this.state.pageNumber) {
			const current = this.props.location.search;
			let search: any = {};
			search = { ...qs.parse(current) };
			let categoryPage = 1;
			let categoryName = '';

			if (search.categoryPage && parseInt(search.categoryPage)) {
				categoryPage = parseInt(search.categoryPage);
			}
			if (search.categoryName) {
				categoryName = search.categoryName;
			}
			await this.props.history.push(
				`/finance?${qs.stringify({
					studioPage: page,
					studioName: this.state.studioName,
					categoryPage: categoryPage,
					categoryName: categoryName
				})}`
			);
			await this.getAllStudioIncomes();
		}
	};

	// Function to search records
	onSearch = async (e: FormEvent<HTMLFormElement> | undefined) => {
		if (e) {
			e.preventDefault();
		}
		new ApiHelper().cancelRequest(); // Cancel previous pending request if any

		const current = this.props.location.search;
		let search: any = {};
		search = { ...qs.parse(current) };
		let categoryPage = 1;
		let categoryName = '';

		if (search.categoryPage && parseInt(search.categoryPage)) {
			categoryPage = parseInt(search.categoryPage);
		}
		if (search.categoryName) {
			categoryName = search.categoryName;
		}
		await this.props.history.push(
			`/finance?${qs.stringify({
				studioPage: 1,
				studioName: this.state.studioName,
				categoryPage: categoryPage,
				categoryName: categoryName
			})}`
		);
		await this.getAllStudioIncomes();
	};

	// Function for search change
	onSearchChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		await asyncSetState(this)({ ...this.state, [name]: value });
		await this.onChangeDebounced();
	};

	onChangeDebounced = async () => {
		await this.onSearch(undefined); // Delayed function, call api to get searched values values
	};

	render() {
		return (
			<Fragment>
				<StudioIncomeComponent
					{...this.state}
					onPageChange={this.onPageChange}
					onSearchChange={this.onSearchChange}
				/>
			</Fragment>
		);
	}
}

export default withRouter(StudioIncome);