import React, { Component, Fragment, MouseEvent, FormEvent, ChangeEvent } from 'react'
import { Breadcrumb, Nav, Row, Col, } from 'react-bootstrap';
import * as qs from 'query-string';
import debounce from 'lodash/debounce';
import {
	logger,
	asyncSetState,
} from '../../../settings/helpers/Common';
import History from '../../../settings/helpers/History';
import { ApiHelper } from '../../../settings/helpers/ApiHelper';
import Service from './Service';
import TitleArrow from '../../../assets/img/title-arrow.svg';
import "./style.scss"
import CustomerDetailComponent from './CustmerDetail';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

class CustomerDeatil extends Component<any, any>{
	constructor(props: any) {
		super(props);
		this.state = {
			customerDetails: [],
			services: [],
			bookings: [],
			pageNumber: 1,
			pageLimit: 10,
			totalRecords: 0,
			title: "",
			customerId: "",
			isDetailLoading: true,
			isServiceLoading: false,
			firstRecordNo: 0,
			lastRecordNo: 0,
		}
		this.showModal = this.showModal.bind(this);
	}
	showModal(){
		this.setState({
			show: !this.state.show,
		});
	};
	componentDidMount = async () => {
		let search: any = {};
		search = { ...qs.parse(History.location.search) };
		if (search.id) {
			this.fetchCustomerData(search.id);
		}
		this.setState({ customerId: search.id },()=>this.getAllOrders())
		// await this.getAllOrders();
		this.onChangeDebounced = debounce(this.onChangeDebounced, 500);
	};
	componentDidUpdate = async (prevProps: any) => {
		if (prevProps.location !== this.props.location) {
			await this.getAllOrders();
		}
	};
	fetchCustomerData = async (customerId: string) => {
		try {
			await asyncSetState(this)({ ...this.state, isDetailLoading: true });
			const result: any = await new ApiHelper().FetchFromServer(
				'/superadmin',
				`/customers/getCustomerProfile?customerId=${customerId}`,
				'GET',
				true,
				undefined,
				{ customerId }
			);

			if (result.isError) {
				throw new Error(result.messages);
			}

			const { data } = result;
			if (data.statusCode === 200 && data.success) {
				if (data.data.length) {
					await asyncSetState(this)({
						...this.state,
						customerDetails: { ...data.data[0], cover: null },
						oldProfileImage: data.data[0].profileImage,
					});

					// const cover: File | null = await getImageBlob(data.data[0].profileImage);
					const cover: File | null = data.data[0].profileImageUrl;
					const customerData: any = {
						...data.data[0],
						cover,
					};
					await asyncSetState(this)({
						...this.state,
						customerDetails: customerData,
						//  customerId: customerData._id,
						oldProfileImage: customerData.profileImage,
						isDetailLoading: false,
					});
				}
			}
		} catch (error) {
			logger(error);
			this.setState(() => ({ ...this.state, isDetailLoading: false }));
		}
	};
	


	// Function to get all orders
	getAllOrders = async () => {
		try {
			console.log(this.state.customerId,'before')

			await asyncSetState(this)({ ...this.state, isServiceLoading: true });
			console.log(this.state.customerId,'after')

			let search: any = {};
			search = { ...qs.parse(History.location.search) };
			let pageNumber = 1;
			let name = '';

			const currentPage = this.state.pageNumber;
			if (search.page && parseInt(search.page) !== currentPage) {
				pageNumber = parseInt(search.page);
			}
			if (search.name) {
				name = search.name;
			}
			await asyncSetState(this)({
				...this.state,
				pageNumber,
				name,
				// customerId,
			});

			const res: any = await new ApiHelper().FetchFromServer(
				'/superadmin',
				`/customers/getCustomerService?page=${this.state.pageNumber}&limit=${this.state.pageLimit}&name=${this.state.title}&customerId=${search.id}`,
				'GET',
				true,
				undefined,
				undefined
			);

			if (res.data.data.length > 0) {
				this.setState({
					services: res.data.data,
					totalRecords: res.data.totalCount,
					totalPages: res.data.totalPages,
					firstRecordNo: ((this.state.pageNumber - 1) * this.state.pageLimit) + 1,
					lastRecordNo: ((this.state.pageNumber - 1) * this.state.pageLimit) + res.data.data.length,
					isServiceLoading: false
				});
			} else {
				this.setState({
					isServiceLoading: false,
					services: [],
					totalRecords: 0,
					totalPages: 0,
					firstRecordNo: 0,
					lastRecordNo: 0
				});
			}
		} catch (error) {
			this.setState({
				isServiceLoading: false,
				orders: [],
				totalRecords: 0,
				totalPages: 0,
				firstRecordNo: 0,
				lastRecordNo: 0,
				title: ""
			});
		}
	};
	// Function for search change
	onSearchChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		await asyncSetState(this)({ ...this.state, [name]: value });
		await this.onChangeDebounced();
	};
	onChangeDebounced = async () => {
		await this.onSearch(undefined); // Delayed function, call api to get searched values values
	};

	// Function to search records
	onSearch = async (e: FormEvent<HTMLFormElement> | undefined) => {
		if (e) {
			e.preventDefault();
		}
		new ApiHelper().cancelRequest(); // Cancel previous pending request if any
		this.props.history.push(
			`/customers/profile?${qs.stringify({
				page: 1,
				name: this.state.title,
				id: this.state.customerId
			})}`
		);
	};

	// Function to change page number
	onPageChange = async (page: number) => {
		console.log(this.state.customerId, 'onPageChange')

		if (page !== this.state.pageNumber) {
			await this.props.history.push(
				`/customers/profile?${qs.stringify({
					page: page,
					name: this.state.title,
					id: this.state.customerId
				})}`
			);
		}
	};
	render() {
		const { customerDetails, services, title, isServiceLoading, isDetailLoading, totalRecords,totalPages, pageLimit,firstRecordNo, lastRecordNo, pageNumber} = this.state
		console.log(this.state.customerId, 'bookingsbookings')
		return (
			<Fragment>
				<div className='page-header-wrap'>
					<div className='page-header'>
						<h3 className='page-title'>
							<span onClick={()=>History.push("/customers")}>
								<i className='fas fa-arrow-left link-text'></i>
							</span>
							<span className='heading-text'>Customer Details</span>
						</h3>
						<Breadcrumb>
							<Breadcrumb.Item
								onClick={(e: MouseEvent) => {
									e.preventDefault();
									History.push('/customers');
								}}
							>
								<span className='breadcrumb-icon'>
									{/* <img src={dashboard} alt='icon' /> */}
								</span>
								<span>Customer</span>
							</Breadcrumb.Item>
							<Breadcrumb.Item active>Customer Profile</Breadcrumb.Item>
						</Breadcrumb>
					</div>
				</div>
				{/* 
				<h2 className="main-title mb-3" onClick={()=>History.push("/customers")}><img src={TitleArrow} alt='Arrow' /> Customer Details</h2> 
					*/}
				<Row>
					{/* LEFT CARD */}
					<Col xl={4} lg={5} className='grid-margin'>
						<CustomerDetailComponent customerDetails={customerDetails} isDetailLoading={isDetailLoading} />
					</Col>
					{/* LEFT CARD */}
					{/* RIGHT CARD */}
					<Col xl={8} lg={7} className='grid-margin'>
						<Service title={title} onSearchChange={this.onSearchChange} services={services} isServiceLoading={isServiceLoading} totalRecords={totalRecords}totalPages={totalPages} pageLimit={pageLimit} onPageChange={this.onPageChange} firstRecordNo={firstRecordNo} lastRecordNo={lastRecordNo} pageNumber={pageNumber}/>
					</Col>
					{/* RIGHT CARD */}
				</Row>
				{/* Bank Info Modal */}
			
			</Fragment>
		)
	}
}

export default CustomerDeatil