import { ApiHelper } from "./ApiHelper";
import Swal from "sweetalert2";

import docicon from "../../assets/img/file-icon/doc.svg";
import docxicon from "../../assets/img/file-icon/docx.svg";
import pdficon from "../../assets/img/file-icon/pdf.svg";
import ppticon from "../../assets/img/file-icon/ppt.svg";
import txticon from "../../assets/img/file-icon/txt.svg";
import xlsxicon from "../../assets/img/file-icon/xlsx.svg";
import xlsicon from "../../assets/img/file-icon/xls.svg";
import xlsmicon from "../../assets/img/file-icon/xlsm.svg";
import xmlicon from "../../assets/img/file-icon/xml.svg";
import { isProd, AcceptedImageFormat } from "../config";

/**
 * logs the text only if the process mode is development
 */
export function logger(...args: any[]) {
  if (!isProd) {
    for (let index = 0; index < args.length; index++) {
      const data = args[index];
      console.log('LOG: -------------------------');
      console.log('LOG: ', data);
      console.log('LOG: -------------------------');
    }
  }
}

export const getImageBlob = async (name: string): Promise<File | null> => {
  try {
    const fileRes: any = await new ApiHelper().FetchFromServer(
      "/commons",
      "/files/downloadFile",
      "POST",
      false,
      undefined,
      { name },
      undefined,
      "blob"
    );

    if (fileRes.isError) {
      throw new Error(fileRes.messages);
    }

    const { data } = fileRes;
    const file: File = new File([data], name, {
      type: data.type
    });
    return file;
  } catch (error) {
    return null;
  }
};

export const blobImageUrl = (blob: any) => {
  if (!blob.url) {
    blob.url = URL.createObjectURL(blob);
  }
  return blob.url;
};

export const blobUrl = (blob: any) => {
  let url: any = blob
  if (blob && blob.name) {
    if (!blob.url) {
      url = URL.createObjectURL(blob);
    } else {
      url = blob.url;
    }
  }
  return url;
};

export const confirmBox = async (obj: object | any) => {
  if (!obj) {
    obj = {};
  }
  let {
    title,
    text,
    confirmButtonColor,
    cancelButtonColor,
    confirmButtonText
  } = obj;
  return await Swal.fire({
    title: title || "Are you sure?",
    text: text || "You want to be able to revert this!",
    type: "warning",
    showCancelButton: true,
    confirmButtonColor: confirmButtonColor || "#3085d6",
    cancelButtonColor: cancelButtonColor || "#d33",
    confirmButtonText: confirmButtonText || "Yes!"
  });
};

/**
 * Async Set State
 */
export const asyncSetState = (instance: any) => (newState: any) =>
  new Promise(resolve => instance.setState(newState, resolve));

export const getFileIconFromExt = (filename: any) => {
  let svgFile = '';
  if (filename) {
    if (filename.split(".").pop() === ("pdf" || "PDF")) {
      svgFile = pdficon;
    } else if (filename.split(".").pop() === ("docx" || "DOCX")) {
      svgFile = docxicon;
    } else if (filename.split(".").pop() === ("doc" || "DOC")) {
      svgFile = docicon;
    } else if (filename.split(".").pop() === ("xlsx" || "XLSX")) {
      svgFile = xlsxicon;
    } else if (filename.split(".").pop() === ("xls" || "XLS")) {
      svgFile = xlsicon;
    } else if (filename.split(".").pop() === ("xlsm" || "XLSM")) {
      svgFile = xlsmicon;
    } else if (filename.split(".").pop() === ("xml" || "XML")) {
      svgFile = xmlicon;
    } else if (filename.split(".").pop() === ("pptx" || "ppt" || "PPTX" || "PPT")) {
      svgFile = ppticon;
    } else if (filename.split(".").pop() === ("txt" || "TXT")) {
      svgFile = txticon;
    }
  }
  return svgFile;
}

// CHECK IMAGE RESOLUTION AND RETURN IT'S PASS VALUE
export const imageValidateAndSave = (
  file: File | null,
  setFieldValue: any,
  setFieldError: any,
  name: string,
  width?: number,
  height?: number
): void => {

  let newHeight: any = '';

  if (width !== height) {
    newHeight = width;
  } else {
    newHeight = height;
  }

  if (file && imageValidate((file || {}).type)) {
    checkResolution(file, width ? width : 1000, height ? height : 1000)
      .then((val: boolean) => {
        if (val) {
          setFieldValue(name, file);
        } else {
          setFieldError(
            name,
            `Please select high resolution image (${width ? width : 1000} x ${
            newHeight ? newHeight : 1000
            })`
          );
        }
      })
      .catch(err => logger(err));
  } else {
    setFieldError(name, 'Only PNG, JPG, JPEG, BMP & GIF are accepted');
  }
};

// CHECK RESOLUTION OF IMAGES
export const checkResolution = async (
  file: File,
  width: number,
  height: number
): Promise<boolean> => {
  const url: string = URL.createObjectURL(file),
    minImageWidth: number = width,
    minImageHeight: number = height;

  const imgResolution: {
    width: number;
    height: number;
  } = await imageProcess(url);

  if (
    imgResolution.width >= minImageWidth &&
    imgResolution.height >= minImageHeight
  ) {
    return true;
  } else {
    return false;
  }
};

// VALIDATION FOR IMAGE BY CHECKING FILE TYPE OF SELECTED FILE
export const imageValidate = (type: string): boolean => {
  const fileType: string = type
    .substring(type.lastIndexOf('/') + 1)
    .toLowerCase();

  let indx: number = 0;

  while (indx < AcceptedImageFormat.length) {
    const accFileType: string = AcceptedImageFormat[indx]
      .substring(type.lastIndexOf('/') + 1)
      .toLowerCase();

    if (fileType === accFileType) {
      return true;
    } else {
      indx++;
    }
  }

  return false;
};

// PROCESS IMAGE TO GET IT'S DIMENSIONS
const imageProcess = (
  src: string
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = reject;
    img.src = src;
  });
};